import { Injectable } from "@angular/core";
import { AppSessionService } from "../session/app-session.service";
import {
    CanActivate,
    Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    CanActivateChild,
    CanLoad
} from "@angular/router";
import { PermissionCheckerService } from "abp-ng2-module";
import { Observable, of } from "rxjs";

@Injectable()
export class AppRouteGuardV1 implements CanActivate, CanActivateChild, CanLoad {

    constructor(
        private _permissionChecker: PermissionCheckerService,
        private _router: Router,
        private _sessionService: AppSessionService,
    ) {
    }

    canActivateInternal(data: any, state: RouterStateSnapshot): Observable<boolean> {
        if (!this._sessionService.user) {
            return of(false);
        }

        if (!data || !data["permission"]) {
            return of(true);
        }

        if (this._permissionChecker.isGranted(data["permission"])) {
            return of(true);
        }

        this._router.navigate([this.selectBestRoute()]);
        return of(false);
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.canActivateInternal(route.data, state);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.canActivate(route, state);
    }

    canLoad(route: any): Observable<boolean> | Promise<boolean> | boolean {
        return this.canActivateInternal(route.data, null);
    }

    selectBestRoute(): string {
        if (!this._sessionService.user) {
            return "/login";
        }

        return "/";
    }
}
