import { Route } from "@angular/router";
import { RootComponent } from "./root.component";
import { InstallationHistoryComponent } from "./mobile/installation-history.component";
import { MauHistoryComponent } from "./mobile/mau-history.component";
import { PrescriptionHistoryComponent } from "./documents/prescription-history.component";

export const statisticsRoutes: Route[] = [
    {
        path: "",
        component: RootComponent
    },
    {
        path: "mobile/installation",
        component: InstallationHistoryComponent
    },
    {
        path: "mobile/mau",
        component: MauHistoryComponent
    },
    {
        path: "prescription/history",
        component: PrescriptionHistoryComponent
    },
];
