import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { TranslocoModule } from "@ngneat/transloco";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { StoreModule } from "@ngrx/store";
import * as fromStore from "./store/index";
import { EffectsModule } from "@ngrx/effects";
import { MatButtonModule } from "@angular/material/button";
import { MatDividerModule } from "@angular/material/divider";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatOptionModule } from "@angular/material/core";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatToolbarModule } from "@angular/material/toolbar";
import { VuiModule } from "@vui";
import { CreateFolderComponent } from "./create-folder/create-folder.component";
import { MatDialogModule } from "@angular/material/dialog";
import { UiDialogModule } from "@ui/ui-dialog";
import { MatInputModule } from "@angular/material/input";
import { A11yModule } from "@angular/cdk/a11y";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { ButtonToggleModule, FuseScrollbarModule } from "@nx-workspace/fuse";
import { MatMenuModule } from "@angular/material/menu";
import { EditFolderComponent } from "./edit-folder/edit-folder.component";
import { RecurrencePickerModule, DateFormFieldModule } from "@vui";
import { AbpHttpInterceptor } from "@api";
import { FolderPickerComponent } from "./folder-picker/reminder-folder-picker.component";
import { FolderPickerDialogComponent } from "./folder-picker/reminder-folder-picker-dialog.component";
import { MatTreeModule } from "@angular/material/tree";
import { ActivityFolderClient } from "../api";
import { CreateReminderComponent } from "./create-reminder/create-reminder.component";
import { EditReminderComponent } from "./edit-reminder/edit-reminder.component";
import { ReminderPickerComponent } from "./reminder-picker/reminder-picker.component";
import { ReminderPickerDialogComponent } from "./reminder-picker/reminder-picker-dialog.component";
import { MoveFolderDialogComponent } from "./folder-picker/move-folder-dialog/move-folder-dialog.component";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RecentActivityPickerComponent } from "./recent-activity-picker/recent-activity-picker.component";
import { MatAutocompleteModule } from "@angular/material/autocomplete";

@NgModule({
    declarations: [
        CreateFolderComponent,
        EditFolderComponent,
        CreateReminderComponent,
        EditReminderComponent,
        ReminderPickerDialogComponent,
        ReminderPickerComponent,
        FolderPickerComponent,
        FolderPickerDialogComponent,
        MoveFolderDialogComponent,
        RecentActivityPickerComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        TranslocoModule,

        StoreModule.forFeature(fromStore.REMINDERS_FEATURE_KEY, fromStore.getRemindersReducer),
        EffectsModule.forFeature([fromStore.ReminderEffects]),
        MatButtonModule,
        MatDividerModule,
        MatFormFieldModule,
        MatIconModule,
        MatOptionModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatSelectModule,
        MatSidenavModule,
        MatSortModule,
        MatTableModule,
        MatDialogModule,
        MatInputModule,
        MatCheckboxModule,
        MatToolbarModule,
        MatTooltipModule,
        MatAutocompleteModule,
        MatTreeModule,
        UiDialogModule,
        A11yModule,
        ButtonToggleModule,
        MatMenuModule,
        FuseScrollbarModule,
        VuiModule,
        DateFormFieldModule,
        RecurrencePickerModule,
    ],
    providers: [ActivityFolderClient, { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true }],
    exports: [
        CreateFolderComponent,
        EditFolderComponent,
        CreateReminderComponent,
        EditReminderComponent,
        ReminderPickerDialogComponent,
        ReminderPickerComponent,
        FolderPickerComponent,
        FolderPickerDialogComponent,
        RecentActivityPickerComponent,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ReminderPoolSharedModule {}
