import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { TranslocoModule } from "@ngneat/transloco";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { StoreModule } from "@ngrx/store";
import * as fromStore from "./store/index";
import { EffectsModule } from "@ngrx/effects";
import { MatButtonModule } from "@angular/material/button";
import { MatDividerModule } from "@angular/material/divider";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatOptionModule } from "@angular/material/core";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatToolbarModule } from "@angular/material/toolbar";
import { VuiModule } from "@vui";
import { CreateFolderComponent } from "./create-folder/create-folder.component";
import { MatDialogModule } from "@angular/material/dialog";
import { UiDialogModule } from "@ui/ui-dialog";
import { MatInputModule } from "@angular/material/input";
import { A11yModule } from "@angular/cdk/a11y";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { ButtonToggleModule, FuseScrollbarModule } from "@nx-workspace/fuse";
import { MatMenuModule } from "@angular/material/menu";
import { EditFolderComponent } from "./edit-folder/edit-folder.component";
import { CreateTemplateComponent } from "./create-template/create-template.component";
import { RecurrencePickerModule, DateFormFieldModule } from "@vui";
import { EditTemplateComponent } from "./edit-template/edit-template.component";
import { SmartFormPickerComponent } from "./form-definition-picker/smart-form-picker.component";
import { SmartFormPickerDialogComponent } from "./form-definition-picker/smart-form-picker-dialog.component";
import { AbpHttpInterceptor } from "@api";
import { FolderPickerComponent } from "./folder-picker/form-folder-picker.component";
import { FolderPickerDialogComponent } from "./folder-picker/form-folder-picker-dialog.component";
import { MatTreeModule } from "@angular/material/tree";
import { FormFolderClient } from "../api";
import { FormsWrapperModule } from "@vhealth/vforms-wrapper";
import { MoveFolderDialogComponent } from "./folder-picker/move-folder-dialog/move-folder-dialog.component";
import { MatTooltipModule } from "@angular/material/tooltip";

@NgModule({
    declarations: [
        CreateFolderComponent,
        EditFolderComponent,
        CreateTemplateComponent,
        EditTemplateComponent,
        SmartFormPickerComponent,
        SmartFormPickerDialogComponent,
        FolderPickerComponent,
        FolderPickerDialogComponent,
        MoveFolderDialogComponent,
    ],
    imports: [
        CommonModule,
        StoreModule.forFeature(fromStore.FORMS_FEATURE_KEY, fromStore.getSmartFormsReducer),
        EffectsModule.forFeature([fromStore.SmartFormTemplatesEffects]),

        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        TranslocoModule,

        MatButtonModule,
        MatDividerModule,
        MatFormFieldModule,
        MatIconModule,
        MatOptionModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatSelectModule,
        MatSidenavModule,
        MatSortModule,
        MatTableModule,
        MatDialogModule,
        MatInputModule,
        MatCheckboxModule,
        MatToolbarModule,
        MatTooltipModule,
        MatTreeModule,
        UiDialogModule,
        A11yModule,
        ButtonToggleModule,
        MatMenuModule,
        FuseScrollbarModule,
        VuiModule,
        DateFormFieldModule,
        RecurrencePickerModule,
        FormsWrapperModule,
    ],
    providers: [FormFolderClient, { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true }],
    exports: [
        CreateFolderComponent,
        EditFolderComponent,
        CreateTemplateComponent,
        EditTemplateComponent,
        SmartFormPickerComponent,
        SmartFormPickerDialogComponent,
        FolderPickerComponent,
        FolderPickerDialogComponent,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class FormPoolSharedModule {}
