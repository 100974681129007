export class CoreFeatures {
    static VPay = "Core.Features.VPay";
    static Financial = "Core.Features.Financial";
    static Marketing = "Core.Features.Marketing";
    static MarketingPush = "Core.Features.MarketingPush";
    static MobileAppBuilder = "Core.Features.MobileAppBuilder";
    static Report = "Core.Features.Report";

    static InternalChat = "CoreFeatures.InternalChat";
    static FeaturePushNotificationLegacy = "HealthFeatures.FeaturePushNotification";
    static FeaturePushNotification = "Core.Features.Marketing.Push";
    static Pwa = "Core.Features.Pwa";
    static NativeApp = "Core.Features.NativeApp";

    static ScheduleGoogleIntegration = "Health.Features.Schedule.Integration.Google";
    static Webhooks = "Core.Features.Webhooks";
}
