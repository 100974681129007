import { createAction, props } from "@ngrx/store";
import { createApiActions } from "@store/api-action";
import { ProtocolDefinition } from "../../model";
import { ProtocolFolder } from "./model";

export const loadFoldersAction = createApiActions("loads", "protocolFolders", {
    clientConfig: props<{ folder: ProtocolFolder }>(),
    errorConfig: props<{ error: any }>(),
    completeConfig: props<{ folders: ProtocolFolder[] }>(),
});

export const createProtocolFolderAction = createApiActions("createFolder", "protocols", {
    clientConfig: props<{ parentFolderId: number | undefined; name: string; isPublic: boolean }>(),
    errorConfig: props<{ error: any }>(),
    completeConfig: props<{ folder: ProtocolFolder }>(),
});

export const updateProtocolFolderAction = createApiActions("updateFolder", "protocols", {
    clientConfig: props<{ id: number; name: string; isPublic: boolean }>(),
    errorConfig: props<{ error: any }>(),
    completeConfig: props<{ folder: ProtocolFolder }>(),
});

export const deleteProtocolFolderAction = createApiActions("deleteFolder", "protocols", {
    clientConfig: props<{ id: number }>(),
    errorConfig: props<{ error: any }>(),
    completeConfig: props<{ id: number }>(),
});

export const createProtocolAction = createApiActions("create", "protocols", {
    clientConfig: props<{ parentFolderId: number | undefined; protocol: ProtocolDefinition }>(),
    errorConfig: props<{ error: any }>(),
    completeConfig: props<{ protocol: ProtocolDefinition }>(),
});

export const updateProtocolAction = createApiActions("update", "protocols", {
    clientConfig: props<{ protocol: ProtocolDefinition }>(),
    errorConfig: props<{ error: any }>(),
    completeConfig: props<{ protocol: ProtocolDefinition }>(),
});

export const deleteProtocolAction = createApiActions("delete", "protocols", {
    clientConfig: props<{ id: number }>(),
    errorConfig: props<{ error: any }>(),
    completeConfig: props<{ id: number }>(),
});

export const loadProtocolsAction = createApiActions("load", "protocols", {
    clientConfig: props<{ id: number }>(),
    errorConfig: props<{ error: any }>(),
    completeConfig: props<{ protocols: ProtocolDefinition[] }>(),
});

export const moveProtocolDefinitionAction = createApiActions("move", "protocols", {
    clientConfig: props<{ id: number; folderId: number }>(),
    errorConfig: props<{ error: any }>(),
    completeConfig: props<{ id: number; folderId: number }>(),
});

export const selectProtocolFolderAction = createAction("[protocols] select", props<{ id: number }>());

export const goBack = createAction("[protocols] goBack");
