export class HealthFeatures {
    static MedicalRecord = "Health.Features.MedicalRecord";
    static Prescription = "Health.Features.Prescription";
    static SmartPrescription = "Health.Features.SmartPrescription";
    static SmartPrescriptionV1 = "Health.Features.Prescription.Smart";
    static Exam = "Health.Features.Exam";
    static Files = "Health.Features.Files";
    static Schedule = "Health.Features.Schedule";
    static ReportAttendance = "Health.Features.Report.Attendance";
    static ReportSchedules = "Health.Features.Report.Schedules";
    static Dashboard = "HealthFeatures.FeatureDashboard";
    static Engage = "Health.Features.Engage";

    public static isSmartPrescriptionEnabled() {
        return abp.features.isEnabled(HealthFeatures.SmartPrescription) || abp.features.isEnabled(HealthFeatures.SmartPrescriptionV1);
    }
}
