import {Component, ViewEncapsulation} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";

@Component({
    selector   : 'fuse-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls  : ['./confirm-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FuseConfirmDialogComponent
{
    public allowCancel: boolean = true;
    public title: string;
    public confirmMessage: string;
    public positiveButtonText: string;
    public cancelButtonText: string;
    public negativeButtonText: string;
    
    public static ActionNegative = 'negative';
    public static ActionCancel = 'cancel';
    public static ActionPossitive = 'possitive';

    /**
     * Constructor
     *
     * @param {MatDialogRef<FuseConfirmDialogComponent>} dialogRef
     */
    constructor(
        public dialogRef: MatDialogRef<FuseConfirmDialogComponent>
    )
    {
    }

}
