import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { TranslocoCoreModule } from "@i18n";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { DateFormFieldComponent } from "./date-form-field.component";
import { IMaskModule } from "angular-imask";
import { ReactiveFormsModule } from "@angular/forms";

@NgModule({
    declarations: [
        DateFormFieldComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        TranslocoCoreModule,
        IMaskModule,
    ],
    exports: [
        DateFormFieldComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DateFormFieldModule {}
