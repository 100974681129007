import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BackLinkComponent } from "./back-link/back-link.component";
import { RouterModule } from "@angular/router";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";

@NgModule({
    imports: [CommonModule, RouterModule, MatIconModule, MatButtonModule],
    declarations: [BackLinkComponent],
    exports: [BackLinkComponent],
})
export class BackLinkModule {
}
