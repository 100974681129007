import { createReducer, on } from "@ngrx/store";
import { ProtocolsState } from "./model";
import * as actions from "./actions";
import { createFolderTree, findFolderById, flattenFolderDatasource } from "../../utils";

export const protocolState: ProtocolsState = {
    loading: false,
    loadingDetails: false,
    updating: false,
    deleting: false,
    creatingFolder: false,
    creating: false,
    error: null,
    folders: [],
    selectedFolderProtocols: [],
    selected: undefined,
};

export const getProtocolsReducer = createReducer(
    protocolState,
    on(actions.loadFoldersAction.execute, (state: ProtocolsState, { folder }) => {
        return {
            ...state,
            loading: true,
        };
    }),
    on(actions.loadFoldersAction.complete, (state: ProtocolsState, { folders }) => {
        return {
            ...state,
            loading: false,
            folders: folders,
        };
    }),
    on(actions.loadFoldersAction.failure, (state: ProtocolsState, { error }) => {
        return {
            ...state,
            error: error,
            loading: false,
        };
    }),
    on(actions.createProtocolFolderAction.execute, (state: ProtocolsState, { parentFolderId, name, isPublic }) => {
        return {
            ...state,
            creatingFolder: true,
        };
    }),
    on(actions.createProtocolFolderAction.complete, (state: ProtocolsState, { folder }) => {
        let flatFolderDatasource = [...flattenFolderDatasource(state.folders), folder];
        let folders = createFolderTree(flatFolderDatasource);
        return {
            ...state,
            folders: folders,
            creatingFolder: false,
        };
    }),
    on(actions.createProtocolFolderAction.failure, (state: ProtocolsState, { error }) => {
        return {
            ...state,
            error: error,
            creatingFolder: false,
        };
    }),
    on(actions.updateProtocolFolderAction.execute, (state: ProtocolsState) => {
        return {
            ...state,
            updating: true,
        };
    }),
    on(actions.updateProtocolFolderAction.complete, (state: ProtocolsState, { folder }) => {
        let flatFolderDatasource = [
            ...flattenFolderDatasource(state.folders).filter((f) => f.id !== folder.id),
            folder,
        ];
        let folders = createFolderTree(flatFolderDatasource);
        return {
            ...state,
            updating: false,
            folders: folders,
        };
    }),
    on(actions.updateProtocolFolderAction.failure, (state: ProtocolsState, { error }) => {
        return {
            ...state,
            error: error,
            updating: false,
        };
    }),

    on(actions.deleteProtocolFolderAction.execute, (state: ProtocolsState) => {
        return {
            ...state,
            deleting: true,
        };
    }),
    on(actions.deleteProtocolFolderAction.complete, (state: ProtocolsState, { id }) => {
        let flatFolderDatasource = flattenFolderDatasource(state.folders).filter((f) => f.id !== id);
        let folders = createFolderTree(flatFolderDatasource);
        return {
            ...state,
            deleting: false,
            selected: state.selected?.id === id ? undefined : state.selected,
            folders: folders,
        };
    }),
    on(actions.deleteProtocolFolderAction.failure, (state: ProtocolsState, { error }) => {
        return {
            ...state,
            error: error,
            deleting: false,
        };
    }),
    on(actions.selectProtocolFolderAction, (state: ProtocolsState, { id }) => {
        return {
            ...state,
            selected: findFolderById(id, state.folders),
        };
    }),
    on(actions.loadProtocolsAction.execute, (state: ProtocolsState) => {
        return {
            ...state,
            loadingDetails: true,
        };
    }),
    on(actions.loadProtocolsAction.complete, (state: ProtocolsState, { protocols }) => {
        return {
            ...state,
            loadingDetails: false,
            selectedFolderProtocols: protocols,
        };
    }),
    on(actions.loadProtocolsAction.failure, (state: ProtocolsState, { error }) => {
        return {
            ...state,
            error: error,
            loadingDetails: false,
            selectedFolderProtocols: [],
            selected: undefined,
        };
    }),
    on(actions.createProtocolAction.execute, (state: ProtocolsState, { parentFolderId }) => {
        return {
            ...state,
            creating: true,
        };
    }),
    on(actions.createProtocolAction.complete, (state: ProtocolsState, { protocol }) => {
        return {
            ...state,
            creating: false,
            selectedFolderProtocols: [...state.selectedFolderProtocols, protocol],
        };
    }),
    on(actions.createProtocolAction.failure, (state: ProtocolsState, { error }) => {
        return {
            ...state,
            error: error,
            creating: false,
        };
    }),
    on(actions.updateProtocolAction.execute, (state: ProtocolsState) => {
        return {
            ...state,
            updating: true,
        };
    }),
    on(actions.updateProtocolAction.complete, (state: ProtocolsState, { protocol }) => {
        return {
            ...state,
            updating: false,
            selectedFolderProtocols: state.selectedFolderProtocols.map((e) => (e.id === protocol.id ? protocol : e)),
        };
    }),
    on(actions.updateProtocolAction.failure, (state: ProtocolsState, { error }) => {
        return {
            ...state,
            error: error,
            updating: false,
        };
    }),

    on(actions.deleteProtocolAction.execute, (state: ProtocolsState) => {
        return {
            ...state,
            deleting: true,
        };
    }),
    on(actions.deleteProtocolAction.complete, (state: ProtocolsState, { id }) => {
        return {
            ...state,
            deleting: false,
            selectedFolderProtocols: state.selectedFolderProtocols.filter((e) => e.id !== id),
        };
    }),
    on(actions.deleteProtocolAction.failure, (state: ProtocolsState, { error }) => {
        return {
            ...state,
            error: error,
            deleting: false,
        };
    }),
    on(actions.moveProtocolDefinitionAction.execute, (state: ProtocolsState) => {
        return {
            ...state,
            updating: true,
        };
    }),
    on(actions.moveProtocolDefinitionAction.complete, (state: ProtocolsState, { id, folderId }) => {
        return {
            ...state,
            updating: false,
            selectedFolderProtocols: state.selectedFolderProtocols.filter((f) => f.id !== id),
        };
    }),
    on(actions.moveProtocolDefinitionAction.failure, (state: ProtocolsState, { error }) => {
        return {
            ...state,
            error: error,
            updating: false,
        };
    })
);
