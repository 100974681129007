import { Injectable, Injector, OnDestroy } from "@angular/core";
import {
    NotificationServiceProxy,
    ObjectPagedResultDto,
    UserNotification,
} from "@shared/service-proxies/service-proxies";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { AppSessionService } from "@shared/session/app-session.service";
import * as moment from "moment";
import { takeUntil } from "rxjs/internal/operators";
import { NotificationParser } from "@app/wapps/notification/notification.parser";
import { AppointmentNotificationParser } from "@app/wapps/calendar/appointment.notification.parser";
import { Notification } from "@app/wapps/notification/notification.model";
import { AppointmentCallFileReceivedNotificationParser } from "@app/wapps/health-care/online/appointment.call.file.notification.parser";
import { ProcedureFormsNotificationParser } from "../health/procedure/forms/procedure.forms.notification.parser";

export const NotificationType = {
    Appointment: "Wapps.Notification.Data.SentAppointmentNotificationData",
    PreProcedureFormResponse: "Wapps.Application.Health.Procedures.PreForms.Notification.PreFormResponseNotificationData",
    PrescriptionUpdateCommentsOrder: "Wapps.Application.Health.Prescriptions.UpdateCommentsOrder.UpdateCommentsOrderNotificationData"
};

@Injectable()
export class NotificationService implements OnDestroy {
    notificationParsers: Map<string, NotificationParser>;

    maxRecentNotifications: number = 10;

    recentNotifications: Notification[];

    onRecentNotificationsChanged: BehaviorSubject<any[]>;

    onNotificationOpened: Subject<boolean> = new Subject();

    notifications: any[];

    notificationSelected: any;

    onNotificationsChanged: BehaviorSubject<any>;

    onNotificationSelected: BehaviorSubject<any>;

    badgeCount: number = 0;

    onBadgeUpdated: BehaviorSubject<number>;

    onNotificationArrived: BehaviorSubject<any>;

    onRefresh: BehaviorSubject<boolean>;

    private _unsubscribeAll: Subject<any>;

    constructor(
        injector: Injector,
        private _notificationProxy: NotificationServiceProxy,
        private appSession: AppSessionService,
    ) {
        this.recentNotifications = [];
        this.notificationParsers = new Map<string, NotificationParser>();
        this.notificationParsers.set(AppointmentNotificationParser.Type, new AppointmentNotificationParser(injector));
        this.notificationParsers.set(ProcedureFormsNotificationParser.Type, new ProcedureFormsNotificationParser(injector));
        this.notificationParsers.set(
            AppointmentNotificationParser.SilentType,
            new AppointmentNotificationParser(injector),
        );
        this.notificationParsers.set(
            AppointmentCallFileReceivedNotificationParser.Type,
            new AppointmentCallFileReceivedNotificationParser(injector),
        );

        this.onNotificationsChanged = new BehaviorSubject(this.notifications);

        this.onNotificationSelected = new BehaviorSubject(this.notificationSelected);

        this.onRefresh = new BehaviorSubject(false);

        this._unsubscribeAll = new Subject();

        this.onBadgeUpdated = new BehaviorSubject(0);

        this.onNotificationArrived = new BehaviorSubject(null);

        this.onRecentNotificationsChanged = new BehaviorSubject([]);

        abp.event.on("abp.notifications.received", userNotification => {
            if (!!userNotification && userNotification.notification.data.type === AppointmentNotificationParser.Type) {
                this.updateBadge(this.badgeCount + 1);
                this.addToRecentNotification(userNotification);
            }

            if (!!userNotification && userNotification.notification.data.type === ProcedureFormsNotificationParser.Type) {
                this.updateBadge(this.badgeCount + 1);
                this.addToRecentNotification(userNotification);
            }

            this.onNotificationArrived.next(userNotification);
        });

        this.appSession.onSessionChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(user => {
            if (user) {
                this.reloadRecentNotifications();
            } else {
                this.updateBadge(0);
            }
        });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    private reloadRecentNotifications() {
        this._notificationProxy.getRecentNotifications().subscribe(result => {
            try {
                this.recentNotifications = [];
                result.notifications.forEach(userNotification => {
                    let parser = this.notificationParsers.get(userNotification.notification.data.type);
                    if (!!parser) {
                        this.recentNotifications.push(parser.parse(userNotification));
                    }
                });
                this.maxRecentNotifications = result.maxNumberOfRecentNotifications;
                this.updateBadge(result.unread);
                this.onRecentNotificationsChanged.next(this.recentNotifications);
            } catch (e) {
            }
        });
    }

    private addToRecentNotification(notification: UserNotification) {
        let parser = this.notificationParsers.get(notification.notification.data.type);
        if (!this.recentNotifications) {
            this.recentNotifications = [];
        }
        let parsedNotification = parser.parse(notification);
        this.recentNotifications = [parsedNotification, ...this.recentNotifications.slice(0, this.maxRecentNotifications - 1)];
        this.onRecentNotificationsChanged.next(this.recentNotifications);
    }

    private updateBadge(count: number) {
        this.badgeCount = count > 0 ? count : 0;
        this.onBadgeUpdated.next(this.badgeCount);
    }

    handleOpenNotification(notification: Notification) {
        let parser = this.notificationParsers.get(notification.userNotification.notification.data.type);
        parser.open(notification);
        this.onNotificationOpened.next(true);
        // Mark as read (dec badge for an quick effect)
        this.updateBadge(this.badgeCount - 1);
        return this._notificationProxy.setRead(notification.userNotification.id).subscribe(() => {
            this.reloadRecentNotifications();
        });
    }

    setRead(id): Promise<boolean> {
        //Mark as read (dec badge for an quick effect)
        let notification = this.recentNotifications.find(n => n.id === id);
        return new Promise<boolean>((resolve, reject) => {
            this._notificationProxy.setRead(id).subscribe(
                () => {
                    if (!!notification) {
                        const index = this.recentNotifications.indexOf(notification);
                        this.recentNotifications.splice(index, 1);
                        this.onRecentNotificationsChanged.next(this.recentNotifications);
                        this.updateBadge(this.badgeCount - 1);
                        this.reloadRecentNotifications();
                    }
                    resolve(true);
                },
                error1 => {
                    reject(error1);
                },
            );
        });
    }

    delete(id): Promise<boolean> {
        let notification = this.recentNotifications.find(n => n.id === id);
        return new Promise<boolean>((resolve, reject) => {
            this._notificationProxy.delete(id).subscribe(
                () => {
                    if (!!notification) {
                        const index = this.recentNotifications.indexOf(notification);
                        this.recentNotifications.splice(index, 1);
                        this.onRecentNotificationsChanged.next(this.recentNotifications);
                        this.updateBadge(this.badgeCount - 1);
                        this.reloadRecentNotifications();
                    }
                    resolve(true);
                },
                error1 => {
                    reject(error1);
                },
            );
        });
    }

    deleteAll(): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this._notificationProxy.deleteAll().subscribe(
                () => {
                    this.recentNotifications = [];
                    this.onRecentNotificationsChanged.next(this.recentNotifications);
                    this.updateBadge(0);
                    this.reloadRecentNotifications();
                    resolve(true);
                },
                error1 => {
                    reject(error1);
                },
            );
        });
    }

    setReadAll(): Promise<boolean> {
        //Mark as read (dec badge for a quick effect)
        return new Promise<boolean>((resolve, reject) => {
            this._notificationProxy.setReadAll().subscribe(
                () => {
                    this.recentNotifications = [];
                    this.onRecentNotificationsChanged.next(this.recentNotifications);
                    this.updateBadge(0);
                    this.reloadRecentNotifications();
                    resolve(true);
                },
                error1 => {
                    reject(error1);
                },
            );
        });
    }

    getNotifications(skipCount: number, maxResult: number): Observable<ObjectPagedResultDto> {
        return this._notificationProxy.getAll(null, skipCount, maxResult);
    }

    parse(items: UserNotification[]): Notification[] {
        let notifications: Notification[] = [];
        items.forEach(userNotification => {
            let parser = this.notificationParsers.get(userNotification.notification.data.type);
            if (!!parser) {
                notifications.push(parser.parse(userNotification));
            }
        });
        return notifications;
    }

    parseNotification(userNotification: UserNotification): Notification {
        let parser = this.notificationParsers.get(userNotification.notification.data.type);
        if (!!parser) {
            return parser.parse(userNotification);
        }
        return null;
    }

    reset() {
        this.notifications = undefined;
        return new Promise((resolve, reject) => {
            resolve(true);
        });
    }

    refresh() {
        this.onRefresh.next(true);
    }

    selectNotification(params) {
        this.notificationSelected = params;
        this.onNotificationSelected.next(this.notificationSelected);
    }

    getDate(date: any): string {
        var today = new Date();
        let dateM = moment(date);

        var diffMs = (today as any) - (dateM.local().toDate() as any); // milliseconds between now and date
        var diffDays = Math.floor(diffMs / 86400000); // days
        var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
        var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes

        if (diffDays > 0) return diffDays.toString() + "d";
        else if (diffHrs > 0) {
            return diffHrs.toString() + "h";
        } else {
            return diffMins.toString() + "m";
        }
    }
}
