import { Component, Inject, Injector, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { finalize, takeUntil } from 'rxjs/operators';
import { BaseDialogComponent } from '../../core/base-dialog/base-dialog.component';
import { PendingTermsOfServiceSignatureService } from './pending-terms-of-service-signature.service';
import { FuseProgressBarService } from '../../../../@fuse/components/progress-bar/progress-bar.service';

@Component({
    selector: 'pending-terms-of-service-signature',
    templateUrl: './pending-terms-of-service-signature.component.html',
})
export class PendingTermsOfServiceSignatureComponent
    extends BaseDialogComponent<PendingTermsOfServiceSignatureComponent>
    implements OnInit, OnDestroy
{
    protected _unsubscribeAll = new Subject();

    termPage = false;

    disabled = true;

    description: Promise<string>;

    content: Promise<string>;

    signing: boolean;

    constructor(
        injector: Injector,
        private _service: PendingTermsOfServiceSignatureService,
        public dialogRef: MatDialogRef<PendingTermsOfServiceSignatureComponent>,
        private _progressBarService: FuseProgressBarService,
        @Inject(MAT_DIALOG_DATA) public data
    ) {
        super(injector, dialogRef);
    }

    ngOnInit(): void {
        this._service.termPageInfoEmitter$.pipe(takeUntil(this._unsubscribeAll)).subscribe((info) => {
            this.termPage = info;
        });

        this._service.disabled$.pipe(takeUntil(this._unsubscribeAll)).subscribe((disabled) => {
            this.disabled = this.signing || disabled;
        });

        this.description = this._service.fetchUrl(this.data.descriptionLink);
        this.content = this._service.fetchUrl(this.data.contentLink);
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    next() {
        this._service.next();
    }

    sign() {
        this._progressBarService.show();
        this.signing = this.disabled = true;
        this._service
            .sign(this.data.id)
            .pipe(
                finalize(() => {
                    this.signing = this.disabled = false;
                    this._progressBarService.hide();
                })
            )
            .subscribe((signature) => {
                if (signature) {
                    this.notify.success('Termo assinado com sucesso!');
                    this.dialogRef.close();
                }
            });
    }

    handleScroll(event) {
        this._service.onScroll(event);
    }
}
