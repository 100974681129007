import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class PhoneMessagingService {

    linkForContact(phone: string) {
        return `${abp.setting.get("Core.Messaging.AppUrlScheme")}/${phone}`;
    }

}
