import { props } from "@ngrx/store";
import { createApiActions } from "@store/api-action";
import { Protocol, ProtocolItemDefinition, ProtocolDefinition } from "../../model";

export const loadFeedAction = createApiActions("load", "activityFeed", {
    clientConfig: props<{ patientId: string }>(),
    errorConfig: props<{ error: any }>(),
    completeConfig: props<{ protocols: Protocol[] }>(),
});

export const createProtocolAction = createApiActions("create", "activityFeed", {
    clientConfig: props<{ patientId: string; name: string; activities: ProtocolItemDefinition[]; sign: boolean }>(),
    errorConfig: props<{ error: any }>(),
    completeConfig: props<{ protocol: Protocol }>(),
});

export const downloadProtocolDocumentAction = createApiActions("downloadDocument", "activityFeed", {
    clientConfig: props<{ id: number; name: string }>(),
    errorConfig: props<{ error: any }>(),
});

export const printProtocolDocumentAction = createApiActions("printDocument", "activityFeed", {
    clientConfig: props<{ id: number }>(),
    errorConfig: props<{ error: any }>(),
});

export const showProtocolDocumentAction = createApiActions("showDocument", "activityFeed", {
    clientConfig: props<{ id: number }>(),
    errorConfig: props<{ error: any }>(),
});

export const deleteProtocolAction = createApiActions("delete", "activityFeed", {
    clientConfig: props<{ id: number }>(),
    errorConfig: props<{ error: any }>(),
    completeConfig: props<{ id: number }>(),
});

export const createProtocolTemplateAction = createApiActions("createTemplate", "activityFeed", {
    clientConfig: props<{ parentFolderId: number | undefined; template: ProtocolDefinition }>(),
    errorConfig: props<{ error: any }>(),
    completeConfig: props<{ protocol: ProtocolDefinition }>(),
});

export const sendProtocolDocumentByEmailAction = createApiActions("sendDocumentByEmail", "activityFeed", {
    clientConfig: props<{ id: number }>(),
    errorConfig: props<{ error: any }>(),
});
