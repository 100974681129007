import { Inject, Injectable } from '@angular/core';
import {AppConsts} from "@shared/AppConsts";
import {environment} from "../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class FacebookPixelService {
    private script: string = "";
    private browserGlobals = {
        windowRef(): any {
            return window;
        },
        documentRef(): any {
            return document;
        }
    };

    constructor() {
        if (environment.production){
            this.script = "!function(f,b,e,v,n,t,s) {if(f.fbq)return;n=f.fbq=function(){n.callMethod? n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script', 'https://connect.facebook.net/en_US/fbevents.js');fbq('init', '#pixelId');fbq('track', 'PageView');";
        }
    }

    facebookPixelScript: any;

    facebookPixelNoScript: any;
    
    public addPixelCode() {
        if(AppConsts.FacebookPixelId && AppConsts.FacebookPixelId.length > 0) {
            const doc = this.browserGlobals.documentRef();
            this.facebookPixelScript = doc.createElement('script');
            this.facebookPixelScript.id = "fbps";
            this.facebookPixelScript.innerHTML = this.script.replace("#pixelId", AppConsts.FacebookPixelId);
            doc.getElementsByTagName('head')[0].appendChild(this.facebookPixelScript);

            this.facebookPixelNoScript = doc.createElement('noscript');
            this.facebookPixelNoScript.id = "fbpns";
            let img = document.createElement('img');
            img.height = 1;
            img.width = 1;
            img.style.display = "none";
            img.src = "https://www.facebook.com/tr?id=#pixelId&ev=PageView&noscript=1".replace("#pixelId", AppConsts.FacebookPixelId);
            this.facebookPixelNoScript.appendChild(img);
            doc.getElementsByTagName('head')[0].appendChild(this.facebookPixelNoScript);
        }
    }
    
    public removePixelCode() {
        const doc = this.browserGlobals.documentRef();
        if(this.facebookPixelScript) {
            doc.getElementsByTagName('head')[0].removeChild(this.facebookPixelScript);
        }
        if(this.facebookPixelNoScript) {
            doc.getElementsByTagName('head')[0].removeChild(this.facebookPixelNoScript);
        }
    }

}