<div class="absolute inset-0 flex flex-col min-w-0 overflow-y-auto" cdkScrollable>

  <!-- Main -->
  <div class="flex-auto p-2 sm:p-4 bg-gray-100">

    <!-- CONTENT GOES HERE -->

    <div class="flex flex-col space-y-2">
      <div class="flex flex-row space-x-2 items-center py-3">
        <button mat-icon-button matTooltip="Voltar" routerLink="../../">
          <mat-icon svgIcon="heroicons_outline:arrow-left"></mat-icon>
        </button>
        <span class="text-2xl font-bold">Evolução Prescrições Mensais</span>
      </div>

      <div class="flex flex-col space-y-2 p-4 bg-white shadow-sm rounded">
        <div class="flex flex-col">
          <span class="text-2xl font-bold">{{total}}</span>
          <span>Total</span>
        </div>
        <apx-chart *ngIf="chartOptions"
                [series]="chartOptions.series"
                [chart]="chartOptions.chart"
                [xaxis]="chartOptions.xaxis"
                [dataLabels]="chartOptions.dataLabels"
                [grid]="chartOptions.grid"
                [stroke]="chartOptions.stroke"
        ></apx-chart>
      </div>
      
    </div>
    
    

  </div>

</div>
