<div class="flex flex-row space-x-8">

  <div class="flex flex-col p-4 bg-white shadow-sm rounded w-1/2">
    <div class="flex flex-row items-center place-content-between">
      <span class="text-xl font-bold">Instalações</span>
      <button mat-icon-button matTooltip="Acompanhar evolução" color="primary"
              routerLink="mobile/installation">
        <mat-icon svgIcon="heroicons_outline:presentation-chart-line"></mat-icon>
      </button>
    </div>

    <apx-chart class="w-full h-full" *ngIf="chartOptions"
               [series]="chartOptions.series"
               [chart]="chartOptions.chart"
               [labels]="chartOptions.labels"
               [legend]="chartOptions.legend"
               [plotOptions]="chartOptions.plotOptions"
               [colors]="chartOptions.colors"
    ></apx-chart>
  </div>

  <div class="flex flex-col p-4 bg-white shadow-sm rounded w-1/2">
    <div class="flex flex-row items-center place-content-between">
      <span class="text-xl font-bold">Usuários Ativos Mensais</span>
      <button mat-icon-button matTooltip="Acompanhar evolução" color="primary"
              routerLink="mobile/mau">
        <mat-icon svgIcon="heroicons_outline:presentation-chart-line"></mat-icon>
      </button>
    </div>

    <apx-chart class="w-full h-full" *ngIf="monthlyActiveUsersChartOptions"
               [series]="monthlyActiveUsersChartOptions.series"
               [chart]="monthlyActiveUsersChartOptions.chart"
               [labels]="monthlyActiveUsersChartOptions.labels"
               [legend]="monthlyActiveUsersChartOptions.legend"
               [plotOptions]="monthlyActiveUsersChartOptions.plotOptions"
               [colors]="monthlyActiveUsersChartOptions.colors"
    ></apx-chart>
  </div>

</div>
