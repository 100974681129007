import { createFeatureSelector, createSelector } from "@ngrx/store";
import { STATS_SESSIONS_FEATURE_KEY } from "./reducer";
import { IStatsSessionsState } from "./model";

export const statsSessionsState = createFeatureSelector<IStatsSessionsState>(STATS_SESSIONS_FEATURE_KEY);

export const selectInactiveClients = createSelector(statsSessionsState, (state) => state.items);

export const selectIsLoading = createSelector(statsSessionsState, (state) => state.loading);

export const selectPeriod = createSelector(statsSessionsState, (state) => state.period);

export const exportInactiveClients = createSelector(statsSessionsState, (state) => state.period);