import { createSelector, createFeatureSelector } from "@ngrx/store";
import { NOTIFICATION_SETTINGS_FEATURE_KEY } from "./base";
import { NotificationSettingsState } from "./model";

export const notificationSettingsFeatureState = createFeatureSelector<NotificationSettingsState>(
    NOTIFICATION_SETTINGS_FEATURE_KEY
);

export const isLoading = createSelector(notificationSettingsFeatureState, (state) => state.loading);

export const allowPreFormResponseNotifications = createSelector(notificationSettingsFeatureState, (state) => state.allowPreFormResponseNotifications);

export const allowScheduleNotifications = createSelector(notificationSettingsFeatureState, (state) => state.allowScheduleNotifications);


