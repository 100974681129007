import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";

export enum NavbarStatus {
    Expanded = 1,
    Collapsed = 2,
    Hidden = 3
}

@Injectable()
export class Layout1Service {
    
    _navbarOpened: boolean;

    _navbarFolded: boolean;
    
    _navbarStatus: NavbarStatus;
    
    onNavbarStatusChanged: BehaviorSubject<NavbarStatus> = new BehaviorSubject<NavbarStatus>(null);
    
    constructor() {       
        
    }

    set navbarOpened(value: boolean) {
        this._navbarOpened = value;
        this.recalculateStatus();
    }

    set navbarFolded(value: boolean) {
        this._navbarFolded = value;
        this.recalculateStatus();
    }
    
    recalculateStatus() {
        if(!this._navbarOpened) {
            this.navbarStatus = NavbarStatus.Hidden;
        } else if (this._navbarFolded) {
            this.navbarStatus = NavbarStatus.Collapsed;
        } else {
            this.navbarStatus = NavbarStatus.Expanded;
        }
    }
    
    get navbarStatus() {
        return this._navbarStatus;
    }

    set navbarStatus(status: NavbarStatus) {
        this._navbarStatus = status;
        this.onNavbarStatusChanged.next(status);
    }
}