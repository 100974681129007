import { Inject, Injectable, Optional } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap } from "rxjs/operators";
import * as actions from "./actions";
import { Observable, of } from "rxjs";
import { assign } from "lodash";
import {
    CreateProtocolDefinitionRequest,
    PatientClient,
    ProtocolClient,
    ProtocolDefinitionDto,
    ProtocolDto,
    ProtocolFolderClient,
    SMART_FORMS_API_BASE_URL,
} from "../../api";
import { Protocol, ProtocolDefinition } from "../../model";
import RRule from "rrule";
import { addLocalOffset } from "../../utils";
import { HttpClient, HttpHeaders, HttpResponse, HttpResponseBase } from "@angular/common/http";

@Injectable()
export class FeedEffects {
    currProtocolId = 1;

    baseUrl;

    constructor(
        private actions$: Actions,
        private _protocolsApi: ProtocolClient,
        private _foldersApi: ProtocolFolderClient,
        private _patientApi: PatientClient,
        private _httpClient: HttpClient,
        @Optional() @Inject(SMART_FORMS_API_BASE_URL) baseUrl?: string
    ) {
        this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : "http://localhost:5000";
    }

    load$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(actions.loadFeedAction.execute),
                mergeMap(({ patientId }) =>
                    this._protocolsApi.list(patientId).pipe(
                        map((result: ProtocolDto[]) =>
                            actions.loadFeedAction.complete({
                                protocols: result.map((r) => {
                                    return assign(new Protocol(), {
                                        ...r,
                                        creationTime: new Date(r.creationTime),
                                        items: [
                                            ...r.items.map((item) => {
                                                let itemRecurrence = RRule.fromString(item.recurrence);
                                                let options = { ...itemRecurrence.options };
                                                if (options.byhour && options.byhour.length > 0) {
                                                    options.byhour = [
                                                        ...options.byhour.map((hour) => addLocalOffset(hour)),
                                                    ];
                                                }

                                                let newRule = new RRule(options);

                                                return assign(item, {
                                                    ...item,
                                                    recurrence: newRule.toString(),
                                                });
                                            }),
                                        ],
                                    });
                                }),
                            })
                        ),
                        catchError((error) => of(actions.loadFeedAction.failure({ error: error })))
                    )
                )
            ),
        { dispatch: true }
    );

    downloadDocument$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(actions.downloadProtocolDocumentAction.execute),
                mergeMap(({ id, name }) => {
                    let url = this.baseUrl + `/api/services/app/health/engage/protocol/${id}/export`;

                    let options: any = {
                        observe: "response",
                        responseType: "blob",
                        headers: new HttpHeaders({
                            "Content-Type": "application/json",
                            Accept: "application/json",
                        }),
                        body: {
                            name: name,
                        },
                    };

                    return this._httpClient.request("post", url, options).pipe(
                        mergeMap((response: any) => {
                            return this.processFileBlob(response);
                        }),
                        mergeMap((blob: Blob) => {
                            let url = window.URL.createObjectURL(blob);
                            let a = document.createElement("a");
                            a.href = url;
                            a.download = name;
                            a.click();
                            window.URL.revokeObjectURL(url);
                            return of(actions.downloadProtocolDocumentAction.complete());
                        }),
                        catchError((error) => of(actions.downloadProtocolDocumentAction.failure({ error: error })))
                    );
                })
            ),
        { dispatch: true }
    );

    printDocument$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(actions.printProtocolDocumentAction.execute),
                mergeMap(({ id }) => {
                    let url = this.baseUrl + `/api/services/app/health/engage/protocol/${id}/export`;

                    let options: any = {
                        observe: "response",
                        responseType: "blob",
                        headers: new HttpHeaders({
                            "Content-Type": "application/json",
                            Accept: "application/json",
                        }),
                        body: {},
                    };

                    return this._httpClient.request("post", url, options).pipe(
                        mergeMap((response: any) => {
                            return this.processFileBlob(response);
                        }),
                        mergeMap((blob: Blob) => {
                            let url = window.URL.createObjectURL(blob);
                            let iframe = document.createElement("iframe");
                            iframe.style.display = "none";
                            iframe.src = url;
                            document.body.appendChild(iframe);
                            iframe.contentWindow?.print();
                            return of(actions.printProtocolDocumentAction.complete());
                        }),
                        catchError((error) => of(actions.printProtocolDocumentAction.failure({ error: error })))
                    );
                })
            ),
        { dispatch: true }
    );

    showDocument$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(actions.showProtocolDocumentAction.execute),
                mergeMap(({ id }) => {
                    let url = this.baseUrl + `/api/services/app/health/engage/protocol/${id}/export`;

                    let options: any = {
                        observe: "response",
                        responseType: "blob",
                        headers: new HttpHeaders({
                            "Content-Type": "application/json",
                            Accept: "application/json",
                        }),
                        body: {},
                    };

                    return this._httpClient.request("post", url, options).pipe(
                        mergeMap((response: any) => {
                            return this.processFileBlob(response);
                        }),
                        map((blob: Blob) => {
                            let url: any = window.URL.createObjectURL(blob);
                            window.open(url, "_blank");
                            return actions.showProtocolDocumentAction.complete();
                        }),
                        catchError((error) => of(actions.showProtocolDocumentAction.failure({ error: error })))
                    );
                })
            ),
        { dispatch: true }
    );

    deleteFolder$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(actions.deleteProtocolAction.execute),
                mergeMap(({ id }) =>
                    this._protocolsApi.delete(id).pipe(
                        map(() => actions.deleteProtocolAction.complete({ id })),
                        catchError((error) => of(actions.deleteProtocolAction.failure({ error: error })))
                    )
                )
            ),
        { dispatch: true }
    );

    createProtocolDefinition$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(actions.createProtocolAction.execute),
                mergeMap(({ patientId, activities, name, sign }) => {
                    return this._protocolsApi
                        .create({ name: name, targetPatients: [patientId], targetTags: [], items: activities })
                        .pipe(
                            map((result: ProtocolDto[]) =>
                                actions.createProtocolAction.complete({
                                    protocol: assign(new Protocol(), result[0]),
                                })
                            ),
                            catchError((error) => of(actions.createProtocolTemplateAction.failure({ error: error })))
                        );
                })
            ),
        { dispatch: true }
    );

    createProtocolTemplate$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(actions.createProtocolTemplateAction.execute),
                mergeMap(({ parentFolderId, template }) => {
                    let request: CreateProtocolDefinitionRequest = {
                        parent: parentFolderId,
                        items: template.items.map((i) => {
                            return {
                                ...i,
                                discriminator:
                                    i.discriminator === "ActivityItemDto"
                                        ? "ActivityItemDefinitionDto"
                                        : "FormItemDefinitionDto",
                            };
                        }),
                        name: template.name,
                        isPublic: template.isPublic,
                    };
                    return this._foldersApi.createProtocol(parentFolderId, request).pipe(
                        map((result: ProtocolDefinitionDto) =>
                            actions.createProtocolTemplateAction.complete({
                                protocol: assign(new Protocol(), result[0]),
                            })
                        ),
                        catchError((error) => of(actions.createProtocolTemplateAction.failure({ error: error })))
                    );
                })
            ),
        { dispatch: true }
    );

    protected processFileBlob(response: HttpResponseBase): Observable<Blob> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse
                ? response.body
                : (<any>response).error instanceof Blob
                    ? (<any>response).error
                    : undefined;

        let _headers: any = {};
        if (response.headers) {
            for (let key of response.headers.keys()) {
                _headers[key] = response.headers.get(key);
            }
        }
        if (status === 200) {
            return of(responseBlob);
        }
        return of<Blob>(<any>null);
    }
}
