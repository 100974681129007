import { createAction, props } from "@ngrx/store";
import { createApiActions } from "@store/api-action";
import { SmartFormDefinition } from "@vhealth/engage";
import { SmartFormFolder } from "./model";

export const loadSmartFormsAction = createApiActions("loads", "smartForms", {
    clientConfig: props<{ folder: SmartFormFolder }>(),
    errorConfig: props<{ error: any }>(),
    completeConfig: props<{ folders: SmartFormFolder[] }>(),
});

export const createSmartFormFolderAction = createApiActions("createFolder", "smartForms", {
    clientConfig: props<{ parentFolderId: number | undefined; name: string; isPublic: boolean }>(),
    errorConfig: props<{ error: any }>(),
    completeConfig: props<{ folder: SmartFormFolder }>(),
});

export const updateSmartFormFolderAction = createApiActions("updateFolder", "smartForms", {
    clientConfig: props<{ id: number; name: string; isPublic: boolean }>(),
    errorConfig: props<{ error: any }>(),
    completeConfig: props<{ folder: SmartFormFolder }>(),
});

export const deleteSmartFormFolderAction = createApiActions("deleteFolder", "smartForms", {
    clientConfig: props<{ id: number }>(),
    errorConfig: props<{ error: any }>(),
    completeConfig: props<{ id: number }>(),
});

export const createSmartFormDefinitionTemplateAction = createApiActions("create", "smartForms", {
    clientConfig: props<{ smartFormDefinition: SmartFormDefinition }>(),
    errorConfig: props<{ error: any }>(),
    completeConfig: props<{ smartFormDefinition: SmartFormDefinition }>(),
});

export const updateSmartFormAction = createApiActions("update", "smartForms", {
    clientConfig: props<{ smartFormDefinition: SmartFormDefinition }>(),
    errorConfig: props<{ error: any }>(),
    completeConfig: props<{ smartFormDefinition: SmartFormDefinition }>(),
});

export const moveFormDefinitionAction = createApiActions("move", "smartForms", {
    clientConfig: props<{ id: number; folderId: number }>(),
    errorConfig: props<{ error: any }>(),
    completeConfig: props<{ id: number; folderId: number }>(),
});

export const deleteSmartFormAction = createApiActions("delete", "smartForms", {
    clientConfig: props<{ id: number }>(),
    errorConfig: props<{ error: any }>(),
    completeConfig: props<{ id: number }>(),
});

export const loadSmartFormDefinitionsAction = createApiActions("load", "smartForms", {
    clientConfig: props<{ id: number }>(),
    errorConfig: props<{ error: any }>(),
    completeConfig: props<{ smartFormDefinitions: SmartFormDefinition[] }>(),
});

export const goBack = createAction("[smartForms] goBack");

export const select = createAction("[smartForms] select", props<{ folderId: number }>());
