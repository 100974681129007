import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class CustomCookieService {
    constructor() {
    }

    set(name: string, value: string, days: number): void {
        let expires = "";
        if (days) {
            const date = new Date();
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    }

    get(name: string): string | null {
        const nameEQ = name + "=";
        const ca = document.cookie.split(";");
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            // tslint:disable-next-line:triple-equals
            while (c.charAt(0) == " ") c = c.substring(1, c.length);
            // tslint:disable-next-line:triple-equals
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    delete(name: string): void {
        document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    }
}