import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Component, OnInit, ViewEncapsulation, OnDestroy, Inject } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Actions, ofType } from "@ngrx/effects";
import { NotifyService } from "@ui/notify";
import { SmartFormPoolFacade } from "../store/facade";
import { FormBuilder, FormGroup } from "@angular/forms";
import { VuiValidators } from "@vui";
import { updateSmartFormFolderAction } from "../store/actions";
import { SmartFormFolder } from "../store";
import { TranslocoService } from "@ngneat/transloco";

@Component({
    selector: "edit-folder",
    templateUrl: "./edit-folder.component.html",
    encapsulation: ViewEncapsulation.None,
})
export class EditFolderComponent implements OnInit, OnDestroy {
    private _unsubscribeAll = new Subject();

    isUpdating$: Observable<boolean>;

    form: FormGroup;

    constructor(
        private _dialogRef: MatDialogRef<EditFolderComponent>,
        @Inject(MAT_DIALOG_DATA) public _data: SmartFormFolder,
        private _formBuilder: FormBuilder,
        private _facade: SmartFormPoolFacade,
        private actions$: Actions,
        private _notifyService: NotifyService,
        private _translateService: TranslocoService
    ) {
        this.isUpdating$ = this._facade.updating$;

        this.form = this._formBuilder.group({
            id: [this._data.id, VuiValidators.required],
            name: [this._data.name, VuiValidators.required],
            isPublic: [this._data.isPublic],
        });
    }

    ngOnInit() {
        this.actions$
            .pipe(ofType(updateSmartFormFolderAction.complete), takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this._notifyService.success(
                    this._translateService.translate("health.engage.form-pool.update-folder.successfully-message")
                );
                this._dialogRef.close();
            });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    save() {
        if (this.form.valid) {
            let data = this.form.getRawValue();
            this._facade.updateFolder(data.id, data.name, data.isPublic);
        }
    }

    closeDialog() {
        this._dialogRef.close();
    }
}
