import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import {
    selectCurrent,
    selectIsLoading,
    selectIsCreating,
    selectIsUpdating,
    selectAtLeastOneFolderExists,
    selectFolders,
    selectSelectedFolderItems,
    selectAtLeastOneFormExists,
    selectIsLoadingDetails,
} from "./selectors";
import { ReminderFolder, RemindersState } from "./model";
import * as actions from "./actions";
import { assign } from "lodash";
import { take } from "rxjs/operators";
// import { ActivityItemDefinition } from "../../api";
import { ActivityItemDefinition } from "../../model";

@Injectable({
    providedIn: "root",
})
export class ReminderPoolFacade {
    _loading$: Observable<boolean> = this._store.select(selectIsLoading);

    _loadingDetails$: Observable<boolean> = this._store.select(selectIsLoadingDetails);

    _folders$: Observable<ReminderFolder[]> = this._store.select(selectFolders);

    _selected$: Observable<ReminderFolder> = this._store.select(selectCurrent);

    selectedFolderItems$: Observable<ActivityItemDefinition[]> = this._store.select(selectSelectedFolderItems);

    isThereAnyForm$: Observable<boolean> = this._store.select(selectAtLeastOneFormExists);

    _isThereAnyData$: Observable<boolean> = this._store.select(selectAtLeastOneFolderExists);

    creating$: Observable<boolean> = this._store.select(selectIsCreating);

    updating$: Observable<boolean> = this._store.select(selectIsUpdating);

    constructor(private _store: Store<RemindersState>) {}

    get loading$() {
        return this._loading$;
    }

    get folders$() {
        return this._folders$;
    }

    get selected$() {
        return this._selected$;
    }

    root() {
        let folder = assign(new ReminderFolder(), {
            id: 0,
            name: "Modelos",
            isPublic: true,
            folders: [],
            Reminders: [],
        });
        this.navigate(folder);
    }

    navigate(folder: ReminderFolder, loadReminders: boolean = false) {
        this._store.dispatch(actions.loadReminderFoldersAction.execute({ folder }));
    }

    select(folderId: number) {
        this._store.dispatch(actions.select({ folderId }));
    }

    goBack() {
        this._store.dispatch(actions.goBack());
    }

    createFolder(name: string, isPublic: boolean, parentFolderId: number = null) {
        this._store.dispatch(
            actions.createReminderFolderAction.execute({
                parentFolderId,
                name,
                isPublic,
            })
        );
    }

    updateFolder(id: number, name: string, isPublic: boolean) {
        this._store.dispatch(actions.updateReminderFolderAction.execute({ id, name, isPublic }));
    }

    deleteFolder(id: number) {
        this._store.dispatch(actions.deleteReminderFolderAction.execute({ id }));
    }

    createTemplate(item: ActivityItemDefinition) {
        this._store
            .select(selectCurrent)
            .pipe(take(1))
            .subscribe((folder) => {
                this._store.dispatch(
                    actions.createReminderDefinitionAction.execute({
                        parentFolderId: folder.id,
                        item,
                    })
                );
            });
    }

    updateDefinitonTemplate(item: ActivityItemDefinition) {
        this._store.dispatch(actions.updateReminderDefinitionAction.execute({ item }));
    }

    moveReminderDefinition(id: number, folderId: number) {
        this._store.dispatch(actions.moveReminderDefinitionAction.execute({ id, folderId }));
    }

    deleteTemplate(id: number) {
        this._store.dispatch(actions.deleteReminderDefinitionAction.execute({ id }));
    }

    loadReminderAction(folderId: number) {
        this._store.dispatch(actions.loadReminderDefinitionsAction.execute({ id: folderId }));
    }
}
