import { ProtocolDefinition } from "../../model";
import { FolderDto } from "../../utils";

export class ProtocolFolder implements FolderDto {
    id: number | undefined;

    name: string | undefined;

    isPublic: boolean | undefined;

    folders: ProtocolFolder[] = [];

    parentFolderId: number | undefined;
}

export enum FeedStatus {
    Hidden = 0,
    PatientHidden = 1,
    Visible = 2,
}

export interface ProtocolsState {
    loading: boolean;

    loadingDetails: boolean;

    creatingFolder: boolean;

    creating: boolean;

    updating: boolean;

    deleting: boolean;

    error: null;

    folders: ProtocolFolder[] | undefined;

    selectedFolderProtocols: ProtocolDefinition[] | undefined;

    selected: ProtocolFolder | undefined;
}
