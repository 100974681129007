import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";

import {
    ApexChart,
    ApexLegend,
    ApexNonAxisChartSeries,
    ApexPlotOptions,
    ChartComponent,
} from "ng-apexcharts";
import { Subject } from "rxjs";
import { MobileStatsFacade } from "./store/facade";
import { takeUntil } from "rxjs/operators";

export type ChartOptions = {
    series: ApexNonAxisChartSeries;
    chart: ApexChart;
    labels: any;
    legend: ApexLegend;
    plotOptions: ApexPlotOptions;
    colors: string[]
};

@Component({
    selector: "mobile-summary",
    templateUrl: "./summary.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileSummaryComponent implements OnInit, OnDestroy {
    private _unsubscribeAll = new Subject();

    @ViewChild("chart") chart: ChartComponent;

    public chartOptions: Partial<ChartOptions>;

    public monthlyActiveUsersChartOptions: Partial<ChartOptions>;

    constructor(private _changeDetector: ChangeDetectorRef,
                private _facade: MobileStatsFacade,) {
    }

    ngOnInit(): void {
        this._facade.installations$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(installations => {
                this.chartOptions = {
                    chart: {
                        type: "donut",
                        animations: {
                            enabled: false
                        }
                    },
                    series: installations?.map(i => i.value),
                    labels: installations?.map(i => i.label),
                    colors: installations?.map(i => i.color),
                    legend: {
                        position: "bottom",
                        horizontalAlign: "center"
                    },
                    plotOptions: {
                        pie: {
                            donut: {
                                size: "60%",
                                labels: {
                                    show: true,
                                    total: {
                                        showAlways: false,
                                        show: true,
                                        // label: "",
                                        color: "#000000"
                                    }
                                }
                            }
                        }
                    },
                };
                this._changeDetector.markForCheck();
            });

        this._facade.mau$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(monthlyActiveUsers => {
                this.monthlyActiveUsersChartOptions = {
                    chart: {
                        type: "donut",
                        animations: {
                            enabled: false
                        }
                    },
                    series: monthlyActiveUsers?.map(i => i.value),
                    labels: monthlyActiveUsers?.map(i => i.label),
                    colors: monthlyActiveUsers?.map(i => i.color),
                    legend: {
                        position: "bottom",
                        horizontalAlign: "center"
                    },
                    plotOptions: {
                        pie: {
                            donut: {
                                size: "60%",
                                labels: {
                                    show: true,
                                    total: {
                                        showAlways: false,
                                        show: true,
                                        color: "#000000"
                                    }
                                }
                            }
                        }
                    }
                };
                this._changeDetector.markForCheck();
            });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
