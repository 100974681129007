import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import * as ApiProxies from "./proxies";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { AbpHttpInterceptor } from "@api";

@NgModule({
    imports: [CommonModule],
    providers: [
        ApiProxies.MobileApi,
        ApiProxies.PrescriptionApi,
        ApiProxies.SessionApi,
        ApiProxies.AppointmentsApi,
        { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true },
    ],
})
export class ApiModule {}
