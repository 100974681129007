import { Injectable } from "@angular/core";
import { ComponentStore } from "@ngrx/component-store";
import { assign } from "lodash";
import { take, takeUntil } from "rxjs/operators";
import { FormItemDefinition, ProtocolDefinition, ActivityItemDefinition } from "@vhealth/engage";
import { Actions, ofType } from "@ngrx/effects";
import { Subject, combineLatest } from "rxjs";
import { ProtocolFacade } from "../store/facade";
import { updateProtocolAction } from "../store/actions";
import RRule from "rrule";
import { RecurrenceHelper } from "@vui";

export interface EditProtocolState {
    protocol: ProtocolDefinition | undefined;
    activityItems: ActivityItemDefinition[];
    formItems: FormItemDefinition[];
    editing: boolean;
    editingItem: ActivityItemDefinition | undefined;
    editingForm: FormItemDefinition | undefined;
    loading: boolean;
    updating: boolean;
}

@Injectable()
export class EditProtocolComponentStore extends ComponentStore<EditProtocolState> {
    private _unsubscribeAll = new Subject();

    currentId: number = 1;

    constructor(private _facade: ProtocolFacade, private _actions$: Actions) {
        super({
            protocol: undefined,
            activityItems: [],
            formItems: [],
            editing: false,
            editingItem: undefined,
            editingForm: undefined,
            loading: false,
            updating: false,
        });

        this._actions$.pipe(ofType(updateProtocolAction.complete), takeUntil(this._unsubscribeAll)).subscribe(() => {
            this.setState((state) => {
                return {
                    ...state,
                    updating: false,
                };
            });
        });
        this._actions$.pipe(ofType(updateProtocolAction.failure), takeUntil(this._unsubscribeAll)).subscribe(() => {
            this.setState((state) => {
                return {
                    ...state,
                    updating: false,
                };
            });
        });
    }

    readonly loading$ = this.select((state) => state.loading || state.updating);

    readonly protocol$ = this.select((state) => state.protocol);

    readonly formItems$ = this.select((state) => state.formItems);

    readonly activityItems$ = this.select((state) => state.activityItems);

    readonly editing$ = this.select((state) => state.editing);

    readonly editingItem$ = this.select((state) => state.editingItem);

    readonly editingForm$ = this.select((state) => state.editingForm);

    readonly isValid$ = this.select(
        (state) =>
            state.formItems.filter((l) => l.storeId > 0).length > 0 ||
            state.activityItems.filter((l) => l.storeId > 0).length > 0
    );

    ngOnDestroy() {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
        super.ngOnDestroy();
    }

    edit(protocol: ProtocolDefinition) {
        this.setState((state) => {
            return {
                ...state,
                protocol,
            };
        });
    }

    addForm(form: FormItemDefinition) {
        this.setState((state) => {
            return {
                ...state,
                formItems: [...state.formItems, form],
                editingForm: form,
                editing: true,
            };
        });
    }

    addActivity(activity: ActivityItemDefinition) {
        this.setState((state) => {
            return {
                ...state,
                activityItems: [...state.activityItems, activity],
                editingItem: activity,
                editing: true,
            };
        });
    }

    startActivityEdition(activity: ActivityItemDefinition) {
        this.editingItem$.pipe(take(1)).subscribe((editingActivity: ActivityItemDefinition) => {
            if (editingActivity) {
                this.cancelActivityEdition(editingActivity, activity);
            } else {
                this.setState((state) => {
                    return {
                        ...state,
                        editingItem: activity,
                        editing: true,
                    };
                });
            }
        });
    }

    startFormEdition(activity: FormItemDefinition) {
        this.editingForm$.pipe(take(1)).subscribe((editingActivity: FormItemDefinition) => {
            if (editingActivity) {
                this.cancelFormEdition(editingActivity, activity);
            } else {
                this.setState((state) => {
                    return {
                        ...state,
                        editingForm: activity,
                        editing: true,
                    };
                });
            }
        });
    }

    acceptActivityChanges(activity) {
        this.activityItems$.pipe(take(1)).subscribe((activities) => {
            let index = activities.findIndex((a) => a.storeId === activity.storeId);

            let updatedActivity = assign(new FormItemDefinition(), activity);

            if (updatedActivity.storeId === 0 || !updatedActivity.storeId) {
                updatedActivity.storeId = this.currentId++;
            }

            if (index >= 0) {
                this.currentId++;
                activities.splice(index, 1, updatedActivity);
                this.setState((state) => {
                    return {
                        ...state,
                        activityItems: [
                            ...activities.sort((a, b) => (a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1)),
                        ],
                        editing: false,
                        editingItem: undefined,
                    };
                });
            } else {
                this.currentId++;
                this.setState((state) => {
                    activities.splice(activities.length - 1, 1, updatedActivity);
                    return {
                        ...state,
                        activityItems: [
                            ...activities.sort((a, b) => (a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1)),
                        ],
                        editing: false,
                        editingItem: undefined,
                    };
                });
            }
        });
    }

    acceptFormChanges(activity: FormItemDefinition) {
        this.formItems$.pipe(take(1)).subscribe((activities) => {
            let index = activities.findIndex((i) => i.storeId === activity.storeId);

            let updatedActivity = assign(new FormItemDefinition(), activity);

            if (updatedActivity.storeId === 0 || !updatedActivity.storeId) {
                updatedActivity.storeId = this.currentId++;
            }

            if (index >= 0) {
                this.currentId++;
                activities.splice(index, 1, updatedActivity);
                this.setState((state) => {
                    return {
                        ...state,
                        formItems: [
                            ...activities.sort((a, b) => (a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1)),
                        ],
                        editing: false,
                        editingForm: undefined,
                    };
                });
            } else {
                this.currentId++;
                this.setState((state) => {
                    activities.splice(activities.length - 1, 1, updatedActivity);
                    return {
                        ...state,
                        formItems: [
                            ...activities.sort((a, b) => (a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1)),
                        ],
                        editing: false,
                        editingForm: undefined,
                    };
                });
            }
        });
    }

    cancelActivityEdition(
        activity: ActivityItemDefinition,
        selectForEdition: ActivityItemDefinition | undefined = undefined
    ) {
        this.activityItems$.pipe(take(1)).subscribe((activities) => {
            let index = activities.findIndex((i) => i.storeId === activity.storeId);
            if (index >= 0) {
                if (!activity.storeId || activity.storeId === 0) {
                    activities.splice(index, 1);
                }
                this.setState((state) => {
                    return {
                        ...state,
                        activityItems: [...activities],
                        editing: !!selectForEdition,
                        editingItem: selectForEdition,
                    };
                });
            }
        });
    }

    cancelFormEdition(activity: FormItemDefinition, selectForEdition: FormItemDefinition | undefined = undefined) {
        this.formItems$.pipe(take(1)).subscribe((activities) => {
            let index = activities.findIndex((i) => i.storeId === activity.storeId);
            if (index >= 0) {
                if (!activity.storeId || activity.storeId === 0) {
                    activities.splice(index, 1);
                }
                this.setState((state) => {
                    return {
                        ...state,
                        formItems: [...activities],
                        editing: !!selectForEdition,
                        editingForm: selectForEdition,
                    };
                });
            }
        });
    }

    deleteActivity(activity: ActivityItemDefinition) {
        this.activityItems$.pipe(take(1)).subscribe((activities) => {
            let index = activities.findIndex((i) => i.storeId === activity.storeId);
            if (index >= 0) {
                activities.splice(index, 1);
                this.currentId--;
                this.setState((state) => {
                    return {
                        ...state,
                        activityItems: [...activities],
                        editing: false,
                        editingItem: undefined,
                    };
                });
            }
        });
    }

    deleteForm(activity: FormItemDefinition) {
        this.formItems$.pipe(take(1)).subscribe((activities) => {
            let index = activities.findIndex((i) => i.storeId === activity.storeId);
            if (index >= 0) {
                activities.splice(index, 1);
                this.currentId--;
                this.setState((state) => {
                    return {
                        ...state,
                        formItems: [...activities],
                        editing: false,
                        editingForm: undefined,
                    };
                });
            }
        });
    }

    save(data: ProtocolDefinition) {
        this.setState((state) => ({
            ...state,
            updating: true,
        }));
        combineLatest([this.activityItems$, this.formItems$])
            .pipe(take(1))
            .subscribe(([activities, forms]) => {
                let template: ProtocolDefinition = assign(new ProtocolDefinition(), {
                    ...data,
                    items: [
                        ...activities.map((a) => {
                            let activityRecurence = RRule.fromString(a.recurrence);

                            let newRule = RecurrenceHelper.checkForLocalOffsetRemoval(activityRecurence);

                            return assign(a, {
                                ...a,
                                comments: a.comments ?? "",
                                recurrence: RRule.optionsToString(newRule.options),
                            });
                        }),
                        ...forms.map((f) => {
                            let formRecurence = RRule.fromString(f.recurrence);
                            
                            let newRule = RecurrenceHelper.checkForLocalOffsetRemoval(formRecurence);

                            return assign(f, {
                                ...f,
                                recurrence: RRule.optionsToString(newRule.options),
                            });
                        }),
                    ],
                });
                this._facade.update(template);
            });
    }
}
