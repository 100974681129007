import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { environment } from "../environments/environment";
import { ErrorHandler, Injectable } from "@angular/core";
import { AngularPlugin } from "@microsoft/applicationinsights-angularplugin-js";
import { Router } from "@angular/router";

@Injectable()
export class MonitoringService {

    private angularPlugin = new AngularPlugin();

    private appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: environment.instrumentationKey,
            extensions: [this.angularPlugin],
            extensionConfig: {
                [this.angularPlugin.identifier]: {
                    router: this.router,
                    errorServices: [new ErrorHandler()],
                },
            },
        },
    });

    constructor(private router: Router) {
        this.appInsights.loadAppInsights();
    }

    logPageView(name?: string, url?: string) { // option to call manually
        this.appInsights.trackPageView({
            name: name,
            uri: url
        });
    }

    logEvent(name: string, properties?: { [key: string]: any }) {
        this.appInsights.trackEvent({name: name}, properties);
    }

    logMetric(name: string, average: number, properties?: { [key: string]: any }) {
        this.appInsights.trackMetric({name: name, average: average}, properties);
    }

    logException(exception: Error, severityLevel?: number) {
        this.appInsights.trackException({exception: exception, severityLevel: severityLevel});
    }

    logTrace(message: string, properties?: { [key: string]: any }) {
        this.appInsights.trackTrace({message: message}, properties);
    }
}
