import { createMask } from "imask";

export const createFiscalIdentifierMask = () =>
    createMask({
        mask: [
            {
                mask: "000.000.000-00",
            },
            {
                mask: "00.000.000/0000-00",
            },
        ],
    });

export const createBusinessFiscalIdentifierMask = () => createMask({ mask: "00.000.000/0000-00" });

export const createPersonalIdentifierMask = () => createMask({ mask: "000.000.000-00" });