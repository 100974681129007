import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Injector,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import { Observable, ReplaySubject, Subject } from "rxjs";
import { ThemePalette } from "@angular/material/core";
import { ProtocolFacade } from "../store/facade";
import { ProtocolFolder, actions } from "../store";
import { MatDialog } from "@angular/material/dialog";
import { take, takeUntil } from "rxjs/operators";
import { FormControl, FormGroup } from "@angular/forms";
import { FuseConfirmationService } from "@nx-workspace/fuse";
import { TranslocoService } from "@ngneat/transloco";
import { DatePipe } from "@angular/common";
import { MoveFolderDialogComponent } from "../folder-picker/move-folder-dialog/move-folder-dialog.component";
import { Router } from "@angular/router";
import { ProtocolDefinition } from "../../model";
import { CreateProtocolComponent } from "../create-template/create-template.component";
import { EditProtocolComponent } from "../edit-template/edit-template.component";
import { NotifyService } from "@ui/notify";
import { Actions, ofType } from "@ngrx/effects";

@Component({
    selector: "folder-details",
    templateUrl: "./folder-details.component.html",
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [DatePipe],
})
export class FolderDetailsComponent implements OnInit, OnDestroy {
    @ViewChild("messageInput") messageInput: ElementRef;

    drawerMode: "over" | "side" = "side";

    private _unsubscribeAll: Subject<any> = new Subject<any>();
    background: ThemePalette = undefined;

    selected$: Observable<ProtocolFolder>;

    isLoading$: Observable<boolean>;

    isUpdating$: Observable<boolean>;

    selected: ProtocolFolder;

    selectedFolderProtocols$: Observable<ProtocolDefinition[]>;

    filteredProtocols$: ReplaySubject<ProtocolDefinition[]> = new ReplaySubject<ProtocolDefinition[]>(1);

    isThereAnyProtocol$: Observable<boolean>;

    displayedColumns: string[] = ["name", "creationTime", "creatorUserName", "symbol"];

    searchForm: FormGroup = new FormGroup({
        text: new FormControl(""),
    });

    constructor(
        _injector: Injector,
        private _facade: ProtocolFacade,
        private _router: Router,
        private _matDialog: MatDialog,
        private _confirmationService: FuseConfirmationService,
        private _translateService: TranslocoService,
        private _notifyService: NotifyService,
        private _actions: Actions
    ) {
        this.selected$ = _facade._selected$;

        this.selectedFolderProtocols$ = _facade.selectedFolderProtocols$;

        this.isThereAnyProtocol$ = _facade._isThereAnyProtocol$;

        this.isLoading$ = _facade._loadingDetails$;

        this.isUpdating$ = _facade.updating$;
    }

    ngOnInit(): void {
        this.selected$.pipe(takeUntil(this._unsubscribeAll)).subscribe((selected) => {
            if (selected) {
                this.selected = selected;
                this._facade.load(this.selected.id);
            }
        });

        this.selectedFolderProtocols$.pipe(takeUntil(this._unsubscribeAll)).subscribe((protocols) => {
            this.filteredProtocols$.next(protocols);
        });

        this.searchForm
            .valueChanges.pipe(takeUntil(this._unsubscribeAll))
            .subscribe((value) => {
                this.applyFilter(value.text.toLowerCase());
            });


        this._actions
            .pipe(takeUntil(this._unsubscribeAll), ofType(actions.deleteProtocolAction.complete))
            .subscribe((action) => {
                this._notifyService.success(
                    this._translateService.translate(
                        "health.engage.protocol-pool.delete-protocol.successfully-message"
                    )
                );
            });

        this._actions
            .pipe(takeUntil(this._unsubscribeAll), ofType(actions.deleteProtocolAction.failure))
            .subscribe((action) => {
                this._notifyService.error(
                    this._translateService.translate("health.engage.protocol-pool.delete-protocol.error-message")
                );
            });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    createProtocolDefinition() {
        this._matDialog.open(CreateProtocolComponent, {
            panelClass: "create-protocol-dialog",
            data: {
                parentFolderId: this.selected.id,
            },
        });
    }

    updateProtocolDefinition(protocol: ProtocolDefinition) {
        this._matDialog.open(EditProtocolComponent, {
            panelClass: "edit-protocol-dialog",
            data: {
                ...protocol,
            },
        });
    }

    deleteProtocolDefinition(protocol: ProtocolDefinition) {
        this._confirmationService
            .open({
                title: this._translateService.translate("health.engage.protocol-pool.delete-protocol.alert-title"),
                message: this._translateService.translate(
                    "health.engage.protocol-pool.delete-protocol.alert-message",
                    {
                        name: protocol.name,
                    }
                ),
                actions: {
                    confirm: {
                        label: this._translateService.translate("ACTION_DELETE"),
                        color: "warn",
                    },
                    cancel: {
                        label: this._translateService.translate("ACTION_CANCEL"),
                    },
                },
            })
            .afterClosed()
            .subscribe((result) => {
                if (result === "confirmed") {
                    this._facade.delete(protocol.id);
                }
            });
    }

    moveProtocolDefinition(protocol: ProtocolDefinition) {
        this._matDialog.open(MoveFolderDialogComponent, {
            panelClass: "folder-picker-dialog",
            data: {
                ...protocol,
            },
        });
    }

    duplicateProtocolDefinition(protocol: ProtocolDefinition) {
        this._matDialog.open(CreateProtocolComponent, {
            panelClass: "create-protocol-dialog",
            data: {
                ...protocol,
                parentFolderId: this.selected.id,
                name: `${protocol.name} - Cópia`,
            },
        });
    }

    applyFilter(text: string) {
        this.selectedFolderProtocols$.pipe(take(1)).subscribe((protocols) => {
            let filteredForms = protocols.filter((protocol) => {
                return protocol.name.toLowerCase().includes(text.toLowerCase());
            });
            this.filteredProtocols$.next(filteredForms);
        });
    }

    redirectToSettings() {
        this._facade.select(undefined);
        this._router.navigate(["/protocols/settings2"]);
    }
}
