import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Component, OnInit, ViewEncapsulation, OnDestroy, Inject } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Actions, ofType } from "@ngrx/effects";
import { NotifyService } from "@ui/notify";
import { FormBuilder, FormGroup } from "@angular/forms";
import { VuiValidators } from "@vui";
import { TranslocoService } from "@ngneat/transloco";
import { ProtocolFacade } from "../store/facade";
import { createProtocolFolderAction } from "../store/actions";

@Component({
    selector: "create-folder",
    templateUrl: "./create-folder.component.html",
    encapsulation: ViewEncapsulation.None,
})
export class CreateFolderComponent implements OnInit, OnDestroy {
    private _unsubscribeAll = new Subject();

    isCreating$: Observable<boolean>;

    form: FormGroup;

    constructor(private _dialogRef: MatDialogRef<CreateFolderComponent>,
                private _formBuilder: FormBuilder,
                private _facade: ProtocolFacade,
                private actions$: Actions,
                private _notifyService: NotifyService,
                private _translateService: TranslocoService,
                @Inject(MAT_DIALOG_DATA) public dialogData) {
        this.isCreating$ = this._facade.creating$;
        this.form = this._formBuilder.group({
            name: [null, VuiValidators.required],
            isPublic: [true],
        });
    }

    ngOnInit() {
        this.actions$
            .pipe(ofType(createProtocolFolderAction.complete), takeUntil(this._unsubscribeAll))
            .subscribe(({folder}) => {
                this._notifyService.success(this._translateService.translate("health.engage.protocol-pool.create-folder.successfully-message"));
                this._dialogRef.close();
            });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    save() {
        if (this.form.valid) {
            let data = this.form.getRawValue();
            this._facade.createFolder(data.name, data.isPublic, this.dialogData.parentId);
        }
    }

    closeDialog() {
        this._dialogRef.close();
    }
}
