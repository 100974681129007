import { Component, OnDestroy, OnInit } from "@angular/core";
import { FuseConfigService } from "@fuse/services/config.service";
import { HealthEngageCommunicationService } from "../../../health-engage-communication.service";

@Component({
    selector: "smart-form-collect-canceled",
    templateUrl: "./smart-form-collect-canceled.component.html",
})
export class SmartFormCollectCanceledComponent implements OnInit, OnDestroy {
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _healthEngageCommunicationService: HealthEngageCommunicationService
    ) {
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true,
                },
                toolbar: {
                    hidden: true,
                },
                footer: {
                    hidden: true,
                },
                sidepanel: {
                    hidden: true,
                },
            },
        };
    }

    ngOnInit(): void {
        this._healthEngageCommunicationService.emitChatVisibility(false);
    }

    ngOnDestroy(): void {
        this._healthEngageCommunicationService.emitChatVisibility(true);
    }
}
