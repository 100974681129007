import { Injector, ElementRef, ChangeDetectorRef, Component, ViewChild } from "@angular/core";
import { AppConsts } from "@shared/AppConsts";
import { AppSessionService } from "@shared/session/app-session.service";
import { Moment } from "moment";
import * as moment from "moment";
import * as IMask from "imask";
import { AbstractControl, FormControl, FormGroup } from "@angular/forms";
import {
    AbpMultiTenancyService,
    FeatureCheckerService,
    LocalizationService, MessageService,
    NotifyService,
    PermissionCheckerService, SettingService
} from "abp-ng2-module";
import { NotificationService } from "@app/wapps/core/notification/notification.service";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { formatCurrency } from "@angular/common";
import { CurrencyMask } from "@app/wapps/core/currency-mask";
import createAutoCorrectedDatePipe from "text-mask-addons/dist/createAutoCorrectedDatePipe";
import { format, parse } from "date-fns"
import { DomSanitizer } from "@angular/platform-browser";
import { Subject } from "rxjs";
import { PhoneMessagingService } from "@app/phone-messaging.service";
import { PhoneUtils } from "@vui";
import { FusePerfectScrollbarDirective } from "@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive";

export const DATE_FORMAT = {
    parse: {
        dateInput: "DD/MM/YYYY",
        dateTimeInput: "DD/MM/YYYY HH:mm",
        timeInput: "HH:mm",
        timeWithSecondsInput: "HH:mm:ss",
        dateInputFns: "dd/MM/yyyy",
    },
    display: {
        dateInput: "DD/MM/YYYY",
        monthYearLabel: "MMM YYYY",
        dateA11yLabel: "LL",
        monthYearA11yLabel: "MMMM YYYY",
    },
};

@Component({
    selector: 'app-base-component',
    template: '',
})
export abstract class AppComponentBase {

    localizationSourceName = AppConsts.localization.defaultLocalizationSourceName;

    localization: LocalizationService;
    permission: PermissionCheckerService;
    featureCheckerService: FeatureCheckerService;
    notify: NotifyService;

    notificationService: NotificationService;

    setting: SettingService;
    message: MessageService;
    multiTenancy: AbpMultiTenancyService;
    public appSession: AppSessionService;
    elementRef: ElementRef;

    protected changeDetector: ChangeDetectorRef;

    public expansionHeaderHeight = "44px";

    protected _domSanitizer: DomSanitizer;

    protected phoneMessagingService: PhoneMessagingService;

    // Private
    protected _unsubscribeSubject: Subject<any>;

    _fusePerfectScrollbar: FusePerfectScrollbarDirective;

    @ViewChild(FusePerfectScrollbarDirective)
        set directive(theDirective: FusePerfectScrollbarDirective) {
            if (!theDirective) {
                return;
            }
        this._fusePerfectScrollbar = theDirective;
    }

    protected constructor(injector: Injector) {
        this.localization = injector.get(LocalizationService);
        this.permission = injector.get(PermissionCheckerService);
        this.featureCheckerService = injector.get(FeatureCheckerService);
        this.notify = injector.get(NotifyService);
        this.setting = injector.get(SettingService);
        this.message = injector.get(MessageService);
        this.multiTenancy = injector.get(AbpMultiTenancyService);
        this.appSession = injector.get(AppSessionService);
        this.elementRef = injector.get(ElementRef);
        this.changeDetector = injector.get(ChangeDetectorRef);
        this.notificationService = injector.get(NotificationService);
        this._domSanitizer = injector.get(DomSanitizer);
        this._unsubscribeSubject = new Subject();
        this.phoneMessagingService = injector.get(PhoneMessagingService);
        this._genders = [
            {
                id: 0,
                title: this.l("NotInformed")
            },
            {
                id: 2,
                title: this.l("Female")
            },
            {
                id: 1,
                title: this.l("Male")
            }
        ];
    }

    public l(key: string, ...args: any[]): string {
        let localizedText = this.localization.localize(key, this.localizationSourceName);

        if (!localizedText) {
            localizedText = key;
        }

        if (!args || !args.length) {
            return localizedText;
        }

        args.unshift(localizedText);
        return abp.utils.formatString.apply(this, args);
    }

    public isGranted(permissionName: string): boolean {
        return this.permission.isGranted(permissionName);
    }

    public getCPFMask() {
        return [/[0-9]/, /\d/, /\d/, ".", /[0-9]/, /\d/, /\d/, ".", /[0-9]/, /\d/, /\d/, "-", /\d/, /\d/,];
    }

    public currencyMask = {
        mask: Number,
        scale: 2,
        thousandsSeparator: " ",
        normalizeZeros: true,
        padFractionalZeros: true,
        radix: ".",
        mapToRadix: [","],
    };

    inputDateMask = {
        mask: Date,
        lazy: false
    };

    public getInputMaskForDate(min: Date = null, max: Date = null) {
        let inputDateMomentFormat = DATE_FORMAT.parse.dateInputFns;
        let mask = {
            mask: Date,
            pattern: "d/m/Y",
            format: function (date) {
                if (date instanceof Date) {
                    return format(date, inputDateMomentFormat);
                }
                return date;
            },
            parse: function (str) {
                if (str instanceof Date) {
                    return str;
                }
                return parse(str, inputDateMomentFormat, new Date());
            },
            lazy: false
        };
        if (min) {
            Object.assign(mask, {
                min: min
            });
        }
        if (max) {
            Object.assign(mask, {
                min: min
            })
        }
        return mask;
    }

    public getInputMaskDate() {
        return this.getInputMaskForDate();
    }

    public getInputMaskWithMinDate(min: Date) {
        return this.getInputMaskForDate(min, new Date(9999, 0, 1));
    }

    public getInputMaskWithMaxDate(max: Date) {
        return this.getInputMaskForDate(new Date(1900, 0, 1), max);
    }

    public getInputMaskForTime() {
        let format = "HH:mm";
        return {
            mask: Date,
            pattern: "h:m",
            blocks: {
                h: {
                    mask: IMask.MaskedRange,
                    from: 0,
                    to: 23,
                    maxLength: 2,
                },
                m: {
                    mask: IMask.MaskedRange,
                    from: 0,
                    to: 59,
                    maxLength: 2,
                }
            },
            format: function (date) {
                return moment(date).format(format);
            },
            parse: function (str) {
                return moment(str, format);
            },
            lazy: false
        }
    }

    public getPhoneMask(raw: string) {
        const thenum = raw.replace(/[^0-9]/g, "");
        if (thenum.length <= 10) {
            return ["(", /[1-9]/, /\d/, ")", " ", /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];
        }
        return ["(", /[1-9]/, /\d/, ")", " ", /\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];
    }

    public phoneMask = IMask.createMask({
        mask: [
            {
                mask: "(00) 0000-0000",
            },
            {
                mask: "(00) 00000-0000",
            },
            {
                // mask for international numbers, using the E.164 international standard
                // https://en.wikipedia.org/wiki/E.164
                mask: "\\+000000000000000",
            },
        ]
    });

    public phonePipe = IMask.createPipe(IMask.createMask(this.phoneMask));

    whatsappLink(phone: string): string {
        return `https://wa.me/${PhoneUtils.toE164Phone(phone).replace("+", "")}`;
    }

    public momentTz(date): Moment {
        return moment(date).zone("-03:00");
    }

    weightPattern = "([0-9]+(\\,?[0-9]?[0-9]?)?)";
    weightMask = createNumberMask({allowDecimal: true, prefix: "", decimalSymbol: ","});

    numberOnlyPattern = "^[0-9]*$";
    phonePattern: string = "\\([0-9]{2}\\)[\\s][0-9]{4,5}-[0-9]{4}";

    cepPattern: string = "^\\d{5}[-]\\d{3}$";

    emailPattern: string = "[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$";

    urlPattern: RegExp = /[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;

    public static personalIdentifierPattern: any[] = [/[0-9]/, /\d/, /\d/, ".", /[0-9]/, /\d/, /\d/, ".", /[0-9]/, /\d/, /\d/, "-", /\d/, /\d/,];
    //XX.XXX.XXX/YYYY-ZZ
    public static commercialIdentifierPattern: any[] = [/[0-9]/, /\d/, ".", /[0-9]/, /\d/, /\d/, ".", /[0-9]/, /\d/, /\d/, "/", /[0-9]/, /\d/, /\d/, /\d/, "-", /[0-9]/, /\d/];
    postalCodePattern: any[] = [/\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/];

    public autoCorrectedDatePipe = createAutoCorrectedDatePipe("dd/mm/yyyy");

    public autoCorrectedHourPipe = createAutoCorrectedDatePipe("HH:MM");

    public maskDate: any = [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/];

    //visa, mastercard, amex, discover, diners_club, jcb
    public static creditCardsPattern: any[] = [
        /^4[0-9]{12}(?:[0-9]{3})?$/,
        /^5[1-5][0-9]{14}$|^2(?:2(?:2[1-9]|[3-9][0-9])|[3-6][0-9][0-9]|7(?:[01][0-9]|20))[0-9]{12}$/,
        /^3[47][0-9]{13}$/,
        /^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})$/,
        /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
        /^(?:2131|1800|35[0-9]{3})[0-9]{11}$/
    ];

    getCreditCardsMask() {
        return AppComponentBase.creditCardsPattern;
    }

    getEmailPattern() {
        return this.emailPattern;
    }

    getUrlPattern() {
        return this.urlPattern;
    }

    getFiscalIdentifierMask(raw: string) {
        const mask = raw.replace(/[^0-9]/g, "");
        if (mask.length <= 11) {
            return AppComponentBase.personalIdentifierPattern;
        }
        return AppComponentBase.commercialIdentifierPattern;
    }

    public cpfMask = IMask.createMask({mask: "000.000.000-00", type: Number});

    public cnpjMask = IMask.createMask({mask: "00.000.000/0000-00", type: Number});

    public fiscalIdentifierMask = IMask.createMask({
        mask: [
            this.cpfMask,
            this.cnpjMask,
        ]
    });

    public cepMask = IMask.createMask({mask: "00.000-000", type: Number});

    getPersonalIdentifierMask(raw: string) {
        return AppComponentBase.personalIdentifierPattern;
    }

    getCommercialIdentifierMask(raw: string) {
        return AppComponentBase.commercialIdentifierPattern;
    }

    numberOnly(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }

    getCpfMasked(cpf) {
        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3\-\$4");
    }

    sliceText(text: string, size: number) {
        if (!text)
            return "";

        if (text.length > size)
            return text.slice(0, size) + "...";
        else
            return text;
    }

    getDatableMessages() {
        return {
            "emptyMessage": this.l("DataTable.Empty"),
            "totalMessage": this.l("DataTable.Total")
        };
    }

    _genders: any[];

    public get genders() {
        return this._genders;
    }

    protected focusField(control: any, delay: number = 500): void {
        setTimeout(() => {
            control.nativeElement.focus();
        }, delay);
    }

    getMedicalTitle(gender) {
        if (!gender) {
            return "";
        }
        if (gender === 1) {
            return this.l("Health.Doctor.Title.1")
        }
        return this.l("Health.Doctor.Title.2")
    }

    validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({onlySelf: true});
            } else if (control instanceof FormGroup) {
                this.validateAllFormFields(control);
            }
        });
    }

    isFieldRequired(form: FormGroup, field: string) {
        const validator = form.get(field).validator({} as AbstractControl);
        return validator && validator.required;
    }

    formatCurrencyValue(value: number) {
        return formatCurrency(value, this.localization.currentLanguage.name, "R$");
    }

    cleanPhone(phone: string): string {
        if (!phone) {
            return "";
        }
        let regex1 = new RegExp("[\\_() -]?", "g");
        return phone.replace(regex1, "");
    }

    resetScrollTop(_elementRef: ElementRef) {
        _elementRef.nativeElement.scrollTop = 0;
    }
}
