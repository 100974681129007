import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";

import {
    ApexAxisChartSeries,
    ApexChart,
    ApexDataLabels,
    ApexGrid,
    ApexStroke,
    ApexTitleSubtitle,
    ApexXAxis,
    ChartComponent
} from "ng-apexcharts";
import { Subject } from "rxjs";
import { DocumentStatsFacade } from "./store/facade";
import { takeUntil } from "rxjs/operators";

export type ChartOptions = {
    series: ApexAxisChartSeries;
    chart: ApexChart;
    xaxis: ApexXAxis;
    dataLabels: ApexDataLabels;
    grid: ApexGrid;
    stroke: ApexStroke;
    title: ApexTitleSubtitle;
};

@Component({
    selector: "prescription-history",
    templateUrl: "./prescription-history.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrescriptionHistoryComponent implements OnInit, OnDestroy {
    private _unsubscribeAll = new Subject();

    @ViewChild("chart") chart: ChartComponent;

    total: number = 0;

    public chartOptions: Partial<ChartOptions>;

    constructor(private _changeDetector: ChangeDetectorRef,
                private _facade: DocumentStatsFacade,) {
    }

    ngOnInit(): void {
        this._facade.prescriptionHistory$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(result => {
                this.chartOptions = {
                    series: [
                        {
                            name: "Inteligente",
                            data: result.map(i => i.smart),
                            color: "#50992d"
                        },
                        {
                            name: "Padrão",
                            data: result.map(i => i.standard),
                            color: "#4587ec"
                        },
                    ],
                    chart: {
                        height: 350,
                        type: "bar",
                        toolbar: {
                            show: false
                        },
                    },
                    dataLabels: {
                        enabled: true
                    },
                    stroke: {
                        curve: "smooth"
                    },
                    xaxis: {
                        type: "datetime",
                        categories: result.map(i => i.date.toLocaleDateString())
                    },
                };

                let sum = 0;
                this.chartOptions.series.map(s => s.data.map(c => {
                    sum += c;
                }));

                this.total = sum;

                this._changeDetector.markForCheck();
            });

        this._facade.getPrescriptionHistory();
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
