<!--<div class="chat-message"-->
     <!--[ngClass]="{'chat-message&#45;&#45;in': message.direction === 'in', 'chat-message&#45;&#45;out': message.direction === 'out'}">-->
    <!--<div *ngIf="showAvatars" class="chat-message-avatar">-->
        <!--<img *ngIf="message.direction === 'in'" [src]="avatar"/>-->
        <!--<img *ngIf="message.direction === 'out'" [src]="chatService.userAvatar$ | async"/>-->
    <!--</div>-->
    <!--<div class="chat-message-text" [ngClass]="{'chat-message-text&#45;&#45;no-avatar':!showAvatars}">-->
        <!--<span [ngxChatLinks]="message.body"></span>-->
    <!--</div>-->
    <!--<div class="chat-message-image-wrapper" *ngIf="imageLink">-->
        <!--<img class="chat-message-image" [src]="imageLink"/>-->
    <!--</div>-->
    <!--<div class="chat-message-footer">-->
        <!--<small title="{{nick}}" class="chat-message-name">-->
            <!--{{nick}}-->
            <!--<ng-container *ngIf="message.direction === 'out'" [ngSwitch]="getMessageState()">-->
                <!--<ng-container *ngSwitchCase="MessageState.SENT">✓</ng-container>-->
                <!--<ng-container *ngSwitchCase="MessageState.RECIPIENT_RECEIVED">✓✓</ng-container>-->
                <!--<ng-container *ngSwitchCase="MessageState.RECIPIENT_SEEN"><span class="state&#45;&#45;seen">✓✓</span></ng-container>-->
            <!--</ng-container>-->
        <!--</small>-->
        <!--<small class="chat-message-datetime">{{message.datetime | date:chatService.translations.timeFormat}}</small>-->
    <!--</div>-->
<!--</div>-->

<div class="message-row" [ngClass]="{
    'me': message.direction === 'out',
    'contact': message.direction === 'in',
    'first-of-group': isFirstMessageOfGroup,
    'last-of-group': isLastMessageOfGroup
    }" fxLayout="row" fxLayoutGap="10px">

    <img *ngIf="shouldShowContactAvatar" [src]="avatar" class="avatar">

    <div class="bubble" fxLayout="column" fxLayoutGap="0px">
        <div *ngIf="shouldShowContactAvatar" class="nick">
            <span>{{nick}}</span>
        </div>
        <div class="message">{{message.body}}</div>
        <div class="time" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="4px">
            <span class="mat-caption">
                {{datepipe.transform(message.datetime, 'HH:mm')}}
            </span>
            <ng-container *ngIf="message.direction === 'out'" [ngSwitch]="getMessageState()">
                <ng-container *ngSwitchCase="MessageState.SENT"><span>✓</span></ng-container>
                <ng-container *ngSwitchCase="MessageState.RECIPIENT_RECEIVED"><span>✓✓</span></ng-container>
                <ng-container *ngSwitchCase="MessageState.RECIPIENT_SEEN"><span class="state--seen">✓✓</span></ng-container>
            </ng-container>
        </div>
    </div>

</div>