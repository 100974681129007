import { createSelector } from "@ngrx/store";
import { getSmartFormsState } from "./base";
import { RemindersState } from "./model";

export const selectCurrent = createSelector(getSmartFormsState, (state: RemindersState) => state.selected);

export const selectFolders = createSelector(getSmartFormsState, (state: RemindersState) => state.folders);

export const selectSelectedFolderItems = createSelector(getSmartFormsState, (state: RemindersState) => {
    return state.selectedFolderItems;
});

export const selectIsLoading = createSelector(getSmartFormsState, (state: RemindersState) => state.loading);

export const selectIsLoadingDetails = createSelector(
    getSmartFormsState,
    (state: RemindersState) => state.loadingDetails
);

export const selectIsCreating = createSelector(
    getSmartFormsState,
    (state: RemindersState) => state.creatingFolder || state.creating
);

export const selectIsUpdating = createSelector(
    getSmartFormsState,
    (state: RemindersState) => state.updating || state.deleting
);

export const selectAtLeastOneFolderExists = createSelector(
    getSmartFormsState,
    (state: RemindersState) => state.folders.length > 0
);

export const selectAtLeastOneFormExists = createSelector(getSmartFormsState, (state: RemindersState) => {
    return !!state.selected && state.selectedFolderItems.length > 0;
});
