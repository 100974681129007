<ng-container *transloco="let t; read: 'employees-list'">
  <ui-dialog-base
          [allowCancelTouchingOutside]="false"
          [title]="'Nome do arquivo'"
          [classes]="['sm:w-50vw']">

    <div class="flex-auto h-full p-6 overflow-y-auto overscroll-y-contain sm:overflow-hidden sm:overscroll-auto"
         fuseScrollbar [formGroup]="form">
      <mat-form-field class="fuse-mat-no-subscript w-full">
        <input cdkFocusInitial matInput formControlName="name" [placeholder]="'Defina o nome do arquivo'" required
               [pattern]="fileNamePattern"/>
        <span matSuffix>{{extension}}</span>
      </mat-form-field>
    </div>

    <dialog-actions class="flex flex-row items-center space-x-4 justify-between sm:justify-start">
      <button mat-stroked-button mat-dialog-close>Cancelar</button>
      <button
              mat-flat-button
              [disabled]="!form.valid"
              (click)="save()"
              color="primary">
        Baixar arquivo
      </button>
    </dialog-actions>
  </ui-dialog-base>
</ng-container>
