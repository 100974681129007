import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ButtonToggleComponent } from "./button-toggle.component";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatMenuModule } from "@angular/material/menu";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatDividerModule } from "@angular/material/divider";

@NgModule({
    declarations: [ButtonToggleComponent],
    imports: [CommonModule, MatMenuModule, MatButtonModule, MatDividerModule, MatButtonToggleModule, MatIconModule],
    exports: [ButtonToggleComponent, MatMenuModule, MatButtonModule],
})
export class ButtonToggleModule {}
