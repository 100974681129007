import { createMask, MaskedRange } from "imask";

export const dateMaskPlaceholder = "dd/MM/yyyy";

export const dateFormat = "dd/MM/yyyy";

export const createDateMask = () =>
    createMask({
        mask: Date,
        pattern: "d{/}`m{/}`Y",
        min: new Date(1900, 1, 1),
        max: new Date(3000, 1, 1),
        lazy: true,
        format: (date) => {
            if (!date) {
                return undefined;
            }
            const day = String(date.getDate()).padStart(2, "0");
            const month = String(date.getMonth() + 1).padStart(2, "0");
            const year = date.getFullYear();
            return [day, month, year].join("/");
        },
        parse: (str) => {
            const [day, month, year] = str.split("/").map((i) => Number.parseInt(i));
            return new Date(year, month - 1, day);
        },
    });

export const createTimeMask = () =>
    createMask({
        mask: "HH:mm",
        blocks: {
            HH: {
                mask: MaskedRange,
                from: 0,
                to: 23
            },
            mm: {
                mask: MaskedRange,
                from: 0,
                to: 59
            }
        },
        validator: (value: string | undefined) => {
            return value?.length === 5;
        }
    });