import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Component, OnInit, ViewEncapsulation, OnDestroy, Inject } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Actions, ofType } from "@ngrx/effects";
import { NotifyService } from "@ui/notify";
import { SmartFormPoolFacade } from "../store/facade";
import { FormBuilder, FormGroup } from "@angular/forms";
import { SmartFormDefinition } from "@vhealth/engage";
import { assign } from "lodash";
import { VuiValidators } from "@vui";
import { TranslocoService } from "@ngneat/transloco";
import { createSmartFormDefinitionTemplateAction } from "../store/actions";
import { Cid10, FormDefinition, FormOptionsComponent } from "@vhealth/vforms-wrapper";

export type CreateTemplateDialogData = {
    parentFolderId: number;
    name: string;
    comments: string;
    definition: string;
    notifyOnAnswered?: boolean;
    displayOnFeed?: boolean;
    horizontalEvolution?: boolean;
};

@Component({
    selector: "create-template",
    templateUrl: "./create-template.component.html",
    encapsulation: ViewEncapsulation.None,
    styleUrls: ["./create-template.component.scss"],
})
export class CreateTemplateComponent implements OnInit, OnDestroy {
    private _unsubscribeAll = new Subject();

    isCreating$: Observable<boolean>;

    currentForm: FormGroup;

    definition: string;

    constructor(
        private _matDialog: MatDialog,
        private _dialogRef: MatDialogRef<CreateTemplateComponent>,
        private _formBuilder: FormBuilder,
        private _facade: SmartFormPoolFacade,
        private actions$: Actions,
        private _notifyService: NotifyService,
        private _translateService: TranslocoService,
        @Inject(MAT_DIALOG_DATA) public data: CreateTemplateDialogData
    ) {
        this.isCreating$ = this._facade.creating$;

        this.currentForm = this._formBuilder.group({
            parentFolderId: [data.parentFolderId, VuiValidators.required],
            name: [null, VuiValidators.required],
            comments: [null, VuiValidators.required],
            definition: [null, VuiValidators.required],
            notifyOnAnswered: [true],
            displayOnFeed: [true],
            horizontalEvolution: [false],
        });

        this.currentForm.patchValue(data);
    }

    ngOnInit() {
        this.actions$
            .pipe(ofType(createSmartFormDefinitionTemplateAction.complete), takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this._notifyService.success(
                    this._translateService.translate("health.engage.form-pool.create-template.successfully-message")
                );
                this._dialogRef.close();
            });

        let prevDefinition;
        if (this.data.definition) {
            prevDefinition = JSON.parse(this.data.definition);
            prevDefinition.excludedFields = [Cid10];
            prevDefinition.requiredDescription = true;
        }

        this.definition = JSON.stringify(prevDefinition || new FormDefinition([Cid10], true));
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    onFormSave(definition: FormDefinition) {
        this.currentForm.patchValue({
            definition: JSON.stringify(definition),
            name: definition.title,
            comments: definition.description,
        });

        this._facade.createTemplate(assign(new SmartFormDefinition(), this.currentForm.getRawValue()));
    }

    onFormCancel() {
        this._dialogRef.close();
    }

    showOptions() {
        let dialogRef = this._matDialog.open(FormOptionsComponent, {
            data: {
                form: JSON.parse(this.definition),
                notifyOnAnswered: this.currentForm.get("notifyOnAnswered").value,
                displayOnFeed: this.currentForm.get("displayOnFeed").value,
                horizontalEvolution: this.currentForm.get("horizontalEvolution").value,
            },
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.definition = JSON.stringify(result);
                this.currentForm.patchValue({
                    notifyOnAnswered: result.notifyOnAnswered,
                    displayOnFeed: result.displayOnFeed,
                    horizontalEvolution: result.horizontalEvolution,
                });
            }
        });
    }
}
