export class AppConsts {

    static remoteServiceBaseUrl: string;
    static appBaseUrl: string;
    static ionicPreviewServiceBaseUrl: string;

    static localeMappings: any = [];

    static MapsApiKey: string;
    static VindiUrl: string;
    static VindiToken: string;
    static GtmId: string;
    static FacebookPixelId: string;
    static MeetDomain: string;
    static ChatEnabled: boolean;
    static GoogleCalendarEnabled: boolean;

    //Add or not the support chat (none, intercom, hubspot)
    static SupportChat: string;

    static vMeetEnabled: boolean;

    static WootricEnabled: boolean;

    static readonly userManagement = {
        defaultAdminUserName: 'admin'
    };

    static readonly localization = {
        defaultLocalizationSourceName: 'Wapps'
    };

    static readonly authorization = {
        encryptedAuthTokenName: 'enc_auth_token'
    };

    static readonly emptyUUID = {
        value: '00000000-0000-0000-0000-000000000000'
    };

    static readonly RolesNames = {
        Admin: "Admin",
        Employee: "Employee",
        Doctor: "Doctor",
        Patient: "Patient"
    };

    static readonly SourceExternal = 'external';

    static readonly Features = {
        InternalChat: "CoreFeatures.InternalChat",
        FeaturePushNotificationLegacy: "HealthFeatures.FeaturePushNotification",
        FeaturePushNotification: "Core.Features.Marketing.Push",
        MobileAppBuilder: "Core.Features.MobileAppBuilder",
        Pwa: "Core.Features.Pwa",
        NativeApp: "Core.Features.NativeApp",

        ScheduleGoogleIntegration: "Health.Features.Schedule.Integration.Google",
        Webhooks: "Core.Features.Webhooks",
    };
}
