import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppSessionService } from './session/app-session.service';
import { AppUrlService } from './nav/app-url.service';
import { AppAuthService } from './auth/app-auth.service';
import { AppRouteGuard } from './auth/auth-route-guard';
import {AbpModule, RefreshTokenService} from "abp-ng2-module";
import { AppRouteGuardV1 } from './auth/auth-route-guard.v1';
import {TranslationService} from "@shared/translation.service";
import {AnonymousRouteGuard} from "@shared/auth/anonymous-route-guard";

@NgModule({
    imports: [
        CommonModule,
        AbpModule,
        RouterModule
    ],
    declarations: [
        
    ],
    exports: [
        
    ]
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [
                AppSessionService,
                AppUrlService,
                AppAuthService,                
                AppRouteGuard,
                AnonymousRouteGuard,
                AppRouteGuardV1,
                TranslationService
            ]
        }
    }
}
