import { Injector } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { AppointmentFormViewerComponent } from "@app/wapps/calendar/public/form/appointment-form-viewer.component";
import { Notification } from "@app/wapps/notification/notification.model";
import { NotificationParser } from "@app/wapps/notification/notification.parser";
import { UserNotification } from "@shared/service-proxies/service-proxies";
import moment from "moment";

export class ProcedureFormsNotificationParser extends NotificationParser {
    static Type = "Wapps.Application.Health.Procedures.PreForms.Notification.PreFormResponseNotificationData";
    private _matDialog: MatDialog;

    constructor(injector: Injector) {
        super(injector);
        this._matDialog = injector.get(MatDialog);
    }

    parse(userNotification: UserNotification): Notification {
        let id = userNotification.id;
        let icon = "assignment_turned_in";
        let title = "Resposta de formulário";
        let subtitle = moment(userNotification.notification.creationTime)
            .locale("pt-BR")
            .local()
            .format("ddd, DD [de] MMM [de] YYYY [às] HH:mm");
        return new Notification(id, icon, title, subtitle, userNotification);
    }

    open(notification: Notification) {
        let data: any = notification.userNotification.notification.data;
        if (!data.responseId) {
            notification.userNotification.notification;
            data = {
                responseId: notification.userNotification.notification.entityId,
            };
        }

        this._matDialog
            .open(AppointmentFormViewerComponent, {
                panelClass: "appointment-form-viewer-dialog",
                data: data,
            })
            .afterClosed()
            .subscribe();
    }
}
