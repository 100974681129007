import { NgModule } from '@angular/core';

import { FuseIfOnDomDirective } from '@fuse/directives/fuse-if-on-dom/fuse-if-on-dom.directive';
import { FuseInnerScrollDirective } from '@fuse/directives/fuse-inner-scroll/fuse-inner-scroll.directive';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { FuseMatSidenavHelperDirective, FuseMatSidenavTogglerDirective } from '@fuse/directives/fuse-mat-sidenav/fuse-mat-sidenav.directive';
import { ControlErrorsDirective } from './error-directive/errors.directive';
import { FormSubmitDirective } from './error-directive/submit.directive';
import { ControlErrorComponent } from './error-directive/errors.component';
import {ControlErrorContainerDirective} from "./error-directive/control-error.container";
import {NumberDirective} from "./only-number/only-number.directive";
import {MediaIfDirective} from "@app/wapps/core/media.if.directive";
import {MatFormFieldModule} from "@angular/material/form-field";
import {CurrencyFormatDirective} from "./currency-format/currency-format.directive";
import {CurrencyPipe} from "@angular/common";

@NgModule({
    declarations: [
        FuseIfOnDomDirective,
        FuseInnerScrollDirective,
        FuseMatSidenavHelperDirective,
        FuseMatSidenavTogglerDirective,
        FusePerfectScrollbarDirective,

        ControlErrorsDirective,
        FormSubmitDirective,
        ControlErrorComponent,
        ControlErrorContainerDirective,

        NumberDirective,
        MediaIfDirective,
        CurrencyFormatDirective
    ],
    imports     : [
        MatFormFieldModule,
    ],
    exports     : [
        FuseIfOnDomDirective,
        FuseInnerScrollDirective,
        FuseMatSidenavHelperDirective,
        FuseMatSidenavTogglerDirective,
        FusePerfectScrollbarDirective,

        ControlErrorsDirective,
        ControlErrorContainerDirective,
        FormSubmitDirective,

        NumberDirective,
        MediaIfDirective,
        CurrencyFormatDirective
    ],
    entryComponents:[
        ControlErrorComponent
    ],
    providers: [
        CurrencyPipe
    ]
})
export class FuseDirectivesModule
{
}
