import { ActivityItemDefinition } from "../../model";
import { FolderDto } from "../../utils";

export class ReminderFolder implements FolderDto {
    constructor() {}

    id: number | undefined;

    name: string | undefined;

    isPublic: boolean | undefined;

    folders: ReminderFolder[] = [];

    parentFolderId: number | undefined;
}

export enum FeedStatus {
    Hidden = 0,
    PatientHidden = 1,
    Visible = 2,
}

export interface RemindersState {
    loading: boolean;

    loadingDetails: boolean;

    creatingFolder: boolean;

    creating: boolean;

    updating: boolean;

    deleting: boolean;

    error: null;

    folders: ReminderFolder[] | undefined;

    selected: ReminderFolder | undefined;

    selectedFolderItems: ActivityItemDefinition[] | undefined;
}
