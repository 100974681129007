/* eslint-disable */
import { Injectable, Injector } from "@angular/core";
import { Observable, of } from "rxjs";
import {
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpEvent,
    HttpResponse,
    HttpErrorResponse,
    HttpHeaders,
} from "@angular/common/http";
import { switchMap, catchError, map } from "rxjs/operators";
import { throwError } from "rxjs";
import { HttpConfigurationService } from "./http-configuration.service";
import { TokenService } from "./token.service";

@Injectable()
export class AbpHttpInterceptor implements HttpInterceptor {
    protected configuration: HttpConfigurationService;

    constructor(
        configuration: HttpConfigurationService,
        private _tokenService: TokenService,
        private _injector: Injector,
    ) {
        this.configuration = configuration;
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const modifiedRequest = this.normalizeRequestHeaders(request);
        return next.handle(modifiedRequest).pipe(
            catchError(error => {
                if (error instanceof HttpErrorResponse && error.status === 401) {
                    //return this.tryAuthWithRefreshToken(request, next, error);
                    return this.handleErrorResponse(error);
                } else {
                    return this.handleErrorResponse(error);
                }
            }),
            switchMap(event => {
                return this.handleSuccessResponse(event);
            }),
        );
    }

    protected normalizeRequestHeaders(request: HttpRequest<any>): HttpRequest<any> {
        let modifiedHeaders = new HttpHeaders();
        modifiedHeaders = request.headers
            .set("Pragma", "no-cache")
            .set("Cache-Control", "no-cache")
            .set("Expires", "Sat, 01 Jan 2000 00:00:00 GMT");

        modifiedHeaders = this.addXRequestedWithHeader(modifiedHeaders);
        modifiedHeaders = this.addAuthorizationHeaders(modifiedHeaders);
        // modifiedHeaders = this.addAspNetCoreCultureHeader(modifiedHeaders);
        // modifiedHeaders = this.addAcceptLanguageHeader(modifiedHeaders);
        //modifiedHeaders = this.addTenantIdHeader(modifiedHeaders);

        return request.clone({
            headers: modifiedHeaders,
        });
    }

    protected addXRequestedWithHeader(headers: HttpHeaders): HttpHeaders {
        if (headers) {
            headers = headers.set("X-Requested-With", "XMLHttpRequest");
        }

        return headers;
    }

    protected addAuthorizationHeaders(headers: HttpHeaders): HttpHeaders {
        let authorizationHeaders = headers ? headers.getAll("Authorization") : null;
        if (!authorizationHeaders) {
            authorizationHeaders = [];
        }
        if (!this.itemExists(authorizationHeaders, (item: string) => item.indexOf("Bearer ") == 0)) {
            const token = this._tokenService.getToken();
            if (headers && token) {
                headers = headers.set("Authorization", "Bearer " + token);
            }
        }

        return headers;
    }

    protected handleSuccessResponse(event: HttpEvent<any>): Observable<HttpEvent<any>> {
        const self = this;

        if (event instanceof HttpResponse) {
            if (event.body instanceof Blob && event.body.type && event.body.type.indexOf("application/json") >= 0) {
                return self.configuration.blobToText(event.body).pipe(
                    map((json: string) => {
                        const responseBody = json == "null" ? {} : JSON.parse(json);
                        const modifiedResponse = self.configuration.handleResponse(
                            event.clone({
                                body: responseBody,
                            }),
                        );
                        return modifiedResponse.clone({
                            body: new Blob([JSON.stringify(modifiedResponse.body)], { type: "application/json" }),
                        });
                    }),
                );
            }
        }
        return of(event);
    }

    protected handleErrorResponse(error: any): Observable<never> {
        if (!(error.error instanceof Blob)) {
            return throwError(error);
        }
        return this.configuration.blobToText(error.error).pipe(
            switchMap((json: string) => {
                const errorBody = json == "" || json == "null" ? {} : JSON.parse(json);
                const errorResponse = new HttpResponse({
                    headers: error.headers,
                    status: error.status,
                    body: errorBody,
                });
                const ajaxResponse = this.configuration.getAbpAjaxResponseOrNull(errorResponse);
                if (ajaxResponse != null) {
                    this.configuration.handleAbpResponse(errorResponse, ajaxResponse);
                } else {
                    this.configuration.handleNonAbpErrorResponse(errorResponse);
                }
                return throwError(errorBody);
            }),
        );
    }

    private itemExists<T>(items: T[], predicate: (item: T) => boolean): boolean {
        for (let i = 0; i < items.length; i++) {
            if (predicate(items[i])) {
                return true;
            }
        }

        return false;
    }
}
