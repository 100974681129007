import { createReducer, on } from "@ngrx/store";
import { NotificationSettingsState } from "./model";
import {
    allowPreFormResponseNotificationsChange,
    allowScheduleNotificationsChange,
    getNotificationsSettings,
} from "./actions";

export const initialState: NotificationSettingsState = {
    allowScheduleNotifications: false,
    allowPreFormResponseNotifications: false,
    loading: false,
};

export const notificationSettingsReducer = createReducer(
    initialState,
    on(getNotificationsSettings.execute, (state) => {
        return {
            ...state,
            loading: true,
        };
    }),
    on(getNotificationsSettings.failure, (state, { error }) => {
        return {
            ...state,
            loading: false,
        };
    }),
    on(
        getNotificationsSettings.complete,
        (state, { settings: { allowPreFormResponseNotifications, allowScheduleNotifications } }) => {
            return {
                ...state,
                loading: false,
                allowScheduleNotifications,
                allowPreFormResponseNotifications,
            };
        }
    ),
    on(allowScheduleNotificationsChange.execute, (state) => {
        return {
            ...state,
            loading: true,
        };
    }),
    on(allowScheduleNotificationsChange.failure, (state, { error }) => {
        return {
            ...state,
            loading: false,
        };
    }),
    on(
        allowScheduleNotificationsChange.complete,
        (state, { settings: { allowPreFormResponseNotifications, allowScheduleNotifications } }) => {
            return {
                ...state,
                loading: false,
                allowScheduleNotifications,
                allowPreFormResponseNotifications,
            };
        }
    ),
    on(allowPreFormResponseNotificationsChange.execute, (state) => {
        return {
            ...state,
            loading: true,
        };
    }),
    on(allowPreFormResponseNotificationsChange.failure, (state, { error }) => {
        return {
            ...state,
            loading: false,
        };
    }),
    on(
        allowPreFormResponseNotificationsChange.complete,
        (state, { settings: { allowPreFormResponseNotifications, allowScheduleNotifications } }) => {
            return {
                ...state,
                loading: false,
                allowScheduleNotifications,
                allowPreFormResponseNotifications,
            };
        }
    )
);
