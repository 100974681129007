import { createAction, props } from "@ngrx/store";

export const getStats = createAction("[Stats Mobile] getStats", props<{
    start: Date,
    end: Date,
    tenantId: number | undefined
}>());

export const getStatsComplete = createAction(
    "[Stats Mobile] getStatsComplete",
    props<{ installations: any[], mau: any[] }>()
);

export const getStatsError = createAction("[Stats Mobile] getStatsError", props<{ error: any }>());

export const getInstallationHistory = createAction("[Stats Mobile] getInstallationHistory");

export const getInstallationHistoryComplete = createAction(
    "[Stats Mobile] getInstallationHistoryComplete",
    props<{ installationHistory: any[] }>()
);

export const getInstallationHistoryError = createAction("[Stats Mobile] getInstallationHistoryError", props<{ error: any }>());

export const getMauHistory = createAction("[Stats Mobile] getMauHistory");

export const getMauHistoryComplete = createAction(
    "[Stats Mobile] getMauHistoryComplete",
    props<{ mauHistory: any[] }>()
);

export const getMauHistoryError = createAction("[Stats Mobile] getMauHistoryError", props<{ error: any }>());