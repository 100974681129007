import { createReducer, on } from "@ngrx/store";
import { actions, IAppointmentStatState } from ".";

export const STATS_APPOINTMENTS_FEATURE_KEY = "stats.appointments";

export const initialAppointmentStatsState: IAppointmentStatState = {
    serviceDuration: undefined,
    scheduledExecuted: undefined,
    loading: false,
};

export const getAppointmentStatsReducer = createReducer(
    initialAppointmentStatsState,
    on(actions.getStats, (state) => ({
        ...state,
        loading: true,
    })),
    on(actions.getStatsComplete, (state, { scheduledExecuted, serviceDuration}) => {
        return {
            ...state,
            scheduledExecuted: scheduledExecuted,
            serviceDuration: serviceDuration,
            loading: false,
        };
    }),
    on(actions.getStatsError, (state, {error}) => ({
        ...state,
        loading: false,
    })),
);
