<div class="layout" *transloco="let t; read: 'health.engage'">
    <mat-tab-group>
        <mat-tab [label]="t('tab-form-label')" class="h-full w-full">
            <ng-template matTabContent>
                <form-pool></form-pool>
            </ng-template>
        </mat-tab>

        <mat-tab [label]="t('tab-reminder-label')" class="h-full w-full">
            <ng-template matTabContent>
                <reminder-pool></reminder-pool>
            </ng-template>
        </mat-tab>

        <mat-tab [label]="t('tab-protocol-label')" class="h-full w-full">
            <ng-template matTabContent>
                <protocol-pool></protocol-pool>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>
