import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PageSimpleFullWidthComponent } from "./layout/pages/simple/full-width/full-width.component";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { RouterModule } from "@angular/router";
import { FuseScrollbarModule } from "@nx-workspace/fuse";
import { SectionHeaderComponent } from "./section-header/section-header.component";
import { PhoneFormFieldComponent } from "./phone-form-field/phone-form-field.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { IMaskModule } from "angular-imask";
import { ReactiveFormsModule } from "@angular/forms";
import { FiscalIdentifierFormFieldComponent } from "./fiscal-identifier-form-field/fiscal-identifier-form-field.component";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { SexFormFieldComponent } from "./sex-form-field/sex-form-field.component";
import { TranslocoCoreModule } from "@i18n";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MaskedFormFieldComponent } from "./masked-form-field/masked-form-field.component";
import { MatInputRequiredDirective } from "./directives/mat-input-required.directive";
import { BackLinkModule } from "@ui/back-link";
import { ExamplePdfViewerComponent } from "./example-pdf-viewer/vui-pdf-viewer.component";
import { NgxExtendedPdfViewerModule } from "ngx-extended-pdf-viewer";
import { ResponsiveTableComponent } from "./responsive-table/responsive-table.component";
import { CreditCardComponent } from "./credit-card/credit-card.component";
import { CreditCardDirectivesModule } from "angular-cc-library";
import { FocusDirective } from "./directives/autofocus.directive";
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { IntlModelPhoneFormFieldComponent } from "./intl-phone-form-field/intl-model-phone-form-field.component";
import { DateFormFieldModule } from "./date-form-field/date-form-field.module";
import { MaskedFormFieldModule } from "./masked-form-field/masked-form-field.module";

@NgModule({
    declarations: [
        PageSimpleFullWidthComponent,
        SectionHeaderComponent,
        PhoneFormFieldComponent,
        FiscalIdentifierFormFieldComponent,
        SexFormFieldComponent,
        MatInputRequiredDirective,
        FocusDirective,
        ExamplePdfViewerComponent,
        ResponsiveTableComponent,
        CreditCardComponent,
        IntlModelPhoneFormFieldComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        FuseScrollbarModule,
        MatIconModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatDatepickerModule,
        MatIconModule,
        IMaskModule,
        TranslocoCoreModule,
        BackLinkModule,
        NgxExtendedPdfViewerModule,
        CreditCardDirectivesModule,
        NgxIntlTelInputModule,
        DateFormFieldModule,
        MaskedFormFieldModule,
    ],
    exports: [
        ResponsiveTableComponent,
        PageSimpleFullWidthComponent,
        SectionHeaderComponent,
        PhoneFormFieldComponent,
        FiscalIdentifierFormFieldComponent,
        SexFormFieldComponent,
        MaskedFormFieldComponent,
        MatInputRequiredDirective,
        FocusDirective,
        ExamplePdfViewerComponent,
        CreditCardComponent,
        IntlModelPhoneFormFieldComponent,

        DateFormFieldModule,
        MaskedFormFieldModule,
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]
})
export class VuiModule {
}
