import { InjectionToken } from "@angular/core";

export const FORMS_BUILDER_URL = new InjectionToken<string>("FORMS_BUILDER_URL");

export class FormDefinition {
    title: string;

    description: string;

    fields: any[] = [];

    sendResponseNotification: boolean = true;

    showOnMedicalRecords: boolean = true;

    excludedFields: string[];

    notifyOnAnswered: boolean = true;

    displayOnFeed: boolean = true;

    horizontalEvolution: boolean;

    requiredDescription: boolean;

    constructor(excludedFields: string[] = [], requiredDescription: boolean = false) {
        this.excludedFields = excludedFields;
        this.requiredDescription = requiredDescription;
    }
}

export const Textbox = "textbox";
export const Textarea = "textarea";
export const Number = "number";
export const Email = "email";
export const MultipleSelectionList = "multiple-selection-list";
export const SingleSelectionList = "single-selection-list";
export const SingleSelectionDropdown = "single-selection-dropdown";
export const MultipleSelectionDropdown = "multiple-selection-dropdown";
export const Datetime = "datetime";
export const Checkbox = "checkbox";
export const Cid10 = "cid10";

export const FieldTypeOperators = {
    textbox: ["equal", "startsWith", "endsWith", "contains"],
    textarea: ["equal", "startsWith", "endsWith", "contains"],
    number: [
        "equal",
        "greaterThan",
        "smallerThan",
        // 'between'
    ],
    email: ["equal"],
    "multiple-selection-list": ["contains"],
    "multiple-selection-dropdown": ["contains"],
    "single-selection-list": ["equal"],
    "single-selection-dropdown": ["equal"],
    datetime: [
        "equal",
        "after",
        "before",
        // 'between'
    ],
    checkbox: ["equal"],
};
