import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Inject, Input,
    OnInit,
    Output,
    ViewChild
} from "@angular/core";
import { FormsMessagingService } from "../forms-messaging.service";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { FORMS_BUILDER_URL } from "../model";

@Component({
    selector: "form-collect",
    templateUrl: "./form-collect.component.html",
    styleUrls: ["./form-collect.component.scss"],
    providers: [FormsMessagingService],
})
export class FormCollectComponent implements OnInit, AfterViewInit {
    @ViewChild("formBuilderContainer") formBuilderContainer: ElementRef;

    vFormsUrl: SafeUrl;

    @Input()
    definition: string;

    @Input()
    formType: string;

    @Input()
    value: any = {};

    @Input()
    bottomActions: any[] = [];

    @Output()
    onSave: EventEmitter<string> = new EventEmitter<string>();

    @Output()
    onExtraAction: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    onCancel: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        @Inject(FORMS_BUILDER_URL) vFormsUrl: string,
        private _messagingService: FormsMessagingService,
        private _sanitizer: DomSanitizer
    ) {
        this.vFormsUrl = this._sanitizer.bypassSecurityTrustResourceUrl(`${vFormsUrl}/vforms/collect`);
    }

    ngOnInit(): void {
        this._messagingService.onMessage.subscribe((message) => {
            let { action, args, sender } = message;
            switch (action) {
                case "ready":
                    this._messagingService.postMessage({
                        action: "load",
                        args: {
                            definition: this.definition,
                            value: this.value,
                            bottomActions: this.bottomActions,
                        },
                    });
                    break;
                case "cancel":
                    this.onCancel.emit(true);
                    break;
                case "save":
                    let data: string = args;
                    this.onSave.emit(data);
                    break;
                case "extraAction":
                    if (sender !== "vforms") return;
                    this.onExtraAction.emit({ actionId: args.actionId, data: args.data });
                    break;
            }
        });
    }

    ngAfterViewInit() {
        this._messagingService.activate(this.formBuilderContainer.nativeElement);
    }
}
