import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { FormPoolComponent } from "./form-pool.component";
import { AbpHttpInterceptor } from "@api";
import { FormFolderClient } from "../api";
import { FolderDetailsComponent } from "./folder-details/folder-details.component";
import { FormPoolSharedModule } from "./form-pool-shared.module";
import { CommonModule } from "@angular/common";
import { TranslocoModule } from "@ngneat/transloco";
import { MatButtonModule } from "@angular/material/button";
import { MatDividerModule } from "@angular/material/divider";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatOptionModule } from "@angular/material/core";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatDialogModule } from "@angular/material/dialog";
import { MatInputModule } from "@angular/material/input";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTreeModule } from "@angular/material/tree";
import { UiDialogModule } from "@ui/ui-dialog";
import { A11yModule } from "@angular/cdk/a11y";
import { ButtonToggleModule, FuseScrollbarModule } from "@nx-workspace/fuse";
import { MatMenuModule } from "@angular/material/menu";
import { DateFormFieldModule, RecurrencePickerModule, VuiModule } from "@vui";
import { FormsWrapperModule } from "@vhealth/vforms-wrapper";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatTooltipModule } from "@angular/material/tooltip";

@NgModule({
    declarations: [FormPoolComponent, FolderDetailsComponent],
    imports: [
        CommonModule,
        TranslocoModule,
        FormsModule,
        ReactiveFormsModule,

        FormPoolSharedModule,
        MatButtonModule,
        MatDividerModule,
        MatFormFieldModule,
        MatIconModule,
        MatOptionModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatSelectModule,
        MatSidenavModule,
        MatSortModule,
        MatTableModule,
        MatDialogModule,
        MatInputModule,
        MatCheckboxModule,
        MatToolbarModule,
        MatTreeModule,
        MatTooltipModule,
        UiDialogModule,
        A11yModule,
        ButtonToggleModule,
        MatMenuModule,
        FuseScrollbarModule,
        VuiModule,
        DateFormFieldModule,
        RecurrencePickerModule,
        FormsWrapperModule,
    ],
    providers: [FormFolderClient, { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true }],
    exports: [FormPoolComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class FormPoolModule {}
