import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Component, OnInit, ViewEncapsulation, OnDestroy, ViewChild, ElementRef, Inject } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Actions, ofType } from "@ngrx/effects";
import { NotifyService } from "@ui/notify";
import { FormBuilder, FormGroup } from "@angular/forms";
import { updateReminderDefinitionAction } from "../store/actions";
import { VuiValidators } from "@vui";
import { TranslocoService } from "@ngneat/transloco";
import { ReminderPoolFacade } from "../store/facade";
import { ActivityItemDefinition } from "../../model";
import { DocumentStatus } from "../../api";
import { MatCheckboxChange } from "@angular/material/checkbox";

@Component({
    selector: "edit-reminder",
    templateUrl: "./edit-reminder.component.html",
    encapsulation: ViewEncapsulation.None,
})
export class EditReminderComponent implements OnInit, OnDestroy {
    private _unsubscribeAll = new Subject();

    isUpdating$: Observable<boolean>;

    form: FormGroup;

    @ViewChild("activityTitle", { read: ElementRef }) activityTitleInput: ElementRef;

    constructor(
        private _dialogRef: MatDialogRef<EditReminderComponent>,
        private _formBuilder: FormBuilder,
        private _facade: ReminderPoolFacade,
        private actions$: Actions,
        private _notifyService: NotifyService,
        private _translateService: TranslocoService,
        @Inject(MAT_DIALOG_DATA) public data: ActivityItemDefinition
    ) {
        this.isUpdating$ = this._facade.updating$;

        this.form = this._formBuilder.group({
            id: [null, VuiValidators.required],
            title: [null, VuiValidators.required],
            comments: [""],
            isPublic: [false],
            documentStatus: [DocumentStatus.Visible],
        });

        this.form.patchValue(data);
    }

    ngOnInit() {
        this.actions$
            .pipe(ofType(updateReminderDefinitionAction.complete), takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this._notifyService.success(
                    this._translateService.translate(
                        "health.engage.reminder-pool.edit-reminder.successfully-message"
                    )
                );
                this._dialogRef.close();
            });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    get documentStatus(): boolean {
        return this.form.get("documentStatus").value === DocumentStatus.Visible;
    }

    documentStatusChange(value: MatCheckboxChange) {
        if (value.checked) {
            this.form.get("documentStatus").setValue(DocumentStatus.Visible);
        } else {
            this.form.get("documentStatus").setValue(DocumentStatus.Hidden);
        }
    }

    save() {
        if (this.form.valid) {
            let data: ActivityItemDefinition = this.form.getRawValue();
            this._facade.updateDefinitonTemplate(data);
        }
    }

    closeDialog() {
        this._dialogRef.close();
    }
}
