import { Component, Inject, ViewEncapsulation } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Subject } from "rxjs";
import { OnDestroy } from "@angular/core";
import { VuiValidators } from "@vui";

@Component({
    selector: "file-name-dialog",
    templateUrl: "./file-name-dialog.component.html",
    encapsulation: ViewEncapsulation.None
})
export class FileNameDialogComponent implements OnDestroy {
    form: FormGroup;

    extension: string;

    private _unsubscribeAll: Subject<any>;

    fileNamePattern = "^[\\w,\\s-]+"

    constructor(public dialogRef: MatDialogRef<FileNameDialogComponent>,
                @Inject(MAT_DIALOG_DATA) private _data: any,) {
        this._unsubscribeAll = new Subject();
        this.extension = this._data.ext;
        this.form = new FormGroup({
            name: new FormControl(this._data.name, [VuiValidators.required]),
        });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    save() {
        this.dialogRef.close(`${this.form.getRawValue().name}${this.extension}`);
    }
}
