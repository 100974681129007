import RRule from "rrule";
import { assign } from "lodash";
import { ProtocolFolder } from "./protocol-pool/store";
import { SmartFormFolder } from "./form-pool/store";
import { ReminderFolder } from "./reminder-pool/store";
import { DocumentStatus, FeedStatus, FormName, ProtocolItemDefinitionDto, UserNameInfo } from "./api";

export class SmartForm {
    id: number | undefined;

    title: string | undefined;

    comments: string | undefined;

    feedStatus: FeedStatus = FeedStatus.Visible;

    //Target
    target: string | undefined;
}

export class SmartFormDefinition {
    name: string | undefined;

    comments: string | undefined;

    feedStatus: FeedStatus = FeedStatus.Visible;

    id: number | undefined;

    parentFolderId: number | undefined;

    parentFolder: SmartFormFolder | undefined;

    definition: string | undefined;

    creator: UserNameInfo | undefined;

    notifyOnAnswered?: boolean;

    displayOnFeed?: boolean;

    horizontalEvolution?: boolean;
}

export abstract class ProtocolItemDefinition implements ProtocolItemDefinitionDto {
    id: number | undefined;

    storeId: number | undefined;

    title: string | undefined;

    comments: string | undefined;

    feedStatus: FeedStatus = FeedStatus.Visible;

    documentStatus: DocumentStatus = DocumentStatus.Visible;

    recurrence?: string;

    discriminator: string;

    isPublic: boolean;
}

export class FormItemDefinition extends ProtocolItemDefinition {
    constructor(recurrence: string = null) {
        super();
        this.id = 0;
        let options = {
            freq: RRule.DAILY,
            interval: 1,
        };
        this.recurrence = recurrence ?? RRule.optionsToString(options);
        this.discriminator = "FormItemDefinitionDto";
    }

    form: FormName | undefined;

    static create(form: FormItemDefinition, id: number, startDate: Date): FormItemDefinition {
        let newActivity = assign(new FormItemDefinition(), form);
        newActivity.id = id;
        let rule = RRule.fromString(newActivity.recurrence);
        rule.options.dtstart = startDate;
        newActivity.recurrence = RRule.optionsToString(rule.options);
        return newActivity;
    }
}

export class ActivityItemDefinition extends ProtocolItemDefinition {
    constructor(recurrence: string = null) {
        super();
        this.id = 0;
        let options = {
            freq: RRule.HOURLY,
            interval: 8,
        };
        this.recurrence = recurrence ?? RRule.optionsToString(options);
        this.discriminator = "ActivityItemDefinitionDto";
    }

    activityId?: number | undefined;

    static create(activity: ActivityItemDefinition, id: number, startDate: Date): ActivityItemDefinition {
        let newActivity = assign(new ActivityItemDefinition(), activity);
        newActivity.id = id;
        let rule = RRule.fromString(newActivity.recurrence);
        rule.options.dtstart = startDate;
        newActivity.recurrence = RRule.optionsToString(rule.options);
        return newActivity;
    }
}

export class ProtocolDefinition {
    id: number | undefined;

    name: string | undefined;

    isPublic: boolean | undefined;

    tenantId: string;

    items: Array<FormItemDefinition | ActivityItemDefinition> = [];

    comments: string | undefined;

    //Recurrence
    recurrence: string | undefined;

    folderId: number | undefined;

    folder: ProtocolFolder | undefined;

    documentId: string | undefined;
}

export class SmartFormFolderNode {
    id: number | undefined;

    title: string;

    parentId: number;

    children?: SmartFormFolderNode[];

    data: SmartFormFolder;

    constructor(data: SmartFormFolder, parentId: number) {
        this.data = data;
        this.title = data.name;
        this.children = data.folders?.map((folder) => new SmartFormFolderNode(folder, data.id));
        this.parentId = parentId;
    }
}

export class SmartFormFolderFlatNode {
    id: number | undefined;

    expandable: boolean;

    title: string;

    parentId: number;

    children?: SmartFormFolderNode[];

    level: number;

    data: SmartFormFolder;
}

export class ReminderFolderNode {
    id: number | undefined;

    title: string;

    parentId: number;

    children?: ReminderFolderNode[];

    data: ReminderFolder;

    constructor(data: ReminderFolder, parentId: number) {
        this.data = data;
        this.title = data.name;
        this.children = data.folders?.map((folder) => new ReminderFolderNode(folder, data.parentFolderId));
        this.parentId = parentId;
    }
}

export class ReminderFolderFlatNode {
    id: number | undefined;

    expandable: boolean;

    title: string;

    parentId: number;

    children?: ReminderFolder[];

    level: number;

    data: ReminderFolder;
}

export class ProtocolFolderNode {
    title: string;

    parentId: number;

    public children?: ProtocolFolderNode[];

    data: ProtocolFolder;

    constructor(data: ProtocolFolder, parentId: number) {
        this.data = data;
        this.title = data.name;
        this.children = data.folders?.map((folder) => new ProtocolFolderNode(folder, data.parentFolderId));
        this.parentId = parentId;
    }
}

/** Flat node with expandable and level information */
export class ProtocolFolderFlatNode {
    expandable: boolean;

    title: string;

    parentId: number;

    children?: ProtocolFolderNode[];

    level: number;

    data: ProtocolFolder;
}

export class Protocol {
    id: number | undefined;

    name: string | undefined;

    isPublic: boolean | undefined;

    tenantId: string;

    items: ProtocolItemDefinition[] = [];

    folderId: number | undefined;

    folder: ProtocolFolder | undefined;

    documentId: string | undefined;

    creationTime: Date | undefined;
}
