import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DialogBaseComponent } from "./dialog-base/dialog-base.component";
import { ReactiveFormsModule } from "@angular/forms";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { FuseScrollbarModule } from "@nx-workspace/fuse";
import { A11yModule } from "@angular/cdk/a11y";

@NgModule({
    imports: [
        CommonModule,

        // fuse
        FuseScrollbarModule,

        // form
        ReactiveFormsModule,

        // material
        MatIconModule,
        MatDialogModule,
        MatButtonModule,
        
        A11yModule,
    ],
    declarations: [DialogBaseComponent],
    exports: [DialogBaseComponent, A11yModule],
})
export class UiDialogModule {}
