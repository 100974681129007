import { Injectable } from "@angular/core";
import { AppConsts } from "@shared/AppConsts";
import { SupportNPSService } from "app/wapps/system/support/support-nps.service";

@Injectable({
    providedIn: "root"
})
export class HtmlInjectorService {

    vMeetExternalUrl = "https://meet.vsaude.com.br/external_api.js";

    wootricScriptUrl = "https://cdn.wootric.com/wootric-sdk.js";

    private browserGlobals = {
        windowRef(): any {
            return window;
        },
        documentRef(): any {
            return document;
        }
    };

    constructor(private _npsService: SupportNPSService) {

    }

    public init() {
        const doc = this.browserGlobals.documentRef();
        
        if (AppConsts.SupportChat === "hubspot") {
            let hubspotChatConfigScript = doc.createElement("script");
            hubspotChatConfigScript.type = "text/javascript";
            hubspotChatConfigScript.innerHTML = "window.hsConversationsSettings = {loadImmediately: false,};";
            hubspotChatConfigScript.id = "hubspot-chat-config";
            doc.getElementsByTagName("head")[0].appendChild(hubspotChatConfigScript);

            let hubspotChatScript = doc.createElement("script");
            hubspotChatScript.id = "hs-script-loader";
            hubspotChatScript.src = "//js.hs-scripts.com/39655218.js";
            hubspotChatScript.type = "text/javascript";
            hubspotChatScript.async = true;
            hubspotChatScript.defer = true;
            doc.getElementsByTagName("head")[0].appendChild(hubspotChatScript);
        }

        if (AppConsts.vMeetEnabled) {
            let script = doc.createElement("script");
            script.id = "vmeet";
            script.src = this.vMeetExternalUrl;
            script.type = "text/javascript";
            script.async = true;
            doc.getElementsByTagName("head")[0].appendChild(script);
        }

        if (AppConsts.WootricEnabled) {
            let script = doc.createElement("script");
            script.id = "wootric-js";
            script.src = this.wootricScriptUrl;
            script.type = "text/javascript";
            script.async = true;
            doc.getElementsByTagName("head")[0].appendChild(script);
            this._npsService.init();
        }
    }
}