import {FuseConfig} from '@fuse/types';

/**
 * Default Fuse Configuration
 *
 * You can edit these options to change the default options. All these options also can be changed per component
 * basis. See `app/main/pages/authentication/login/login.component.ts` constructor method to learn more
 * about changing these options per component basis.
 */

export const fuseConfig: FuseConfig = {
    layout: {
        style: 'vertical-layout-1',
        width: 'fullwidth',
        ionicbar: false,
        navbar: {
            background: 'primary-white-bg',
            folded: true,
            hidden: false,
            position: 'left',
            variant: 'vertical-style-1'
        },
        toolbar: {
            background: 'mat-primary-bg',
            hidden: false,
            position: 'below-fixed',
            showItemProfileInfo: true
        },
        footer: {
            background: 'mat-fuse-dark-900-bg',
            hidden: true,
            position: 'above'
        },
        sidepanel: {
            hidden: false,
            position: 'right',
        }
    },
    customScrollbars: true
};
