import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatIconModule } from "@angular/material/icon";
import { RouterModule } from "@angular/router";
import { FuseScrollbarModule } from "@nx-workspace/fuse";
import { ReactiveFormsModule } from "@angular/forms";
import { TranslocoCoreModule } from "@i18n";
import {MaskedFormFieldComponent} from "./masked-form-field.component";
import {IMaskModule} from "angular-imask";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";

@NgModule({
    declarations: [
        MaskedFormFieldComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        FuseScrollbarModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        TranslocoCoreModule,
        IMaskModule,
        
    ],
    exports: [
        MaskedFormFieldComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class MaskedFormFieldModule {}
