import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {FormControl, FormGroup} from "@angular/forms";
import {cloneDeep} from "lodash-es";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";

@Component({
    selector: "vui-masked-form-field",
    templateUrl: "./masked-form-field.component.html",
})
export class MaskedFormFieldComponent implements OnInit, OnDestroy {
    private _unsubscribeAll = new Subject();

    @Input()
    matDense: boolean = false;

    @Input()
    label: string;

    @Input()
    placeholder: string;

    @Input()
    form: FormGroup;

    @Input()
    controlName: string;

    _mask;

    @Input()
    set mask(mask) {
        this._mask = cloneDeep(mask);
    }

    get mask() {
        return this._mask;
    }

    @Input()
    unmask: boolean = true;

    constructor() {
    }

    ngOnInit(): void {
        let control = this.form.get(this.controlName) as FormControl;
        let validator = this.mask.validator;
        if (validator) {
            control.valueChanges
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(a => {
                    let valid = validator(a);
                    if (valid) {
                        control.setErrors(null);
                        control.markAllAsTouched();
                    } else {
                        control.setErrors({invalid: true});
                        control.markAllAsTouched();
                    }
                });
        }
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
