import { createReducer, on } from "@ngrx/store";
import { FeedState } from "./model";
import * as actions from "./actions";

export const initialState: FeedState = {
    loading: false,
    creating: false,
    error: null,
    patientId: undefined,
    datasource: [],
};

export const getFeedReducer = createReducer(
    initialState,
    on(actions.loadFeedAction.execute, (state: FeedState, { patientId }) => {
        return {
            ...state,
            datasource: [],
            loading: true,
            patientId: patientId,
        };
    }),
    on(actions.loadFeedAction.complete, (state: FeedState, { protocols }) => {
        return {
            ...state,
            loading: false,
            datasource: [...protocols],
        };
    }),
    on(actions.loadFeedAction.failure, (state: FeedState, { error }) => {
        return {
            ...state,
            error: error,
            loading: false,
        };
    }),

    //Create
    on(actions.createProtocolAction.execute, (state: FeedState) => {
        return {
            ...state,
            creating: true,
        };
    }),
    on(actions.createProtocolAction.complete, (state: FeedState, { protocol }) => {
        return {
            ...state,
            creating: false,
            datasource: [protocol, ...state.datasource],
        };
    }),
    on(actions.createProtocolAction.failure, (state: FeedState, { error }) => {
        return {
            ...state,
            error: error,
            creating: false,
        };
    }),

    on(actions.downloadProtocolDocumentAction.execute, (state: FeedState) => {
        return {
            ...state,
            loading: true,
        };
    }),
    on(actions.downloadProtocolDocumentAction.complete, (state: FeedState) => {
        return {
            ...state,
            loading: false,
        };
    }),
    on(actions.downloadProtocolDocumentAction.failure, (state: FeedState, { error }) => {
        return {
            ...state,
            error: error,
            loading: false,
        };
    }),

    on(actions.printProtocolDocumentAction.execute, (state: FeedState) => {
        return {
            ...state,
            loading: true,
        };
    }),
    on(actions.printProtocolDocumentAction.complete, (state: FeedState) => {
        return {
            ...state,
            loading: false,
        };
    }),
    on(actions.printProtocolDocumentAction.failure, (state: FeedState, { error }) => {
        return {
            ...state,
            error: error,
            loading: false,
        };
    }),

    on(actions.showProtocolDocumentAction.execute, (state: FeedState) => {
        return {
            ...state,
            loading: true,
        };
    }),
    on(actions.showProtocolDocumentAction.complete, (state: FeedState) => {
        return {
            ...state,
            loading: false,
        };
    }),
    on(actions.showProtocolDocumentAction.failure, (state: FeedState, { error }) => {
        return {
            ...state,
            error: error,
            loading: false,
        };
    }),

    //Delete
    on(actions.deleteProtocolAction.execute, (state: FeedState) => {
        return {
            ...state,
            loading: true,
        };
    }),
    on(actions.deleteProtocolAction.complete, (state: FeedState, { id }) => {
        return {
            ...state,
            loading: false,
            datasource: [...state.datasource.filter((r) => r.id !== id)],
        };
    }),
    on(actions.deleteProtocolAction.failure, (state: FeedState, { error }) => {
        return {
            ...state,
            error: error,
            loading: false,
        };
    }),

    //Create template
    on(actions.createProtocolTemplateAction.execute, (state: FeedState) => {
        return {
            ...state,
            loading: true,
        };
    }),
    on(actions.createProtocolTemplateAction.complete, (state: FeedState) => {
        return {
            ...state,
            loading: false,
        };
    }),
    on(actions.createProtocolTemplateAction.failure, (state: FeedState, { error }) => {
        return {
            ...state,
            error: error,
            loading: false,
            creating: false,
        };
    }),
    //Send document by email
    on(actions.sendProtocolDocumentByEmailAction.execute, (state: FeedState) => {
        return {
            ...state,
            loading: true,
        };
    }),
    on(actions.sendProtocolDocumentByEmailAction.complete, (state: FeedState) => {
        return {
            ...state,
            loading: false,
        };
    }),
    on(actions.sendProtocolDocumentByEmailAction.failure, (state: FeedState, { error }) => {
        return {
            ...state,
            error: error,
            loading: false,
        };
    })
);
