import { createMask } from "imask";

import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { countries } from "./countries";

export const eightDigtisE164CountryCodes: number[] = [
    countries["Denmark"].code,
    countries["Norway"].code,
    countries["Singapore"].code,
];

export const sevenDigitsE164CountryCodes: number[] = [
    countries["Palau"].code,
    countries["Marshall Islands"].code,
    countries["Aruba"].code,
    countries["Micronesia, Federated States of Micronesia"].code,
    countries["Belize"].code,
    countries["Maldives"].code,
    countries["Solomon Islands"].code,
    countries["Gabon"].code,
    countries["Lebanon"].code,
    countries["Liberia"].code,
];

export class PhoneUtils {
    static toE164Phone(input: string): string {
        if (!input) {
            return null;
        }
        let value = input.replace(/[^\d]/g, "");

        let isEightDigitsInternationalNumber: boolean = eightDigtisE164CountryCodes.includes(
            parseInt(value.slice(0, 2))
        );

        let isSevenDigitsInternationalNumber: boolean = sevenDigitsE164CountryCodes.includes(
            parseInt(value.slice(0, 3))
        );

        let parsedDDD = parseInt(value.slice(0, 2));

        let isBrazillianPhone =
            (value.length === 11 && value[2] === "9") ||
            (!isEightDigitsInternationalNumber &&
                !isSevenDigitsInternationalNumber &&
                value.length === 10 &&
                !isNaN(parsedDDD) &&
                parsedDDD > 10);

        return isBrazillianPhone ? `+55${value}` : `+${value}`;
    }

    static isValidE164Phone(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const phoneNumber = control.value;

            if (!phoneNumber) {
                return null;
            }

            const e164PhonePattern: RegExp = /^\+\d{7,18}$/;

            if (!e164PhonePattern.test(phoneNumber)) {
                return { invalidPhoneNumber: true };
            }

            return null;
        };
    }
}
