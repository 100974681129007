import { Directive, DoCheck } from "@angular/core";
import { AbstractControl, ValidationErrors, Validators, ValidatorFn } from "@angular/forms";
import { MatInput } from "@angular/material/input";

const _clearValidators = AbstractControl.prototype.clearValidators;
AbstractControl.prototype.clearValidators = function () {
    (this as any).isRequired = false;
    _clearValidators.call(this);
}

const _setValidators = AbstractControl.prototype.setValidators;
AbstractControl.prototype.setValidators = function (newValidator: ValidatorFn | ValidatorFn[] | null): void {
    (this as any).isRequired = false;
    _setValidators.call(this, newValidator);
}

export class VuiValidators {
    static required(control: AbstractControl): ValidationErrors | null {
        (control as any).isRequired = true;
        return Validators.required(control);
    }
}

@Directive({selector: "[matInput]:not([required])"})
export class MatInputRequiredDirective implements DoCheck {
    constructor(private readonly input: MatInput) {
    }

    ngDoCheck() {
        const isRequired = (this.input.ngControl && this.input.ngControl.control as any)?.isRequired || false;
        if (isRequired !== this.input.required) {
            this.input.required = isRequired;
            this.input.ngOnChanges();
        }
    }
}
