import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PendingTermsOfServiceSignatureService } from './pending-terms-of-service-signature.service';
import { PendingTermsOfServiceSignatureComponent } from './pending-terms-of-service-signature.component';
import { CoreModule } from '../../core/core.module';
import { CustomMaterialModule } from '../../core/material.module';
import { FuseSharedModule } from '../../../../@fuse/shared.module';

@NgModule({
    declarations: [PendingTermsOfServiceSignatureComponent],
    imports: [CommonModule, CoreModule, CustomMaterialModule, FuseSharedModule],
    providers: [PendingTermsOfServiceSignatureService],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    exports: [PendingTermsOfServiceSignatureComponent],
})
export class PendingTermsOfServiceSignatureModule {}
