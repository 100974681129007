<div class="chat-container" fxLayout="column">

  <div id="messages" class="messages" fxFlex fusePerfectScrollbar #messageArea>

      <ng-container *ngIf="contact">

          <ng-container *ngFor="let dateMessagesGroup of contact.dateMessagesGroups">
              <div class="chat-messages-date-group">{{dateMessagesGroup.date | date:chatService.translations.dateFormat:undefined:chatService.translations.locale}}</div>
              <c360-chat-message *ngFor="let message of dateMessagesGroup.messages"
                                [nick]="message.direction === Direction.in ? contact.name : ''"
                                [avatar]="contact.avatar"
                                [group]="dateMessagesGroup"
                                [message]="message"
                                [contact]="contact"
                                [showAvatars]="showAvatars">
              </c360-chat-message>
          </ng-container>

          <ng-container *ngIf="contact.dateMessagesGroups.length === 0">
              <div class="no-messages" >
                  Ainda não há mensagens.
              </div>
          </ng-container>

      </ng-container>

      <ng-container *ngIf="!contact">

          <div class="no-contact-selected">

              <div class="no-contact-icon">
                  <mat-icon class="s-128">chat</mat-icon>
              </div>

              <div class="no-contact secondary-text">
                  Selecione um contato para começar sua conversa.
                  <!-- Select a contact to start a conversation. -->
              </div>

          </div>

      </ng-container>


  </div>

  <div class="reply-form" fxLayout="row" fxLayoutAlign="center center" *ngIf="contact">

      <form #replyForm="ngForm" (ngSubmit)="reply($event)" (keydown.enter)="reply($event)" fxFlex fxLayout="row"
            fxLayoutAlign="start center">

          <mat-form-field class="message-text-field" fxFlex floatLabel="never" appearance="standard">
                  <textarea matInput #replyInput ngModel name="message" placeholder="Mensagem..." [rows]="1"
                            [matTextareaAutosize]="true"></textarea>
          </mat-form-field>

          <button class="send-message-button" mat-icon-button type="submit" aria-label="Send message">
              <mat-icon class="secondary-text">send</mat-icon>
          </button>

      </form>

  </div>

</div>