import { createAction, props } from "@ngrx/store";
import { createApiActions } from "@store/api-action";
import { ActivityItemDefinition } from "@vhealth/engage";
import { ReminderFolder } from "./model";

export const loadReminderFoldersAction = createApiActions("loadFolders", "Reminders", {
    clientConfig: props<{ folder: ReminderFolder }>(),
    errorConfig: props<{ error: any }>(),
    completeConfig: props<{ folders: ReminderFolder[] }>(),
});

export const createReminderFolderAction = createApiActions("createFolder", "Reminders", {
    clientConfig: props<{ parentFolderId: number | undefined; name: string; isPublic: boolean }>(),
    errorConfig: props<{ error: any }>(),
    completeConfig: props<{ folder: ReminderFolder }>(),
});

export const updateReminderFolderAction = createApiActions("updateFolder", "Reminders", {
    clientConfig: props<{ id: number; name: string; isPublic: boolean }>(),
    errorConfig: props<{ error: any }>(),
    completeConfig: props<{ folder: ReminderFolder }>(),
});

export const deleteReminderFolderAction = createApiActions("deleteFolder", "Reminders", {
    clientConfig: props<{ id: number }>(),
    errorConfig: props<{ error: any }>(),
    completeConfig: props<{ id: number }>(),
});

export const createReminderDefinitionAction = createApiActions("create", "Reminders", {
    clientConfig: props<{ parentFolderId: number; item: ActivityItemDefinition }>(),
    errorConfig: props<{ error: any }>(),
    completeConfig: props<{ item: ActivityItemDefinition }>(),
});

export const updateReminderDefinitionAction = createApiActions("update", "Reminders", {
    clientConfig: props<{ item: ActivityItemDefinition }>(),
    errorConfig: props<{ error: any }>(),
    completeConfig: props<{ item: ActivityItemDefinition }>(),
});

export const moveReminderDefinitionAction = createApiActions("move", "Reminders", {
    clientConfig: props<{ id: number; folderId: number }>(),
    errorConfig: props<{ error: any }>(),
    completeConfig: props<{ id: number; folderId: number }>(),
});

export const deleteReminderDefinitionAction = createApiActions("delete", "Reminders", {
    clientConfig: props<{ id: number }>(),
    errorConfig: props<{ error: any }>(),
    completeConfig: props<{ id: number }>(),
});

export const loadReminderDefinitionsAction = createApiActions("load", "Reminders", {
    clientConfig: props<{ id: number }>(),
    errorConfig: props<{ error: any }>(),
    completeConfig: props<{ items: ActivityItemDefinition[] }>(),
});

export const goBack = createAction("[Reminders] goBack");

export const select = createAction("[Reminders] select", props<{ folderId: number }>());
