import { NgModule, NgZone } from "@angular/core";
import { SnackbarNotifyService } from "./snackbar.notify.service";
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBar, MatSnackBarModule } from "@angular/material/snack-bar";
import { NotifyService } from "./notify.service";

@NgModule({
    declarations: [],
    imports: [MatSnackBarModule],
    providers: [
        { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { horizontalPosition: "end" } },
        { provide: NotifyService, useClass: SnackbarNotifyService, deps: [MatSnackBar, NgZone] },
    ],
})
export class UiNotifyModule {}
