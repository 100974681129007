import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { RouterModule } from "@angular/router";
import { statisticsRoutes } from "./statistics.routing";
import { ApiModule } from "./api/api.module";
import { RootComponent } from "./root.component";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { TranslocoModule } from "@ngneat/transloco";
import { NgApexchartsModule } from "ng-apexcharts";
import { MobileSummaryComponent } from "./mobile/summary.component";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatOptionModule } from "@angular/material/core";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { InstallationHistoryComponent } from "./mobile/installation-history.component";
import { MauHistoryComponent } from "./mobile/mau-history.component";
import { InactiveTenantsComponent } from "./session/inactive-tenants.component";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import * as fromStatsSessionsStore from "./session/store/reducer";
import { StatsSessionEffects } from "./session/store";
import * as fromStatsStore from "./store/reducer";
import { ToolbarComponent } from "./toolbar.component";
import * as fromMobileStatsStore from "./mobile/store/reducer";
import { MobileStatsEffects } from "./mobile/store";
import * as fromAppointmentStatsStore from "./appointments/store/reducer";
import { AppointmentStatsEffects } from "./appointments/store";
import { ApiCommonModule, TenantServiceProxy } from "@api";
import { AppointmentSummaryComponent } from "./appointments/summary.component";
import { DocumentsSummaryComponent } from "./documents/summary.component";
import * as fromDocumentStatsStore from "./documents/store/reducer";
import { DocumentStatsEffects } from "./documents/store";
import { PrescriptionHistoryComponent } from "./documents/prescription-history.component";
import { MatProgressBarModule } from "@angular/material/progress-bar";

@NgModule({
    declarations: [
        RootComponent,
        ToolbarComponent,

        AppointmentSummaryComponent,
        MobileSummaryComponent,
        InstallationHistoryComponent,
        MauHistoryComponent,
        InactiveTenantsComponent,

        DocumentsSummaryComponent,
        PrescriptionHistoryComponent
    ],
    imports: [
        RouterModule.forChild(statisticsRoutes),
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        ApiModule,
        MatButtonModule,
        MatMenuModule,
        MatIconModule,
        MatFormFieldModule,
        MatOptionModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        NgxMatSelectSearchModule,
        MatTooltipModule,
        TranslocoModule,
        NgApexchartsModule,
        MatProgressBarModule,
        ApiCommonModule,

        StoreModule.forFeature(fromStatsSessionsStore.STATS_SESSIONS_FEATURE_KEY, fromStatsSessionsStore.getStatsSessionsReducer),
        StoreModule.forFeature(fromStatsStore.STATS_FEATURE_KEY, fromStatsStore.getStatsReducer),
        StoreModule.forFeature(fromMobileStatsStore.STATS_MOBILE_FEATURE_KEY, fromMobileStatsStore.getMobileStatsReducer),
        StoreModule.forFeature(fromAppointmentStatsStore.STATS_APPOINTMENTS_FEATURE_KEY, fromAppointmentStatsStore.getAppointmentStatsReducer),
        StoreModule.forFeature(fromDocumentStatsStore.STATS_DOCUMENTS_FEATURE_KEY, fromDocumentStatsStore.getDocumentStatsReducer),
        EffectsModule.forFeature([StatsSessionEffects, MobileStatsEffects, AppointmentStatsEffects, DocumentStatsEffects]),
    ],
    providers: [
        TenantServiceProxy,
    ]
})
export class StatisticsModule {
}
