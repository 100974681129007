import { Injectable } from "@angular/core";
import { AppSessionService } from "../session/app-session.service";
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from "@angular/router";
import { AppConsts } from "@shared/AppConsts";
import {
    InvitationServiceServiceProxy,
    MedicalAppointmentServiceServiceProxy,
    TenantInfo
} from "@shared/service-proxies/service-proxies";
import { UrlHelper } from "@shared/helpers/UrlHelper";
import { PermissionCheckerService } from "abp-ng2-module";
import { AppAuthService } from "@shared/auth/app-auth.service";

@Injectable()
export class AppRouteGuard implements CanActivate, CanActivateChild {

    allowedRoles: string[] = [AppConsts.RolesNames.Admin, AppConsts.RolesNames.Doctor, AppConsts.RolesNames.Employee];

    tenantInfo: TenantInfo;

    constructor(
        private _permissionChecker: PermissionCheckerService,
        private _appointmentService: MedicalAppointmentServiceServiceProxy,
        private _invitationService: InvitationServiceServiceProxy,
        private _router: Router,
        private _sessionService: AppSessionService,
        private _authService: AppAuthService) {
        this._sessionService.onTrialPayed
            .subscribe(tenantInfo => {
                if(!!tenantInfo) {
                    this.tenantInfo = tenantInfo;
                    this.checkForTrial();
                }
            });
    }

    checkIfAllowed() : boolean {
        for(let i = 0; i < this.allowedRoles.length; i++) {
            if(this._sessionService.isUserInRoleTenant(this.allowedRoles[i])) {
                return true;
            }
        }
        return false;
    }

    checkForTrial(route: ActivatedRouteSnapshot = null) : boolean {
        if (!this.tenantInfo) {
            return true;
        }

        let mandatoryPayment = !this.tenantInfo.subscriptionInfo || this.tenantInfo.subscriptionInfo?.status.code === 1 || this.tenantInfo.subscriptionInfo?.status.code === 7;
        if (mandatoryPayment) {
            if (route && route.url.findIndex(s => s.path === "checkout") >= 0) {
                return true;
            }
            let params = [];
            for (let key in route.queryParams) {
                params.push(`${key}=${route.queryParams[key]}`);
            }
            this._router.navigateByUrl(`/checkout?returnUrl=%2Fdashboard&mandatory=true&${params.join("&")}`).then();
            return false;
        }
        return true;
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            let queryParams = {
                source: undefined
            };

            let origin = route.url[0] !== undefined ? route.url[0].path : "";
            queryParams["origin"] = origin;

            let baseUrl = route.url.map(s => s.path).join("/");
            let params = [];
            for (let key in route.queryParams) {
                params.push(`${key}=${route.queryParams[key]}`);
            }
            queryParams["returnUrl"] = `${baseUrl}?${params.join("&")}`;

            if (!this._sessionService.user) {
                if(this.accessingAppointmentCall(route)) {
                    this._appointmentService.getOwner(route.url[route.url.length - 1].path).subscribe(owner => {
                        queryParams["source"] = AppConsts.SourceExternal;
                        queryParams["owner"] = owner;
                        this._router.navigate([UrlHelper.loginUrl], {queryParams: queryParams}).then();
                        resolve(false);
                    });
                } else if(origin === "invitation") {
                    queryParams["returnUrl"] = "/invitation?id=" + route.queryParams.id;
                    if(queryParams.source) {
                        queryParams["returnUrl"] = queryParams["returnUrl"] + "&source=" + queryParams.source;
                    }
                    this._invitationService.getOwner(route.queryParams.id).subscribe(owner => {
                        queryParams["owner"] = owner;
                        this._router.navigate([UrlHelper.loginUrl], {queryParams: queryParams}).then();
                        resolve(false);
                    });
                } else {
                    this._router.navigate([UrlHelper.loginUrl], {queryParams: queryParams}).then();
                    resolve(false);
                }
                return false;
            }

            if(this._sessionService.isHostUser()) {
                resolve(true);
                return true;
            }

            if (route.routeConfig.path === 'tenants' && !this._sessionService.isHostUser()) {
                this._router.navigate(['/']).then();
                resolve(false);
                return true;
            }

            if(!queryParams.source || queryParams.source !== AppConsts.SourceExternal) {
                //Check for Trial Mode
                let result = this.checkForTrial(route);
                if(!result) {
                    resolve(false);
                }
            }

            if (this._sessionService.isUserInRoleTenant('User') && !this.checkIfAllowed()) {
                if(this.accessingAppointmentCall(route)) {
                    resolve(true);
                    return true;
                }
                if (origin.indexOf('invitation') >= 0 && this._sessionService.hasActiveInvitation()) {
                    resolve(true);
                    return true;
                }
                if (this._sessionService.hasActiveInvitation()) {
                    this._router.navigate(['/invitation'], {queryParams: {id: this._sessionService.firstActiveInvitation()}}).then();
                    resolve(false);
                    return false;
                }
                //Log the user out, and redirect
                this._authService.logout(true);
                this._router.navigate([UrlHelper.loginUrl], {}).then();
                resolve(false);
                return false;
            }

            if (!this.checkIfAllowed()) {
                //Log the user out, and redirect
                this._authService.logout(true);
                resolve(false);
                return false;
            }
            resolve(true);
            return true;
        });
    }

    accessingAppointmentCall(route: ActivatedRouteSnapshot) : boolean {
        let returnUrl = route.url[0] !== undefined ? route.url.join('/') : "";
        return returnUrl.indexOf("medical-care/call/") >= 0;
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return this.canActivate(route, state);
    }
}
