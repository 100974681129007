import { createSelector } from "@ngrx/store";
import { getSmartFormsState } from "./base";
import { SmartFormFoldersState } from "./model";

export const selectCurrent = createSelector(getSmartFormsState, (state: SmartFormFoldersState) => state.selected);

export const selectFolders = createSelector(getSmartFormsState, (state: SmartFormFoldersState) => state.folders);

export const selectSelectedFolderForms = createSelector(
    getSmartFormsState,
    (state: SmartFormFoldersState) => state.selectedFolderForms
);

export const selectIsLoading = createSelector(getSmartFormsState, (state: SmartFormFoldersState) => state.loading);

export const selectIsLoadingDetails = createSelector(
    getSmartFormsState,
    (state: SmartFormFoldersState) => state.loadingDetails
);

export const selectIsCreating = createSelector(
    getSmartFormsState,
    (state: SmartFormFoldersState) => state.creatingFolder || state.creating
);

export const selectIsUpdating = createSelector(getSmartFormsState, (state: SmartFormFoldersState) => state.updating || state.deleting);

export const selectAtLeastOneFolderExists = createSelector(
    getSmartFormsState,
    (state: SmartFormFoldersState) => state.folders.length > 0
);

export const selectAtLeastOneFormExists = createSelector(
    getSmartFormsState,
    (state: SmartFormFoldersState) => !!state.selected && state.selectedFolderForms.length > 0
);
