import { NgModule } from '@angular/core';
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {NotificationService} from "./notification.service";

@NgModule({
    declarations: [
    ],
    imports: [
        MatSnackBarModule
    ], exports: [
        
    ],
    entryComponents: [
        
    ],
    providers: [
        NotificationService
    ]
})
export class NotificationModule {
}
