import {Injectable, Injector} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpInterceptor, HttpHandler, HttpRequest, HttpEvent} from '@angular/common/http';
import {HealthCareUnitService} from "../health/care-units/health-care-unit.service";

@Injectable()
export class CareUnitHttpInterceptor implements HttpInterceptor {

    protected _service: HealthCareUnitService;

    constructor(service: HealthCareUnitService,
                private _injector: Injector) {
        this._service = service;
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let modifiedRequest = this.normalizeRequestHeaders(request);
        return next.handle(modifiedRequest);
    }

    protected normalizeRequestHeaders(request: HttpRequest<any>): HttpRequest<any> {
        if (this._service.careUnitId) {
            return request.clone({
                headers: request.headers.set("CareUnit", `${this._service.careUnitId}`)
            });
        }
        return request;
    }
}
