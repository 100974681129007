import { Injectable } from "@angular/core";
import { ComponentStore } from "@ngrx/component-store";
import { ActivityClient, ActivityItemHistoryDto } from "../../api";

export interface ActivytPickerState {
    selectedActivity: ActivityItemHistoryDto;
    activities: ActivityItemHistoryDto[];
    loading: boolean;
}

export let mockedActivityList: ActivityItemHistoryDto[] = [];

@Injectable()
export class RecentActivityPickerComponentStore extends ComponentStore<ActivytPickerState> {
    constructor(private _api: ActivityClient) {
        super({ selectedActivity: null, activities: [], loading: false });
    }

    readonly selectedActivity$ = this.select((state) => state.selectedActivity);

    readonly activities$ = this.select((state) => state.activities);

    readonly loading$ = this.select((state) => state.loading);

    loadActivities(keyword: string) {
        if (!keyword) return;
        this.setState((state) => ({
            ...state,
            loading: true,
        }));

        this._api.getActivitiesHistory(keyword).subscribe((activities: ActivityItemHistoryDto[]) => {
            this.setState((state) => ({
                ...state,
                loading: false,
                activities: activities,
            }));
        });
    }

    selectActivity(activity: ActivityItemHistoryDto) {
        this.setState((state) => ({
            ...state,
            selectedActivity: activity,
        }));
    }

    removeFromHistory(activity: ActivityItemHistoryDto) {
        this.setState((state) => ({
            ...state,
            loading: true,
        }));

        this._api.deleteActivityFromHistory(activity.id).subscribe(() => {
            this.setState((state) => ({
                ...state,
                loading: false,
                activities: state.activities.filter((a) => a.id !== activity.id),
            }));
        });
    }
}
