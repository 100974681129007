import { Injectable } from '@angular/core';
import { MonitoringService } from "app/monitoring.service";

export enum LogLevel {
    Disabled = 0,
    Error,
    Warn,
    Info,
    Debug,
}

@Injectable()
export class LogService {

    public logLevel = LogLevel.Info;
    public writer = console;
    
    constructor(private _monitoringService: MonitoringService) {}

    public error(...messages: any[]) {
        if (this.logLevel >= LogLevel.Error) {
            this.writer.error(this.messagePrefix(), ...messages);
            this._monitoringService?.logException(new Error(`${this.messagePrefix()} ${messages.join(' ')}`));
        }
    }

    public warn(...messages: any[]) {
        if (this.logLevel >= LogLevel.Warn) {
            this.writer.warn(this.messagePrefix(), ...messages);
            this._monitoringService?.logTrace(`${this.messagePrefix()} ${messages.join(' ')}`);
        }
    }

    public info(...messages: any[]) {
        if (this.logLevel >= LogLevel.Info) {
            this.writer.info(this.messagePrefix(), ...messages);
            this._monitoringService?.logTrace(`${this.messagePrefix()} ${messages.join(' ')}`);
        }
    }

    public debug(...messages: any[]) {
        if (this.logLevel >= LogLevel.Debug) {
            this.writer.log(this.messagePrefix(), ...messages);
        }
    }

    public messagePrefix() {
        const user = localStorage.getItem('vusade-user-id');
        if (user) {
            return `ChatService[${user}]:`;
        }

        return 'ChatService:';
    }
}
