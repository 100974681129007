import {Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import {Contact} from "../../../../../@ngx-chat/lib/core/contact";
import {Presence} from "../../../../../@ngx-chat/lib/core/presence";
import {ChatService, ChatServiceToken} from "../../../../../@ngx-chat/lib/services/chat-service";
import {UnreadMessageCountPlugin} from "../../../../../@ngx-chat/lib/services/adapters/xmpp/plugins/unread-message-count.plugin";
import {XmppChatService} from "@app/wapps/chat-panel/xmpp/xmpp-chat.service";
import {UserAvatarServiceProxy, UserServiceProxy} from "@shared/service-proxies/service-proxies";
import {dummyAvatar} from "../../../../../@ngx-chat/lib/core/contact-avatar";

@Component({
    selector: 'c360-chat-roster-contact',
    templateUrl: './roster-contact.component.html',
    styleUrls: ['./roster-contact.component.scss']
})
export class C360RosterContactComponent implements OnInit, OnDestroy {

    @Input()
    contact: Contact;

    presence = Presence;

    unreadCount = 0;

    private ngDestroy = new Subject<void>();
    
    active: boolean;

    constructor(@Inject(ChatServiceToken) private chatService: ChatService, private xmppChatService: XmppChatService, 
                private _userAvatarApi: UserAvatarServiceProxy) {
    }

    ngOnInit() {
        this.chatService.getPlugin(UnreadMessageCountPlugin).jidToUnreadCount$
            .pipe(
                map(jidToUnreadCount => jidToUnreadCount[this.contact.jidBare.toString()] || 0),
                distinctUntilChanged(),
                debounceTime(500),
                takeUntil(this.ngDestroy),
            ).subscribe(unreadCount => this.unreadCount = unreadCount);

        this.xmppChatService.selectedContactChanged
            .pipe(takeUntil(this.ngDestroy))
            .subscribe(contact => {
                this.active = contact !== null && contact.equalsBareJid(this.contact);

            });

        this._userAvatarApi.get(this.contact.jidBare.local)
            .pipe(takeUntil(this.ngDestroy))
            .subscribe(avatar => {
                this.contact.avatar = avatar && avatar.length > 0 ? avatar : dummyAvatar;
            });
    }

    ngOnDestroy(): void {
        this.ngDestroy.next();
        this.ngDestroy.complete();
    }
 
    
}
