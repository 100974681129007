import { createReducer, on } from "@ngrx/store";
import { SmartFormFoldersState } from "./model";
import * as actions from "./actions";
import { createFolderTree, findFolderById, flattenFolderDatasource } from "../../utils";

export const initialCashFlowReportState: SmartFormFoldersState = {
    loading: false,
    loadingDetails: false,
    updating: false,
    deleting: false,
    creatingFolder: false,
    creating: false,
    error: null,
    folders: [],
    selected: undefined,
    selectedFolderForms: [],
};

export const getSmartFormsReducer = createReducer(
    initialCashFlowReportState,
    on(actions.loadSmartFormsAction.execute, (state, { folder }) => {
        return {
            ...state,
            loading: true,
        };
    }),
    on(actions.loadSmartFormsAction.complete, (state, { folders }) => {
        return {
            ...state,
            loading: false,
            folders: folders,
        };
    }),
    on(actions.loadSmartFormsAction.failure, (state, { error }) => {
        return {
            ...state,
            loading: false,
            error: error,
        };
    }),
    on(actions.select, (state, { folderId }) => {
        return {
            ...state,
            selected: findFolderById(folderId, state.folders),
        };
    }),
    on(actions.createSmartFormFolderAction.execute, (state) => {
        return {
            ...state,
            creatingFolder: true,
        };
    }),
    on(actions.createSmartFormFolderAction.complete, (state, { folder }) => {
        let flatFolderDatasource = [...flattenFolderDatasource(state.folders), folder];
        let folders = createFolderTree(flatFolderDatasource);
        return {
            ...state,
            folders: folders,
            creatingFolder: false,
        };
    }),
    on(actions.createSmartFormFolderAction.failure, (state, { error }) => {
        return {
            ...state,
            creatingFolder: false,
            error: error,
        };
    }),
    on(actions.updateSmartFormFolderAction.execute, (state) => {
        return {
            ...state,
            updating: true,
        };
    }),
    on(actions.updateSmartFormFolderAction.complete, (state, { folder }) => {
        let flatFolderDatasource = [
            ...flattenFolderDatasource(state.folders).filter((f) => f.id !== folder.id),
            folder,
        ];
        let folders = createFolderTree(flatFolderDatasource);
        return {
            ...state,
            updating: false,
            folders: folders,
        };
    }),
    on(actions.updateSmartFormFolderAction.failure, (state, { error }) => {
        return {
            ...state,
            updating: false,
            error: error,
        };
    }),
    on(actions.deleteSmartFormFolderAction.execute, (state) => {
        return {
            ...state,
            deleting: true,
        };
    }),
    on(actions.deleteSmartFormFolderAction.complete, (state, { id }) => {
        let flatFolderDatasource = flattenFolderDatasource(state.folders).filter((f) => f.id !== id);
        let folders = createFolderTree(flatFolderDatasource);
        return {
            ...state,
            deleting: false,
            selected: state.selected?.id === id ? undefined : state.selected,
            folders: folders,
        };
    }),
    on(actions.deleteSmartFormFolderAction.failure, (state, { error }) => {
        return {
            ...state,
            deleting: false,
            error: error,
        };
    }),
    on(actions.loadSmartFormDefinitionsAction.execute, (state) => {
        return {
            ...state,
            loadingDetails: true,
        };
    }),
    on(actions.loadSmartFormDefinitionsAction.complete, (state, { smartFormDefinitions }) => {
        return {
            ...state,
            loadingDetails: false,
            selectedFolderForms: smartFormDefinitions,
        };
    }),
    on(actions.loadSmartFormDefinitionsAction.failure, (state, { error }) => {
        return {
            ...state,
            loadingDetails: false,
            error: error,
            selectedFolderForms: [],
            selected: undefined,
        };
    }),
    on(actions.createSmartFormDefinitionTemplateAction.execute, (state) => {
        return {
            ...state,
            creating: true,
        };
    }),
    on(actions.createSmartFormDefinitionTemplateAction.complete, (state, { smartFormDefinition }) => {
        return {
            ...state,
            creating: false,
            selectedFolderForms: [...state.selectedFolderForms, smartFormDefinition],
        };
    }),
    on(actions.createSmartFormDefinitionTemplateAction.failure, (state, { error }) => {
        return {
            ...state,
            creating: false,
            error: error,
        };
    }),
    on(actions.updateSmartFormAction.execute, (state, { smartFormDefinition }) => {
        return {
            ...state,
            updating: true,
        };
    }),
    on(actions.updateSmartFormAction.complete, (state, { smartFormDefinition }) => {
        return {
            ...state,
            updating: false,
            selectedFolderForms: state.selectedFolderForms.map((f) =>
                f.id === smartFormDefinition.id ? smartFormDefinition : f
            ),
        };
    }),
    on(actions.updateSmartFormAction.failure, (state, { error }) => {
        return {
            ...state,
            updating: false,
            error: error,
        };
    }),
    on(actions.moveFormDefinitionAction.execute, (state) => {
        return {
            ...state,
            updating: true,
        };
    }),
    on(actions.moveFormDefinitionAction.complete, (state, { id, folderId }) => {
        return {
            ...state,
            updating: false,
            selectedFolderForms: state.selectedFolderForms.filter((f) => f.id !== id),
        };
    }),
    on(actions.moveFormDefinitionAction.failure, (state, { error }) => {
        return {
            ...state,
            updating: false,
            error: error,
        };
    }),
    on(actions.deleteSmartFormAction.execute, (state) => {
        return {
            ...state,
            deleting: true,
        };
    }),
    on(actions.deleteSmartFormAction.complete, (state, { id }) => {
        return {
            ...state,
            deleting: false,
            selectedFolderForms: state.selectedFolderForms.filter((f) => f.id !== id),
        };
    }),
    on(actions.deleteSmartFormAction.failure, (state, { error }) => {
        return {
            ...state,
            deleting: false,
            error: error,
        };
    })
);
