import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Component, OnInit, ViewEncapsulation, OnDestroy, Input, Inject } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { ReminderFolder } from "../store";
import { ReminderPoolFacade } from "../store/facade";
import { takeUntil } from "rxjs/operators";
import { ActivityItemDefinition } from "../../model";

@Component({
    selector: "reminder-picker-dialog",
    templateUrl: "./reminder-picker-dialog.component.html",
    encapsulation: ViewEncapsulation.None,
})
export class ReminderPickerDialogComponent implements OnInit, OnDestroy {
    private _unsubscribeAll = new Subject();

    @Input()
    classes: string[] = ["md:w-60vw", "lg:w-50vw", "xl:w-40vw"];

    folders$: Observable<ReminderFolder[]>;

    selectedFolder$: Observable<ReminderFolder>;

    selectedFolder: ReminderFolder;

    selected: ActivityItemDefinition;

    constructor(
        private _dialogRef: MatDialogRef<ReminderPickerDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _facade: ReminderPoolFacade
    ) {
        this._dialogRef.addPanelClass(["sm-full-screen", "dialog-v2"]);

        this.folders$ = this._facade.folders$;
        this.selectedFolder$ = this._facade.selected$;
    }

    ngOnInit() {
        this.selectedFolder$.pipe(takeUntil(this._unsubscribeAll)).subscribe((selected) => {
            this.selectedFolder = selected;
        });

        let position: any = {
            top: `${this._data.top}px`,
            right: `${window.innerWidth - Number(this._data.right)}px`,
        };

        if (this._data.left) {
            position = {
                top: `${this._data.top}px`,
                left: `${Number(this._data.left)}px`,
            };
        }

        this._dialogRef.updatePosition(position);
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    goBack() {
        this._facade.goBack();
    }

    save() {
        this._dialogRef.close(this.selected);
    }

    closeDialog() {
        this._dialogRef.close();
    }

    handleSelection(selected: ActivityItemDefinition) {
        this.selected = selected;
    }
}
