import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import {
    Component,
    OnInit,
    ViewEncapsulation,
    OnDestroy,
    ChangeDetectorRef,
    ViewChild,
    ElementRef,
    Inject,
} from "@angular/core";
import { Observable, of, Subject } from "rxjs";
import { delay, takeUntil } from "rxjs/operators";
import { Actions, ofType } from "@ngrx/effects";
import { NotifyService } from "@ui/notify";
import { FormBuilder, FormGroup } from "@angular/forms";
import { assign } from "lodash";
import { RecurrencePickerDialogComponent, RecurrenceHelper, VuiValidators } from "@vui";
import { FuseConfirmationService } from "@nx-workspace/fuse";
import { TranslocoService } from "@ngneat/transloco";
import { EditProtocolComponentStore } from "./edit-template-component-store";
import { FeedStatus } from "../store";
import { updateProtocolAction } from "../store/actions";
import { ActivityItemDefinition, FormItemDefinition, ProtocolDefinition } from "../../model";
import { SmartFormPickerDialogComponent } from "../../form-pool/form-definition-picker/smart-form-picker-dialog.component";
import { ReminderPickerDialogComponent } from "../../reminder-pool/reminder-picker/reminder-picker-dialog.component";
import { DocumentStatus } from "../../api";

@Component({
    selector: "edit-template",
    templateUrl: "./edit-template.component.html",
    encapsulation: ViewEncapsulation.None,
    providers: [EditProtocolComponentStore],
})
export class EditProtocolComponent implements OnInit, OnDestroy {
    private _unsubscribeAll = new Subject();

    loading$: Observable<boolean>;

    form: FormGroup;

    formItems$ = this._store.formItems$;

    activityItems$ = this._store.activityItems$;

    readonly validActivities$ = this._store.isValid$;

    readonly editing$ = this._store.editing$;

    readonly editingItem$ = this._store.editingItem$;

    readonly editingForm$ = this._store.editingForm$;

    currentActivityForm: FormGroup;

    feedStatuses = [FeedStatus.Visible, FeedStatus.PatientHidden, FeedStatus.Hidden];

    @ViewChild("activityTitle", { read: ElementRef }) activityTitleInput: ElementRef;

    constructor(
        private _matDialog: MatDialog,
        private _changeDetector: ChangeDetectorRef,
        private _dialogRef: MatDialogRef<EditProtocolComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: ProtocolDefinition,
        private _formBuilder: FormBuilder,
        private _actions$: Actions,
        private _notifyService: NotifyService,
        private _store: EditProtocolComponentStore,
        private _translateService: TranslocoService,
        private _confirmationService: FuseConfirmationService
    ) {
        this.loading$ = this._store.loading$;

        this.form = this._formBuilder.group({
            id: [null, VuiValidators.required],
            name: [null, VuiValidators.required],
            isPublic: [true],
        });

        this.currentActivityForm = this._formBuilder.group({
            id: [null],
            storeId: [null, VuiValidators.required],
            title: [null, VuiValidators.required],
            activityId: [null],
            formId: [null],
            formName: [null],
            comments: [null],
            recurrence: [null],
            feedStatus: [FeedStatus.Visible, VuiValidators.required],
            documentStatus: [DocumentStatus.Visible, VuiValidators.required],
        });

        if (this._data.id) {
            this.form.patchValue({
                id: this._data.id,
                name: this._data.name,
                isPublic: this._data.isPublic,
            });

            for (const activity of this._data?.items) {
                if (activity) {
                    switch (activity.discriminator) {
                        case "FormItemDefinitionDto":
                            let formItem = activity as FormItemDefinition;
                            this._store.addForm(formItem);
                            this.currentActivityForm.patchValue({
                                id: formItem.id,
                                title: formItem.title,
                                comments: formItem.comments,
                                formId: formItem.form.id,
                                formName: formItem.form.name,
                                feedStatus: formItem.feedStatus,
                                documentStatus: formItem.documentStatus,
                                recurrence: formItem.recurrence,
                            });
                            this.acceptFormChanges(this.currentActivityForm.getRawValue());
                            break;
                        case "ActivityItemDefinitionDto":
                            let activityItem = activity as ActivityItemDefinition;
                            this._store.addActivity(activityItem);
                            this.currentActivityForm.patchValue({
                                id: activityItem.id,
                                title: activityItem.title,
                                comments: activityItem.comments,
                                feedStatus: activityItem.feedStatus,
                                documentStatus: activityItem.documentStatus,
                                recurrence: activityItem.recurrence,
                            });
                            this.acceptActivityChanges(this.currentActivityForm.getRawValue());
                            break;
                        default:
                            break;
                    }
                }
            }
        }
    }

    ngOnInit() {
        this._actions$.pipe(ofType(updateProtocolAction.complete), takeUntil(this._unsubscribeAll)).subscribe(() => {
            this._notifyService.success(
                this._translateService.translate("health.engage.protocol-pool.edit-protocol.successfully-message")
            );
            this._dialogRef.close();
        });

        this._store.protocol$.pipe(takeUntil(this._unsubscribeAll)).subscribe((protocol: ProtocolDefinition) => {
            this.form.patchValue({ ...protocol });
        });

        this._store.editingItem$.pipe(takeUntil(this._unsubscribeAll)).subscribe((activity: ActivityItemDefinition) => {
            if (activity) {
                this.currentActivityForm.patchValue({
                    ...activity,
                    storeId: this._store.currentId,
                    activityId: activity.activityId,
                });

                //Focus on the title element
                of(true)
                    .pipe(delay(150))
                    .subscribe(() => {
                        this.activityTitleInput.nativeElement.focus();
                    });
            } else {
                this.currentActivityForm.reset();
                this.currentActivityForm.patchValue({ delivered: true });
            }
            this._changeDetector.detectChanges();
        });

        this._store.editingForm$.pipe(takeUntil(this._unsubscribeAll)).subscribe((activity: FormItemDefinition) => {
            if (activity) {
                this.currentActivityForm.patchValue({
                    ...activity,
                    formName: activity.form?.name,
                    formId: activity.form?.id,
                    storeId: this._store.currentId,
                });

                //Focus on the title element
                of(true)
                    .pipe(delay(150))
                    .subscribe(() => {
                        this.activityTitleInput.nativeElement.focus();
                    });
            } else {
                this.currentActivityForm.reset();
                this.currentActivityForm.patchValue({ delivered: true });
            }
            this._changeDetector.detectChanges();
        });

        this._store.edit(this._data);
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    addForm() {
        this._store.addForm(new FormItemDefinition());
        this._matDialog
            .open(SmartFormPickerDialogComponent)
            .afterClosed()
            .subscribe((result) => {
                if (result) {
                    this.currentActivityForm.patchValue({
                        title: result.name,
                        comments: result.comments,
                        formId: result.id,
                        formName: result.name,
                        feedStatus: result.feedStatus,
                    });
                }
            });
    }

    startWithActivityTemplate(event) {
        if (event instanceof MouseEvent) {
            event.stopPropagation();
        }
        this._matDialog
            .open(ReminderPickerDialogComponent)
            .afterClosed()
            .subscribe((result: ActivityItemDefinition) => {
                if (result) {
                    this.currentActivityForm.patchValue({
                        title: result.title,
                        comments: result.comments,
                        activityId: result.activityId,
                        feedStatus: result.feedStatus,
                    });
                }
            });
    }

    addActivity() {
        this._store.addActivity(new ActivityItemDefinition());
    }

    editForm() {
        this._matDialog
            .open(SmartFormPickerDialogComponent)
            .afterClosed()
            .subscribe((result) => {
                if (result) {
                    this.currentActivityForm.patchValue({
                        title: result.title,
                        comments: result.comments,
                        formId: result.id,
                        formName: result.name,
                        feedStatus: result.feedStatus,
                    });
                }
            });
    }

    updateRecurrence(activity: FormItemDefinition | ActivityItemDefinition) {
        let ref = this._matDialog.open(RecurrencePickerDialogComponent, {
            data: {
                recurrence: activity.recurrence,
            },
        });

        ref.afterClosed().subscribe((recurrence: string | undefined) => {
            if (recurrence) {
                this.currentActivityForm.patchValue({ recurrence });
                activity.recurrence = recurrence;
                this._changeDetector.markForCheck();
            }
        });
    }

    parseRecurrenceText(activity: FormItemDefinition | ActivityItemDefinition) {
        return RecurrenceHelper.parseRecurrenceMessage(activity.recurrence, false);
    }

    acceptFormChanges(activity: FormItemDefinition) {
        let newActivity = assign(activity, {
            ...this.currentActivityForm.getRawValue(),
            form: {
                id: this.currentActivityForm.get("formId").value,
                name: this.currentActivityForm.get("formName").value,
            },
        });
        this._store.acceptFormChanges(newActivity);
    }

    acceptActivityChanges(activity: ActivityItemDefinition) {
        let newActivity = assign(activity, {
            ...this.currentActivityForm.getRawValue(),
            activityId: this.currentActivityForm.get("activityId").value,
            title: this.currentActivityForm.get("title").value,
            comments: this.currentActivityForm.get("comments").value,
            recurrence: this.currentActivityForm.get("recurrence").value,
            feedStatus: this.currentActivityForm.get("feedStatus").value,
            documentStatus: this.currentActivityForm.get("documentStatus").value,
            storeId: this.currentActivityForm.get("storeId").value,
        });
        this._store.acceptActivityChanges(newActivity);
    }

    cancelFormEdition(activity: FormItemDefinition) {
        this._store.cancelFormEdition(activity);
    }

    cancelActivityEdition(activity: ActivityItemDefinition) {
        this._store.cancelActivityEdition(activity);
    }

    startActivityEdition(activity: ActivityItemDefinition) {
        this._store.startActivityEdition(activity);
    }

    startFormEdition(activity: FormItemDefinition) {
        assign(new FormItemDefinition(), {
            ...activity,
            form: {
                id: this.currentActivityForm.get("formId").value,
                name: this.currentActivityForm.get("formName").value,
            },
        });

        this._store.startFormEdition(activity);
    }

    deleteForm(event: any, activity: FormItemDefinition) {
        event.stopPropagation();
        this._confirmationService
            .open({
                title: this._translateService.translate(
                    "health.engage.protocol-pool.edit-protocol.form-deletion-alert-title"
                ),
                message: this._translateService.translate(
                    "health.engage.protocol-pool.edit-protocol.form-deletion-alert-message",
                    { name: activity.title }
                ),
                actions: {
                    confirm: {
                        label: this._translateService.translate("actions.yes"),
                    },
                    cancel: {
                        label: this._translateService.translate("actions.no"),
                    },
                },
            })
            .afterClosed()
            .subscribe((result) => {
                if (result === "confirmed") {
                    this._store.deleteActivity(activity);
                }
            });
    }

    deleteActivity(event: any, activity: ActivityItemDefinition) {
        event.stopPropagation();
        this._confirmationService
            .open({
                title: this._translateService.translate(
                    "health.engage.protocol-pool.edit-protocol.reminder-deletion-alert-title"
                ),
                message: this._translateService.translate(
                    "health.engage.protocol-pool.edit-protocol.reminder-deletion-alert-title",
                    { name: activity.title }
                ),
                actions: {
                    confirm: {
                        label: this._translateService.translate("actions.yes"),
                    },
                    cancel: {
                        label: this._translateService.translate("actions.no"),
                    },
                },
            })
            .afterClosed()
            .subscribe((result) => {
                if (result === "confirmed") {
                    this._store.deleteActivity(activity);
                }
            });
    }

    save() {
        if (this.form.valid) {
            let data = this.form.getRawValue();
            this._store.save(data);
        }
    }

    closeDialog() {
        this._dialogRef.close();
    }
}
