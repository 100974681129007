import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef, OnDestroy,
    ViewChild
} from "@angular/core";
import {
    ApexAxisChartSeries,
    ApexChart,
    ApexDataLabels,
    ApexFill,
    ApexGrid,
    ApexLegend,
    ApexNonAxisChartSeries,
    ApexPlotOptions,
    ApexStroke,
    ApexTitleSubtitle,
    ApexXAxis,
    ApexYAxis,
} from "ng-apexcharts";
import { AppointmentStatsFacade } from "./store/facade";
import { Observable, Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import { GetServiceDurationResult } from "@dashboard-statistics";

export type ChartOptions = {
    series: ApexAxisChartSeries;
    chart: ApexChart;
    dataLabels: ApexDataLabels;
    plotOptions: ApexPlotOptions;
    stroke: ApexStroke;
    xaxis: ApexXAxis;
    yaxis: ApexYAxis;
    colors: string[];
    fill: ApexFill;
    legend: ApexLegend;
    title: ApexTitleSubtitle;
    grid: ApexGrid;
};

export type PieChartOptions = {
    series: ApexNonAxisChartSeries;
    chart: ApexChart;
    labels: any;
    legend: ApexLegend;
    plotOptions: ApexPlotOptions;
};

@Component({
    selector: "appointment-summary",
    templateUrl: "./summary.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppointmentSummaryComponent implements AfterViewInit, OnDestroy {
    private _unsubscribeAll = new Subject();
    
    @ViewChild("graphContainer") graphContainer: ElementRef;

    public totalChartOptions: Partial<ChartOptions>;

    public inPersonChartOptions: Partial<ChartOptions>;

    public onlineChartOptions: Partial<ChartOptions>;

    public attendanceTypeChartOptions: Partial<PieChartOptions>;

    public serviceDuration$: Observable<GetServiceDurationResult>;

    constructor(private _changeDetector: ChangeDetectorRef,
                private _facade: AppointmentStatsFacade,) {
        this.serviceDuration$ = this._facade.serviceDuration$;
    }

    ngAfterViewInit() {
        let chartHeight = this.graphContainer.nativeElement.getBoundingClientRect().height - 16;
        this._facade.scheduledExecuted$
            .pipe(takeUntil(this._unsubscribeAll), filter(stats => !!stats))
            .subscribe(stats => {
                this.totalChartOptions = {
                    series: [
                        {
                            name: "Agendado",
                            data: [stats.total.scheduled],
                            color: "#fd7f6f"
                        },
                        {
                            name: "Realizado",
                            data: [stats.total.executed],
                            color: "#bd7ebe"
                        },
                    ],
                    chart: {
                        type: "bar",
                        height: chartHeight,
                        toolbar: {
                            show: false
                        },
                        zoom: {
                            enabled: false,
                        }
                    },
                    stroke: {
                        width: 1,
                        colors: ["#fff"]
                    },
                    xaxis: {
                        categories: [
                            "",
                        ]
                    },
                    fill: {
                        opacity: 1
                    },
                    yaxis: {
                        show: false,
                    },
                    legend: {
                        position: "bottom",
                        horizontalAlign: "center"
                    },
                    grid: {
                        show: false
                    },
                };

                this.inPersonChartOptions = {
                    series: [
                        {
                            name: "Agendado",
                            data: [stats.inPerson.scheduled],
                            color: "#fd7f6f"
                        },
                        {
                            name: "Realizado",
                            data: [stats.inPerson.executed],
                            color: "#bd7ebe"
                        },
                    ],
                    chart: {
                        type: "bar",
                        height: chartHeight,
                        toolbar: {
                            show: false
                        },
                        zoom: {
                            enabled: false,
                        }
                    },
                    stroke: {
                        width: 1,
                        colors: ["#fff"]
                    },
                    xaxis: {
                        categories: [
                            "",
                        ]
                    },
                    fill: {
                        opacity: 1
                    },
                    yaxis: {
                        show: false,
                    },
                    legend: {
                        position: "bottom",
                        horizontalAlign: "center"
                    },
                    grid: {
                        show: false
                    },
                };

                this.onlineChartOptions = {
                    series: [
                        {
                            name: "Agendado",
                            data: [stats.remotely.scheduled],
                            color: "#fd7f6f"
                        },
                        {
                            name: "Realizado",
                            data: [stats.remotely.executed],
                            color: "#bd7ebe"
                        },
                    ],
                    chart: {
                        type: "bar",
                        height: chartHeight,
                        toolbar: {
                            show: false
                        },
                        zoom: {
                            enabled: false,
                        }
                    },
                    stroke: {
                        width: 1,
                        colors: ["#fff"]
                    },
                    xaxis: {
                        categories: [
                            "",
                        ]
                    },
                    fill: {
                        opacity: 1
                    },
                    yaxis: {
                        show: false,
                    },
                    legend: {
                        position: "bottom",
                        horizontalAlign: "center"
                    },
                    grid: {
                        show: false
                    },
                };

                this.attendanceTypeChartOptions = {
                    series: [
                        stats.inPersonPrivate.executed + stats.remotelyPrivate.executed,
                        stats.inPersonInsurance.executed + stats.remotelyInsurance.executed],
                    chart: {
                        type: "donut",
                        height: chartHeight
                    },
                    labels: ["Particular", "Convênio"],
                    legend: {
                        position: "bottom",
                        horizontalAlign: "center"
                    },
                    plotOptions: {
                        pie: {
                            donut: {
                                size: "60%",
                                labels: {
                                    show: true,
                                    total: {
                                        showAlways: false,
                                        show: true,
                                        // label: "",
                                        color: "#000000"
                                    }
                                }
                            }
                        }
                    },
                };
                
                this._changeDetector.markForCheck();
            });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
