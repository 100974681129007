import { createFeatureSelector, createSelector } from "@ngrx/store";
import { STATS_APPOINTMENTS_FEATURE_KEY } from "./reducer";
import { IAppointmentStatState } from "./model";

export const featureState = createFeatureSelector<IAppointmentStatState>(STATS_APPOINTMENTS_FEATURE_KEY);

export const selectScheduledExecuted = createSelector(featureState, (state) => state.scheduledExecuted);

export const selectServiceDuration = createSelector(featureState, (state) => state.serviceDuration);

export const selectIsLoading = createSelector(featureState, (state) => state.loading);