<!-- PROGRESS BAR -->
<fuse-progress-bar></fuse-progress-bar>
<!-- / PROGRESS BAR -->

<!-- VERTICAL LAYOUT 1 -->
<ng-container *ngIf="fuseConfig.layout.style === 'vertical-layout-1'">
    <vertical-layout-1></vertical-layout-1>
</ng-container>
<!-- / VERTICAL LAYOUT 1 -->

<!-- VERTICAL LAYOUT 2 -->
<ng-container *ngIf="fuseConfig.layout.style === 'vertical-layout-2'">
    <vertical-layout-2></vertical-layout-2>
</ng-container>
<!-- / VERTICAL LAYOUT 2 -->

<!-- VERTICAL LAYOUT 3 -->
<ng-container *ngIf="fuseConfig.layout.style === 'vertical-layout-3'">
    <vertical-layout-3></vertical-layout-3>
</ng-container>
<!-- / VERTICAL LAYOUT 3 -->

<!-- HORIZONTAL LAYOUT 1 -->
<ng-container *ngIf="fuseConfig.layout.style === 'horizontal-layout-1'">
    <horizontal-layout-1></horizontal-layout-1>
</ng-container>
<!-- / HORIZONTAL LAYOUT 1 -->
<div class="centered-tour-element" tourAnchor="start-tour"></div>

<tour-step-template>
  <ng-template let-step="step">
    <mat-card (click)="$event.stopPropagation()" class="bg-primary text-white">
      <mat-card-title class="flex items-center justify-between mb-1.5">
        <div class="text-lg font-semibold tracking-tight leading-none truncate pr-4">
          <span *ngIf="step?.title">{{ step?.title }}</span>
        </div>
        <button mat-icon-button class="title-close -mt-2 -mr-2" (click)="tourService.end()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-card-title>

      <mat-card-content *ngIf="step?.content" class="pr-4 text-default leading-6" [innerHTML]="step?.content"></mat-card-content>

      <mat-card-actions class="w-full">
        <div class="w-full flex justify-between items-center space-x-4">
         <button mat-button (click)="showLater()">
           Mostrar Depois
         </button>

          <div class="w-full flex justify-end items-center space-x-4">
            <button mat-button class="prev" *ngIf="tourService.hasPrev(step)" (click)="tourService.prev()">
              {{ step?.prevBtnTitle }}
            </button>
            <button mat-button class="next" *ngIf="tourService.hasNext(step)" (click)="tourService.next()">
              {{ step?.nextBtnTitle }}
            </button>
            <button mat-button (click)="tourService.end()" *ngIf="!tourService.hasNext(step)">
              {{ step?.endBtnTitle }}
            </button>
          </div>
        </div>
        </mat-card-actions>
    </mat-card>
  </ng-template>
</tour-step-template>
