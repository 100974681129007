import {NgModule} from '@angular/core';
import {MatGoogleMapsAutocompleteModule} from '@angular-material-extensions/google-maps-autocomplete';
import {FuseSharedModule} from '@fuse/shared.module';
import {TextMaskModule} from 'angular2-text-mask';
import {FuseDirectivesModule} from '@fuse/directives/directives';
import {PortugueseMatPaginatorIntl} from "@app/wapps/core/pt-br-paginator-intl";
import {VindiService} from "@app/wapps/core/vindi.service";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CpfCnpjValidService} from "@fuse/components/cpf-cnpj/cpf-cnpj.validator.directive";
import {CreditCardDirectivesModule} from "angular-cc-library";
import {CustomMaterialModule} from "@app/wapps/core/material.module";
import {SupportChatComponent} from "@app/wapps/core/support.chat.component";
import {DynamicScriptLoaderService} from "@app/wapps/core/dynamic-script-loader-service";
import {InfoComponentDialog} from "@app/wapps/core/info-dialog.component";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatPaginatorIntl} from "@angular/material/paginator";
import {OnboardingTutorialComponent} from './onboarding-tutorial/onboarding-tutorial.component';
import {AddressModule} from "@app/wapps/core/address/address.module";
import {PatientAgePipe} from "@shared/patient-age.pipe";

@NgModule({
    declarations: [
        SupportChatComponent,
        InfoComponentDialog,
        OnboardingTutorialComponent,
        PatientAgePipe
    ],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CustomMaterialModule,
        TextMaskModule,
        FuseSharedModule,
        MatDatepickerModule,
        MatProgressBarModule,
        MatGoogleMapsAutocompleteModule,
        FuseDirectivesModule,
        CreditCardDirectivesModule,
        AddressModule
    ], 
    exports: [
        CustomMaterialModule,
        FormsModule,
        ReactiveFormsModule,
        TextMaskModule,
        FuseSharedModule,
        CreditCardDirectivesModule,
        InfoComponentDialog,
        PatientAgePipe
    ],
    entryComponents: [
        SupportChatComponent,
        InfoComponentDialog,
        OnboardingTutorialComponent
    ],
    providers: [
        {provide: MatPaginatorIntl, useClass: PortugueseMatPaginatorIntl},
        VindiService,
        CpfCnpjValidService,
        DynamicScriptLoaderService
    ]
})
export class CoreModule {
}
