import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class HealthEngageCommunicationService {
    private chatVisibilitySubject = new Subject<boolean>();

    chatVisibility$ = this.chatVisibilitySubject.asObservable();

    constructor() {}

    emitChatVisibility(visible: boolean) {
        this.chatVisibilitySubject.next(visible);
    }
}
