import { NgModule } from "@angular/core";
import { FuseSharedModule } from "@fuse/shared.module";
import { NgxChatModule } from "@ngx-chat/lib/ngx-chat.module";
import { XmppComponent } from "@app/wapps/chat-panel/xmpp/xmpp.component";
import { RouterModule } from "@angular/router";
import { C360RosterContactComponent } from "@app/wapps/chat-panel/xmpp/roster-contact/roster-contact.component";
import { C360RosterListComponent } from "@app/wapps/chat-panel/xmpp/roster-list/roster-list.component";
import { XmppChatService } from "@app/wapps/chat-panel/xmpp/xmpp-chat.service";
import { C360ChatMessageListComponent } from "@app/wapps/chat-panel/xmpp/chat-message-list/chat-message-list.component";
import { C360ChatMessageComponent } from "@app/wapps/chat-panel/xmpp/chat-message/chat-message.component";
import { DatePipe } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatRippleModule } from "@angular/material/core";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { ChatPanelService } from "app/wapps/chat-panel/chat-panel.service";
import { FuseScrollbarModule } from "@nx-workspace/fuse";

const routes = [
    {
        path: "xmpp-chat",
        component: XmppComponent
    }
];

@NgModule({
    declarations: [
        XmppComponent,
        C360RosterContactComponent,
        C360RosterListComponent,
        C360ChatMessageListComponent,
        C360ChatMessageComponent
    ],
    providers: [
        XmppChatService,
        DatePipe,
        ChatPanelService,
    ],
    imports: [
        RouterModule.forChild(routes),
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatTabsModule,
        MatTooltipModule,
        MatRippleModule,
        MatProgressSpinnerModule,

        FuseSharedModule,

        // AngularFireModule.initializeApp(environment.firebase),

        NgxChatModule.forRoot(),
        FuseScrollbarModule
    ],
    exports: [
        XmppComponent,
        C360RosterContactComponent,
        C360RosterListComponent,
        C360ChatMessageListComponent,
        C360ChatMessageComponent
    ],
})
export class ChatPanelWappModule {
}
