import { Directive, Input, Injectable } from '@angular/core';
import { Validator, NG_VALIDATORS, AbstractControl, ValidationErrors } from '@angular/forms';
// import * as CpfCnpjValidator from  'cpf-cnpj-validator';
// const Joi = require('joi').extend(require('cpf-cnpj-validator'));
// const STRICT_STRIP_REGEX$1 = /[-\\/.]/g;
// const LOOSE_STRIP_REGEX$1 = /[^\d]/g;

// @Directive({
//   selector: '[appCpfCnpjValidate]',
//   providers: [{
//     provide: NG_VALIDATORS,
//     useExisting: CpfCnpjValidatorDirective,
//     multi: true
//   }]
// })
// // [ngModel]
// // extends CpfCnpjValidator
// export class CpfCnpjValidatorDirective implements Validator {
//   @Input("appCpfCnpjValidate") cpfCnpj: any;
//   private _onChange: () => void;
//   validate(c: AbstractControl): ValidationErrors {
//     // return WappsValidator.ValidateCpfCnpj(c);
//     console.log("directive", c);
//     return WappsValidator.ValidateCpf(c);
//   }
//   registerOnValidatorChange?(fn: () => void): void {
//     this._onChange = fn;
//   }
// }

// export class WappsValidator {
//   static ValidateCpfCnpj(control: AbstractControl): ValidationErrors {


//     let value = control.value.replace(/[^0-9]/g, '');
//     let isCpf = false;
//     let isCnpj = false;
//     let isValid = false;
//     // Verifica CPF
//     if (value.length === 11) {
//       isCpf = true;
//     }

//     // Verifica CNPJ
//     else if (value.length === 14) {
//       isCnpj = false;
//     }

//     if (isCpf) {
//       // valida o CPF
//       // isValid = CpfCnpjValidator.cpf.isValid(value,LOOSE_STRIP_REGEX$1);
//       // #=> true

//     } else if (isCnpj) {

//       // valida o CNPJ
//       // isValid = CpfCnpjValidator.cnpj.isValid(value,LOOSE_STRIP_REGEX$1);
//       // #=> true
//     }
//     else {
//       return { invalidCpfCnpj: true };
//     }

//     if (isValid) return null;

//     return { invalidCpfCnpj: true };


//   }

//   static ValidateCpf(control: AbstractControl): ValidationErrors {
//     const cpf = control.value;

//     let sum: number = 0;
//     let resto: number;
//     let valid: boolean;

//     const regex = new RegExp('[0-9]{11}');

//     if (
//       cpf == '00000000000' ||
//       cpf == '11111111111' ||
//       cpf == '22222222222' ||
//       cpf == '33333333333' ||
//       cpf == '44444444444' ||
//       cpf == '55555555555' ||
//       cpf == '66666666666' ||
//       cpf == '77777777777' ||
//       cpf == '88888888888' ||
//       cpf == '99999999999' ||
//       !regex.test(cpf)
//     )
//       valid = false;
//     else {
//       for (let i = 1; i <= 9; i++)
//         sum = sum + parseInt(cpf.substring(i - 1, i)) * (11 - i);
//       resto = (sum * 10) % 11;

//       if (resto == 10 || resto == 11) resto = 0;
//       if (resto != parseInt(cpf.substring(9, 10))) valid = false;

//       sum = 0;
//       for (let i = 1; i <= 10; i++)
//         sum = sum + parseInt(cpf.substring(i - 1, i)) * (12 - i);
//       resto = (sum * 10) % 11;

//       if (resto == 10 || resto == 11) resto = 0;
//       if (resto != parseInt(cpf.substring(10, 11))) valid = false;
//       valid = true;
//     }

//     if (valid) return null;

//     return { invalidCPF: true };
//   }

//   // static MaiorQue18Anos(controle: AbstractControl) {
//   //   const nascimento = controle.value;
//   //   const [ano, mes, dia] = nascimento.split('-');
//   //   const hoje = new Date();
//   //   const dataNascimento = new Date(ano, mes, dia, 0, 0, 0);
//   //   const tempoParaTeste = 1000 * 60 * 60 * 24 * 365 * 18; //18 anos em mili segundos...

//   //   if (hoje.getTime() - dataNascimento.getTime() >= tempoParaTeste)
//   //     return null;

//   //   return { menorDeIdade: true };
//   // }

//   // static SenhasCombinam(controle: AbstractControl) {
//   //   let senha = controle.get('senha').value;
//   //   let confirmarSenha = controle.get('confirmarSenha').value;

//   //   if (senha === confirmarSenha) return null;

//   //   controle.get('confirmarSenha').setErrors({ senhasNaoCoincidem: true });
//   // }
// }

@Injectable()
export class CpfCnpjValidService {
  /*
   verifica_cpf_cnpj
   
   Verifica se é CPF ou CNPJ
   
   @see http://www.todoespacoonline.com/w/
  */

  constructor() {
  }

  verify_cpf_cnpj(value) {
    if(!value) {
      return false;
    }
    // Garante que o valor é uma string
    value = value.toString();

    // Remove caracteres inválidos do valor
    value = value.replace(/[^0-9]/g, '');

    // Verifica CPF
    if (value.length === 11) {
      return 'CPF';
    }

    // Verifica CNPJ
    else if (value.length === 14) {
      return 'CNPJ';
    }

    // Não retorna nada
    else {
      return false;
    }

  } // verifica_cpf_cnpj

  verifyCpf(value) {
    value = value.toString();
    value = value.replace(/[^0-9]/g, '');
    return value.length === 11;     

  }

  /*
  calc_digitos_posicoes
  
  Multiplica dígitos vezes posições
  
  @param string digitos Os digitos desejados
  @param string posicoes A posição que vai iniciar a regressão
  @param string soma_digitos A soma das multiplicações entre posições e dígitos
  @return string Os dígitos enviados concatenados com o último dígito
  */
  calc_digits_positions(digits, positions = 10, sum_digits = 0) {

    // Garante que o valor é uma string
    digits = digits.toString();

    // Faz a soma dos dígitos com a posição
    // Ex. para 10 posições:
    //   0    2    5    4    6    2    8    8   4
    // x10   x9   x8   x7   x6   x5   x4   x3  x2
    //   0 + 18 + 40 + 28 + 36 + 10 + 32 + 24 + 8 = 196
    for (var i = 0; i < digits.length; i++) {
      // Preenche a soma com o dígito vezes a posição
      sum_digits = sum_digits + (digits[i] * positions);

      // Subtrai 1 da posição
      positions--;

      // Parte específica para CNPJ
      // Ex.: 5-4-3-2-9-8-7-6-5-4-3-2
      if (positions < 2) {
        // Retorno a posição para 9
        positions = 9;
      }
    }

    // Captura o resto da divisão entre soma_digitos dividido por 11
    // Ex.: 196 % 11 = 9
    sum_digits = sum_digits % 11;

    // Verifica se soma_digitos é menor que 2
    if (sum_digits < 2) {
      // soma_digitos agora será zero
      sum_digits = 0;
    } else {
      // Se for maior que 2, o resultado é 11 menos soma_digitos
      // Ex.: 11 - 9 = 2
      // Nosso dígito procurado é 2
      sum_digits = 11 - sum_digits;
    }

    // Concatena mais um dígito aos primeiro nove dígitos
    // Ex.: 025462884 + 2 = 0254628842
    var cpf = digits + sum_digits;

    // Retorna
    return cpf;

  } // calc_digitos_posicoes

  /*
  Valida CPF
  
  Valida se for CPF
  
  @param  string cpf O CPF com ou sem pontos e traço
  @return bool True para CPF correto - False para CPF incorreto
  */
  validate_cpf(value) {
    if(!value) {
      return false;
    }
    // Garante que o valor é uma string
    value = value.toString();

    // Remove caracteres inválidos do valor
    value = value.replace(/[^0-9]/g, '');

    let blackList = ["00000000000", "11111111111", "22222222222", "33333333333", "44444444444",
      "55555555555", "66666666666", "77777777777", "88888888888", "99999999999"];
    if (blackList.indexOf(value) != -1)
      return false;

    // Captura os 9 primeiros dígitos do CPF
    // Ex.: 02546288423 = 025462884
    var digitos = value.substr(0, 9);

    // Faz o cálculo dos 9 primeiros dígitos do CPF para obter o primeiro dígito
    var novo_cpf = this.calc_digits_positions(digitos);

    // Faz o cálculo dos 10 dígitos do CPF para obter o último dígito
    var novo_cpf = this.calc_digits_positions(novo_cpf, 11);

    // Verifica se o novo CPF gerado é idêntico ao CPF enviado
    if (novo_cpf === value) {
      // CPF válido
      return true;
    } else {
      // CPF inválido
      return false;
    }

  } // valida_cpf

  /*
  valida_cnpj
  
  Valida se for um CNPJ
  
  @param string cnpj
  @return bool true para CNPJ correto
  */
  validate_cnpj(value) {
    if(!value) {
      return false;
    }
    // Garante que o valor é uma string
    value = value.toString();

    // Remove caracteres inválidos do valor
    value = value.replace(/[^0-9]/g, '');


    // O valor original
    var cnpj_original = value;

    // Captura os primeiros 12 números do CNPJ
    var primeiros_numeros_cnpj = value.substr(0, 12);

    // Faz o primeiro cálculo
    var primeiro_calculo = this.calc_digits_positions(primeiros_numeros_cnpj, 5);

    // O segundo cálculo é a mesma coisa do primeiro, porém, começa na posição 6
    var segundo_calculo = this.calc_digits_positions(primeiro_calculo, 6);

    // Concatena o segundo dígito ao CNPJ
    var cnpj = segundo_calculo;

    // Verifica se o CNPJ gerado é idêntico ao enviado
    if (cnpj === cnpj_original) {
      return true;
    }

    // Retorna falso por padrão
    return false;

  } // valida_cnpj

  /*
  valida_cpf_cnpj
  
  Valida o CPF ou CNPJ
  
  @access public
  @return bool true para válido, false para inválido
  */
  validate_cpf_cnpj(valor) {
    
    // Verifica se é CPF ou CNPJ
    let valida = this.verify_cpf_cnpj(valor);
    
    if(!valida) {
      return false;
    }

    // Garante que o valor é uma string
    valor = valor.toString();

    // Remove caracteres inválidos do valor
    valor = valor.replace(/[^0-9]/g, '');


    // Valida CPF
    if (valida === 'CPF') {
      // Retorna true para cpf válido
      return this.validate_cpf(valor);
    }

    // Valida CNPJ
    else if (valida === 'CNPJ') {
      // Retorna true para CNPJ válido
      return this.validate_cnpj(valor);
    }

    // Não retorna nada
    else {
      return false;
    }

  } // valida_cpf_cnpj

  /*
  formata_cpf_cnpj
  
  Formata um CPF ou CNPJ
  
  @access public
  @return string CPF ou CNPJ formatado
  */
  format_cpf_cnpj(valor) {

    // O valor formatado
    let formatado: any = false;

    // Verifica se é CPF ou CNPJ
    var valida = this.verify_cpf_cnpj(valor);

    // Garante que o valor é uma string
    valor = valor.toString();

    // Remove caracteres inválidos do valor
    valor = valor.replace(/[^0-9]/g, '');


    // Valida CPF
    if (valida === 'CPF') {

      // Verifica se o CPF é válido
      if (this.validate_cpf(valor)) {

        // Formata o CPF ###.###.###-##
        formatado = valor.substr(0, 3) + '.';
        formatado += valor.substr(3, 3) + '.';
        formatado += valor.substr(6, 3) + '-';
        formatado += valor.substr(9, 2) + '';

      }

    }

    // Valida CNPJ
    else if (valida === 'CNPJ') {

      // Verifica se o CNPJ é válido
      if (this.validate_cnpj(valor)) {

        // Formata o CNPJ ##.###.###/####-##
        formatado = valor.substr(0, 2) + '.';
        formatado += valor.substr(2, 3) + '.';
        formatado += valor.substr(5, 3) + '/';
        formatado += valor.substr(8, 4) + '-';
        formatado += valor.substr(12, 14) + '';

      }

    }

    // Retorna o valor 
    return formatado;

  } // formata_cpf_cnpj
}