import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: 'form-view-dialog',
    templateUrl: './form-preview-dialog.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['form-preview-dialog.component.scss']
})
export class FormPreviewDialogComponent implements OnInit {

    public definition: string;

    constructor(
        public dialogRef: MatDialogRef<FormPreviewDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public _data: any) {
        this.dialogRef.addPanelClass('form-preview-dialog');
        this.definition = _data.definition;
    }

    ngOnInit(): void {

    }

    onCancel() {
        this.dialogRef.close();
    }
}
