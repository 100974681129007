<ng-container *transloco="let t; read: 'notification'">
  <div class="absolute inset-0 flex flex-col min-w-0 overflow-hidden">
    <div class="flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between p-4 border-b dark:bg-transparent">
      <div class="flex flex-row items-center space-x-2">
        <button mat-icon-button title="Voltar" [routerLink]="'../'">
          <mat-icon [svgIcon]="'heroicons_outline:arrow-narrow-left'"></mat-icon>
        </button>
        <div class="space-y-2">
          <div class="section-title">{{ t("title") }}</div>
        </div>
      </div>
    </div>

    <div class="container-vertical-scroll" fuseScrollbar>
      <table mat-table [dataSource]="rows" class="w-full">
        <ng-container matColumnDef="emptyFooter">
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="icon">
          <th *matHeaderCellDef class="pl-6 w-18 text-base font-semibold tracking-tight !text-[#4d4d4d]"></th>
          <td mat-cell *matCellDef="let item">
            <mat-icon>{{ item.icon }}</mat-icon>
          </td>
        </ng-container>

        <ng-container matColumnDef="title">
          <th *matHeaderCellDef class="text-base font-semibold tracking-tight !text-[#4d4d4d]">
            {{ t("column.title") }}
          </th>
          <td mat-cell *matCellDef="let item">{{ item.title }}</td>
        </ng-container>

        <ng-container matColumnDef="subtitle">
          <th *matHeaderCellDef class="text-base font-semibold tracking-tight !text-[#4d4d4d]">
            {{ t("column.subtitle") }}
          </th>
          <td mat-cell *matCellDef="let item">{{ item.subtitle }}</td>
        </ng-container>

        <ng-container matColumnDef="id">
          <th *matHeaderCellDef class="text-base font-semibold tracking-tight !text-[#4d4d4d]"></th>
          <td mat-cell *matCellDef="let item">
            <div class="flex items-center justify-end space-x-4 pr-4">
              <button *ngIf="!item.isRead" mat-icon-button color="primary" (click)="markAsRead($event, item)"
                [matTooltip]="l('Marcar como lida')">
                <mat-icon class="mat-icon material-icons">check</mat-icon>
              </button>

              <button mat-icon-button (click)="onDelete($event, item)" [matTooltip]="l('Excluir')">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>

        <tr class="border-b" mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr class="hover:bg-gray-50 cursor-pointer" mat-row *matRowDef="let row; columns: displayedColumns"
          (click)="onSelect(row)"></tr>
      </table>
    </div>

    <div class="flex items-center justify-between border-t min-h-16">
      <mat-paginator [length]="totalItems" [pageSize]="pageSize" [hidePageSize]="true" (page)="onPageChanged($event)"
        class="bg-gray-50 dark:bg-transparent" [ngClass]="{ 'pointer-events-none': false }"
        [showFirstLastButtons]="true"></mat-paginator>
      <div class="flex space-x-4">
        <button mat-stroked-button color="warn" (click)="deleteAll()">{{ l("Excluir todas") }}</button>

        <button mat-stroked-button color="primary" (click)="markAllAsRead()">
          {{ l("Pages.Notifications.Action.MarkAllAsRead.Title") }}
        </button>
      </div>
    </div>
  </div>
</ng-container>