import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import { Observable, ReplaySubject, Subject } from "rxjs";
import { ThemePalette } from "@angular/material/core";
import { ReminderPoolFacade } from "../store/facade";
import { ReminderFolder, actions } from "../store";
import { ActivityItemDefinition } from "../../model";
import { MatDialog } from "@angular/material/dialog";
import { take, takeUntil } from "rxjs/operators";
import { FormControl, FormGroup } from "@angular/forms";
import { FuseConfirmationService } from "@nx-workspace/fuse";
import { TranslocoService } from "@ngneat/transloco";
import { DatePipe } from "@angular/common";
import { Router } from "@angular/router";
import { CreateReminderComponent } from "../create-reminder/create-reminder.component";
import { EditReminderComponent } from "../edit-reminder/edit-reminder.component";
import { MoveFolderDialogComponent } from "../folder-picker/move-folder-dialog/move-folder-dialog.component";
import { NotifyService } from "@ui/notify";
import { Actions, ofType } from "@ngrx/effects";

@Component({
    selector: "folder-details",
    templateUrl: "./folder-details.component.html",
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [DatePipe],
})
export class FolderDetailsComponent implements OnInit, OnDestroy {
    @ViewChild("messageInput") messageInput: ElementRef;

    drawerMode: "over" | "side" = "side";

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    background: ThemePalette = undefined;

    selected$: Observable<ReminderFolder>;

    isLoading$: Observable<boolean>;

    isUpdating$: Observable<boolean>;

    selected: ReminderFolder;

    selectedFolderItems$: Observable<ActivityItemDefinition[]>;

    filteredForms$: ReplaySubject<ActivityItemDefinition[]> = new ReplaySubject<ActivityItemDefinition[]>(1);

    isThereAnyForm$: Observable<boolean>;

    displayedColumns: string[] = ["name", "description", "creationTime", "creatorUserName", "symbol"];

    searchForm: FormGroup = new FormGroup({
        text: new FormControl(""),
    });

    constructor(
        public _facade: ReminderPoolFacade,
        private _router: Router,
        private _matDialog: MatDialog,
        private _confirmationService: FuseConfirmationService,
        private _translateService: TranslocoService,
        private _notifyService: NotifyService,
        private _actions: Actions
    ) {
        this.selected$ = _facade.selected$;

        this.selectedFolderItems$ = _facade.selectedFolderItems$;

        this.isThereAnyForm$ = _facade.isThereAnyForm$;

        this.isLoading$ = _facade._loadingDetails$;

        this.isUpdating$ = _facade.updating$;
    }

    ngOnInit(): void {
        this.selected$.pipe(takeUntil(this._unsubscribeAll)).subscribe((selected) => {
            if (selected) {
                this.selected = selected;
                this._facade.loadReminderAction(this.selected.id);
            }
        });

        this.selectedFolderItems$.pipe(takeUntil(this._unsubscribeAll)).subscribe((forms) => {
            this.filteredForms$.next(forms);
        });

        this.searchForm
            .get("text")
            .valueChanges.pipe(takeUntil(this._unsubscribeAll))
            .subscribe((text) => {
                this.applyFilter(text.toLowerCase());
            });

        this._actions
            .pipe(takeUntil(this._unsubscribeAll), ofType(actions.deleteReminderDefinitionAction.complete))
            .subscribe((action) => {
                this._notifyService.success(
                    this._translateService.translate(
                        "health.engage.reminder-pool.delete-reminder.successfully-message"
                    )
                );
            });

        this._actions
            .pipe(takeUntil(this._unsubscribeAll), ofType(actions.deleteReminderDefinitionAction.failure))
            .subscribe((action) => {
                this._notifyService.error(
                    this._translateService.translate("health.engage.reminder-pool.delete-reminder.error-message")
                );
            });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    createFormDefinitionTemplate() {
        this._matDialog.open(CreateReminderComponent, {
            panelClass: "create-reminder-dialog",
            data: {
                parentFolderId: this.selected.id,
            },
        });
    }

    updateFormDefinitionTemplate(form: ActivityItemDefinition) {
        this._matDialog.open(EditReminderComponent, {
            panelClass: "edit-reminder-dialog",
            data: {
                parentFolderId: this.selected.id,
                title: form.title,
                comments: form.comments,
                id: form.id,
                isPublic: form.isPublic,
                documentStatus: form.documentStatus,
            },
        });
    }

    deleteTemplate(form: ActivityItemDefinition) {
        this._confirmationService
            .open({
                title: this._translateService.translate("health.engage.reminder-pool.delete-reminder.alert-title"),
                message: this._translateService.translate(
                    "health.engage.reminder-pool.delete-reminder.alert-message",
                    {
                        name: form.title,
                    }
                ),
                actions: {
                    confirm: {
                        label: this._translateService.translate("ACTION_DELETE"),
                        color: "warn",
                    },
                    cancel: {
                        label: this._translateService.translate("ACTION_CANCEL"),
                    },
                },
            })
            .afterClosed()
            .subscribe((result) => {
                if (result === "confirmed") {
                    this._facade.deleteTemplate(form.id);
                }
            });
    }

    moveFormDefinition(item: ActivityItemDefinition) {
        this._matDialog.open(MoveFolderDialogComponent, {
            panelClass: "reminder-folder-picker-dialog",
            data: {
                itemId: item.id,
            },
        });
    }

    duplicateTemplate(form: ActivityItemDefinition) {
        this._matDialog.open(CreateReminderComponent, {
            panelClass: "create-reminder-dialog",
            data: {
                parentFolderId: this.selected.id,
                title: form.title + " - Cópia",
                comments: form.comments,
                id: form.id,
                isPublic: form.isPublic,
                documentStatus: form.documentStatus,
            },
        });
    }

    applyFilter(text: string) {
        this.selectedFolderItems$.pipe(take(1)).subscribe((forms) => {
            let filteredForms = forms.filter((form) => {
                return form.title.toLowerCase().includes(text.toLowerCase());
            });
            this.filteredForms$.next(filteredForms);
        });
    }

    redirectToSettings() {
        this._facade.select(undefined);
        this._router.navigate(["/reminders/settings"]);
    }
}
