import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { IMaskModule } from "angular-imask";
import { FileNameDialogComponent } from "./file-name-dialog.component";
import { TranslocoModule } from "@ngneat/transloco";
import { UiDialogModule } from "@ui/ui-dialog";
import { A11yModule } from "@angular/cdk/a11y";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatDialogModule } from "@angular/material/dialog";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";

@NgModule({
    declarations: [
        FileNameDialogComponent,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        IMaskModule,
        TranslocoModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatDialogModule,
        UiDialogModule,
        A11yModule,
    ],
    exports: [
        FileNameDialogComponent,
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]
})
export class FileNameDialogModule {
}
