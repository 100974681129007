import { Injectable } from '@angular/core';
import {
    SessionServiceProxy,
    UserLoginInfoDto,
    TenantLoginInfoDto,
    ApplicationInfoDto,
    GetCurrentLoginInformationsOutput,
    AuthenticateResultModel,
    TenantInfo, HealthTenantServiceProxy
} from '@shared/service-proxies/service-proxies'
import {BehaviorSubject, Subscription} from 'rxjs';
import {AppConsts} from "@shared/AppConsts";
import {AppAuthService} from "@shared/auth/app-auth.service";
import {AbpMultiTenancyService} from "abp-ng2-module";

@Injectable({
    providedIn: 'root'
})
export class AppSessionService {

    private _user: UserLoginInfoDto;

    private _tenant: TenantLoginInfoDto;

    private _application: ApplicationInfoDto;

    onSessionChanged: BehaviorSubject<any>;

    onTenantInfoChanged: BehaviorSubject<any>;

    tenantInfo: TenantInfo;

    onTrialPayed: BehaviorSubject<TenantInfo>;

    checkTrialSubscription: Subscription;

    welcomeMessageDisplayed: boolean;

    constructor(
        private _sessionService: SessionServiceProxy,
        private _authService: AppAuthService,
        private _healthTenantService: HealthTenantServiceProxy,
        private _abpMultiTenancyService: AbpMultiTenancyService) {
        this.onSessionChanged = new BehaviorSubject(null);
        this.onTenantInfoChanged = new BehaviorSubject(null);
        this.onTrialPayed = new BehaviorSubject(null);

        this._authService.onLogin.subscribe(value => {
            if(!!value && value) {
                this.welcomeMessageDisplayed = abp.setting.getBoolean("WelcomeMessageDisplayed");
                this.checkTrial();
            }
        });

        this._authService.onLogout.subscribe(value => {
            this.onSessionChanged.next(null);
            if(!!value && value && !!this.checkTrialSubscription) {
                this.checkTrialSubscription.unsubscribe();
            }
        });
    }

    checkTrial() {
        this.checkTrialSubscription = this._healthTenantService.getTenantInfo().subscribe((info: TenantInfo) => {
            this.tenantInfo = info;
            this.onTenantInfoChanged.next(info);
            this.onTrialPayed.next(this.tenantInfo);
        }, (() => {

        }));
    }

    clearTrial() {
        this.tenantInfo.subscriptionInfo.status.code = 4;
        this.onTenantInfoChanged.next(this.tenantInfo);
        this.onTrialPayed.next(this.tenantInfo);
    }

    get application(): ApplicationInfoDto {
        return this._application;
    }

    get user(): UserLoginInfoDto {
        return this._user;
    }

    get userId(): number {
        return this.user ? this.user.id : null;
    }

    get tenant(): TenantLoginInfoDto {
        return this._tenant;
    }

    get tenantId(): number {
        return this.tenant ? this.tenant.id : null;
    }

    getShownLoginName(): string {
        let userName = this._user.userName;
        if (!this._abpMultiTenancyService.isEnabled) {
            return userName;
        }

        return (this._tenant ? this._tenant.tenancyName : ".") + "\\" + userName;
    }

    init(): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this._sessionService.getCurrentLoginInformations().toPromise().then((result: GetCurrentLoginInformationsOutput) => {
                this._application = result.application;
                this._user = result.user;
                localStorage.setItem('vusade-user-id', this._user?.id.toString());
                if (!!result.user ) {
                    if (!!result.user.avatar) {
                        this._user.avatar = result.user.avatar + "?d=" + (new Date()).getTime();
                    }
                }
                this._tenant = result.tenant;
                this.onSessionChanged.next(this._user);
                resolve(true);
            }, (err) => {
                reject(err);
            });
        });
    }

    reloadTenantInfo(proposedName: string) {
        this.tenant.name = proposedName;
        this.onTenantInfoChanged.next(this.tenant);
        this._sessionService.getCurrentLoginInformations().subscribe((result: GetCurrentLoginInformationsOutput) => {
            this._tenant = result.tenant;
            this.onTenantInfoChanged.next(result.tenant);
        });
    }

    trialPayed(tenantInfo: TenantInfo) {
        this.tenantInfo = tenantInfo;
        this.onTrialPayed.next(tenantInfo);
    }

    changeTenantIfNeeded(tenantId?: number): boolean {
        if (this.isCurrentTenant(tenantId)) {
            return false;
        }

        abp.multiTenancy.setTenantIdCookie(tenantId);
        location.reload();
        return true;
    }

    private isCurrentTenant(tenantId?: number) {
        if (!tenantId && this.tenant) {
            return false;
        } else if (tenantId && (!this.tenant || this.tenant.id !== tenantId)) {
            return false;
        }

        return true;
    }

    isUserInRoleTenant(role: string): boolean {
        return this.user && this.user.roleNames && this.user.roleNames.indexOf(role) >= 0;
    }

    isDoctor(): boolean {
        return this.isUserInRoleTenant(AppConsts.RolesNames.Doctor);
    }

    isAdmin(): boolean {
        return this.isUserInRoleTenant(AppConsts.RolesNames.Admin);
    }

    isEmployee(): boolean {
        return this.isUserInRoleTenant(AppConsts.RolesNames.Employee);
    }

    hasActiveInvitation(): boolean {
        return this.user.hasActiveInvitation;
    }

    firstActiveInvitation(): string {
        return this.user.firstActiveInvitation;
    }

    tenantHasAtLeastOneMobileApp(): boolean {
        return this.tenant.hasMobileApplication;
    }

    isImpersonating() : boolean {
        let impersonatorUserId = this._authService.getClaim("http://www.aspnetboilerplate.com/identity/claims/impersonatorUserId");
        return impersonatorUserId && Number.parseInt(impersonatorUserId) > 0;
    }

    isHostUser(): boolean {
        return !this.tenantId || this.isImpersonating();
    }

    checkSession(): Promise<boolean> {
        return this.init();
    }

    changeTenant(info: TenantLoginInfoDto) : Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this._sessionService.changeTenant(info).toPromise().then((result: AuthenticateResultModel) => {
                this._authService.processAuthenticateResult(result, true, AppConsts.appBaseUrl);
                resolve(true);
            }, (err) => {
                reject(err);
            });
        });
    }

    isLocked() {
        return this.tenantInfo !== undefined && this.tenantInfo.subscriptionInfo?.status.code === 1;
    }

    clear() {
        this._user = null;
    }
}
