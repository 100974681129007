import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { SmartFormCollectComponent } from "./form/smart-form-collect.component";
import { SmartFormCollectCompletedComponent } from "./form/smart-form-collect-completed.component";
import { SmartFormCollectCanceledComponent } from "./form/smart-form-collect-canceled.component";
import { SmartFormCollectUnavailableComponent } from "./form/smart-form-collect-unavailable.component";
import { ActivityCollectComponent } from "./activity/activity-collect.component";
import { ActivityCollectCompletedComponent } from "./activity/activity-collect-completed.component";
import { ActivityCollectCanceledComponent } from "./activity/activity-collect-canceled.component";
import { ActivityCollectUnavailableComponent } from "./activity/activity-collect-unavailable.component";

const routes: Routes = [
    {
        path: "",
        children: [
            {
                path: ":itemId/activity/:timestamp",
                component: ActivityCollectComponent,
            },
            {
                path: ":itemId/activity/:timestamp/completed",
                component: ActivityCollectCompletedComponent,
            },
            {
                path: ":itemId/activity/:timestamp/canceled",
                component: ActivityCollectCanceledComponent,
            },
            {
                path: ":itemId/activity/:timestamp/locked",
                component: ActivityCollectUnavailableComponent,
            },
            {
                path: ":itemId/form/:formId/:timestamp",
                component: SmartFormCollectComponent,
            },
            {
                path: ":itemId/form/:formId/:timestamp/completed",
                component: SmartFormCollectCompletedComponent,
            },
            {
                path: ":itemId/form/:formId/:timestamp/canceled",
                component: SmartFormCollectCanceledComponent,
            },
            {
                path: ":itemId/form/:formId/:timestamp/locked",
                component: SmartFormCollectUnavailableComponent,
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class EngagePublicRoutingModule { }
