import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { NotificationSettingsFacade } from "./store";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
    selector: "notification-settings",
    templateUrl: "./settings.component.html",
})
export class NotificationSettingsComponent implements OnInit {
    _unsubscribeAll = new Subject();

    allowScheduleNotifications: boolean;

    allowPreFormResponseNotifications: boolean;

    allowScheduleNotifications$: Observable<boolean>;

    allowPreFormResponseNotifications$: Observable<boolean>;

    loading$: Observable<boolean>;

    constructor(private _location: Location, private _facade: NotificationSettingsFacade) {
        this.loading$ = _facade.loading$;

        this.allowPreFormResponseNotifications$ = _facade.allowPreFormResponseNotifications$;

        this.allowScheduleNotifications$ = _facade.allowScheduleNotifications$;
    }

    ngOnInit() {
        this._facade.getNotificationSettings();

        this.allowScheduleNotifications$.pipe(takeUntil(this._unsubscribeAll)).subscribe((value) => {
            this.allowScheduleNotifications = value;
        });

        this.allowPreFormResponseNotifications$.pipe(takeUntil(this._unsubscribeAll)).subscribe((value) => {
            this.allowPreFormResponseNotifications = value;
        });
    }

    navigateBack() {
        this._location.back();
    }

    allowScheduleNotificationsChange(event) {
        this._facade.allowScheduleNotificationsChange(event.checked);
    }

    allowPreFormResponseNotificationsChange(event) {
        this._facade.allowPreFormResponseNotificationsChange(event.checked);
    }
}
