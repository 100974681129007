<mat-form-field class="fuse-mat-no-subscript w-full" [formGroup]="form" [ngClass]="{'fuse-mat-dense': matDense}">
    <mat-label *ngIf="label">{{ label }}</mat-label>
    <input
        type="text"
        matInput
        [imask]="mask"
        [unmask]="unmask"
        [placeholder]="placeholder"
        [formControlName]="controlName"/>
</mat-form-field>
