export const FinancialPermissionNames = {

    Root:  "Valor.Financial",

    Configuration:  "Valor.Financial.Config",

    Revenues:  "Valor.Financial.Revenues",
    QueryRevenues:  "Valor.Financial.Revenues.List",
    AddRevenue:  "Valor.Financial.Revenues.Add",
    DeleteRevenue:  "Valor.Financial.Revenues.Delete",
    UpdateRevenue:  "Valor.Financial.Revenues.Update",
    ExportRevenue:  "Valor.Financial.Revenues.Export",

    Expenses:  "Valor.Financial.Expenses",
    QueryExpenses:  "Valor.Financial.Expenses.List",
    AddExpense:  "Valor.Financial.Expenses.Add",
    DeleteExpense:  "Valor.Financial.Expenses.Delete",
    UpdateExpense:  "Valor.Financial.Expenses.Update",
    ExportExpense: "Valor.Financial.Expenses.Export",

    Reports:  "Valor.Financial.Reports",
    CashFlow:  "Valor.Financial.Reports.CashFlow",
};

export const AllPermissionName = [
    FinancialPermissionNames.Root,

    FinancialPermissionNames.Revenues,
    FinancialPermissionNames.QueryRevenues,
    FinancialPermissionNames.AddRevenue,
    FinancialPermissionNames.DeleteRevenue,
    FinancialPermissionNames.UpdateRevenue,

    FinancialPermissionNames.Expenses,
    FinancialPermissionNames.QueryExpenses,
    FinancialPermissionNames.AddExpense,
    FinancialPermissionNames.DeleteExpense,
    FinancialPermissionNames.UpdateExpense,

    FinancialPermissionNames.Reports,
    FinancialPermissionNames.CashFlow
];