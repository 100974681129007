<ng-container *transloco="let t; read: 'vui.recurrence-picker'">
    <vui-dialog [allowCancelTouchingOutside]="true" [title]="t('TITLE')" [classes]="['sm:w-40vw lg:w-20vw']">
        <div
            class="flex-auto h-full p-6 overflow-y-auto overscroll-y-contain sm:overflow-hidden sm:overscroll-auto"
            fuseScrollbar
        >
            <form [formGroup]="form">
                <div class="flex flex-col space-y-6">
                    <div class="flex flex-col space-y-2" *ngIf="allowPickStartDate">
                        <mat-label>{{ "Horário de início" }}</mat-label>
                        <div class="flex flex-row space-x-2">
                            <vui-date-form-field
                                class="w-48"
                                [form]="form"
                                [placeholder]="'Data'"
                                [controlName]="'dtstart'"
                            >
                            </vui-date-form-field>
                            <vui-masked-form-field
                                class="w-24"
                                [mask]="timeMask"
                                [unmask]="false"
                                [form]="form"
                                [placeholder]="'Horário'"
                                [controlName]="'start'"
                            >
                            </vui-masked-form-field>
                        </div>
                    </div>

                    <div class="flex flex-row space-x-2 items-center">
                        <mat-checkbox formControlName="allowByHourDefinition">
                            {{ t("ALLOW_BY_HOUR_DEFINITION") }}
                        </mat-checkbox>
                    </div>

                    <div class="flex flex-col space-y-2">
                        <mat-label>{{ t("FIELD_FREQ_TITLE") }}</mat-label>
                        <div class="flex flex-row space-x-2 items-center">
                            <mat-form-field class="fuse-mat-no-subscript w-20">
                                <input matInput type="number" [placeholder]="'1'" formControlName="interval" min="1" />
                            </mat-form-field>
                            <mat-form-field class="fuse-mat-no-subscript w-40">
                                <mat-select formControlName="freq">
                                    <mat-option *ngFor="let freq of frequencyOptions" [value]="freq.id">{{
                                        freq.text
                                    }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="flex flex-col space-y-2" *ngIf="allowPickDayOfWeek">
                        <mat-label>{{ t("WEEKLY_DAY_PICKER") }}</mat-label>
                        <div>
                            <mat-button-toggle-group multiple="multiple" formControlName="byweekday">
                                <mat-button-toggle
                                    *ngFor="let option of repeatOptions"
                                    [value]="option.day"
                                    [checked]="option.checked"
                                    >{{ option.text }}</mat-button-toggle
                                >
                            </mat-button-toggle-group>
                        </div>
                    </div>

                    <div class="flex flex-col space-y-2" *ngIf="allowByHourDefinition">
                        <mat-label>{{ t("BY_HOUR_TITLE") }}</mat-label>
                        <span class="text-sm text-hint">{{ t("BY_HOUR_HINT") }}</span>
                        <div *ngFor="let byhour of byHourArray.controls; let i = index" class="flex flex-row space-x-2">
                            <mat-form-field class="fuse-mat-no-subscript w-20">
                                <mat-select [formControl]="getByHourControlByIndex(i)">
                                    <mat-option *ngFor="let hour of availableHours" [value]="hour">
                                        {{ hour }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <button mat-icon-button color="warn" (click)="removeByHour(i)">
                                <mat-icon svgIcon="feather:trash-2"></mat-icon>
                            </button>
                        </div>
                        <mat-error *ngIf="hasMinLengthError">{{ t("BY_HOUR_MIN_LENGTH") }}</mat-error>
                        <div>
                            <button mat-stroked-button (click)="addHour()" color="primary">
                                {{ t("ADD_HOUR") }}
                            </button>
                        </div>
                    </div>

                    <div class="flex flex-col space-y-4">
                        <mat-label>{{ t("END_DEF_TITLE") }}</mat-label>

                        <mat-radio-group formControlName="finishType" class="flex flex-col space-y-2">
                            <div class="flex flex-row space-x-2 pb-3">
                                <mat-radio-button value="never">{{ t("END_NEVER") }}</mat-radio-button>
                            </div>

                            <div class="flex flex-row space-x-2 items-center">
                                <mat-radio-button value="endDate" class="w-32">{{ t("END_AT") }}</mat-radio-button>

                                <div class="flex flex-row space-x-2 items-center">
                                    <mat-form-field class="fuse-mat-no-subscript w-20">
                                        <input
                                            matInput
                                            type="number"
                                            [placeholder]="'1'"
                                            formControlName="endInterval"
                                            min="1"
                                        />
                                    </mat-form-field>
                                    <mat-form-field class="fuse-mat-no-subscript w-40">
                                        <mat-select formControlName="endFreq">
                                            <mat-option *ngFor="let freq of frequencyOptions" [value]="freq.id">{{
                                                freq.text
                                            }}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="flex flex-row space-x-2 items-center">
                                <mat-radio-button value="until" class="w-32">{{ t("END_AT") }}</mat-radio-button>

                                <vui-date-form-field class="w-48" [form]="form" [controlName]="'until'">
                                </vui-date-form-field>
                            </div>

                            <div class="flex flex-row space-x-2 items-center">
                                <mat-radio-button value="count" class="w-32">{{ t("END_AFTER") }}</mat-radio-button>
                                <mat-form-field class="fuse-mat-no-subscript w-48">
                                    <input matInput type="number" min="1" formControlName="count" />
                                    <span matSuffix>{{ t("END_AFTER_OCCURRENCE_HINT") }}</span>
                                </mat-form-field>
                            </div>
                        </mat-radio-group>
                    </div>

                    <span class="text-primary">{{ recurrenceText }}</span>
                </div>
            </form>
        </div>

        <dialog-actions class="flex flex-row items-center space-x-4">
            <button mat-stroked-button mat-dialog-close>
                {{ t("ACTION_CANCEL") }}
            </button>
            <button mat-flat-button [disabled]="!form.valid" (click)="save()" color="primary">
                {{ t("ACTION_ACCEPT") }}
            </button>
        </dialog-actions>
    </vui-dialog>
</ng-container>
