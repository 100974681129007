import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { FormsBuilderComponent } from "./builder/forms-builder.component";
import { FormsPreviewComponent } from "./preview/forms-preview.component";
import { FormPreviewDialogComponent } from "./preview/dialog/form-preview-dialog.component";
import { FormCollectComponent } from "./collect/form-collect.component";
import { FormAnswerViewerComponent } from "./answer-viewer/answer-viewer.component";
import { MatDialogModule } from "@angular/material/dialog";
import { FormOptionsComponent } from './options/form-options.component';
import { TranslocoCoreModule } from '@i18n';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableModule } from '@angular/material/table';
import { MatMenuModule } from '@angular/material/menu';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { A11yModule } from '@angular/cdk/a11y';

@NgModule({
    declarations: [
        FormsBuilderComponent,
        FormsPreviewComponent,
        FormPreviewDialogComponent,
        FormCollectComponent,
        FormAnswerViewerComponent,
        FormOptionsComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        MatDialogModule,
        TranslocoCoreModule,
        MatIconModule,
        MatButtonModule,
        MatCheckboxModule,
        MatTableModule,
        MatMenuModule,
        A11yModule,
    ],
    providers: [],
    exports: [
        FormsBuilderComponent,
        FormsPreviewComponent,
        FormPreviewDialogComponent,
        FormCollectComponent,
        FormAnswerViewerComponent,
        FormOptionsComponent
    ]
})
export class FormsWrapperModule {
}

