import { createSelector } from "@ngrx/store";
import { getProtocolsState } from "./base";
import { ProtocolsState } from "./model";

export const selectCurrent = createSelector(getProtocolsState, (state: ProtocolsState) => state.selected);

export const selectAll = createSelector(getProtocolsState, (state: ProtocolsState) => state.folders);

export const selectSelectedFolderProtocols = createSelector(
    getProtocolsState,
    (state: ProtocolsState) => state.selectedFolderProtocols
);

export const selectIsLoading = createSelector(getProtocolsState, (state: ProtocolsState) => state.loading);

export const selectLoadingDetails = createSelector(getProtocolsState, (state: ProtocolsState) => state.loadingDetails);

export const selectIsCreating = createSelector(
    getProtocolsState,
    (state: ProtocolsState) => state.creatingFolder || state.creating
);

export const selectIsUpdating = createSelector(getProtocolsState, (state: ProtocolsState) => state.updating || state.deleting);

export const selectAtLeastOneFolderExists = createSelector(
    getProtocolsState,
    (state: ProtocolsState) => state.folders.length > 0
);

export const selectAtLeastOneProtocolExists = createSelector(
    getProtocolsState,
    (state: ProtocolsState) => !!state.selected && state.selectedFolderProtocols.length > 0
);
