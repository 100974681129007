<div>
    <div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">
        <h2>{{ data.title }}</h2>
        <button mat-icon-button (click)="close()" *ngIf="!data.required">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div *ngIf="!termPage">
        <mat-dialog-content class="mat-typography">
            <div mat-dialog-content class="mat-typography" [innerHtml]="description | async"></div>
        </mat-dialog-content>
        <div mat-dialog-actions align="end">
            <button mat-button (click)="close()" *ngIf="!data.required">Fechar</button>
            <button mat-flat-button color="accent" (click)="next()">Próximo</button>
        </div>
    </div>

    <div *ngIf="termPage">
        <div mat-dialog-content class="mat-typography" (scroll)="handleScroll($event)" [innerHtml]="content | async"></div>
        <div mat-dialog-actions align="end">
            <button mat-button (click)="close()" *ngIf="!data.required">Ignorar por enquanto</button>
            <button mat-flat-button color="primary" [disabled]="disabled" (click)="sign()">Assinar</button>
        </div>
    </div>
</div>
