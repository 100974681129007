import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap } from "rxjs/operators";
import * as actions from "./actions";
import { of } from "rxjs";
import { assign } from "lodash";
import {
    CreateSmartFormFolderRequest,
    FormDefinitionClient,
    FormFolderClient,
    SmartFormDefinitionDto,
    SmartFormFolderDto,
} from "@vhealth/engage";
import { SmartFormDefinition } from "@vhealth/engage";
import { SmartFormFolder } from "./model";
import { createFolderTree } from "../../utils";
import { CreateSmartFormDefinitionRequest, UpdateSmartFormDefinitionRequest } from "../../api";

@Injectable()
export class SmartFormTemplatesEffects {
    constructor(
        private actions$: Actions,
        private _definitionApi: FormDefinitionClient,
        private _folderApi: FormFolderClient
    ) { }

    load$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(actions.loadSmartFormsAction.execute),
                mergeMap(({ folder }) => {
                    return this._folderApi.list().pipe(
                        map((result: SmartFormFolderDto[]) => {
                            let folders: SmartFormFolder[] = createFolderTree<SmartFormFolder>(
                                result.map((r) => assign(new SmartFormFolder(), r))
                            );

                            return actions.loadSmartFormsAction.complete({
                                folders: folders,
                            });
                        }),
                        catchError((error) => of(actions.loadSmartFormsAction.failure({ error: error })))
                    );
                })
            ),
        { dispatch: true }
    );

    createFolder$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(actions.createSmartFormFolderAction.execute),
                mergeMap(({ parentFolderId, name, isPublic }) => {
                    let request: CreateSmartFormFolderRequest = {
                        name: name,
                        isPublic: isPublic,
                    };

                    return this._folderApi.create(request, parentFolderId).pipe(
                        map((result) =>
                            actions.createSmartFormFolderAction.complete({
                                folder: assign(new SmartFormFolder(), result),
                            })
                        ),
                        catchError((error) => of(actions.createSmartFormFolderAction.failure({ error: error })))
                    );
                })
            ),
        { dispatch: true }
    );

    updateFolder$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(actions.updateSmartFormFolderAction.execute),
                mergeMap(({ id, name, isPublic }) => {
                    let request = {
                        name: name,
                        isPublic: isPublic,
                    };
                    return this._folderApi.update(id, request).pipe(
                        map((result) =>
                            actions.updateSmartFormFolderAction.complete({
                                folder: assign(new SmartFormFolder(), result),
                            })
                        ),
                        catchError((error) => of(actions.updateSmartFormFolderAction.failure({ error: error })))
                    );
                })
            ),
        { dispatch: true }
    );

    deleteFolder$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(actions.deleteSmartFormFolderAction.execute),
                mergeMap(({ id }) =>
                    this._folderApi.delete(id).pipe(
                        map(() => actions.deleteSmartFormFolderAction.complete({ id })),
                        catchError((error) => of(actions.deleteSmartFormFolderAction.failure({ error: error })))
                    )
                )
            ),
        { dispatch: true }
    );

    createSmartFormDefinitionTemplate$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(actions.createSmartFormDefinitionTemplateAction.execute),
                mergeMap(({ smartFormDefinition }) => {
                    let request: CreateSmartFormDefinitionRequest = {
                        ...smartFormDefinition,
                    };
                    return this._folderApi.createForm(smartFormDefinition.parentFolderId, request).pipe(
                        map((result) =>
                            actions.createSmartFormDefinitionTemplateAction.complete({
                                smartFormDefinition: assign(new SmartFormDefinition(), {
                                    ...result,
                                    creationTime: new Date(),
                                }),
                            })
                        ),
                        catchError((error) =>
                            of(actions.createSmartFormDefinitionTemplateAction.failure({ error: error }))
                        )
                    );
                })
            ),
        { dispatch: true }
    );

    updateTemplate$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(actions.updateSmartFormAction.execute),
                mergeMap(({ smartFormDefinition }) => {
                    let request: UpdateSmartFormDefinitionRequest = {
                        ...smartFormDefinition,
                    };

                    return this._definitionApi.update(smartFormDefinition.id, request).pipe(
                        map((result) =>
                            actions.updateSmartFormAction.complete({
                                smartFormDefinition: assign(new SmartFormDefinition(), {
                                    ...result,
                                    creationTime: new Date(),
                                }),
                            })
                        ),
                        catchError((error) => of(actions.updateSmartFormAction.failure({ error: error })))
                    );
                })
            ),
        { dispatch: true }
    );

    moveDefinition$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(actions.moveFormDefinitionAction.execute),
                mergeMap(({ id, folderId }) =>
                    this._definitionApi.move(id, folderId).pipe(
                        map(() => actions.moveFormDefinitionAction.complete({ id, folderId })),
                        catchError((error) => of(actions.moveFormDefinitionAction.failure({ error: error })))
                    )
                )
            ),
        { dispatch: true }
    );

    deleteTemplate$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(actions.deleteSmartFormAction.execute),
                mergeMap(({ id }) =>
                    this._definitionApi.delete(id).pipe(
                        map(() => actions.deleteSmartFormAction.complete({ id })),
                        catchError((error) => of(actions.deleteSmartFormAction.failure({ error: error })))
                    )
                )
            ),
        { dispatch: true }
    );

    loadTemplate$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(actions.loadSmartFormDefinitionsAction.execute),
                mergeMap(({ id }) =>
                    this._folderApi.forms(id).pipe(
                        map((result: SmartFormDefinitionDto[]) =>
                            actions.loadSmartFormDefinitionsAction.complete({
                                smartFormDefinitions: result.map((r) => assign(new SmartFormDefinition(), r)),
                            })
                        ),
                        catchError((error) => of(actions.loadSmartFormDefinitionsAction.failure({ error: error })))
                    )
                )
            ),
        { dispatch: true }
    );
}
