import { Injectable } from "@angular/core";
import { AppSessionService } from "@shared/session/app-session.service";
import { FeatureCheckerService } from "abp-ng2-module";
import { AppConsts } from "shared/AppConsts";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class ChatPanelService {
    chatEnabled: BehaviorSubject<boolean>;
    

    constructor(
        private _sessionService: AppSessionService,
        private _featureChecker: FeatureCheckerService,
    ) {
        this.chatEnabled = new BehaviorSubject<boolean>(this._featureChecker.isEnabled(AppConsts.Features.InternalChat));
        
        this._sessionService.onTrialPayed
            .subscribe(tenantInfo => {
                if (!!tenantInfo) {
                    this.chatEnabled.next(this._featureChecker.isEnabled(AppConsts.Features.InternalChat));
                }
            });
    }
}