import { AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { isValid, parse } from "date-fns";
import { createDateMask, dateMaskPlaceholder, dateFormat } from "@vui";
import { VuiValidators } from "@vui";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
    selector: "vui-date-form-field",
    templateUrl: "./date-form-field.component.html",
})
export class DateFormFieldComponent implements AfterViewInit, OnChanges, OnDestroy {
    private _unsubscribeAll = new Subject();

    @Input()
    label: string;

    @Input()
    placeholder: string = dateMaskPlaceholder;

    @Input()
    form: FormGroup;

    @Input()
    controlName: string;

    dateTextParserControl = new FormControl();

    _control: FormControl;

    required: boolean;

    mask = createDateMask();

    @Input()
    classes: string[] = [];

    constructor(private _changeDetector: ChangeDetectorRef) {

    }

    ngAfterViewInit(): void {
        this._control = this.form.get(this.controlName) as FormControl;
        this.dateTextParserControl.valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(a => {
                let text = a?.replace(/\D/g, "");
                if (!this.required && (!a || text.length === 0)) {
                    this.dateTextParserControl.setErrors(null);
                    this.dateTextParserControl.markAllAsTouched();
                    this._control.setErrors(null);
                    this._control.markAllAsTouched();
                    return;
                }
                let validDate = text?.length === 8;
                try {
                    let parsedDate = parse(a, dateFormat, new Date(1800, 1, 1));
                    validDate = validDate && isValid(parsedDate);
                } catch (e) {
                    validDate = false;
                }
                if (!validDate) {
                    this._control.setErrors({invalid: true});
                    this._control.markAllAsTouched();
                    this.dateTextParserControl.markAllAsTouched();
                    this.dateTextParserControl.setErrors({invalid: true});
                }
            });
        
        if (this._control.hasValidator(VuiValidators.required)) {
            this.dateTextParserControl.setValidators(VuiValidators.required);
        }

        this.required = this.dateTextParserControl.hasValidator(VuiValidators.required);
        this._changeDetector.detectChanges();

        let date = this.form.get(this.controlName).value;
        if (date) {
            this.dateTextParserControl.setValue(this.mask.format(date));
            this._changeDetector.detectChanges();
        }
        
        this._control.statusChanges.pipe(takeUntil(this._unsubscribeAll))
            .subscribe(a => {
                if (this.form.get(this.controlName).disabled) {
                    this.dateTextParserControl.disable({emitEvent: false, onlySelf: true});
                } else {
                    this.dateTextParserControl.enable({emitEvent: false, onlySelf: true});
                }
            });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    ngOnChanges(): void {
        if (this.form.get(this.controlName).disabled) {
            this.dateTextParserControl.disable();
        } else {
            this.dateTextParserControl.enable();
        }
    }

    valueChanged(formattedDate: string) {
        try {
            let date = parse(formattedDate, dateFormat, new Date());
            this.form.get(this.controlName).setValue(date);
        } catch {
        }
        this._changeDetector.detectChanges();
    }

    dateChanged(date: Date) {
        this.dateTextParserControl.setValue(this.mask.format(date));
        this._changeDetector.detectChanges();
    }
}
