import { Component, EventEmitter, Injector } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { AppComponentBase } from "@shared/app-component-base";
import { MatDialog } from "@angular/material/dialog";
import { FuseConfirmDialogComponent } from "@fuse/components/confirm-dialog/confirm-dialog.component";
import { finalize } from "rxjs/internal/operators";
import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";

export enum DialogClosingResult {
    Proceed = 1,
    Cancel = 1,
}

export class SaveOperationResult {
    closeDialog: boolean;
    dialogResult?: any

    constructor(closeDialog: boolean, dialogResult: any) {
        this.closeDialog = closeDialog;
        this.dialogResult = dialogResult;
    }
}

@Component({
    template: ""
})
export abstract class BaseDialogComponent<T, SaveType = any> extends AppComponentBase {

    _matDialog: MatDialog;

    onSaving: EventEmitter<boolean> = new EventEmitter<boolean>();

    protected _fuseSidebarService: FuseSidebarService;

    protected constructor(injector: Injector,
                          public _dialogRef: MatDialogRef<T>) {
        super(injector);
        this._matDialog = injector.get(MatDialog);
        this.disableClose = true;
        this._fuseSidebarService = injector.get(FuseSidebarService);
    }

    set disableClose(disable: boolean) {
        this._dialogRef.disableClose = disable;
    }

    ngOnInit(): void {

    }

    hasPendingChanges() {
        return false;
    }

    public close(dialogResult?: any) {
        if (this.hasPendingChanges()) {
            this.onClosing(dialogResult);
        } else {
            this._dialogRef.close(dialogResult);
        }
    }

    protected onClosing(dialogResult?: any) {
        let dialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });
        dialogRef.componentInstance.title = this.l("Confirmar alterações");
        dialogRef.componentInstance.confirmMessage = this.l("Você deseja salvar as alterações feitas neste item de trabalho?");
        dialogRef.componentInstance.positiveButtonText = this.l("Dialog.ConfirmChanges.Action.SaveChanges.Title");
        dialogRef.componentInstance.cancelButtonText = this.l("Action.Cancel");
        dialogRef.componentInstance.negativeButtonText = this.l("Dialog.ConfirmChanges.Action.DiscardChanges.Title");
        //dialogRef.componentInstance.allowCancel = false;
        dialogRef.afterClosed().subscribe(result => {
            if (result === FuseConfirmDialogComponent.ActionPossitive) {
                this.onSave().subscribe(result => {
                    if (result.closeDialog) {
                        this._dialogRef.close(result.dialogResult);
                    }
                });
            } else if (result === FuseConfirmDialogComponent.ActionNegative) {
                this._dialogRef.close();
            }
        });
    }

    public save(params?: SaveType) {
        this.onSaving.emit(true);
        this.onSave(params)
            .pipe(finalize(() => {
                this.onSaving.emit(false);
            }))
            .subscribe(result => {
                if (result.closeDialog) {
                    this._dialogRef.close(result.dialogResult);
                }
            });
    }

    public onSave(params?: SaveType): Observable<SaveOperationResult> {
        return new Observable<SaveOperationResult>(observer => {
            observer.next(new SaveOperationResult(true, {}));
            observer.complete();
        });
    }

    public toggleChat() {
        this._fuseSidebarService.getSidebar("chatPanel").unfoldTemporarily();
    }
}
