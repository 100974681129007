import {
    AfterViewInit,
    Component,
    ElementRef,
    Inject,
    Injector, Input, OnChanges,
    OnDestroy,
    OnInit, QueryList, SimpleChanges,
    ViewChild,
    ViewChildren,
    ViewEncapsulation
} from '@angular/core';
import {MultiUserChatPlugin} from "@ngx-chat/lib/services/adapters/xmpp/plugins/multi-user-chat.plugin";
import {UnreadMessageCountPlugin} from "@ngx-chat/lib/services/adapters/xmpp/plugins/unread-message-count.plugin";
import {ChatService, ChatServiceToken} from "../../../../@ngx-chat/lib/services/chat-service";
import {ContactFactoryService} from "../../../../@ngx-chat/lib/services/contact-factory.service";
import {LogLevel, LogService} from "../../../../@ngx-chat/lib/services/log.service";
import {ChatListStateService} from "../../../../@ngx-chat/lib/services/chat-list-state.service";
import {ChatBackgroundNotificationService} from "../../../../@ngx-chat/lib/services/chat-background-notification.service";
import {LogInRequest} from "../../../../@ngx-chat/lib/core/log-in-request";
import {RegistrationPlugin} from "../../../../@ngx-chat/lib/services/adapters/xmpp/plugins/registration.plugin";
import {XmppChatService} from "@app/wapps/chat-panel/xmpp/xmpp-chat.service";
import {AppComponentBase} from "@shared/app-component-base";
import {FuseSidebarService} from "../../../../@fuse/components/sidebar/sidebar.service";
import {UserServiceProxy} from "@shared/service-proxies/service-proxies";
import {takeUntil} from "rxjs/internal/operators";
import {Observable, Subject} from "rxjs";
import {NgForm} from "@angular/forms";
import {FusePerfectScrollbarDirective} from "../../../../@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive";
import {Contact} from "../../../../@ngx-chat/lib/core/contact";
import {Translations} from "../../../../@ngx-chat/lib/core/translations";
import { AudioService } from "@app/audio.service";
import { SupportChatService } from '@app/wapps/system/support/support-chat.service';

@Component({
    selector: 'xmpp-chat',
    templateUrl: './xmpp.component.html',
    styleUrls: ['./xmpp.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class XmppComponent extends AppComponentBase implements OnInit, AfterViewInit, OnDestroy, OnChanges {
    public domain: string;
    public service: string;
    public password: string;
    public username: string;
    public otherJid: any;
    public multiUserChatPlugin: MultiUserChatPlugin;
    public unreadMessageCountPlugin: UnreadMessageCountPlugin;
    public registrationMessage: string;

    selectedContact: Contact;

    sidebarFolded: boolean;
    user: any;

    //--

    @ViewChild('replyForm')
    set replyForm(content: NgForm) {
        this._replyForm = content;
    }

    @ViewChild('replyInput')
    set replyInput(content: ElementRef) {
        this._replyInput = content;
    }

    @ViewChildren(FusePerfectScrollbarDirective)
    private _fusePerfectScrollbarDirectives: QueryList<FusePerfectScrollbarDirective>;

    private _chatViewScrollbar: FusePerfectScrollbarDirective;
    private _replyForm: NgForm;
    private _replyInput: ElementRef;
    private _unsubscribeAll: Subject<any>;

    /**
     * If supplied, translations contain an object with the structure of the Translations interface.
     */
    @Input()
    public translations: Partial<Translations> = {};

    /*
    * */
    /**
     * If supplied, the contacts input attribute takes an [Observable<Contact[]>]{@link Contact} as source for your roster list.
     */
    @Input()
    public contacts: undefined | Observable<Contact[]>;

    /**
     * If supplied, the contacts input attribute takes an [Observable<Contact[]>]{@link Contact} as source for your incoming contact
     * requests list.
     */
    @Input()
    contactRequestsReceived$: undefined | Observable<Contact[]>;

    /**
     * If supplied, the contacts input attribute takes an [Observable<Contact[]>]{@link Contact} as source for your outgoing contact
     * requests list.
     */
    @Input()
    contactRequestsSent$: undefined | Observable<Contact[]>;

    /**
     * If supplied, the contacts input attribute takes an [Observable<Contact[]>]{@link Contact} as source for your unaffiliated contact
     * list.
     */
    @Input()
    contactsUnaffiliated$: undefined | Observable<Contact[]>;

    /**
     * If supplied, userAvatar$ contains an Obervable<string>, which is used as the src attribute of the img for the current user.
     */
    @Input()
    public userAvatar$: undefined | Observable<string>;

    private defaultTranslations: Translations = {
        chat: 'Chat1',
        contacts: 'Contacts2',
        contactRequestIn: 'Incoming contact requests',
        contactRequestOut: 'Outgoing contact requests',
        contactsUnaffiliated: 'Unknown',
        noContacts: 'No contacts yet.',
        noMessages: 'No messages yet.',
        placeholder: 'Enter your message!',
        subscriptionRequestMessage: 'I want to add you as a contact.',
        acceptSubscriptionRequest: 'Accept',
        denySubscriptionRequest: 'Deny',
        timeFormat: 'shortTime',
        dateFormat: 'EEEE, dd/MM/yyyy',
        locale: undefined,
        dropMessage: 'Drop your file to send it',
    };

    isAudioEnabled: boolean = true;

    constructor(@Inject(ChatServiceToken) public chatService: ChatService,
                private contactFactory: ContactFactoryService,
                private logService: LogService,
                private xmppChatService: XmppChatService,
                private chatListStateService: ChatListStateService,
                chatBackgroundNotificationService: ChatBackgroundNotificationService,
                private _fuseSidebarService: FuseSidebarService,
                private _userProxy: UserServiceProxy,
                private injector: Injector,
                private _audioService: AudioService,
                private _supportChatService: SupportChatService) {
        super(injector);

        this._unsubscribeAll = new Subject();

        const contactData: any = JSON.parse(localStorage.getItem('data')) || {};
        this.logService.logLevel = LogLevel.Debug;
        this.domain = contactData.domain;
        this.service = contactData.service;
        this.password = contactData.password;
        this.username = contactData.username;

        this.chatService.state$.subscribe((state) => this.stateChanged(state));
        this.multiUserChatPlugin = this.chatService.getPlugin(MultiUserChatPlugin);
        this.unreadMessageCountPlugin = this.chatService.getPlugin(UnreadMessageCountPlugin);

        chatBackgroundNotificationService.enable();

        // @ts-ignore
        window.chatService = chatService;

        this.selectedContact = null;
        this.sidebarFolded = true;

        this.chatService.translations = {...this.defaultTranslations};
    }

    ngOnInit(): void {

        this.onRosterStateChanged();

        if (this.userAvatar$) {
            this.userAvatar$.subscribe(avatar => this.chatService.userAvatar$.next(avatar));
        }

        this.xmppChatService.selectedContactChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(contact => {
                this.selectedContact = contact;
                let panel = this._fuseSidebarService.getSidebar('chatPanel');
                if (contact && panel.folded) {
                    panel.unfoldTemporarily();
                }
            });

        this.mergeAndSetTranslations();

        this.chatService.message$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((contact: Contact) => {
                if (!!this.isAudioEnabled && contact !== this.selectedContact) {
                    this._audioService.play();
                }
            });
    }

    private mergeAndSetTranslations() {
        this.chatService.translations = {...this.defaultTranslations, ...this.translations};
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.mergeAndSetTranslations();
        if (changes.rosterState) {
            //this.onRosterStateChanged((changes as any).rosterState.currentValue);
        }
    }

    ngAfterViewInit(): void {
        this._chatViewScrollbar = this._fusePerfectScrollbarDirectives.find((directive) => {
            return directive.elementRef.nativeElement.id === 'messages';
        });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    onRosterStateChanged() {
        this.updateBodyClass();
    }

    private updateBodyClass() {
        const rosterClass = 'has-roster';
        document.body.classList.add(rosterClass);
    }

    onLogin() {
        const logInRequest: LogInRequest = {
            domain: this.domain,
            service: this.service,
            password: this.password,
            username: this.username
        };
        localStorage.setItem('data', JSON.stringify(logInRequest));
        this.chatService.logIn(logInRequest);
    }

    onLogout() {
        this.chatService.logOut();
    }

    async onRegister() {
        this.registrationMessage = 'registering ...';
        try {
            await this.chatService.getPlugin(RegistrationPlugin).register(
                this.username,
                this.password,
                this.service,
                this.domain
            );
            this.registrationMessage = 'registration successful';
        } catch (e) {
            this.registrationMessage = 'registration failed: ' + e.toString();
            throw e;
        }
    }

    private async stateChanged(state: 'disconnected' | 'connecting' | 'online') {

    }

    /**
     * Fold the temporarily unfolded sidebar back
     */
    foldSidebarTemporarily(): void {
        this._fuseSidebarService.getSidebar('chatPanel').foldTemporarily();
        this._supportChatService.show();
    }

    /**
     * Unfold the sidebar temporarily
     */
    unfoldSidebarTemporarily(): void {
        this._fuseSidebarService.getSidebar('chatPanel').unfoldTemporarily();
        this._supportChatService.hide();
    }

    /**
     * Toggle sidebar opened status
     */
    toggleSidebarOpen(): void {
        this._fuseSidebarService.getSidebar('chatPanel').toggleOpen();
    }

    /**
     * Remove the selected contact and unload the chat
     */
    resetChat(): void {
        this.xmppChatService.selectContact(null);
    }

    toggleAudioNotification() {
        this.isAudioEnabled = !this.isAudioEnabled;
    }
}
