import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { FuseSharedModule } from "@fuse/shared.module";
import { NotificationsComponent } from "@app/wapps/notification/notifications.component";
import { AppRouteGuard } from "@shared/auth/auth-route-guard";
import { RouterModule } from "@angular/router";
import { CoreModule } from "@app/wapps/core/core.module";
import { CustomMaterialModule } from "@app/wapps/core/material.module";
import { FuseDirectivesModule } from "../../../@fuse/directives/directives";
import { FuseConfirmDialogModule } from "../../../@fuse/components";
import { NotificationSettingsComponent } from "./settings/settings.component";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { NOTIFICATION_SETTINGS_FEATURE_KEY, NotificationsEffects, notificationSettingsReducer } from "./settings/store";
import { TranslocoCoreModule } from "@i18n";

const routes = [
    {
        path: "notification",
        component: NotificationsComponent,
        canActivate: [AppRouteGuard]
    },
    {
        path: "notification/settings",
        component:  NotificationSettingsComponent
    }
];

@NgModule({
    declarations: [
        NotificationsComponent,
        NotificationSettingsComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        CoreModule,
        CustomMaterialModule,
        FuseSharedModule,
        FuseDirectivesModule,
        FuseConfirmDialogModule,

        TranslocoCoreModule,

        StoreModule.forFeature(NOTIFICATION_SETTINGS_FEATURE_KEY, notificationSettingsReducer),
        EffectsModule.forFeature([NotificationsEffects])
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ],
    exports: [
        NotificationsComponent
    ]
})
export class NotificationModule {
    constructor() {
    }
}
