export const countries = {
    Afghanistan: {
        code: 93,
        iso: "AF",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/af.svg",
        mask: "00-000-0000",
    },
    "Aland Islands": {
        code: 358,
        iso: "AX",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/ax.svg",
        mask: "(000)000-00-00",
    },
    Albania: {
        code: 355,
        iso: "AL",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/al.svg",
        mask: "(000)000-000",
    },
    Algeria: {
        code: 213,
        iso: "DZ",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/dz.svg",
        mask: "00-000-0000",
    },
    "American Samoa": {
        code: 1,
        iso: "AS",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/as.svg",
        mask: "(684)000-0000",
    },
    Andorra: {
        code: 376,
        iso: "AD",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/ad.svg",
        mask: "000-000",
    },
    Angola: {
        code: 244,
        iso: "AO",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/ao.svg",
        mask: "(000)000-000",
    },
    Anguilla: {
        code: 1,
        iso: "AI",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/ai.svg",
        mask: "(264)000-0000",
    },
    Antarctica: {
        code: 672,
        iso: "AQ",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/aq.svg",
        mask: "100-000",
    },
    "Antigua and Barbuda": {
        code: 1,
        iso: "AG",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/ag.svg",
        mask: "(268)000-0000",
    },
    Argentina: {
        code: 54,
        iso: "AR",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/ar.svg",
        mask: "(000)000-0000",
    },
    Armenia: {
        code: 374,
        iso: "AM",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/am.svg",
        mask: "00-000-000",
    },
    Aruba: {
        code: 297,
        iso: "AW",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/aw.svg",
        mask: "000-0000",
    },
    "Ascension Island": {
        code: 247,
        iso: "AC",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/sh.svg",
        mask: "0000",
    },
    Australia: {
        code: 61,
        iso: "AU",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/au.svg",
        mask: "0-0000-0000",
    },
    Austria: {
        code: 43,
        iso: "AT",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/at.svg",
        mask: "(000)000-0000",
    },
    Azerbaijan: {
        code: 994,
        iso: "AZ",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/az.svg",
        mask: "00-000-00-00",
    },
    Bahamas: {
        code: 1,
        iso: "BS",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/bs.svg",
        mask: "(242)000-0000",
    },
    Bahrain: {
        code: 973,
        iso: "BH",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/bh.svg",
        mask: "0000-0000",
    },
    Bangladesh: {
        code: 880,
        iso: "BD",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/bd.svg",
        mask: "1000-000000",
    },
    Barbados: {
        code: 1,
        iso: "BB",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/bb.svg",
        mask: "(246)000-0000",
    },
    Belarus: {
        code: 375,
        iso: "BY",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/by.svg",
        mask: "(00)000-00-00",
    },
    Belgium: {
        code: 32,
        iso: "BE",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/be.svg",
        mask: "(000)000-000",
    },
    Belize: {
        code: 501,
        iso: "BZ",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/bz.svg",
        mask: "000-0000",
    },
    Benin: {
        code: 229,
        iso: "BJ",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/bj.svg",
        mask: "00-00-0000",
    },
    Bermuda: {
        code: 1,
        iso: "BM",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/bm.svg",
        mask: "(441)000-0000",
    },
    Bhutan: {
        code: 975,
        iso: "BT",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/bt.svg",
        mask: ["17-000-000", "77-000-000", "0-000-000"],
    },
    Bolivia: {
        code: 591,
        iso: "BO",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/bo.svg",
        mask: "0-000-0000",
    },
    "Bosnia and Herzegovina": {
        code: 387,
        iso: "BA",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/ba.svg",
        mask: ["00-0000", "00-00000"],
    },
    Botswana: {
        code: 267,
        iso: "BW",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/bw.svg",
        mask: "00-000-000",
    },
    Brazil: {
        code: 55,
        iso: "BR",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/br.svg",
        mask: ["(00)0000-0000", "(00)00000-0000"],
    },
    "British Indian Ocean Territory": {
        code: 246,
        iso: "IO",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/io.svg",
        mask: "000-0000",
    },
    "Brunei Darussalam": {
        code: 673,
        iso: "BN",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/bn.svg",
        mask: "000-0000",
    },
    Bulgaria: {
        code: 359,
        iso: "BG",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/bg.svg",
        mask: "(000)000-000",
    },
    "Burkina Faso": {
        code: 226,
        iso: "BF",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/bf.svg",
        mask: "00-00-0000",
    },
    Burundi: {
        code: 257,
        iso: "BI",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/bi.svg",
        mask: "00-00-0000",
    },
    Cambodia: {
        code: 855,
        iso: "KH",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/kh.svg",
        mask: "00-000-000",
    },
    Cameroon: {
        code: 237,
        iso: "CM",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/cm.svg",
        mask: "0000-0000",
    },
    Canada: {
        code: 1,
        iso: "CA",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/ca.svg",
        mask: "(000)000-0000",
    },
    "Cape Verde": {
        code: 238,
        iso: "CV",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/cv.svg",
        mask: "(000)00-00",
    },
    "Cayman Islands": {
        code: 1,
        iso: "KY",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/ky.svg",
        mask: "(345)000-0000",
    },
    "Central African Republic": {
        code: 236,
        iso: "CF",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/cf.svg",
        mask: "00-00-0000",
    },
    Chad: {
        code: 235,
        iso: "TD",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/td.svg",
        mask: "00-00-00-00",
    },
    Chile: {
        code: 56,
        iso: "CL",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/cl.svg",
        mask: "0-0000-0000",
    },
    China: {
        code: 86,
        iso: "CN",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/cn.svg",
        mask: ["(000)0000-000", "(000)0000-0000", "00-00000-00000"],
    },
    "Christmas Island": {
        code: 61,
        iso: "CX",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/cx.svg",
        mask: "0-0000-0000",
    },
    "Cocos (Keeling) Islands": {
        code: 61,
        iso: "CC",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/cc.svg",
        mask: "0-0000-0000",
    },
    Colombia: {
        code: 57,
        iso: "CO",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/co.svg",
        mask: "(000)000-0000",
    },
    Comoros: {
        code: 269,
        iso: "KM",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/km.svg",
        mask: "00-00000",
    },
    Congo: {
        code: 242,
        iso: "CG",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/cg.svg",
        mask: "00-00000",
    },
    "Cook Islands": {
        code: 682,
        iso: "CK",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/ck.svg",
        mask: "00-000",
    },
    "Costa Rica": {
        code: 506,
        iso: "CR",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/cr.svg",
        mask: "0000-0000",
    },
    Croatia: {
        code: 385,
        iso: "HR",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/hr.svg",
        mask: "00-000-000",
    },
    Cuba: {
        code: 53,
        iso: "CU",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/cu.svg",
        mask: "0-000-0000",
    },
    Cyprus: {
        code: 357,
        iso: "CY",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/cy.svg",
        mask: "00-000-000",
    },
    "Czech Republic": {
        code: 420,
        iso: "CZ",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/cz.svg",
        mask: "(000)000-000",
    },
    "Democratic Republic of the Congo": {
        code: 243,
        iso: "CD",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/cd.svg",
        mask: "(000)000-000",
    },
    Denmark: {
        code: 45,
        iso: "DK",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/dk.svg",
        mask: "00-00-00-00",
    },
    Djibouti: {
        code: 253,
        iso: "DJ",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/dj.svg",
        mask: "00-00-00-00",
    },
    Dominica: {
        code: 1,
        iso: "DM",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/dm.svg",
        mask: "(767)000-0000",
    },
    "Dominican Republic": {
        code: 1,
        iso: "DO",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/do.svg",
        mask: ["(809)000-0000", "(829)000-0000", "(849)000-0000"],
    },
    Ecuador: {
        code: 593,
        iso: "EC",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/ec.svg",
        mask: ["0-000-0000", "00-000-0000"],
    },
    Egypt: {
        code: 20,
        iso: "EG",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/eg.svg",
        mask: "(000)000-0000",
    },
    "El Salvador": {
        code: 503,
        iso: "SV",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/sv.svg",
        mask: "00-00-0000",
    },
    "Equatorial Guinea": {
        code: 240,
        iso: "GQ",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/gq.svg",
        mask: "00-000-0000",
    },
    Eritrea: {
        code: 291,
        iso: "ER",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/er.svg",
        mask: "0-000-000",
    },
    Estonia: {
        code: 372,
        iso: "EE",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/ee.svg",
        mask: ["000-0000", "0000-0000"],
    },
    Eswatini: {
        code: 268,
        iso: "SZ",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/sz.svg",
        mask: "00-00-0000",
    },
    Ethiopia: {
        code: 251,
        iso: "ET",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/et.svg",
        mask: "00-000-0000",
    },
    "Falkland Islands (Malvinas)": {
        code: 500,
        iso: "FK",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/fk.svg",
        mask: "00000",
    },
    "Faroe Islands": {
        code: 298,
        iso: "FO",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/fo.svg",
        mask: "000-000",
    },
    Fiji: {
        code: 679,
        iso: "FJ",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/fj.svg",
        mask: "00-00000",
    },
    Finland: {
        code: 358,
        iso: "FI",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/fi.svg",
        mask: "(000)000-00-00",
    },
    France: {
        code: 33,
        iso: "FR",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/fr.svg",
        mask: "(000)000-000",
    },
    "French Guiana": {
        code: 594,
        iso: "GF",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/gf.svg",
        mask: "00000-0000",
    },
    "French Polynesia": {
        code: 689,
        iso: "PF",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/pf.svg",
        mask: "00-00-00",
    },
    Gabon: {
        code: 241,
        iso: "GA",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/ga.svg",
        mask: "0-00-00-00",
    },
    Gambia: {
        code: 220,
        iso: "GM",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/gm.svg",
        mask: "(000)00-00",
    },
    Georgia: {
        code: 995,
        iso: "GE",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/ge.svg",
        mask: "(000)000-000",
    },
    Germany: {
        code: 49,
        iso: "DE",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/de.svg",
        mask: ["000-000", "(000)00-00", "(000)00-000", "(000)00-0000", "(000)000-0000", "(0000)000-0000"],
    },
    Ghana: {
        code: 233,
        iso: "GH",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/gh.svg",
        mask: "(000)000-000",
    },
    Gibraltar: {
        code: 350,
        iso: "GI",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/gi.svg",
        mask: "000-00000",
    },
    Greece: {
        code: 30,
        iso: "GR",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/gr.svg",
        mask: "(000)000-0000",
    },
    Greenland: {
        code: 299,
        iso: "GL",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/gl.svg",
        mask: "00-00-00",
    },
    Grenada: {
        code: 1,
        iso: "GD",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/gd.svg",
        mask: "(473)000-0000",
    },
    Guadeloupe: {
        code: 590,
        iso: "GP",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/gp.svg",
        mask: "(000)000-000",
    },
    Guam: {
        code: 1,
        iso: "GU",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/gu.svg",
        mask: "(671)000-0000",
    },
    Guatemala: {
        code: 502,
        iso: "GT",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/gt.svg",
        mask: "0-000-0000",
    },
    Guernsey: {
        code: 44,
        iso: "GG",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/gg.svg",
        mask: "(0000)000000",
    },
    Guinea: {
        code: 224,
        iso: "GN",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/gn.svg",
        mask: "00-000-000",
    },
    "Guinea-Bissau": {
        code: 245,
        iso: "GW",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/gw.svg",
        mask: "0-000000",
    },
    Guyana: {
        code: 592,
        iso: "GY",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/gy.svg",
        mask: "000-0000",
    },
    Haiti: {
        code: 509,
        iso: "HT",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/ht.svg",
        mask: "00-00-0000",
    },
    "Holy See (Vatican City State)": {
        code: 39,
        iso: "VA",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/va.svg",
        mask: "06 69800000",
    },
    Honduras: {
        code: 504,
        iso: "HN",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/hn.svg",
        mask: "0000-0000",
    },
    "Hong Kong": {
        code: 852,
        iso: "HK",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/hk.svg",
        mask: "0000-0000",
    },
    Hungary: {
        code: 36,
        iso: "HU",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/hu.svg",
        mask: "(000)000-000",
    },
    Iceland: {
        code: 354,
        iso: "IS",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/is.svg",
        mask: "000-0000",
    },
    India: {
        code: 91,
        iso: "IN",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/in.svg",
        mask: "(0000)000-000",
    },
    Indonesia: {
        code: 62,
        iso: "ID",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/id.svg",
        mask: ["00-000-00", "00-000-000", "00-000-0000", "(800)000-000", "(800)000-00-000"],
    },
    Iran: {
        code: 98,
        iso: "IR",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/ir.svg",
        mask: "(000)000-0000",
    },
    Iraq: {
        code: 924,
        iso: "IQ",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/iq.svg",
        mask: "(000)000-0000",
    },
    Ireland: {
        code: 353,
        iso: "IE",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/ie.svg",
        mask: "(000)000-000",
    },
    "Isle of Man": {
        code: 44,
        iso: "IM",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/im.svg",
        mask: "(0000)000000",
    },
    Israel: {
        code: 972,
        iso: "IL",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/il.svg",
        mask: ["0-000-0000", "50-000-0000"],
    },
    Italy: {
        code: 39,
        iso: "IT",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/it.svg",
        mask: "(000)0000-000",
    },
    "Ivory Coast / Cote d'Ivoire": {
        code: 225,
        iso: "CI",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/ci.svg",
        mask: "00-000-000",
    },
    Jamaica: {
        code: 1,
        iso: "JM",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/jm.svg",
        mask: "(876)000-0000",
    },
    Japan: {
        code: 81,
        iso: "JP",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/jp.svg",
        mask: ["(000)000-000", "00-0000-0000"],
    },
    Jersey: {
        code: 44,
        iso: "JE",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/je.svg",
        mask: "(0000)0000-000000",
    },
    Jordan: {
        code: 962,
        iso: "JO",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/jo.svg",
        mask: "0-0000-0000",
    },
    Kazakhstan: {
        code: 77,
        iso: "KZ",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/kz.svg",
        mask: ["(600)000-00-00", "(700)000-00-00"],
    },
    Kenya: {
        code: 254,
        iso: "KE",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/ke.svg",
        mask: "000-000000",
    },
    Kiribati: {
        code: 686,
        iso: "KI",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/ki.svg",
        mask: "00-000",
    },
    "Korea, Democratic People's Republic of Korea": {
        code: 850,
        iso: "KP",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/kp.svg",
        mask: ["000-000", "0000-0000", "00-000-000", "000-0000-000", "191-000-0000", "0000-0000000000000"],
    },
    "Korea, Republic of South Korea": {
        code: 82,
        iso: "KR",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/kr.svg",
        mask: "00-000-0000",
    },
    Kosovo: {
        code: 383,
        iso: "XK",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/xk.svg",
        mask: ["00-000-000", "000-000-000"],
    },
    Kuwait: {
        code: 965,
        iso: "KW",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/kw.svg",
        mask: "0000-0000",
    },
    Kyrgyzstan: {
        code: 996,
        iso: "KG",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/kg.svg",
        mask: "(000)000-000",
    },
    Laos: {
        code: 856,
        iso: "LA",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/la.svg",
        mask: ["00-000-000", "(2000)000-000"],
    },
    Latvia: {
        code: 371,
        iso: "LV",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/lv.svg",
        mask: "00-000-000",
    },
    Lebanon: {
        code: 961,
        iso: "LB",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/lb.svg",
        mask: ["0-000-000", "00-000-000"],
    },
    Lesotho: {
        code: 266,
        iso: "LS",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/ls.svg",
        mask: "0-000-0000",
    },
    Liberia: {
        code: 231,
        iso: "LR",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/lr.svg",
        mask: "00-000-000",
    },
    Libya: {
        code: 218,
        iso: "LY",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/ly.svg",
        mask: ["00-000-000", "21-000-0000"],
    },
    Liechtenstein: {
        code: 423,
        iso: "LI",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/li.svg",
        mask: "(000)000-0000",
    },
    Lithuania: {
        code: 370,
        iso: "LT",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/lt.svg",
        mask: "(000)00-000",
    },
    Luxembourg: {
        code: 352,
        iso: "LU",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/lu.svg",
        mask: "(000)000-000",
    },
    Macau: {
        code: 853,
        iso: "MO",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/mo.svg",
        mask: "0000-0000",
    },
    Madagascar: {
        code: 261,
        iso: "MG",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/mg.svg",
        mask: "00-00-00000",
    },
    Malawi: {
        code: 265,
        iso: "MW",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/mw.svg",
        mask: ["1-000-000", "0-0000-0000"],
    },
    Malaysia: {
        code: 60,
        iso: "MY",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/my.svg",
        mask: ["0-000-000", "00-000-000", "(000)000-000", "00-000-0000"],
    },
    Maldives: {
        code: 960,
        iso: "MV",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/mv.svg",
        mask: "000-0000",
    },
    Mali: {
        code: 223,
        iso: "ML",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/ml.svg",
        mask: "00-00-0000",
    },
    Malta: {
        code: 356,
        iso: "MT",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/mt.svg",
        mask: "0000-0000",
    },
    "Marshall Islands": {
        code: 692,
        iso: "MH",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/mh.svg",
        mask: "000-0000",
    },
    Martinique: {
        code: 596,
        iso: "MQ",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/mq.svg",
        mask: "(000)00-00-00",
    },
    Mauritania: {
        code: 222,
        iso: "MR",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/mr.svg",
        mask: "00-00-0000",
    },
    Mauritius: {
        code: 230,
        iso: "MU",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/mu.svg",
        mask: "000-0000",
    },
    Mayotte: {
        code: 262,
        iso: "YT",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/yt.svg",
        mask: "00000-0000",
    },
    Mexico: {
        code: 52,
        iso: "MX",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/mx.svg",
        mask: ["00-00-0000", "(000)000-0000"],
    },
    "Micronesia, Federated States of Micronesia": {
        code: 691,
        iso: "FM",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/fm.svg",
        mask: "000-0000",
    },
    Moldova: {
        code: 373,
        iso: "MD",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/md.svg",
        mask: "0000-0000",
    },
    Monaco: {
        code: 377,
        iso: "MC",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/mc.svg",
        mask: ["00-000-000", "(000)000-000"],
    },
    Mongolia: {
        code: 976,
        iso: "MN",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/mn.svg",
        mask: "00-00-0000",
    },
    Montenegro: {
        code: 382,
        iso: "ME",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/me.svg",
        mask: "00-000-000",
    },
    Montserrat: {
        code: 1,
        iso: "MS",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/ms.svg",
        mask: "(664)000-0000",
    },
    Morocco: {
        code: 212,
        iso: "MA",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/ma.svg",
        mask: "00-0000-000",
    },
    Mozambique: {
        code: 258,
        iso: "MZ",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/mz.svg",
        mask: "00-000-000",
    },
    Myanmar: {
        code: 95,
        iso: "MM",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/mm.svg",
        mask: ["000-000", "0-000-000", "00-000-000"],
    },
    Namibia: {
        code: 224,
        iso: "NA",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/na.svg",
        mask: "00-000-0000",
    },
    Nauru: {
        code: 674,
        iso: "NR",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/nr.svg",
        mask: "000-0000",
    },
    Nepal: {
        code: 977,
        iso: "NP",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/np.svg",
        mask: "00-000-000",
    },
    Netherlands: {
        code: 31,
        iso: "NL",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/nl.svg",
        mask: "00-000-0000",
    },
    "Netherlands Antilles": {
        code: 599,
        iso: "AN",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/an.svg",
        mask: ["000-0000", "9000-0000"],
    },
    "New Caledonia": {
        code: 687,
        iso: "NC",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/nc.svg",
        mask: "00-0000",
    },
    "New Zealand": {
        code: 24,
        iso: "NZ",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/nz.svg",
        mask: ["0-000-000", "(000)000-000", "(000)000-0000"],
    },
    Nicaragua: {
        code: 505,
        iso: "NI",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/ni.svg",
        mask: "0000-0000",
    },
    Niger: {
        code: 227,
        iso: "NE",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/ne.svg",
        mask: "00-00-0000",
    },
    Nigeria: {
        code: 234,
        iso: "NG",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/ng.svg",
        mask: ["00-000-00", "00-000-000", "(000)000-0000"],
    },
    Niue: {
        code: 683,
        iso: "NU",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/nu.svg",
        mask: "0000",
    },
    "Norfolk Island": {
        code: 672,
        iso: "NF",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/nf.svg",
        mask: "300-000",
    },
    "North Macedonia": {
        code: 389,
        iso: "MK",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/mk.svg",
        mask: "00-000-000",
    },
    "Northern Mariana Islands": {
        code: 1,
        iso: "MP",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/mp.svg",
        mask: "(670)000-0000",
    },
    Norway: {
        code: 47,
        iso: "NO",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/no.svg",
        mask: "(000)00-000",
    },
    Oman: {
        code: 968,
        iso: "OM",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/om.svg",
        mask: "00-000-000",
    },
    Pakistan: {
        code: 92,
        iso: "PK",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/pk.svg",
        mask: "(000)000-0000",
    },
    Palau: {
        code: 680,
        iso: "PW",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/pw.svg",
        mask: "000-0000",
    },
    Palestine: {
        code: 970,
        iso: "PS",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/ps.svg",
        mask: "00-000-0000",
    },
    Panama: {
        code: 507,
        iso: "PA",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/pa.svg",
        mask: "000-0000",
    },
    "Papua New Guinea": {
        code: 675,
        iso: "PG",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/pg.svg",
        mask: "(000)00-000",
    },
    Paraguay: {
        code: 595,
        iso: "PY",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/py.svg",
        mask: "(000)000-000",
    },
    Peru: {
        code: 51,
        iso: "PE",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/pe.svg",
        mask: "(000)000-000",
    },
    Philippines: {
        code: 63,
        iso: "PH",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/ph.svg",
        mask: "(000)000-0000",
    },
    Pitcairn: {
        code: 870,
        iso: "PN",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/pn.svg",
        mask: "000-000-000",
    },
    Poland: {
        code: 48,
        iso: "PL",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/pl.svg",
        mask: "(000)000-000",
    },
    Portugal: {
        code: 351,
        iso: "PT",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/pt.svg",
        mask: "00-000-0000",
    },
    "Puerto Rico": {
        code: 1,
        iso: "PR",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/pr.svg",
        mask: ["(787) 000 0000", "(939) 000 0000"],
    },
    Qatar: {
        code: 974,
        iso: "QA",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/qa.svg",
        mask: "0000-0000",
    },
    Reunion: {
        code: 262,
        iso: "RE",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/re.svg",
        mask: "00000-0000",
    },
    Romania: {
        code: 40,
        iso: "RO",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/ro.svg",
        mask: "00-000-0000",
    },
    Russia: {
        code: 7,
        iso: "RU",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/ru.svg",
        mask: "(000)000-00-00",
    },
    Rwanda: {
        code: 250,
        iso: "RW",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/rw.svg",
        mask: "(000)000-000",
    },
    "Saint Barthelemy": {
        code: 590,
        iso: "BL",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/bl.svg",
        mask: "000-00-00-00",
    },
    "Saint Helena, Ascension and Tristan Da Cunha": {
        code: 290,
        iso: "SH",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/sh.svg",
        mask: "0000",
    },
    "Saint Kitts and Nevis": {
        code: 1,
        iso: "KN",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/kn.svg",
        mask: "(869)000-0000",
    },
    "Saint Lucia": {
        code: 1,
        iso: "LC",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/lc.svg",
        mask: "(758)000-0000",
    },
    "Saint Martin": {
        code: 590,
        iso: "MF",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/mf.svg",
        mask: "(000)000-000",
    },
    "Saint Pierre and Miquelon": {
        code: 508,
        iso: "PM",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/pm.svg",
        mask: "00-0000",
    },
    "Saint Vincent and the Grenadines": {
        code: 1,
        iso: "VC",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/vc.svg",
        mask: "(784)000-0000",
    },
    Samoa: {
        code: 685,
        iso: "WS",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/ws.svg",
        mask: "00-0000",
    },
    "San Marino": {
        code: 378,
        iso: "SM",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/sm.svg",
        mask: "0000-000000",
    },
    "Sao Tome and Principe": {
        code: 239,
        iso: "ST",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/st.svg",
        mask: "00-00000",
    },
    "Saudi Arabia": {
        code: 966,
        iso: "SA",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/sa.svg",
        mask: ["0-000-0000", "50-0000-0000"],
    },
    Senegal: {
        code: 221,
        iso: "SN",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/sn.svg",
        mask: "00-000-0000",
    },
    Serbia: {
        code: 381,
        iso: "RS",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/rs.svg",
        mask: "00-000-0000",
    },
    Seychelles: {
        code: 248,
        iso: "SC",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/sc.svg",
        mask: "0-000-000",
    },
    "Sierra Leone": {
        code: 232,
        iso: "SL",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/sl.svg",
        mask: "00-000000",
    },
    Singapore: {
        code: 65,
        iso: "SG",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/sg.svg",
        mask: "0000-0000",
    },
    "Sint Maarten": {
        code: 1,
        iso: "SX",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/sx.svg",
        mask: "(721)000-0000",
    },
    Slovakia: {
        code: 421,
        iso: "SK",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/sk.svg",
        mask: "(000)000-000",
    },
    Slovenia: {
        code: 386,
        iso: "SI",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/si.svg",
        mask: "00-000-000",
    },
    "Solomon Islands": {
        code: 677,
        iso: "SB",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/sb.svg",
        mask: ["00000", "000-0000"],
    },
    Somalia: {
        code: 252,
        iso: "SO",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/so.svg",
        mask: ["0-000-000", "00-000-000"],
    },
    "South Africa": {
        code: 27,
        iso: "ZA",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/za.svg",
        mask: "00-000-0000",
    },
    "South Georgia and the South Sandwich Islands": {
        code: 500,
        iso: "GS",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/gs.svg",
        mask: "00000",
    },
    "South Sudan": {
        code: 211,
        iso: "SS",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/ss.svg",
        mask: "00-000-0000",
    },
    Spain: {
        code: 34,
        iso: "ES",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/es.svg",
        mask: "(000)000-000",
    },
    "Sri Lanka": {
        code: 94,
        iso: "LK",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/lk.svg",
        mask: "00-000-0000",
    },
    Sudan: {
        code: 249,
        iso: "SD",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/sd.svg",
        mask: "00-000-0000",
    },
    Suriname: {
        code: 597,
        iso: "SR",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/sr.svg",
        mask: ["000-000", "000-0000"],
    },
    "Svalbard and Jan Mayen": {
        code: 47,
        iso: "SJ",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/sj.svg",
        mask: "(000)00-000",
    },
    Sweden: {
        code: 46,
        iso: "SE",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/se.svg",
        mask: "00-000-0000",
    },
    Switzerland: {
        code: 41,
        iso: "CH",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/ch.svg",
        mask: "00-000-0000",
    },
    "Syrian Arab Republic": {
        code: 963,
        iso: "SY",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/sy.svg",
        mask: "00-0000-000",
    },
    Taiwan: {
        code: 886,
        iso: "TW",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/tw.svg",
        mask: ["0000-0000", "0-0000-0000"],
    },
    Tajikistan: {
        code: 992,
        iso: "TJ",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/tj.svg",
        mask: "00-000-0000",
    },
    "Tanzania, United Republic of Tanzania": {
        code: 255,
        iso: "TZ",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/tz.svg",
        mask: "00-000-0000",
    },
    Thailand: {
        code: 66,
        iso: "TH",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/th.svg",
        mask: ["00-000-000", "00-000-0000"],
    },
    "Timor-Leste": {
        code: 670,
        iso: "TL",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/tl.svg",
        mask: ["000-0000", "770-00000", "780-00000"],
    },
    Togo: {
        code: 228,
        iso: "TG",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/tg.svg",
        mask: "00-000-000",
    },
    Tokelau: {
        code: 690,
        iso: "TK",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/tk.svg",
        mask: "0000",
    },
    Tonga: {
        code: 676,
        iso: "TO",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/to.svg",
        mask: "00000",
    },
    "Trinidad and Tobago": {
        code: 1,
        iso: "TT",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/tt.svg",
        mask: "(868)000-0000",
    },
    Tunisia: {
        code: 216,
        iso: "TN",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/tn.svg",
        mask: "00-000-000",
    },
    Turkey: {
        code: 90,
        iso: "TR",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/tr.svg",
        mask: "(000)000-0000",
    },
    Turkmenistan: {
        code: 993,
        iso: "TM",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/tm.svg",
        mask: "0-000-0000",
    },
    "Turks and Caicos Islands": {
        code: 1,
        iso: "TC",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/tc.svg",
        mask: "(249)000-000",
    },
    Tuvalu: {
        code: 688,
        iso: "TV",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/tv.svg",
        mask: ["20000", "900000"],
    },
    Uganda: {
        code: 256,
        iso: "UG",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/ug.svg",
        mask: "(000)000-000",
    },
    Ukraine: {
        code: 380,
        iso: "UA",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/ua.svg",
        mask: "(00)000-00-00",
    },
    "United Arab Emirates": {
        code: 971,
        iso: "AE",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/ae.svg",
        mask: ["0-000-0000", "50-000-0000"],
    },
    "United Kingdom": {
        code: 44,
        iso: "GB",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/gb.svg",
        mask: "00-0000-0000",
    },
    "United States": {
        code: 1,
        iso: "US",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/us.svg",
        mask: "(000)000-0000",
    },
    Uruguay: {
        code: 598,
        iso: "UY",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/uy.svg",
        mask: "0-000-00-00",
    },
    Uzbekistan: {
        code: 998,
        iso: "UZ",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/uz.svg",
        mask: "00-000-0000",
    },
    Vanuatu: {
        code: 678,
        iso: "VU",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/vu.svg",
        mask: ["00000", "00-00000"],
    },
    "Venezuela, Bolivarian Republic of Venezuela": {
        code: 58,
        iso: "VE",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/ve.svg",
        mask: "(000)000-0000",
    },
    Vietnam: {
        code: 84,
        iso: "VN",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/vn.svg",
        mask: ["00-0000-000", "(000)0000-000"],
    },
    "Virgin Islands, British": {
        code: 1,
        iso: "VG",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/vg.svg",
        mask: "(284)000-0000",
    },
    "Virgin Islands, U.S.": {
        code: 1,
        iso: "VI",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/vi.svg",
        mask: "(340)000-0000",
    },
    "Wallis and Futuna": {
        code: 681,
        iso: "WF",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/wf.svg",
        mask: "00-0000",
    },
    Yemen: {
        code: 967,
        iso: "YE",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/ye.svg",
        mask: ["0-000-000", "00-000-000", "000-000-000"],
    },
    Zambia: {
        code: 260,
        iso: "ZM",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/zm.svg",
        mask: "00-000-0000",
    },
    Zimbabwe: {
        code: 263,
        iso: "ZW",
        flag: "https://cdn.kcak11.com/CountryFlags/countries/zw.svg",
        mask: "0-000000",
    },
};
