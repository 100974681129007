import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import {
    selectCurrent,
    selectIsLoading,
    selectAtLeastOneFolderExists,
    selectAll,
    selectIsCreating,
    selectIsUpdating,
    selectSelectedFolderProtocols,
    selectAtLeastOneProtocolExists,
    selectLoadingDetails,
} from "./selectors";
import { ProtocolFolder, ProtocolsState } from "./model";
import { ProtocolDefinition } from "../../model";
import * as actions from "./actions";
import { take } from "rxjs/operators";
import { assign } from "lodash";

@Injectable({
    providedIn: "root",
})
export class ProtocolFacade {
    _loading$: Observable<boolean> = this._store.select(selectIsLoading);

    _loadingDetails$: Observable<boolean> = this._store.select(selectLoadingDetails);

    _folders$: Observable<ProtocolFolder[]> = this._store.select(selectAll);

    _selected$: Observable<ProtocolFolder> = this._store.select(selectCurrent);

    selectedFolderProtocols$: Observable<ProtocolDefinition[]> = this._store.select(selectSelectedFolderProtocols);

    _isThereAnyFolder$: Observable<boolean> = this._store.select(selectAtLeastOneFolderExists);

    _isThereAnyProtocol$: Observable<boolean> = this._store.select(selectAtLeastOneProtocolExists);

    creating$: Observable<boolean> = this._store.select(selectIsCreating);

    updating$: Observable<boolean> = this._store.select(selectIsUpdating);

    constructor(private _store: Store<ProtocolsState>) {}

    get loading$() {
        return this._loading$;
    }

    get folders$() {
        return this._folders$;
    }

    get current$() {
        return this._selected$;
    }

    root() {
        let folder = assign(new ProtocolFolder(), {
            id: null,
            name: "Modelos",
            isPublic: true,
            folders: [],
        });
        this.navigate(folder);
    }

    navigate(folder: ProtocolFolder) {
        this._store.dispatch(actions.loadFoldersAction.execute({ folder }));
    }

    select(id: number) {
        this._store.dispatch(actions.selectProtocolFolderAction({ id }));
    }

    goBack() {
        this._store.dispatch(actions.goBack());
    }

    createFolder(name: string, isPublic: boolean, parentFolderId: number = null) {
        this._store.dispatch(
            actions.createProtocolFolderAction.execute({
                parentFolderId,
                name,
                isPublic,
            })
        );
    }

    updateFolder(id: number, name: string, isPublic: boolean) {
        this._store.dispatch(actions.updateProtocolFolderAction.execute({ id, name, isPublic }));
    }

    deleteFolder(id: number) {
        this._store.dispatch(actions.deleteProtocolFolderAction.execute({ id }));
    }

    create(protocol: ProtocolDefinition) {
        this._store
            .select(selectCurrent)
            .pipe(take(1))
            .subscribe((folder: ProtocolFolder) => {
                this._store.dispatch(
                    actions.createProtocolAction.execute({
                        parentFolderId: folder.id,
                        protocol,
                    })
                );
            });
    }

    update(protocol: ProtocolDefinition) {
        this._store.dispatch(actions.updateProtocolAction.execute({ protocol }));
    }

    delete(id: number) {
        this._store.dispatch(actions.deleteProtocolAction.execute({ id }));
    }

    load(folderId: number) {
        this._store.dispatch(actions.loadProtocolsAction.execute({ id: folderId }));
    }

    moveProtocolDefinition(protocolId: number, folderId: number) {
        this._store.dispatch(actions.moveProtocolDefinitionAction.execute({ id: protocolId, folderId }));
    }
}
