import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Component, OnInit, ViewEncapsulation, OnDestroy, Input, Inject } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { SmartFormFolder, actions } from "../store";
import { SmartFormPoolFacade } from "../store/facade";
import { takeUntil } from "rxjs/operators";
import { Actions, ofType } from "@ngrx/effects";
import { NotifyService } from "abp-ng2-module";
import { TranslocoService } from "@ngneat/transloco";

export type FolderPickerDialogData = {
    formId: number;
};

@Component({
    selector: "form-folder-picker-dialog",
    templateUrl: "./form-folder-picker-dialog.component.html",
    encapsulation: ViewEncapsulation.None,
})
export class FolderPickerDialogComponent implements OnInit, OnDestroy {
    private _unsubscribeAll = new Subject();

    @Input()
    classes: string[] = ["w-30vw", "sm:w-30vw"];

    folders$: Observable<SmartFormFolder[]>;

    selected: SmartFormFolder;

    constructor(
        private _dialogRef: MatDialogRef<FolderPickerDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: FolderPickerDialogData,
        private _facade: SmartFormPoolFacade,
        private _actions$: Actions,
        private _notifyService: NotifyService,
        private _translateService: TranslocoService
    ) {
        this._dialogRef.addPanelClass(["xs-full-screen", "dialog-v2"]);

        this.folders$ = this._facade.folders$;
    }

    ngOnInit() {
        this._actions$
            .pipe(ofType(actions.moveFormDefinitionAction.complete), takeUntil(this._unsubscribeAll))
            .subscribe((action) => {
                this._notifyService.success(
                    this._translateService.translate("health.engage.form-pool.move-definition.successfully-message")
                );
                this._dialogRef.close();
            });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    goBack() {
        this._facade.goBack();
    }

    save() {
        this._dialogRef.close(this.selected);
    }

    closeDialog() {
        this._dialogRef.close();
    }

    handleSelection(folder: SmartFormFolder) {
        this.selected = folder;
    }
}
