import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {BrowserAnimationsModule, NoopAnimationsModule} from '@angular/platform-browser/animations';
import { AutocompleteComponent } from './autocomplete.component';
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {MatSelectModule} from "@angular/material/select";
import {MatCardModule} from "@angular/material/card";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatIconModule} from "@angular/material/icon";
import {CommonModule} from "@angular/common";
import {AutocompleteSelectionPickerComponent} from "@app/wapps/core/autocomplete/selection-picker/autocomplete-selection-picker.component";
import {NgxMatSelectSearchModule} from "ngx-mat-select-search";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
      // NoopAnimationsModule,
    // BrowserAnimationsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatCardModule,
    MatAutocompleteModule,
    MatIconModule,
    MatProgressBarModule,
      NgxMatSelectSearchModule
  ],
  declarations: [AutocompleteComponent, AutocompleteSelectionPickerComponent],
  exports: [AutocompleteComponent, AutocompleteSelectionPickerComponent]
})
export class NgMatAutocompleteModule { }
