import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import {
    selectInstallationHistory,
    selectInstallations,
    selectIsLoading, selectMau, selectMauHistory,
} from "./selectors";
import {
    ActiveUser,
    IMobileStatState,
    Installation,
    InstallationHistoryItem,
    MonthlyActiveUsersHistoryItem
} from "./model";
import { actions } from ".";

@Injectable({
    providedIn: "root",
})
export class MobileStatsFacade {
    isLoading$: Observable<boolean> = this._store.select(selectIsLoading);

    installations$: Observable<Installation[]> = this._store.select(selectInstallations);

    installationHistory$: Observable<InstallationHistoryItem[]> = this._store.select(selectInstallationHistory);

    mau$: Observable<ActiveUser[]> = this._store.select(selectMau);

    mauHistory$: Observable<MonthlyActiveUsersHistoryItem[]> = this._store.select(selectMauHistory);

    constructor(private _store: Store<IMobileStatState>) {
    }

    getInstallationHistory() {
        this._store.dispatch(actions.getInstallationHistory());
    }

    getMauHistory() {
        this._store.dispatch(actions.getMauHistory());
    }
}
