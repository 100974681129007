import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import {
    selectPeriod, selectTenant, selectTenants,
} from "./selectors";
import { FilterPeriod, IStatsState } from "./model";
import { actions } from "."
import { endOfMonth, startOfDay, startOfMonth } from "date-fns";

@Injectable({
    providedIn: "root",
})
export class StatsFacade {
    period$: Observable<FilterPeriod> = this._store.select(selectPeriod);

    tenants$: Observable<any[]> = this._store.select(selectTenants);

    tenant$: Observable<any> = this._store.select(selectTenant);

    constructor(private _store: Store<IStatsState>) {
        //Start with current month
        this.updatePeriod({id: 1, start: startOfMonth(new Date()), end: endOfMonth(new Date())});
    }

    updatePeriod(period: FilterPeriod) {
        this._store.dispatch(actions.setPeriod({
            period: {
                id: period.id,
                start: startOfDay(period.start),
                end: startOfDay(period.end)
            }
        }));
    }

    setClients(tenants: any[]) {
        this._store.dispatch(actions.setClients({tenants}));
    }

    selectClient(tenant: any) {
        this._store.dispatch(actions.setClient({tenant}));
    }
}
