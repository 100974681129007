import { Injectable } from '@angular/core';
import {AppConsts} from "@shared/AppConsts";
import {LazyMapsAPILoaderConfigLiteral} from "@agm/core";

@Injectable({
    providedIn: 'root'
})
export class MapsApiLoaderService implements LazyMapsAPILoaderConfigLiteral {

    public apiKey: string
    public libraries: string[]
    constructor() {
        this.apiKey = AppConsts.MapsApiKey
        this.libraries = ['places']
    }
}