import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormDefinition } from "../model";

export class FormOptionsData {
    form: FormDefinition;
    notifyOnAnswered?: boolean;
    displayOnFeed?: boolean;
    horizontalEvolution?: boolean;
}

@Component({
    selector: "form-options",
    templateUrl: "./form-options.component.html",
    styleUrls: ["./form-options.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class FormOptionsComponent implements OnInit {
    form: FormDefinition;

    notifyOnAnswered?: boolean;
    displayOnFeed?: boolean;
    horizontalEvolution?: boolean;

    constructor(
        public dialogRef: MatDialogRef<FormOptionsComponent>,
        @Inject(MAT_DIALOG_DATA) public _data: FormOptionsData
    ) {
        this.dialogRef.addPanelClass("form-options");

        this.form = this._data.form;
        this.notifyOnAnswered = this._data.notifyOnAnswered;
        this.displayOnFeed = this._data.displayOnFeed;
        this.horizontalEvolution = this._data.horizontalEvolution;
    }

    ngOnInit(): void {}

    save() {
        this.dialogRef.close({
            ...this.form,
            notifyOnAnswered: this.notifyOnAnswered,
            displayOnFeed: this.displayOnFeed,
            horizontalEvolution: this.horizontalEvolution,
        });
    }
}
