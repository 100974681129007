export const SubscriptionPermissionName = {

    Root:  "Valor.Subscription",
    
    PaymentLink:  "Valor.Subscription.PaymentLink",
};

export const AllPermissionName = [
    SubscriptionPermissionName.Root,

    SubscriptionPermissionName.PaymentLink,
];