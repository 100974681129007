import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { RouterModule } from "@angular/router";
import { FuseScrollbarModule } from "@nx-workspace/fuse";
import { MatFormFieldModule } from "@angular/material/form-field";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { TranslocoCoreModule } from "@i18n";
import { MatDatepickerModule } from "@angular/material/datepicker";
import {RecurrencePickerDialogComponent} from "./recurrence-picker-dialog.component";
import {MatDialogModule} from "@angular/material/dialog";
import {MatRadioModule} from "@angular/material/radio";
import { DialogModule } from "../dialog/dialog.module";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {DateFormFieldModule} from "../date-form-field/date-form-field.module";
import {MaskedFormFieldModule} from "../masked-form-field/masked-form-field.module";

@NgModule({
    declarations: [
        RecurrencePickerDialogComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        FuseScrollbarModule,
        MatIconModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatDatepickerModule,
        MatIconModule,
        MatDialogModule,
        MatRadioModule,
        TranslocoCoreModule,
        DialogModule,
        MatCheckboxModule,
        MatProgressBarModule,
        MatButtonToggleModule,
        DateFormFieldModule,
        MaskedFormFieldModule,
    ],
    exports: [
        RecurrencePickerDialogComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class RecurrencePickerModule {}
