<ng-container *transloco="let t; read: 'health.engage.reminder-pool.create-reminder'">
    <ng-container *transloco="let root">
        <ui-dialog-base [allowCancelTouchingOutside]="false" [title]="t('title')" [classes]="['sm:w-80vw lg:w-60vw']">
            <div *ngIf="isCreating$ | async" class="absolute inset-x-0 top-0">
                <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
            </div>

            <div
                class="flex-auto h-full p-6 overflow-y-auto overscroll-y-contain sm:overflow-hidden sm:overscroll-auto"
                fuseScrollbar
            >
                <div class="flex flex-col space-y-4 w-full" [formGroup]="form">
                    <div class="flex flex-col space-y-4 border rounded shadow-md w-full p-4 bg-gray-50">
                        <mat-form-field class="fuse-mat-no-subscript">
                            <mat-label>{{ t("field-title-title") }}</mat-label>
                            <input
                                #activityTitle
                                matInput
                                formControlName="title"
                                [placeholder]="t('field-title-placeholder')"
                            />
                        </mat-form-field>
                        <mat-form-field class="fuse-mat-no-subscript">
                            <mat-label>{{ t("field-comments-title") }}</mat-label>
                            <textarea
                                matInput
                                formControlName="comments"
                                [placeholder]="t('field-comments-placeholder')"
                            ></textarea>
                        </mat-form-field>
                        <mat-checkbox [checked]="documentStatus" (change)="documentStatusChange($event)">
                            {{ t("field-document-status-title") }}
                        </mat-checkbox>
                    </div>
                    <div>
                        <mat-checkbox
                            formControlName="isPublic"
                            [matTooltip]="t('field-public-tooltip')"
                            [matTooltipPosition]="'right'"
                        >
                            {{ t("field-public-title") }}
                        </mat-checkbox>
                    </div>
                </div>
            </div>

            <dialog-actions class="flex flex-row items-center space-x-4 justify-end">
                <button mat-stroked-button mat-dialog-close>
                    {{ root("actions.cancel") }}
                </button>
                <button
                    mat-flat-button
                    [disabled]="!form.valid || (isCreating$ | async)"
                    (click)="save()"
                    color="primary"
                >
                    {{ root("actions.save") }}
                </button>
            </dialog-actions>
        </ui-dialog-base>
    </ng-container>
</ng-container>
