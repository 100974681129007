<div class="flex flex-col w-full h-full" [ngClass]="classes">
    <div
        class="flex flex-0 items-center justify-between h-16 pr-2 sm:pr-2 pl-4 sm:pl-6 text-default cursor-move border-b shadow-sm"
        cdkDrag
        cdkDragRootElement=".cdk-overlay-pane"
        cdkDragHandle>
        <div class="text-lg font-medium">{{title}}</div>
        <button *ngIf="showCloseButton"
                mat-icon-button
                (click)="close()"
                [tabIndex]="-1">
            <mat-icon
                class="text-current"
                [svgIcon]="'heroicons_outline:x'"></mat-icon>
        </button>
    </div>

    <div cdkTrapFocus [cdkTrapFocusAutoCapture]="true" class="flex-grow w-full overflow-hidden py-1 relative">
        <ng-content></ng-content>
    </div>

    <!-- Actions -->
    <div class="flex flex-col sm:flex-row sm:items-center justify-between py-4 px-6 border-t shadow bg-gray-50 space-y-2 sm:space-y-0">
        <div>
            <ng-content select="dialog-complementary-actions"></ng-content>
        </div>

        <ng-content select="dialog-actions"></ng-content>
    </div>
</div>
