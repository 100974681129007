import { Component, OnDestroy, OnInit, ViewEncapsulation, Injector } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FuseConfigService } from "@fuse/services/config.service";
import { navigation } from "app/navigation/navigation";
import { AppComponentBase } from "@shared/app-component-base";
import { Layout1Service } from "@app/layout/vertical/layout-1/layout-1.service";
import { AudioService } from "@app/audio.service";
import { ChatPanelService } from "app/wapps/chat-panel/chat-panel.service";

@Component({
    selector: "vertical-layout-1",
    templateUrl: "./layout-1.component.html",
    styleUrls: ["./layout-1.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class VerticalLayout1Component extends AppComponentBase implements OnInit, OnDestroy {
    fuseConfig: any;

    navigation: any;

    private _unsubscribeAll: Subject<any>;

    chatEnabled: boolean;

    constructor(
        private _service: Layout1Service,
        private _fuseConfigService: FuseConfigService,
        private _internalChatService: ChatPanelService,
        injector: Injector,
        private _audioService: AudioService) {
        super(injector);
        this.navigation = navigation;
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.fuseConfig = config;
            });

        this._internalChatService.chatEnabled
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(chatEnabled => {
                this.chatEnabled = chatEnabled;
            });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    onNavbarOpenedChanged(opened: boolean) {
        this._service.navbarOpened = opened;
    }

    onNavbarFoldedChanged(folded: boolean) {
        this._service.navbarFolded = folded;
    }

    globalClick() {
        this._audioService.init();
    }
}
