import {NotificationParser} from "@app/wapps/notification/notification.parser";
import {Notification} from "@app/wapps/notification/notification.model";
import {Injector} from "@angular/core";
import * as moment from 'moment';

export class AppointmentCallFileReceivedNotificationParser extends  NotificationParser
{
    static Type = "Wapps.Health.Appointment.Notifications.AppointmentFileUploadedNotificationData";
    
    constructor(injector: Injector)
    {
        super(injector);
    }

    parse(userNotification) : Notification {
        let id = userNotification.id;
        let icon = "file";
        let data : any = userNotification.notification.data;
        let title = this.l(`Você recebeu um novo arquivo`);
        let subtitle = this.l(`Toque aqui para abrir`);;
        return new Notification(id, icon, title, subtitle, userNotification);
    }
    
    static getFileId(notification: Notification) {
        let data : any = notification.userNotification.notification.data;
        return data.fileId;
    }
    
    static getFileName(notification: Notification) {
        let data : any = notification.userNotification.notification.data;
        return data.fileName;
    }

    open(notification: Notification) {
        //console.log('Open calendar and select appointment');
        let data : any = notification.userNotification.notification.data;
        let id = data.id;
        let date: moment.Moment = moment(data.date.toString());
        let queryParams = {
            id: id,
            date: date
        }
        this.router.navigate(['/calendar'], {
            queryParams
        });
    }
}