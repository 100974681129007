import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import {
    selectInactiveClients,
    selectIsLoading, selectPeriod,
} from "./selectors";
import { IStatsSessionsState } from "./model";
import { actions } from ".";
import { TenantLastActive } from "../../api/proxies";
import { SessionHelper } from "../../tools/utils";

@Injectable({
    providedIn: "root",
})
export class StatsSessionsFacade {
    isLoading$: Observable<boolean> = this._store.select(selectIsLoading);

    datasource$: Observable<TenantLastActive[]> = this._store.select(selectInactiveClients);

    period$: Observable<number> = this._store.select(selectPeriod);

    constructor(private _store: Store<IStatsSessionsState>) {
        this.updatePeriod(1);
    }

    getInactiveClients(since: Date) {
        this._store.dispatch(actions.getInactiveClients({sinceDate: since}));
    }

    updatePeriod(period: number) {
        this._store.dispatch(actions.setPeriod({ period }));
        let sinceDate = SessionHelper.PeriodConverter(period);
        this.getInactiveClients(sinceDate);
    }

    exportInactiveClients(period: number) {
        let sinceDate = SessionHelper.PeriodConverter(period);
        this._store.dispatch(actions.exportInactiveClients({sinceDate: sinceDate}));
    }
}
