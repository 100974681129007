<div
  fxLayout="row"
  fxLayoutAlign="start center"
  *ngIf="trialPeriod && (status === 0 || status === 1)"
  class="pl-4 trial-message-container"
>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" *xcMediaIf="'gt-xs'">
    <div class="mat-body pr-4" [innerHTML]="trialDaysLeftText"></div>
    <button mat-raised-button class="mat-warn" (click)="pay()" *ngIf="!subscriptionPage && allowBuy">
      {{ l("Tenant.Trial.Action.Buy.Title") }}
    </button>
  </div>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" *xcMediaIf="'xs'">
    <button mat-raised-button class="mat-warn" (click)="pay()" *ngIf="!subscriptionPage && allowBuy">
      {{ l("Tenant.Trial.Action.Buy.Title") }}
    </button>
  </div>
</div>

<div fxLayout="row" fxLayoutAlign="start center" *ngIf="status === 3" class="pl-4 trial-message-container">
  <div class="mat-body pr-4" [innerHTML]="trialDaysLeftText"></div>
</div>

<div fxLayout="row" fxLayoutAlign="start center" *ngIf="status === 71" class="pl-4 trial-message-container">
  <div class="mat-body pr-4" [innerHTML]="trialDaysLeftText"></div>
  <button mat-raised-button class="mat-warn" (click)="abandonCancellation()">
    Desistir do cancelamento
  </button>
</div>
