import { Injectable } from "@angular/core";
import { FinancialPermissionNames } from "@app/wapps/financial/permissions-name";

@Injectable({
    providedIn: "root",
})
export class FinancialConfigService {

    isEnabled() {
        return abp.features.isEnabled("Core.Features.Financial");
    }

    isMenuEnabled() {
        return abp.features.isEnabled("Core.Features.Financial") &&
            abp.auth.isAnyGranted(FinancialPermissionNames.Configuration, FinancialPermissionNames.Revenues, FinancialPermissionNames.Expenses, FinancialPermissionNames.Reports);
    }

    allowAccessConfig() {
        return abp.auth.isGranted(FinancialPermissionNames.Configuration);
    }

    allowAccessRevenues() {
        return abp.auth.isAnyGranted(FinancialPermissionNames.Revenues, FinancialPermissionNames.QueryRevenues, FinancialPermissionNames.AddRevenue, FinancialPermissionNames.DeleteRevenue, FinancialPermissionNames.UpdateRevenue);
    }

    allowAccessExpenses() {
        return abp.auth.isAnyGranted(FinancialPermissionNames.Expenses, FinancialPermissionNames.QueryExpenses, FinancialPermissionNames.AddExpense, FinancialPermissionNames.DeleteExpense, FinancialPermissionNames.UpdateExpense);
    }

    allowAccessReports() {
        return abp.auth.isAnyGranted(FinancialPermissionNames.Reports, FinancialPermissionNames.CashFlow);
    }
}
