<ng-container *transloco="let t; read: 'notification.settings'">
  <div class="absolute inset-0 flex flex-col min-w-0 overflow-hidden">
    <div *ngIf="loading$ | async" class="absolute inset-x-0 top-0">
      <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
    </div>

    <!-- Header -->
    <div class="flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between p-4 border-b dark:bg-transparent">
      <div class="flex flex-row items-center space-x-2">
        <button mat-icon-button title="Voltar" (click)="navigateBack()">
          <mat-icon [svgIcon]="'heroicons_outline:arrow-narrow-left'"></mat-icon>
        </button>
        <div class="space-y-2">
          <div class="section-title">{{ t("TITLE") }}</div>
        </div>
      </div>
    </div>

    <!-- Main -->
    <div class="container-vertical-scroll bg-card" fuseScrollbar>
      <div class="flex w-full p-4 space-x-2 items-center border-b text-base tracking-tight">
        <mat-checkbox [(ngModel)]="allowScheduleNotifications"
          (change)="allowScheduleNotificationsChange($event)">{{t("ALLOW_SCHEDULE_NOTIFICATIONS")}}</mat-checkbox>
      </div>
      <div class="flex w-full p-4 space-x-2 items-center border-b text-base tracking-tight">
        <mat-checkbox [(ngModel)]="allowPreFormResponseNotifications"
          (change)="allowPreFormResponseNotificationsChange($event)">{{t("ALLOW_PRE_FORM_RESPONSE_NOTIFICATION")}}</mat-checkbox>
      </div>
    </div>
  </div>
</ng-container>