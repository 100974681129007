import { Observable, throwError } from "rxjs";
import { ApiException } from "@api";
import { addDays, addMonths } from "date-fns";

export function blobToText(blob: any): Observable<string> {
    return new Observable<string>((observer: any) => {
        if (!blob) {
            observer.next("");
            observer.complete();
        } else {
            let reader = new FileReader();
            reader.onload = function () {
                observer.next(this.result);
                observer.complete();
            };
            reader.readAsText(blob);
        }
    });
}

export function throwException(
    message: string,
    status: number,
    response: string,
    headers: { [key: string]: any },
    result?: any
): Observable<any> {
    if (result !== null && result !== undefined) return throwError(result);
    else return throwError(new ApiException(message, status, response, headers, null));
}

export class SessionHelper {
    static PeriodConverter(period: number) : Date {
        let sinceDate  = addDays(new Date(), -7);
        switch (period) {
            case 2:
                sinceDate = addDays(new Date(), -14);
                break;
            case 3:
                sinceDate = addMonths(new Date(), -1);
                break;
            case 4:
                sinceDate = addMonths(new Date(), -6);
                break;
        }

        return sinceDate;
    }
}