import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConsts } from '@shared/AppConsts';
import { HttpClient } from '@angular/common/http';
import { UpdateUserDto, UserDto, UserServiceProxy, UploadResponse } from '@shared/service-proxies/service-proxies';
import { FormGroup } from '@angular/forms';
import { AppSessionService } from '@shared/session/app-session.service';
import { finalize } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class UsersService {
    readonly onLoggedUserAvatarChanged: EventEmitter<string> = new EventEmitter();

    readonly onLoggedUserChanged: EventEmitter<UserDto> = new EventEmitter();

    readonly onUserChanged: EventEmitter<UserDto> = new EventEmitter();

    constructor(
        private _api: UserServiceProxy,
        private _httpClient: HttpClient,
        private _sessionService: AppSessionService
    ) {}

    update(form: FormGroup): Observable<UserDto> {
        return new Observable<UserDto>((observer) => {
            let input = UpdateUserDto.fromJS(form.value);
            this._api
                .update(input)
                .pipe(
                    finalize(() => {
                        observer.complete();
                    })
                )
                .subscribe(
                    (result) => {
                        if (input.id === this._sessionService.userId) {
                            this.onLoggedUserChanged.next(result);
                        }
                        this.onUserChanged.next(result);
                        observer.next(result);
                    },
                    (error) => {
                        observer.error(error);
                    }
                );
        });
    }

    validateEmail(email: string): Observable<boolean> {
        return this._api.validateEmail(email);
    }

    allowResetPassword(userId: number, extraAllowedRoles: string[] = []) {
        if (userId <= 0) {
            return false;
        }
        let allowedRoles = [...extraAllowedRoles, AppConsts.RolesNames.Admin];
        return (
            this._sessionService.isHostUser() ||
            userId === this._sessionService.userId ||
            allowedRoles.findIndex((role) => this._sessionService.isUserInRoleTenant(role)) >= 0
        );
    }

    allowImpersonate(userId: number) {
        return this._sessionService.isHostUser() && userId !== this._sessionService.userId;
    }

    public uploadAvatarImage(tempAvatarUrl: string, userId: number): Observable<any> {
        return new Observable<any>((observer) => {
            const formData = new FormData();
            formData.append('path', tempAvatarUrl);
            formData.append('userId', '' + userId);
            let url = AppConsts.remoteServiceBaseUrl + '/api/services/app/UserAvatar/Update';
            this._httpClient
                .post(url, formData)
                .pipe(
                    finalize(() => {
                        observer.complete();
                    })
                )
                .subscribe(
                    (response: any) => {
                        if (response.success && userId === this._sessionService.userId) {
                            this.onLoggedUserAvatarChanged.next(response.result.url + '?d=' + new Date().getTime());
                        }
                        observer.next(response);
                    },
                    (error) => {
                        observer.error(error);
                    }
                );
        });
    }

    get(): Observable<UserDto> {
        return this._api.get(this._sessionService.userId);
    }

    isTourVisualized(tourName: string): Observable<boolean> {
        return this._api.isTourVisualized(tourName);

    }

    markTourAsVisualized(tourName: string): Observable<boolean> {
        return this._api.markTourAsVisualized(tourName);
    }
}
