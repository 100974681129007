import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { FormControl } from "@angular/forms";
import { TenantLastActive } from "../api/proxies";
import { ClipboardUtils } from "@vui";
import { NotifyService } from "@ui/notify";
import { StatsSessionsFacade } from "./store/facade";

@Component({
    selector: "inactive-tenants",
    templateUrl: "./inactive-tenants.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InactiveTenantsComponent implements OnInit, OnDestroy {
    private _unsubscribeAll = new Subject();

    selected$: Observable<number>;

    datasource$: Observable<TenantLastActive[]>;

    loading$ = new Observable<boolean>();

    filterCtrl: FormControl = new FormControl();
    
    selectedPeriod: number;

    constructor(private _notifyService: NotifyService,
                private _facade: StatsSessionsFacade) {
        this.datasource$ = this._facade.datasource$;
        this.selected$ = this._facade.period$;
        this.loading$ = this._facade.isLoading$;
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    selectPeriod(period: number) {
        this._facade.updatePeriod(period);
        this.selectedPeriod = period;
    }

    copy(text: any) {
        ClipboardUtils.copyText(text);
        this._notifyService.info("Copiado para a area de transferencia");
    }
    
    exportSpreadsheet() {
        this._facade.exportInactiveClients(this.selectedPeriod);
    }
}
