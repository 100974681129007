import { createFeatureSelector, createSelector } from "@ngrx/store";
import { STATS_MOBILE_FEATURE_KEY } from "./reducer";
import { IMobileStatState } from "./model";

export const mobileStatsState = createFeatureSelector<IMobileStatState>(STATS_MOBILE_FEATURE_KEY);

export const selectInstallations = createSelector(mobileStatsState, (state) => state.installations);

export const selectMau = createSelector(mobileStatsState, (state) => state.mau);

export const selectIsLoading = createSelector(mobileStatsState, (state) => state.loading);

export const selectInstallationHistory = createSelector(mobileStatsState, (state) => state.installationHistory);

export const selectMauHistory = createSelector(mobileStatsState, (state) => state.mauHistory);