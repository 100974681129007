<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center" *ngIf="result">
  <h2 class="section-title">Resposta de formulário</h2>
  <button mat-icon-button (click)="close()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-dialog-content class="mat-typography flex flex-col space-y-4 h-full" cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
  <div class="flex flex-col space-y-4" *ngIf="patient">
    <span>Paciente: {{patient.name}}</span>
  </div>
  <mat-divider></mat-divider>
  <form-answer-viewer [response]="result" *ngIf="result"></form-answer-viewer>
</mat-dialog-content>
<mat-dialog-actions align="end" *ngIf="allowViewOnMedicalRecord">
  <button mat-flat-button color="primary" (click)="navigateToPatientTimeline()">
    Ver no prontuário
  </button>
</mat-dialog-actions>