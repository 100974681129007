import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'vui-dialog',
    templateUrl: './dialog.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class DialogComponent implements OnInit {
    @Input()
    allowCancelTouchingOutside: boolean = true;

    @Input()
    showCloseButton: boolean = true;

    @Input()
    classes: string[] = ['sm:w-60vw'];

    @Input()
    title: string;

    constructor(public matDialogRef: MatDialogRef<DialogComponent>) {
        this.matDialogRef.addPanelClass(['xs-full-screen', 'dialog-v2']);
    }

    ngOnInit(): void {
        this.matDialogRef.disableClose = !this.allowCancelTouchingOutside;
    }

    close() {
        this.matDialogRef.close();
    }
}
