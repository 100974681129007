<div class="alerts-container">
    <ng-container *ngFor="let alert of alerts">
        <div class="card-container" *ngIf="!alert.isHidden">
            <mat-card class="{{alert.type}}-card mat-elevation-z2" >
                <div class="flex-row {{alert.type}}-card-dark">
                    <mat-icon aria-hidden="true" class="icon">{{alert.type}}</mat-icon>
                    <div class="flex-col stretch underline-hover">
                        <mat-card-title class="{{alert.type}}-card-dark" (click)="_alertService.execute(alert)">{{alert.title}}</mat-card-title>
                        <mat-card-subtitle class="{{alert.type}}-card-dark" (click)="_alertService.execute(alert)">{{alert.description}}</mat-card-subtitle>
                    </div>
                    <mat-button mat-button *ngIf="alert.closeable" (click)="_alertService.dismiss(alert)" title="close-button">
                        <mat-icon class="bttn-hover">close</mat-icon>
                    </mat-button>
                </div>
            </mat-card>
        </div>
    </ng-container>
</div>

