<div
        class="absolute inset-0 flex flex-col min-w-0 overflow-y-auto"
        cdkScrollable>

  <!-- Main -->
  <div class="flex-auto p-2 sm:p-4 bg-gray-100">

    <!-- CONTENT GOES HERE -->
    <div class="grid grid-cols-2 gap-8 w-full">
      <inactive-tenants class="col-span-2"></inactive-tenants>
      <toolbar class="col-span-2"></toolbar>
      <mobile-summary class="col-span-2"></mobile-summary>
      <appointment-summary class="col-span-2"></appointment-summary>
      <documents-summary class="col-span-1"></documents-summary>
    </div>

  </div>

</div>
