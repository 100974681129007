import { Component, Inject, Injector, OnInit, ViewEncapsulation } from "@angular/core";
import { FuseProgressBarService } from "@fuse/components/progress-bar/progress-bar.service";
import { AppointmentServiceProxy, PatientNameInfo } from "@shared/service-proxies/service-proxies";
import { finalize } from "rxjs/operators";
import { BaseDialogComponent } from "../../../core/base-dialog/base-dialog.component";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {
    GetMedicalAppointmentPreProcedureFormAnswerRequest,
    GetMedicalAppointmentPreProcedureFormAnswerResponse,
} from "@shared/service-proxies/service-proxies";
import { Router } from "@angular/router";

@Component({
    selector: "appointment-form-viewer",
    templateUrl: "./appointment-form-viewer.component.html",
    styleUrls: ["./appointment-form-viewer.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class AppointmentFormViewerComponent
    extends BaseDialogComponent<AppointmentFormViewerComponent>
    implements OnInit
{
    result: GetMedicalAppointmentPreProcedureFormAnswerResponse;

    patient: PatientNameInfo;

    allowViewOnMedicalRecord: boolean;

    constructor(
        injector: Injector,
        private _progressBarService: FuseProgressBarService,
        public dialogRef: MatDialogRef<AppointmentFormViewerComponent>,
        @Inject(MAT_DIALOG_DATA) public _data: any,
        private _api: AppointmentServiceProxy,
        private _router: Router
    ) {
        super(injector, dialogRef);
        this.disableClose = false;
        this.dialogRef.addPanelClass("appointment-form-viewer-dialog");
    }

    ngOnInit(): void {
        this._progressBarService.show();
        this._api
            .getAnswer(
                GetMedicalAppointmentPreProcedureFormAnswerRequest.fromJS({
                    responseId: this._data.responseId,
                })
            )
            .pipe(
                finalize(() => {
                    this._progressBarService.hide();
                })
            )
            .subscribe((result: GetMedicalAppointmentPreProcedureFormAnswerResponse) => {
                this.result = result;

                this.patient = result.patient;

                this.allowViewOnMedicalRecord = result.allowViewOnMedicalRecord;

                this.changeDetector.detectChanges();
            });
    }

    navigateToPatientTimeline() {
        this._router.navigate(["patient", this.result.patient.id], { queryParams: { tab: "medicalRecord" } }).then();
        this.dialogRef.close();
    }
}
