import { Injectable } from "@angular/core";
import { ComponentStore } from "@ngrx/component-store";
import { assign } from "lodash";
import { take } from "rxjs/operators";
import { ActivityItemDefinition, FormItemDefinition, ProtocolDefinition } from "../../model";
import { combineLatest } from "rxjs";
import { FeedFacade } from "../store/facade";
import { addDays, setHours, setMinutes } from "date-fns";
import RRule from "rrule";
import { RecurrenceHelper } from "@vui";

export interface CreateState {
    activityItems: ActivityItemDefinition[];
    formItems: FormItemDefinition[];
    editing: boolean;
    editingItem: ActivityItemDefinition | undefined;
    editingForm: FormItemDefinition | undefined;
}

@Injectable()
export class CreateProtocolComponentStore extends ComponentStore<CreateState> {
    currentId: number = 1;

    proposedStartDate: Date;

    constructor(private _facade: FeedFacade) {
        super({
            activityItems: [],
            formItems: [],
            editing: false,
            editingItem: undefined,
            editingForm: undefined,
        });

        this.proposedStartDate = addDays(setHours(setMinutes(new Date(), 0), 8), 1);
    }

    readonly formItems$ = this.select((state) => state.formItems);

    readonly activityItems$ = this.select((state) => state.activityItems);

    readonly editing$ = this.select((state) => state.editing);

    readonly editingItem$ = this.select((state) => state.editingItem);

    readonly editingForm$ = this.select((state) => state.editingForm);

    readonly isValid$ = this.select(
        (state) =>
            state.formItems.filter((l) => l.id > 0).length > 0 || state.activityItems.filter((l) => l.id > 0).length > 0
    );

    addForm(form = new FormItemDefinition()) {
        let activity = FormItemDefinition.create(form, this.currentId, this.proposedStartDate);
        this.setState((state) => {
            return {
                ...state,
                formItems: [...state.formItems, activity],
                editingForm: activity,
                editing: true,
            };
        });
    }

    addActivity(item = new ActivityItemDefinition()) {
        let activity = ActivityItemDefinition.create(item, this.currentId, this.proposedStartDate);
        this.setState((state) => {
            return {
                ...state,
                activityItems: [...state.activityItems, activity],
                editingItem: activity,
                editing: true,
            };
        });
    }

    handleActivitySelection(activity: ActivityItemDefinition) {
        this.setState((state) => {
            return {
                ...state,
                activityItems: state.activityItems.map((a) => {
                    if (a.id === this.currentId) {
                        return assign(a, {
                            ...a,
                            recurrence: activity.recurrence,
                        });
                    }
                    return a;
                }),
                editingItem: {
                    ...state.editingItem,
                    title: activity.title,
                    recurrence: activity.recurrence,
                    comments: activity.comments,
                },
                editing: true,
            };
        });
    }

    startActivityEdition(activity: ActivityItemDefinition) {
        this.editingItem$.pipe(take(1)).subscribe((editingProtocol) => {
            if (editingProtocol) {
                this.cancelItemEdition(activity, editingProtocol);
            } else {
                this.setState((state) => {
                    return {
                        ...state,
                        editingItem: activity,
                        editing: true,
                    };
                });
            }
        });
    }

    startFormEdition(activity: FormItemDefinition) {
        this.editingForm$.pipe(take(1)).subscribe((editingProtocol) => {
            if (editingProtocol) {
                this.cancelFormEdition(activity, editingProtocol);
            } else {
                this.setState((state) => {
                    return {
                        ...state,
                        editingForm: activity,
                        editing: true,
                    };
                });
            }
        });
    }

    acceptFormChanges(activity: FormItemDefinition) {
        this.formItems$.pipe(take(1)).subscribe((activities) => {
            let index = activities.findIndex((i) => i.id === activity.id);
            let fixedRecurrence = activities[index].recurrence;
            let updatedActivity = assign(new FormItemDefinition(), { ...activity, recurrence: fixedRecurrence });

            if (updatedActivity.id === 0 || !updatedActivity.id) {
                updatedActivity.id = this.currentId++;
            }

            if (index >= 0) {
                this.currentId++;
                activities.splice(index, 1, updatedActivity);
                this.setState((state) => {
                    return {
                        ...state,
                        formItems: [
                            ...activities.sort((a, b) => (a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1)),
                        ],
                        editing: false,
                        editingForm: undefined,
                    };
                });
            } else {
                this.currentId++;
                this.setState((state) => {
                    activities.splice(activities.length - 1, 1, updatedActivity);
                    return {
                        ...state,
                        formItems: [
                            ...activities.sort((a, b) => (a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1)),
                        ],
                        editing: false,
                        editingForm: undefined,
                    };
                });
            }
        });
    }

    acceptActivityChanges(activity: ActivityItemDefinition) {
        this.activityItems$.pipe(take(1)).subscribe((activities) => {
            let index = activities.findIndex((i) => i.id === activity.id);

            let fixedRecurrence = activities[index].recurrence;

            let updatedActivity = assign(new ActivityItemDefinition(), {
                ...activity,
                recurrence: fixedRecurrence,
            });

            if (updatedActivity.id === 0 || !updatedActivity.id) {
                updatedActivity.id = this.currentId++;
            }

            if (index >= 0) {
                this.currentId++;
                activities.splice(index, 1, updatedActivity);
                this.setState((state) => {
                    return {
                        ...state,
                        activityItems: [
                            ...activities.sort((a, b) => (a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1)),
                        ],
                        editing: false,
                        editingItem: undefined,
                    };
                });
            } else {
                this.currentId++;
                this.setState((state) => {
                    activities.splice(activities.length - 1, 1, updatedActivity);
                    return {
                        ...state,
                        activityItems: [
                            ...activities.sort((a, b) => (a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1)),
                        ],
                        editing: false,
                        editingItem: undefined,
                    };
                });
            }
        });
    }

    cancelFormEdition(activity: FormItemDefinition, selectForEdition: FormItemDefinition | undefined = undefined) {
        this.formItems$.pipe(take(1)).subscribe((activities) => {
            let index = activities.findIndex((i) => i.id === activity.id);
            if (index >= 0) {
                if (activity.id === this.currentId) {
                    activities.splice(index, 1);
                }
                this.setState((state) => {
                    return {
                        ...state,
                        formItems: [...activities],
                        editing: !!selectForEdition,
                        editingForm: selectForEdition,
                    };
                });
            }
        });
    }

    cancelItemEdition(
        activity: ActivityItemDefinition,
        selectForEdition: ActivityItemDefinition | undefined = undefined
    ) {
        this.activityItems$.pipe(take(1)).subscribe((activities) => {
            let index = activities.findIndex((i) => i.id === activity.id);

            if (index >= 0) {
                if (activity.id === this.currentId) {
                    activities.splice(index, 1);
                }
                this.setState((state) => {
                    return {
                        ...state,
                        activityItems: [...activities],
                        editing: !!selectForEdition,
                        editingItem: selectForEdition,
                    };
                });
            }
        });
    }

    deleteForm(protocol: FormItemDefinition) {
        this.formItems$.pipe(take(1)).subscribe((activities) => {
            let index = activities.findIndex((i) => i.id === protocol.id);
            if (index >= 0) {
                activities.splice(index, 1);
                this.currentId--;
                this.setState((state) => {
                    return {
                        ...state,
                        formItems: [...activities],
                        editing: false,
                        editingForm: undefined,
                    };
                });
            }
        });
    }

    deleteActivity(protocol: ActivityItemDefinition) {
        this.activityItems$.pipe(take(1)).subscribe((activities) => {
            let index = activities.findIndex((i) => i.id === protocol.id);
            if (index >= 0) {
                activities.splice(index, 1);
                this.currentId--;
                this.setState((state) => {
                    return {
                        ...state,
                        activityItems: [...activities],
                        editing: false,
                        editingItem: undefined,
                    };
                });
            }
        });
    }

    save(data: any) {
        this.setState((state) => ({
            ...state,
            adding: true,
        }));
        combineLatest([this.activityItems$, this.formItems$])
            .pipe(take(1))
            .subscribe(([activities, forms]) => {
                let template: ProtocolDefinition = assign(new ProtocolDefinition(), {
                    ...data,
                    items: [
                        ...activities.map((a) => {
                            let activityRecurence = RRule.fromString(a.recurrence);

                            let newRule = RecurrenceHelper.checkForLocalOffsetRemoval(activityRecurence);

                            return assign(a, {
                                ...a,
                                comments: a.comments ?? "",
                                discriminator: "ActivityItemDto",
                                recurrence: RRule.optionsToString(newRule.options),
                            });
                        }),
                        ...forms.map((f) => {
                            let formRecurence = RRule.fromString(f.recurrence);

                            let newRule = RecurrenceHelper.checkForLocalOffsetRemoval(formRecurence);

                            return assign(f, {
                                ...f,
                                discriminator: "FormItemDto",
                                recurrence: RRule.optionsToString(newRule.options),
                            });
                        }),
                    ],
                });
                this._facade.create(template.name, template.items, data.sign);
            });
    }
}
