import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable(    )
export class UrlHistoryService {
    
    constructor() {
        
    }    

    replace(data: Map<string, string>) {
        let url = window.location.href;
        let index = 0;
        data.forEach((value, key) => {
            //let index = url.indexOf()
            url = url.replace(key, value);
        });
        window.history.replaceState({}, '',url);
    }

    append(data: string[]) {
        let url = window.location.href;
        let index = 0;
        data.forEach(value => {
            //let index = url.indexOf()
            url = url + "/" + value;
        });
        window.history.replaceState({}, '',url);
    }
}
