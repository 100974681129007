import { Protocol } from "../../model";

export const SmartFormActivityEntryStatusCreated = 2;

export const SmartFormActivityEntryStatusSent = 4;

export const SmartFormActivityEntryStatusVisualized = 8;

export const SmartFormActivityEntryStatusIgnored = 16;

export const SmartFormActivityEntryStatusExecuted = 32;

export interface FeedState {
    loading: boolean;

    creating: boolean;

    error: null;

    patientId: string | undefined;

    datasource: Protocol[];
}
