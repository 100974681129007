import { SmartFormDefinition } from "@vhealth/engage";
import { FolderDto } from "../../utils";

export class SmartFormFolder implements FolderDto {
    constructor() {}

    id: number | undefined;

    name: string | undefined;

    isPublic: boolean | undefined;

    folders: SmartFormFolder[] = [];

    parentFolderId: number | undefined;
}

export enum FeedStatus {
    Hidden = 0,
    PatientHidden = 1,
    Visible = 2,
}

export interface SmartFormFoldersState {
    loading: boolean;

    loadingDetails: boolean;

    creatingFolder: boolean;

    creating: boolean;

    updating: boolean;

    deleting: boolean;

    error: null;

    folders: SmartFormFolder[] | undefined;

    selected: SmartFormFolder | undefined;

    selectedFolderForms: SmartFormDefinition[] | undefined;
}
