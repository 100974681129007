import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import {
    selectIsLoading, selectPrescriptionHistory, selectPrescriptionStats,
} from "./selectors";
import { IPrescriptionStatItem, PrescriptionHistoryItem } from "@dashboard-statistics";
import { IDocumentStatState } from "./model";
import { actions } from ".";

@Injectable({
    providedIn: "root",
})
export class DocumentStatsFacade {
    isLoading$: Observable<boolean> = this._store.select(selectIsLoading);

    prescription$: Observable<IPrescriptionStatItem> = this._store.select(selectPrescriptionStats);

    prescriptionHistory$: Observable<PrescriptionHistoryItem[]> = this._store.select(selectPrescriptionHistory);

    constructor(private _store: Store<IDocumentStatState>) {
    }

    getPrescriptionHistory() {
        this._store.dispatch(actions.getPrescriptionHistory());
    }
}
