import { createReducer, on } from "@ngrx/store";
import { actions, IMobileStatState } from ".";

export const STATS_MOBILE_FEATURE_KEY = "stats.mobile";

export const initialStatsSessionsState: IMobileStatState = {
    installations: [],
    installationHistory: [],
    mau: [],
    mauHistory: [],
    loading: false,
};

export const getMobileStatsReducer = createReducer(
    initialStatsSessionsState,
    on(actions.getStats, (state) => ({
        ...state,
        loading: true,
    })),
    on(actions.getStatsComplete, (state, {installations, mau}) => {
        return {
            ...state,
            installations: installations,
            mau: mau,
            loading: false,
        };
    }),
    on(actions.getStatsError, (state, {error}) => ({
        ...state,
        loading: false,
    })),

    on(actions.getInstallationHistory, (state) => ({
        ...state,
        loading: true,
    })),
    on(actions.getInstallationHistoryComplete, (state, {installationHistory}) => {
        return {
            ...state,
            installationHistory: installationHistory,
            loading: false,
        };
    }),
    on(actions.getInstallationHistoryError, (state, {error}) => ({
        ...state,
        loading: false,
    })),

    on(actions.getMauHistory, (state) => ({
        ...state,
        loading: true,
    })),
    on(actions.getMauHistoryComplete, (state, {mauHistory}) => {
        return {
            ...state,
            mauHistory: mauHistory,
            loading: false,
        };
    }),
    on(actions.getMauHistoryError, (state, {error}) => ({
        ...state,
        loading: false,
    })),
);
