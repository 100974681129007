import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { Subject } from "rxjs";
import { fuseAnimations } from "@fuse/animations";
import { AppComponentBase } from "@shared/app-component-base";
import { Injector } from "@angular/core";
import { HealthTenantServiceProxy, TenantInfo } from "@shared/service-proxies/service-proxies";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { NavigationEnd, Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { AppSessionService } from "@shared/session/app-session.service";
import { takeUntil } from "rxjs/internal/operators";
import { AppConsts } from "@shared/AppConsts";
import { differenceInCalendarDays, format } from "date-fns";
import { SubscriptionStatusCode } from "@shared/service-proxies/service-proxies";
import { FuseProgressBarService } from "@fuse/components/progress-bar/progress-bar.service";

export const MY_FORMATS = {
    parse: {
        dateInput: "MM/YYYY",
    },
    display: {
        dateInput: "MM/YYYY",
        monthYearLabel: "MMM YYYY",
        dateA11yLabel: "LL",
        monthYearA11yLabel: "MMMM YYYY",
    },
};

@Component({
    selector: "trial-message",
    templateUrl: "./trial-message.component.html",
    styleUrls: ["./trial-message.component.scss"],
    animations: fuseAnimations,
    providers: [
        {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
        {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    ],
})
export class TrialMessageComponent extends AppComponentBase implements OnInit, OnDestroy {
    trialPeriod: boolean = false;

    private _unsubscribeAll: Subject<any>;

    loaded: boolean = true;

    trialDaysLeftText: string;

    subscriptionPage: boolean;

    allowBuy: boolean;

    status: SubscriptionStatusCode;

    constructor(
        injector: Injector,
        public _matDialog: MatDialog,
        private _healthTenantService: HealthTenantServiceProxy,
        private _sessionService: AppSessionService,
        private _router: Router,
        private _fuseProgressBarService: FuseProgressBarService
    ) {
        super(injector);

        this._unsubscribeAll = new Subject();

        this._sessionService.onTrialPayed.pipe(takeUntil(this._unsubscribeAll)).subscribe((tenantInfo) => {
            if (!!tenantInfo) {
                this.status = tenantInfo.subscriptionInfo.status.code;
                switch (tenantInfo.subscriptionInfo.status.code) {
                    case 0:
                    case 3:
                        this.trialPeriod = true;
                        this.trialDaysLeftText = `Seu teste gratuito expira em <b>${differenceInCalendarDays(
                            tenantInfo.subscriptionInfo.status.date.toDate(),
                            new Date()
                        )} dias </b>`;
                        break;
                    case 1:
                        this.trialPeriod = true;
                        this.trialDaysLeftText = `Seu teste gratuito expirou em <b>${format(
                            tenantInfo.subscriptionInfo.status.date.toDate(),
                            "dd/MM/yyyy"
                        )}</b>`;
                        break;
                    case 71:
                        this.trialPeriod = true;
                        this.trialDaysLeftText = `Seu teste gratuito expira em <b>${differenceInCalendarDays(
                            tenantInfo.subscriptionInfo.status.date.toDate(),
                            new Date()
                        )} dias </b>`;
                        break;
                    default:
                        this.trialPeriod = false;
                        break;
                }
                this.allowBuy = this._sessionService.isUserInRoleTenant(AppConsts.RolesNames.Admin);
            }
        });

        this._router.events.pipe(takeUntil(this._unsubscribeAll)).subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.subscriptionPage = event.url.indexOf("subscription") > -1;
            }
        });
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    pay(): void {
        this._router.navigate(["/checkout"], {queryParams: {returnUrl: this._router.url}}).then();
    }

    abandonCancellation() {
        this._router.navigate(["/abandon-cancellation"]).then();
    }
}
