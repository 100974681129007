import { createFeatureSelector, createSelector } from "@ngrx/store";
import { STATS_FEATURE_KEY } from "./reducer";
import { IStatsState } from "./model";

export const statsState = createFeatureSelector<IStatsState>(STATS_FEATURE_KEY);

export const selectPeriod = createSelector(statsState, (state) => state.period);

export const selectTenant = createSelector(statsState, (state) => state.tenant);

export const selectTenants = createSelector(statsState, (state) => state.tenants);