import { createReducer, on } from "@ngrx/store";
import { actions, IStatsState } from ".";
import { endOfDay, startOfMonth } from "date-fns";

export const STATS_FEATURE_KEY = "stats";

export const initialStatsSessionsState: IStatsState = {
    tenants: [],
    tenant: undefined,
    period: {
        id: 1,
        start: startOfMonth(new Date()),
        end: endOfDay(new Date())
    },
};

export const getStatsReducer = createReducer(
    initialStatsSessionsState,
    on(actions.setPeriod, (state, { period }) => ({
        ...state,
        period: period,
    })),
    on(actions.setClient, (state, { tenant }) => {
        if (tenant) {
            return {
                ...state,
                tenant: tenant,
                tenants: [tenant]
            };    
        }
        
        return {
            ...state,
            tenant: undefined,
            tenants: []
        };
    }),
    on(actions.setClients, (state, { tenants }) => {
        if (state.tenant) {
            return {
                ...state,
                tenants: [state.tenant]
            };
        }
        
        return {
            ...state,
            tenants: [...tenants]
        };
    }),
);
