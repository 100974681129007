/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAction } from "@ngrx/store";
import { ActionCreator, ActionCreatorProps, NotAllowedCheck, TypedAction } from "@ngrx/store/src/models";

type Equals<T, U, Y = unknown, N = never> = (<G>() => G extends T ? 1 : 2) extends <G>() => G extends U ? 1 : 2 ? Y : N;

type ErrorAction<entityName extends string, actionName extends string> = `[${entityName}] ${actionName}`;
type CompleteAction<
    entityName extends string,
    actionName extends string,
> = `[${entityName}] [API] ${actionName} Complete`;
type ClientAction<entityName extends string, actionName extends string> = `[${entityName}] [API] ${actionName} Error`;

type OptionalProps<ActionDescription extends string, Props extends object> = Equals<Props, never> extends never
    ? ActionCreator<
          ActionDescription,
          (props: Props & NotAllowedCheck<Props>) => Props & TypedAction<ActionDescription>
      >
    : ActionCreator<ActionDescription, () => TypedAction<ActionDescription>>;

export type ApiActions<
    EntityNameSingular extends string,
    ActionName extends string,
    PComplete extends object = never,
    PFailure extends object = never,
    PAction extends object = never,
> = {
    failure: OptionalProps<ErrorAction<EntityNameSingular, ActionName>, PFailure>;
    execute: OptionalProps<ClientAction<EntityNameSingular, ActionName>, PAction>;
    complete: OptionalProps<CompleteAction<EntityNameSingular, ActionName>, PComplete>;
};

type ApiActionsProps<PComplete extends object, PAction extends object, PError extends object> = {
    completeConfig?: ActionCreatorProps<PComplete> & NotAllowedCheck<PComplete>;
    clientConfig?: ActionCreatorProps<PAction> & NotAllowedCheck<PAction>;
    errorConfig?: ActionCreatorProps<PError> & NotAllowedCheck<PError>;
};

const apiActionsPropsTypeGuard = <PC extends object, PA extends object, PE extends object>(
    a: ApiActionsProps<PC, PA, PE> | (ActionCreatorProps<PA> & NotAllowedCheck<PA>),
): a is ApiActionsProps<PC, PA, PE> =>
    !!(a as any).completeConfig || !!(a as any).clientConfig || !!(a as any).errorConfig;

export function createApiActions<
    EntityNameSingular extends string,
    ActionName extends string,
    PComplete extends object = never,
    PAction extends object = never,
    PError extends object = never,
>(
    actionName: ActionName,
    entityName: EntityNameSingular,
    completeConfig?: ActionCreatorProps<PComplete> & NotAllowedCheck<PComplete>,
    clientConfig?: ActionCreatorProps<PAction> & NotAllowedCheck<PAction>,
    errorConfig?: ActionCreatorProps<PError> & NotAllowedCheck<PError>,
): ApiActions<EntityNameSingular, ActionName, PComplete, PError, PAction>;

export function createApiActions<
    EntityNameSingular extends string,
    ActionName extends string,
    PComplete extends object = never,
    PAction extends object = never,
    PError extends object = never,
>(
    actionName: ActionName,
    entityName: EntityNameSingular,
    props: ApiActionsProps<PComplete, PAction, PError>,
): ApiActions<EntityNameSingular, ActionName, PComplete, PError, PAction>;

export function createApiActions<
    EntityNameSingular extends string,
    ActionName extends string,
    PComplete extends object = never,
    PAction extends object = never,
    PError extends object = never,
>(
    actionName: ActionName,
    entityName: EntityNameSingular,
    propsOrCompleteConfig:
        | ApiActionsProps<PComplete, PAction, PError>
        | (ActionCreatorProps<PAction> & NotAllowedCheck<PAction>),
    clientConfig?: ActionCreatorProps<PAction> & NotAllowedCheck<PAction>,
    errorConfig?: ActionCreatorProps<PError> & NotAllowedCheck<PError>,
) {
    if (apiActionsPropsTypeGuard(propsOrCompleteConfig)) {
        return createApiActions_Internal(
            entityName,
            actionName,
            propsOrCompleteConfig.completeConfig,
            propsOrCompleteConfig.clientConfig,
            propsOrCompleteConfig.errorConfig,
        );
    } else {
        return createApiActions_Internal(entityName, actionName, propsOrCompleteConfig, clientConfig, errorConfig);
    }
}
function createApiActions_Internal<
    EntityNameSingular extends string,
    ActionName extends string,
    PComplete extends object,
    PAction extends object = never,
    PError extends object = never,
>(
    entityName: EntityNameSingular,
    actionName: ActionName,
    completeConfig?: ActionCreatorProps<PComplete> & NotAllowedCheck<PComplete>,
    clientConfig?: ActionCreatorProps<PAction> & NotAllowedCheck<PAction>,
    errorConfig?: ActionCreatorProps<PError> & NotAllowedCheck<PError>,
): ApiActions<EntityNameSingular, ActionName, PComplete, PError, PAction> {
    const action = !clientConfig
        ? createAction(`[${entityName}] [API] ${actionName}`)
        : createAction(`[${entityName}] [API] ${actionName}`, clientConfig);
    const actionComplete = !completeConfig
        ? createAction(`[${entityName}] [API] ${actionName} Complete`)
        : createAction(`[${entityName}] [API] ${actionName} Complete`, completeConfig);
    const actionFailure = !errorConfig
        ? createAction(`[${entityName}] [API] ${actionName} Error`)
        : createAction(`[${entityName}] [API] ${actionName} Error`, errorConfig);

    const actions = {
        execute: action,
        complete: actionComplete,
        failure: actionFailure,
    };

    return actions as any;
}
