import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import {
    allowPreFormResponseNotificationsChange,
    allowScheduleNotificationsChange,
    getNotificationsSettings,
} from "./actions";
import { catchError, map, mergeMap } from "rxjs/operators";
import { NotificationServiceProxy, NotificationSettingsRequest } from "@shared/service-proxies/service-proxies";
import { of } from "rxjs";

@Injectable()
export class NotificationsEffects {
    constructor(private _actions: Actions, private _api: NotificationServiceProxy) {}

    getNotificationsSettings$ = createEffect(() =>
        this._actions.pipe(
            ofType(getNotificationsSettings.execute),
            mergeMap(() =>
                this._api.getNotificationSettings().pipe(
                    map(({ allowScheduleNotifications, allowPreFormResponseNotifications }) =>
                        getNotificationsSettings.complete({
                            settings: { allowScheduleNotifications, allowPreFormResponseNotifications },
                        })
                    )
                )
            ),
            catchError((error) => of(getNotificationsSettings.failure({ error })))
        )
    );

    allowScheduleNotificationsChange$ = createEffect(() =>
        this._actions.pipe(
            ofType(allowScheduleNotificationsChange.execute),
            mergeMap(({ allowPreFormResponseNotifications, allowScheduleNotifications }) =>
                this._api
                    .updateNotificationSettings(
                        NotificationSettingsRequest.fromJS({
                            allowPreFormResponseNotifications,
                            allowScheduleNotifications,
                        })
                    )
                    .pipe(
                        map(({ allowScheduleNotifications, allowPreFormResponseNotifications }) =>
                            getNotificationsSettings.complete({
                                settings: { allowScheduleNotifications, allowPreFormResponseNotifications },
                            })
                        )
                    )
            ),
            catchError((error) => of(getNotificationsSettings.failure({ error })))
        )
    );

    allowPreFormResponseNotificationsChange$ = createEffect(() =>
        this._actions.pipe(
            ofType(allowPreFormResponseNotificationsChange.execute),
            mergeMap(({ allowPreFormResponseNotifications, allowScheduleNotifications }) =>
                this._api
                    .updateNotificationSettings(
                        NotificationSettingsRequest.fromJS({
                            allowPreFormResponseNotifications,
                            allowScheduleNotifications,
                        })
                    )
                    .pipe(
                        map(({ allowScheduleNotifications, allowPreFormResponseNotifications }) =>
                            getNotificationsSettings.complete({
                                settings: { allowScheduleNotifications, allowPreFormResponseNotifications },
                            })
                        )
                    )
            ),
            catchError((error) => of(getNotificationsSettings.failure({ error })))
        )
    );
}
