/* eslint-disable */
import {
    ChangeDetectionStrategy,
    Component,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from "@angular/core";
import { Observable, Subject } from "rxjs";
import { StatsFacade } from "./store/facade";
import {
    addDays,
    addMonths,
    addYears,
    endOfDay,
    endOfMonth,
    endOfYear,
    startOfMonth,
    startOfYear
} from "date-fns";
import { FilterPeriod } from "./store";
import { FormControl } from "@angular/forms";
import { debounceTime, finalize, takeUntil } from "rxjs/operators";
import { TenantServiceProxy } from "@api";

@Component({
    selector: "toolbar",
    templateUrl: "./toolbar.component.html",
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolbarComponent implements OnInit, OnDestroy {

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    recommendedPeriods: FilterPeriod[];

    selected: Observable<FilterPeriod>;

    selectedTenant: Observable<any>;

    datasource$: Observable<any[]>;

    loading$ = new Subject<boolean>();

    filterCtrl: FormControl = new FormControl();

    constructor(private _facade: StatsFacade,
                private _api: TenantServiceProxy) {
        this.selected = this._facade.period$;
        this.selectedTenant = this._facade.tenant$;
        this.datasource$ = this._facade.tenants$;

        let date = new Date();
        this.recommendedPeriods = [
            {
                id: 1,
                start: startOfMonth(date),
                end: endOfMonth(date),
            },
            {
                id: 2,
                start: addDays(date, -7),
                end: endOfDay(date),
            },
            {
                id: 3,
                start: startOfMonth(addMonths(date, -1)),
                end: endOfMonth(addMonths(date, -1)),
            },
            {
                id: 4,
                start: startOfMonth(addMonths(date, -3)),
                end: endOfMonth(addMonths(date, -1)),
            },
            {
                id: 5,
                start: startOfMonth(addMonths(date, -6)),
                end: endOfMonth(addMonths(date, -1)),
            },
            {
                id: 6,
                start: startOfYear(date),
                end: endOfYear(date),
            },
            {
                id: 7,
                start: startOfYear(addYears(date, -1)),
                end: endOfYear(addYears(date, -1)),
            },
        ];
    }

    ngOnInit(): void {
        this.filterCtrl.valueChanges
            .pipe(takeUntil(this._unsubscribeAll), debounceTime(500))
            .subscribe((keyword) => {
                if (!!keyword) {
                    this.loading$.next(true);
                    this._facade.selectClient(undefined);
                    this.search(keyword)
                        .pipe(finalize(() => this.loading$.next(false)))
                        .subscribe((result) => {
                            this._facade.setClients(result);
                        });
                } else {
                    this._facade.setClients([]);
                }
            });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    compareFilter(a: FilterPeriod, b: FilterPeriod) {
        return a.id === b.id;
    }

    selectPeriod(period: FilterPeriod) {
        this._facade.updatePeriod(period);
    }

    search(keyword: string | undefined): Observable<any[]> {
        return new Observable<any[]>(observer => {
            if (keyword) {
                let searchObservable = this._api.getAll(keyword, "name asc", 0, 1000);
                searchObservable
                    .pipe(finalize(() => {
                        observer.complete();
                    }))
                    .subscribe(result => {
                        observer.next(result.items);
                    });
            } else {
                observer.next([]);
                observer.complete();
            }
        });
    }

    selectClient(client: any) {
        this._facade.selectClient(client);
    }

    compareTenant(a: any, b: any) {
        return a && b && a.id === b.id;
    }
}
