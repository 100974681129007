import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { RouterModule } from '@angular/router';;
import { FuseSearchBarModule, FuseShortcutsModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import { ToolbarComponent } from 'app/layout/components/toolbar/toolbar.component';
import { NotificationServiceProxy } from '@shared/service-proxies/service-proxies';
import { NotificationService } from '@app/wapps/notification/notification.service';
import { NotificationModule } from '@app/wapps/notification/notification.module';
import {FlexLayoutModule} from "@angular/flex-layout";
import {TrialMessageComponent} from "@app/layout/components/toolbar/trial-message/trial-message.component";
import {CoreModule} from "@app/wapps/core/core.module";
import {TextMaskModule} from "angular2-text-mask";
import {CustomMaterialModule} from "@app/wapps/core/material.module";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatListModule} from "@angular/material/list";
import {MatDividerModule} from "@angular/material/divider";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatMenuModule} from "@angular/material/menu";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatBadgeModule} from "@angular/material/badge";
import {MatDialogModule} from "@angular/material/dialog";
import {FuseDirectivesModule} from "../../../../@fuse/directives/directives";

@NgModule({
    declarations: [
        ToolbarComponent,
        TrialMessageComponent
    ],
    imports     : [
        RouterModule,

        CoreModule,
        TextMaskModule,
        MatDatepickerModule,
        CustomMaterialModule,
        
        FlexLayoutModule,
        MatListModule,
        MatDividerModule,
        MatFormFieldModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatToolbarModule,
        MatBadgeModule,
        MatListModule,
        MatDialogModule,
        NotificationModule,
        
        FuseDirectivesModule,
        FuseSharedModule,
        FuseSearchBarModule,
        FuseShortcutsModule
    ],
    exports     : [
        ToolbarComponent,
        TrialMessageComponent
    ],
    providers:[
        NotificationServiceProxy,
        NotificationService
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]
})
export class ToolbarModule
{
}
