import {Router} from "@angular/router";

export class UrlHelper {
    /**
     * The URL requested, before initial routing.
     */
    static readonly initialUrl = location.href;

    static readonly entryUrl = "calendar";

    static readonly loginUrl = "account/login";

    static readonly wizardUrl = "app/wizard";

    static getQueryParameters(): any {
        return document.location.search.replace(/(^\?)/, '').split("&").map(function (n) { return n = n.split("="), this[n[0]] = n[1], this }.bind({}))[0];
    }

    static navigateToMedicalCareCall(router: Router, appointmentId: string): any {
        let queryParams = {
            returnUrl: location.pathname
        };
        router.navigate(["/medical-care/call/" + appointmentId], {queryParams: queryParams}).then();
    }
}