import {
    AfterViewInit,
    Component,
    ElementRef,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit, QueryList,
    SimpleChanges,
    ViewChild,
    ViewChildren
} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {Contact} from "@ngx-chat/lib/core/contact";
import {Direction} from "@ngx-chat/lib/core/message";
import {ChatListStateService} from "@ngx-chat/lib/services/chat-list-state.service";
import {ChatService, ChatServiceToken} from "@ngx-chat/lib/services/chat-service";
import {ChatMessageListRegistryService} from "@ngx-chat/lib/services/chat-message-list-registry.service";
import {XmppChatService} from "@app/wapps/chat-panel/xmpp/xmpp-chat.service";
import {takeUntil} from "rxjs/internal/operators";
import {NgForm} from "@angular/forms";
import {FusePerfectScrollbarDirective} from "@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive";

@Component({
    selector: 'c360-chat-message-list',
    templateUrl: './chat-message-list.component.html',
    styleUrls: ['./chat-message-list.component.scss']
})
export class C360ChatMessageListComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {

    @Input()
    contact: Contact;

    @Input()
    showAvatars: boolean;

    @ViewChild('messageArea')
    chatMessageAreaElement: ElementRef<HTMLElement>;

    private messageSubscription: Subscription;
    Direction = Direction;

    @ViewChild('replyForm')
    set replyForm(content: NgForm) {
        this._replyForm = content;
    }

    @ViewChild('replyInput')
    set replyInput(content: ElementRef) {
        this._replyInput = content;
    }

    @ViewChildren(FusePerfectScrollbarDirective)
    private _fusePerfectScrollbarDirectives: QueryList<FusePerfectScrollbarDirective>;

    // Private
    private _chatViewScrollbar: FusePerfectScrollbarDirective;
    private _replyForm: NgForm;
    private _replyInput: ElementRef;

    private _unsubscribeAll: Subject<any>;

    constructor(public chatListService: ChatListStateService,
                @Inject(ChatServiceToken) public chatService: ChatService,
                private chatMessageListRegistry: ChatMessageListRegistryService,
                private xmppChatService: XmppChatService) {

        this._unsubscribeAll = new Subject();

    }

    ngOnInit() {

        this.xmppChatService.selectedContactChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(contact => {
                if(this.contact) {
                    this.chatMessageListRegistry.decrementOpenWindowCount(this.contact);
                }
                this.contact = contact;

                if(this.messageSubscription) {
                    this.messageSubscription.unsubscribe();
                }

                if (this.contact) {
                    this.chatMessageListRegistry.incrementOpenWindowCount(this.contact);
                    this.messageSubscription = this.contact.messages$.subscribe(() => {
                        this.scheduleScrollToLastMessage();
                    });
                    this.scheduleScrollToLastMessage();
                    //this.contact.dateMessagesGroups.sort((a,b) => (a.date.getTime() - b.date.getTime()));
                }

            });
    }

    ngAfterViewInit(): void {
        this._chatViewScrollbar = this._fusePerfectScrollbarDirectives.find((directive) => {
            return directive.elementRef.nativeElement.id === 'messages';
        });
    }

    ngOnChanges(changes: SimpleChanges): void {

    }

    ngOnDestroy(): void {
        if(!!this.messageSubscription) {
            this.messageSubscription.unsubscribe();
        }

        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    acceptSubscriptionRequest(event: Event) {
        event.preventDefault();
        this.chatService.addContact(this.contact.jidBare.toString());
    }

    denySubscriptionRequest(event: Event) {
        event.preventDefault();
        this.chatService.removeContact((this.contact.jidBare.toString()));
        this.chatListService.closeChat(this.contact);
    }

    private scheduleScrollToLastMessage() {
        setTimeout(() => this.scrollToLastMessage(), 0);
    }

    private scrollToLastMessage() {
        if (this.chatMessageAreaElement) {
            this.chatMessageAreaElement.nativeElement.scrollTop = this.chatMessageAreaElement.nativeElement.scrollHeight;
        }
    }

    reply(event): void {
        event.preventDefault();

        if (!this._replyForm.form.value.message) {
            return;
        }

        let message = this._replyForm.form.value.message;

        // Add the message to the chat
        if (message.trim().length > 0) {
            this.chatService.sendMessage(this.contact.jidBare.toString(), message);
        }

        if (!!this._replyForm)
            this._replyForm.reset();

        // this._prepareChatForReplies();

    }
}
