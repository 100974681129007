import {UserNotification, UserNotificationState} from "@shared/service-proxies/service-proxies";

export class Notification
{
    id: string;
    icon: string;
    title: string;
    subtitle: string;
    
    userNotification: UserNotification
    
    constructor(id, icon, title, subtitle, userNotification)
    {
        this.id = id;
        this.icon = icon;
        this.title = title;
        this.subtitle = subtitle;
        this.userNotification = userNotification;
    }
    
    get isRead() {
        return this.userNotification.state === UserNotificationState._1;
    }
}
