import { createReducer, on } from "@ngrx/store";
import { RemindersState } from "./model";
import * as actions from "./actions";
import { createFolderTree, findFolderById, flattenFolderDatasource } from "../../utils";

export const initialCashFlowReportState: RemindersState = {
    loading: false,
    loadingDetails: false,
    updating: false,
    deleting: false,
    creatingFolder: false,
    creating: false,
    error: null,
    folders: [],
    selected: undefined,
    selectedFolderItems: [],
};

export const getRemindersReducer = createReducer(
    initialCashFlowReportState,
    on(actions.loadReminderFoldersAction.execute, (state) => {
        return {
            ...state,
            loading: true,
        };
    }),
    on(actions.loadReminderFoldersAction.complete, (state, { folders }) => {
        return {
            ...state,
            loading: false,
            folders: folders,
        };
    }),
    on(actions.loadReminderFoldersAction.failure, (state, { error }) => {
        return {
            ...state,
            loading: false,
            error: error,
        };
    }),
    on(actions.select, (state, { folderId }) => {
        return {
            ...state,
            selected: findFolderById(folderId, state.folders),
        };
    }),
    on(actions.createReminderFolderAction.execute, (state) => {
        return {
            ...state,
            creatingFolder: true,
        };
    }),
    on(actions.createReminderFolderAction.complete, (state, { folder }) => {
        let flatFolderDatasource = [...flattenFolderDatasource(state.folders), folder];
        let folders = createFolderTree(flatFolderDatasource);
        return {
            ...state,
            creatingFolder: false,
            folders: folders,
        };
    }),
    on(actions.createReminderFolderAction.failure, (state, { error }) => {
        return {
            ...state,
            creatingFolder: false,
            error: error,
        };
    }),
    on(actions.updateReminderFolderAction.execute, (state) => {
        return {
            ...state,
            updating: true,
        };
    }),
    on(actions.updateReminderFolderAction.complete, (state, { folder }) => {
        let flatFolderDatasource = [
            ...flattenFolderDatasource(state.folders).filter((f) => f.id !== folder.id),
            folder,
        ];
        let folders = createFolderTree(flatFolderDatasource);
        return {
            ...state,
            updating: false,
            folders: folders,
        };
    }),
    on(actions.updateReminderFolderAction.failure, (state, { error }) => {
        return {
            ...state,
            updating: false,
            error: error,
        };
    }),
    on(actions.deleteReminderFolderAction.execute, (state) => {
        return {
            ...state,
            deleting: true,
        };
    }),
    on(actions.deleteReminderFolderAction.complete, (state, { id }) => {
        let flatFolderDatasource = flattenFolderDatasource(state.folders).filter((f) => f.id !== id);
        let folders = createFolderTree(flatFolderDatasource);
        return {
            ...state,
            deleting: false,
            selected: state.selected?.id === id ? undefined : state.selected,
            folders: folders,
        };
    }),
    on(actions.deleteReminderFolderAction.failure, (state, { error }) => {
        return {
            ...state,
            deleting: false,
            error: error,
        };
    }),
    on(actions.loadReminderDefinitionsAction.execute, (state) => {
        return {
            ...state,
            loadingDetails: true,
        };
    }),
    on(actions.loadReminderDefinitionsAction.complete, (state, { items }) => {
        return {
            ...state,
            loadingDetails: false,
            selectedFolderItems: items,
        };
    }),
    on(actions.loadReminderDefinitionsAction.failure, (state, { error }) => {
        return {
            ...state,
            loadingDetails: false,
            error: error,
            selectedFolderItems: [],
            selected: undefined,
        };
    }),
    on(actions.createReminderDefinitionAction.execute, (state) => {
        return {
            ...state,
            creating: true,
        };
    }),
    on(actions.createReminderDefinitionAction.complete, (state, { item }) => {
        return {
            ...state,
            creating: false,
            selectedFolderItems: [...state.selectedFolderItems, item],
        };
    }),
    on(actions.createReminderDefinitionAction.failure, (state, { error }) => {
        return {
            ...state,
            creating: false,
            error: error,
        };
    }),
    on(actions.updateReminderDefinitionAction.execute, (state, { item }) => {
        return {
            ...state,
            updating: true,
        };
    }),
    on(actions.updateReminderDefinitionAction.complete, (state, { item }) => {
        return {
            ...state,
            updating: false,
            selectedFolderItems: state.selectedFolderItems.map((f) => (f.id === item.id ? item : f)),
        };
    }),
    on(actions.updateReminderDefinitionAction.failure, (state, { error }) => {
        return {
            ...state,
            updating: false,
            error: error,
        };
    }),
    on(actions.moveReminderDefinitionAction.execute, (state) => {
        return {
            ...state,
            updating: true,
        };
    }),
    on(actions.moveReminderDefinitionAction.complete, (state, { id, folderId }) => {
        return {
            ...state,
            updating: false,
            selectedFolderItems: state.selectedFolderItems.filter((f) => f.id !== id),
        };
    }),
    on(actions.moveReminderDefinitionAction.failure, (state, { error }) => {
        return {
            ...state,
            updating: false,
            error: error,
        };
    }),
    on(actions.deleteReminderDefinitionAction.execute, (state) => {
        return {
            ...state,
            deleting: true,
        };
    }),
    on(actions.deleteReminderDefinitionAction.complete, (state, { id }) => {
        return {
            ...state,
            deleting: false,
            selectedFolderItems: state.selectedFolderItems.filter((f) => f.id !== id),
        };
    }),
    on(actions.deleteReminderDefinitionAction.failure, (state, { error }) => {
        return {
            ...state,
            deleting: false,
            error: error,
        };
    })
);
