import { AppComponentBase } from "shared/app-component-base";
import { Injector } from '@angular/core';

export class PagedResultDto {
    items: any[];
    totalCount: number;
}

export class EntityDto {
    id: number;
}

export class PagedRequestDto {
    skipCount: number;
    maxResultCount: number;
}

export class PagedAndSortedRequestDto {
    skipCount: number;
    maxResultCount: number;
    sorting: string;
}

export abstract class PagedListingComponentBase<EntityDto> extends AppComponentBase {

    public pageSize: number = 10;
    public pageNumber: number = 0;
    public totalPages: number = 1;
    public totalItems: number;
    public isTableLoading = false;
    
    public hidePageSize: boolean = true;
    public showFirstLastButtons: boolean = true;

    datatableMessages = {
        'emptyMessage': '',
        'totalMessage': ''
    };

    protected constructor(protected injector: Injector) {
        super(injector);
        this.datatableMessages.totalMessage = this.l("DataTable.Total");
        this.datatableMessages.emptyMessage = this.l("DataTable.Empty");
    }

    refresh(page: number = 0): void {
        this.pageNumber = page;
        this.getDataPage(this.pageNumber);
    }
    
    protected getFilter() : PagedAndSortedRequestDto {
        return new PagedAndSortedRequestDto();
    }

    public showPaging(result: PagedResultDto, pageNumber: number): void {
        this.totalPages = ((result.totalCount - (result.totalCount % this.pageSize)) / this.pageSize) + 1;
        this.totalItems = result.totalCount;
        this.pageNumber = pageNumber - 1;
    }

    public getDataPage(page: number): void {
        let req = this.getFilter();
        req.maxResultCount = this.pageSize;
        req.skipCount = page * this.pageSize;
        this.isTableLoading = true;
        this.list(req, page + 1, () => {
            this.isTableLoading = false;
        });
    }

    protected abstract list(request: PagedRequestDto, pageNumber: number, finishedCallback: Function): void;
    
    protected abstract delete(entity: EntityDto): void;
}
