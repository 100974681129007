import { NotificationParser } from "@app/wapps/notification/notification.parser";
import { Notification } from "@app/wapps/notification/notification.model";
import { Injector } from "@angular/core";
import * as moment from "moment";

export class AppointmentNotificationParser extends NotificationParser {
    static Type = "Wapps.Health.Appointment.Notifications.AppointmentNotificationData";

    static SilentType = "Wapps.Health.Appointment.Notifications.AppointmentSilentNotificationData";

    constructor(injector: Injector) {
        super(injector);
    }

    parse(userNotification): Notification {
        let id = userNotification.id;
        let icon = "today";
        let data: any = userNotification.notification.data;
        if (!data.date) {
            data = data.properties;
        }
        let title = this.l(`Health.Appointment.Status.${data.status}.Notification.Title`);
        let date: moment.Moment = moment(userNotification.notification.creationTime);
        let subtitle = date.locale("pt-BR").local().format("ddd, DD [de] MMM [de] YYYY [às] HH:mm");
        return new Notification(id, icon, title, subtitle, userNotification);
    }

    open(notification: Notification) {
        let data: any = notification.userNotification.notification.data;
        if (!data.date) {
            data = data.properties;
        }
        let id = data.id;
        let date: moment.Moment = moment(data.date.toString());
        let queryParams = {
            id: id,
            date: date.toDate().toUTCString(),
        };
        this.router
            .navigate(["/calendar"], {
                queryParams,
            })
            .then();
    }
}
