import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Component, OnInit, ViewEncapsulation, OnDestroy, Input, Inject } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { ReminderFolder, actions } from "../../store";
import { ReminderPoolFacade } from "../../store/facade";
import { Actions, ofType } from "@ngrx/effects";
import { TranslocoService } from "@ngneat/transloco";
import { NotifyService } from "abp-ng2-module";
import { takeUntil } from "rxjs/operators";

@Component({
    selector: "move-folder-dialog",
    templateUrl: "./move-folder-dialog.component.html",
    encapsulation: ViewEncapsulation.None,
})
export class MoveFolderDialogComponent implements OnInit, OnDestroy {
    private _unsubscribeAll = new Subject();

    @Input()
    classes: string[] = ["w-30vw", "sm:w-30vw"];

    folders$: Observable<ReminderFolder[]>;

    selected: ReminderFolder;

    constructor(
        private _dialogRef: MatDialogRef<MoveFolderDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _facade: ReminderPoolFacade,
        private _actions$: Actions,
        private _notifyService: NotifyService,
        private _translateService: TranslocoService
    ) {
        this._dialogRef.addPanelClass(["xs-full-screen", "dialog-v2"]);

        this.folders$ = this._facade.folders$;
    }

    ngOnInit() {
        this._actions$
            .pipe(ofType(actions.moveReminderDefinitionAction.complete), takeUntil(this._unsubscribeAll))
            .subscribe((action) => {
                this._notifyService.success(
                    this._translateService.translate("health.engage.reminder-pool.move-definition.successfully-message")
                );
                this._dialogRef.close();
            });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    goBack() {
        this._facade.goBack();
    }

    save() {
        this._facade.moveReminderDefinition(this._data.itemId, this.selected.id);
    }

    closeDialog() {
        this._dialogRef.close();
    }

    handleSelection(folder: ReminderFolder) {
        this.selected = folder;
    }
}
