import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { ProtocolFacade } from "./store/facade";
import { Observable, ReplaySubject, Subject } from "rxjs";
import { TranslocoService } from "@ngneat/transloco";
import { ProtocolFolderFlatNode, ProtocolFolderNode } from "@vhealth/engage";
import { ProtocolFolder, actions } from "./store";
import { MatDialog } from "@angular/material/dialog";
import { FuseConfirmationService } from "@nx-workspace/fuse";
import { FormControl, FormGroup } from "@angular/forms";
import { FlatTreeControl } from "@angular/cdk/tree";
import { MatTreeFlatDataSource, MatTreeFlattener } from "@angular/material/tree";
import { CreateFolderComponent } from "./create-folder/create-folder.component";
import { EditFolderComponent } from "./edit-folder/edit-folder.component";
import { take, takeUntil } from "rxjs/operators";
import { NotifyService } from "@ui/notify";
import { Actions, ofType } from "@ngrx/effects";
@Component({
    selector: "protocol-pool",
    templateUrl: "./protocol-pool.component.html",
    styleUrls: ["./protocol-pool.component.scss"],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProtocolPoolComponent implements OnInit, OnDestroy {
    private _unsubscribeAll = new Subject();

    isLoading$: Observable<boolean>;

    isUpdating$: Observable<boolean>;

    folders$: Observable<ProtocolFolder[]>;

    selected$: Observable<ProtocolFolder>;

    isThereAnyFolder$: Observable<boolean>;

    filteredProtocolFolders$: ReplaySubject<ProtocolFolder[]> = new ReplaySubject<ProtocolFolder[]>(1);

    protocolFolders: ProtocolFolderNode[] = [];

    flatNodeMap: Map<ProtocolFolderFlatNode, ProtocolFolderNode> = new Map();

    nestedNodeMap: Map<string, ProtocolFolderFlatNode> = new Map();

    searchForm: FormGroup = new FormGroup({
        text: new FormControl(""),
    });

    private _transformer = (node: ProtocolFolderNode, level: number) => {
        const flatNode = new ProtocolFolderFlatNode();
        flatNode.level = level;
        flatNode.expandable = node.children && node.children.length > 0;
        flatNode.title = node.title;
        (flatNode.parentId = node.parentId), (flatNode.data = node.data);
        this.flatNodeMap.set(flatNode, node);
        this.nestedNodeMap.set(node.title, flatNode);
        return flatNode;
    };

    treeControl = new FlatTreeControl<ProtocolFolderFlatNode>(
        (node) => node.level,
        (node) => node.expandable
    );

    treeFlattener = new MatTreeFlattener(
        this._transformer,
        (node) => node.level,
        (node) => node.expandable,
        (node) => node.children
    );

    dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    hasChild = (_: number, node: ProtocolFolderFlatNode) => node.expandable;

    constructor(
        private _changeDetector: ChangeDetectorRef,
        private _matDialog: MatDialog,
        private _translateService: TranslocoService,
        private _facade: ProtocolFacade,
        private _confirmationService: FuseConfirmationService,
        private _notifyService: NotifyService,
        private _actions: Actions
    ) {
        this.folders$ = this._facade.folders$;
        this.selected$ = this._facade.current$;
        this.isThereAnyFolder$ = this._facade._isThereAnyFolder$;
        this.isLoading$ = this._facade.loading$;
        this.isUpdating$ = this._facade.updating$;
        this._facade.root();
    }

    ngOnInit(): void {
        this.filteredProtocolFolders$.pipe(takeUntil(this._unsubscribeAll)).subscribe((folders) => {
            this.protocolFolders = folders.map((folder) => {
                return new ProtocolFolderNode(folder, folder.parentFolderId ?? 0);
            });
            this.dataSource.data = this.protocolFolders;
            this.treeControl.expandAll();
            this._changeDetector.markForCheck();
        });

        this.folders$.pipe(takeUntil(this._unsubscribeAll)).subscribe((folders) => {
            this.filteredProtocolFolders$.next(folders);
        });

        this.searchForm
            .get("text")
            .valueChanges.pipe(takeUntil(this._unsubscribeAll))
            .subscribe((text) => {
                this.applyFilter(text.toLowerCase());
            });

        this._actions
            .pipe(ofType(actions.deleteProtocolFolderAction.complete), takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this._notifyService.success(
                    this._translateService.translate("health.engage.protocol-pool.delete.successfully-message")
                );
            });

        this._actions
            .pipe(ofType(actions.deleteProtocolFolderAction.failure), takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this._notifyService.error(
                    this._translateService.translate("health.engage.protocol-pool.delete.error-message")
                );
            });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    reload(nodes: ProtocolFolderNode[]) {
        nodes.forEach((node) => {
            if (node.children) {
                this.reload(node.children);
            }
        });
    }

    map(items: ProtocolFolder[], parentId: number | null = null): ProtocolFolderNode[] {
        return items.map((item) => {
            const node = new ProtocolFolderNode(item, parentId);
            node.children = this.map(item.folders, item.parentFolderId);
            return node;
        });
    }

    createFolder(parentId: number = null) {
        //Without the timeout the text inside the Dialog will not gain the focus
        setTimeout(() => {
            this._matDialog.open(CreateFolderComponent, {
                panelClass: "dialog-v2",
                data: {
                    parentId,
                },
            });
        });
    }

    editFolder(event: any, folder: ProtocolFolderFlatNode) {
        event.stopPropagation();
        this._matDialog.open(EditFolderComponent, {
            panelClass: "dialog-v2",
            data: folder.data,
        });
    }

    deleteFolder(event: any, folder: ProtocolFolderFlatNode) {
        event.stopPropagation();
        this._confirmationService
            .open({
                title: this._translateService.translate("health.engage.protocol-pool.delete.alert.title"),
                message: this._translateService.translate("health.engage.protocol-pool.delete.alert.message", {
                    name: folder.data.name,
                }),
                actions: {
                    confirm: {
                        label: this._translateService.translate("ACTION_DELETE"),
                    },
                    cancel: {
                        label: this._translateService.translate("ACTION_CANCEL"),
                    },
                },
            })
            .afterClosed()
            .subscribe((result) => {
                if (result === "confirmed") {
                    this._facade.deleteFolder(folder.data.id);
                }
            });
    }

    trackByFn(index: number, item: ProtocolFolder) {
        return item.id;
    }

    applyFilter(text: string) {
        this.folders$.pipe(take(1)).subscribe((folders) => {
            let filteredFolders = this.filterFoldersByName(folders, text);
            this.filteredProtocolFolders$.next(filteredFolders);
        });
    }

    filterFoldersByName(folders: ProtocolFolder[], searchText: string) {
        let filteredFolders = [];
        folders.forEach((folder) => {
            if (folder.name.toLowerCase().includes(searchText)) {
                filteredFolders.push(folder);
            } else if (folder.folders) {
                let filteredSubFolders = this.filterFoldersByName(folder.folders, searchText);
                if (filteredSubFolders.length > 0) {
                    let filteredFolder = Object.assign({}, folder);
                    filteredFolder.folders = filteredSubFolders;
                    filteredFolders.push(filteredFolder);
                }
            }
        });
        return filteredFolders;
    }

    selectNode(node: ProtocolFolderFlatNode) {
        this._facade.select(node.data.id);
    }
}
