import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Inject, Input,
    OnInit,
    Output,
    ViewChild
} from "@angular/core";
import { FormsMessagingService } from "../forms-messaging.service";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { FormDefinition, FORMS_BUILDER_URL } from "../model";

@Component({
    selector: "vform-builder-wrapper",
    templateUrl: "./forms-builder.component.html",
    styleUrls: ["./forms-builder.component.scss"],
    providers: [FormsMessagingService],
})
export class FormsBuilderComponent implements OnInit, AfterViewInit {
    @ViewChild("formBuilderContainer") formBuilderContainer: ElementRef;

    vFormsUrl: SafeUrl;

    @Input()
    definition: string;

    @Output()
    onSave: EventEmitter<FormDefinition> = new EventEmitter<FormDefinition>();

    @Output()
    onCancel: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        @Inject(FORMS_BUILDER_URL) vFormsUrl: string,
        private _messagingService: FormsMessagingService,
        private _sanitizer: DomSanitizer
    ) {
        this.vFormsUrl = this._sanitizer.bypassSecurityTrustResourceUrl(`${vFormsUrl}/vforms/builder`);
    }

    ngOnInit(): void {
        this._messagingService.onMessage.subscribe((message) => {
            if (message.action === "ready") {
                this._messagingService.postMessage({
                    action: "load",
                    args: this.definition,
                });
            } else if (message.action === "save") {
                let definition: FormDefinition = JSON.parse(message.args);
                this.onSave.emit(definition);
            } else if (message.action === "cancel") {
                this.onCancel.emit(true);
            }
        });
    }

    ngAfterViewInit() {
        this._messagingService.activate(this.formBuilderContainer.nativeElement);
    }
}
