<div class="chat-message"
     [ngClass]="{'chat-message--in': message.direction === 'in', 'chat-message--out': message.direction === 'out'}">
    <div *ngIf="showAvatars" class="chat-message-avatar">
        <img *ngIf="message.direction === 'in'" [src]="avatar"/>
        <img *ngIf="message.direction === 'out'" [src]="chatService.userAvatar$ | async"/>
    </div>
    <div class="chat-message-text" [ngClass]="{'chat-message-text--no-avatar':!showAvatars}">
        <span [ngxChatLinks]="message.body"></span>
    </div>
    <div class="chat-message-image-wrapper" *ngIf="imageLink">
        <img class="chat-message-image" [src]="imageLink"/>
    </div>
    <div class="chat-message-footer">
        <small title="{{nick}}" class="chat-message-name">
            {{nick}}
            <ng-container *ngIf="message.direction === 'out'" [ngSwitch]="getMessageState()">
                <ng-container *ngSwitchCase="MessageState.SENT">✓</ng-container>
                <ng-container *ngSwitchCase="MessageState.RECIPIENT_RECEIVED">✓✓</ng-container>
                <ng-container *ngSwitchCase="MessageState.RECIPIENT_SEEN"><span class="state--seen">✓✓</span></ng-container>
            </ng-container>
        </small>
        <small class="chat-message-datetime">{{message.datetime | date:chatService.translations.timeFormat}}</small>
    </div>
</div>
