import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { AbpHttpInterceptor } from "@api";
import { ProtocolFolderClient } from "../api";
import { ProtocolPoolSharedModule } from "./protocol-pool.shared.module";
import { ProtocolPoolComponent } from "./protocol-pool.component";
import { FolderDetailsComponent } from "./folder-details/folder-details.component";
import { MatButtonModule } from "@angular/material/button";
import { MatDividerModule } from "@angular/material/divider";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatOptionModule } from "@angular/material/core";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatTreeModule } from "@angular/material/tree";
import { MatDialogModule } from "@angular/material/dialog";
import { MatInputModule } from "@angular/material/input";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { UiDialogModule } from "@ui/ui-dialog";
import { A11yModule } from "@angular/cdk/a11y";
import { ButtonToggleModule, FuseScrollbarModule } from "@nx-workspace/fuse";
import { MatMenuModule } from "@angular/material/menu";
import { DateFormFieldModule, RecurrencePickerModule, VuiModule } from "@vui";
import { TranslocoModule } from "@ngneat/transloco";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatTooltipModule } from "@angular/material/tooltip";

@NgModule({
    declarations: [ProtocolPoolComponent, FolderDetailsComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ProtocolPoolSharedModule,
        TranslocoModule,
        MatButtonModule,
        MatDividerModule,
        MatFormFieldModule,
        MatIconModule,
        MatOptionModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatSelectModule,
        MatSidenavModule,
        MatSortModule,
        MatTableModule,
        MatTreeModule,
        MatDialogModule,
        MatInputModule,
        MatCheckboxModule,
        UiDialogModule,
        A11yModule,
        ButtonToggleModule,
        MatMenuModule,
        FuseScrollbarModule,
        VuiModule,
        MatTooltipModule,
        DateFormFieldModule,
        RecurrencePickerModule,
    ],
    exports: [ProtocolPoolComponent],
    providers: [ProtocolFolderClient, { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true }],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ProtocolPoolModule {}
