/* eslint-disable */
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { Subject } from "rxjs";
import { StatsFacade } from "./store/facade";
import { AppointmentStatsFacade } from "./appointments/store/facade";
import { DocumentStatsFacade } from "./documents/store/facade";

@Component({
    selector: "statistics-root-component",
    templateUrl: "./root.component.html",
    styleUrls: ["./root.component.scss"],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RootComponent implements OnInit, OnDestroy {

    isScreenSmall: boolean;

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    //For some reason, if this facades are removed, the app broke
    constructor(private _facade: StatsFacade,
                private _appointmentStatsFacade: AppointmentStatsFacade,
                private _documentStatsFacade: DocumentStatsFacade,) {

    }

    ngOnInit(): void {

    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
