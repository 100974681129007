import { EventEmitter, Injectable, OnDestroy } from "@angular/core";

@Injectable()
export class FormsMessagingService implements OnDestroy {

    _iframe: HTMLIFrameElement;

    onMessage: EventEmitter<any> = new EventEmitter<any>();

    constructor() {
        let service = this;
        window.onmessage = function (e) {
            let message = JSON.parse(e.data);
            service.onMessage.emit(message);
        };
    }

    ngOnDestroy() {

    }

    activate(iframe: HTMLIFrameElement) {
        this._iframe = iframe;
    }

    postMessage(message: any) {
        this._iframe.contentWindow.postMessage(JSON.stringify(message), "*");
    }
}

