import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from "@angular/core";
import { ApexChart, ApexLegend, ApexNonAxisChartSeries, ApexPlotOptions } from "ng-apexcharts";
import { DocumentStatsFacade } from "./store/facade";
import { filter, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

export type PieChartOptions = {
    series: ApexNonAxisChartSeries;
    chart: ApexChart;
    labels: any;
    legend: ApexLegend;
    plotOptions: ApexPlotOptions;
};

@Component({
    selector: "documents-summary",
    templateUrl: "./summary.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentsSummaryComponent implements AfterViewInit, OnDestroy {
    private _unsubscribeAll = new Subject();

    public prescriptionChartOptions: Partial<PieChartOptions>;

    constructor(private _changeDetector: ChangeDetectorRef,
                private _facade: DocumentStatsFacade,) {
    }

    ngAfterViewInit() {
        this._facade.prescription$
            .pipe(takeUntil(this._unsubscribeAll), filter(stats => !!stats))
            .subscribe(stats => {
                setTimeout(() => {
                    this.prescriptionChartOptions = {
                        series: [stats.smart, stats.standard],
                        chart: {
                            type: "donut",
                        },
                        labels: ["Inteligentes", "Padrão"],
                        legend: {
                            position: "bottom",
                            horizontalAlign: "center"
                        },
                        plotOptions: {
                            pie: {
                                donut: {
                                    size: "60%",
                                    labels: {
                                        show: true,
                                        total: {
                                            showAlways: false,
                                            show: true,
                                            color: "#000000"
                                        }
                                    }
                                }
                            }
                        },
                    };
                    this._changeDetector.markForCheck();
                });
            });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
