<div class="w-full" [formGroup]="form">
    <mat-form-field class="w-full fuse-mat-no-subscript">
        <mat-label> Item </mat-label>
        <input
            matInput
            [formControlName]="controlName"
            [matAutocomplete]="activityAutocomplete"
            [placeholder]="'Pesquisar ou definir título do item'"
            required
            cdkFocusInitial
        />
        <button mat-button color="accent" (click)="startWithTemplate()">Usar Modelo</button>
        <mat-autocomplete
            #activityAutocomplete="matAutocomplete"
            [displayWith]="displayActivity"
            (optionSelected)="selectActivity($event)"
        >
            <mat-option *ngFor="let activity of activities$ | async" [value]="activity">
                <div class="w-full flex items-center justify-between">
                    <span
                        >{{ activity.title }} - {{ activity.comments }} -
                        {{ parseRecurrenceText(activity.recurrence) }}</span
                    >
                    <button mat-icon-button (click)="removeActivityFromHistory($event, activity)" color="warn">
                        <mat-icon class="m-0" svgIcon="mat_outline:delete"></mat-icon>
                    </button>
                </div>
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</div>
