import { Injectable } from '@angular/core';
import { AppSessionService } from '../session/app-session.service';
import {CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild} from '@angular/router';
import {UrlHelper} from "../helpers/UrlHelper";

@Injectable()
export class AnonymousRouteGuard implements CanActivate, CanActivateChild {

    constructor(
        private _router: Router,
        private _sessionService: AppSessionService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            let allow = !this._sessionService.user;
            if (!allow) {
                this._router.navigate([UrlHelper.entryUrl]).then();
            }
            resolve(allow);
        });
    }
    
    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return this.canActivate(route, state);
    }
}