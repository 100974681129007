import {
    Directive,
    ChangeDetectorRef,
    Input,
    TemplateRef,
    ViewContainerRef,
    OnDestroy,
} from '@angular/core';
import {combineLatest, Subject} from 'rxjs';
import { startWith } from 'rxjs/operators';
import {MediaObserver} from "@angular/flex-layout";

@Directive({
    selector: '[xcMediaIf]',
})
export class MediaIfDirective implements OnDestroy {
    private hasView = false;
    private matcher = new Subject<string>();
    private subscription = combineLatest(
        this.matcher,
        this.media.asObservable().pipe(startWith(null)),
    ).subscribe(([matcher, _]) => {
        const condition = this.media.isActive(matcher);

        if (condition && !this.hasView) {
            this.viewContainer.createEmbeddedView(this.template);
            this.changeRef.markForCheck();
            this.hasView = true;
        } else if (!condition && this.hasView) {
            this.viewContainer.clear();
            this.hasView = false;
        }
    });

    @Input()
    set xcMediaIf(value: string) {
        this.matcher.next(value);
    }

    constructor(
        private template: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private media: MediaObserver,
        private changeRef: ChangeDetectorRef,
    ) {}

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}

//
//
// import { Directive, Input, TemplateRef, ViewContainerRef, OnDestroy, ChangeDetectorRef } from '@angular/core';
// import { BreakPointRegistry } from '@angular/flex-layout';
// import { Subject, combineLatest } from 'rxjs';
// import { startWith, switchMap, tap, map } from 'rxjs/operators';
// import { MediaChange, MediaObserver } from '@angular/flex-layout';
//
// @Directive({
//     selector: '[xcMediaIf]',
// })
// export class MediaIfDirective implements OnDestroy {
//     private hasView = false;
//     private matcher = new Subject<string>();
//     private subscription = this.matcher
//         .pipe(
//             map(alias => this.breakpoints.findByAlias(alias).mediaQuery),
//             switchMap(mq => {
//                 return this.matchMedia.observe(mq).pipe(
//                     map(result => result.matches),
//                     startWith(this.matchMedia.isActive(mq))
//                 );
//             }),
//         )
//         .subscribe(matches => matches ? this.createView() : this.destroyView());
//
//     @Input()
//     set xcMediaIf(value: string) {
//         if(value === 'true') {
//             this.createView();
//         } else if(value === 'false') {
//             this.destroyView();
//         } else {
//             this.matcher.next(value);
//         }
//     }
//
//     constructor(
//         private template: TemplateRef<any>,
//         private viewContainer: ViewContainerRef,
//         private breakpoints: BreakPointRegistry,
//         private matchMedia: MediaObserver,
//         private changeRef: ChangeDetectorRef,
//     ) {}
//
//     ngOnDestroy() {
//         this.subscription.unsubscribe();
//     }
//
//     private createView() {
//         if (!this.hasView) {
//             this.viewContainer.createEmbeddedView(this.template);
//             this.changeRef.markForCheck();
//             this.hasView = true;
//         }
//     }
//
//     private destroyView() {
//         if (this.hasView) {
//             this.viewContainer.clear();
//             this.hasView = false;
//         }
//     }
// }