import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Component, OnInit, ViewEncapsulation, OnDestroy, ViewChild, ElementRef, Inject } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Actions, ofType } from "@ngrx/effects";
import { NotifyService } from "@ui/notify";
import { FormBuilder, FormGroup } from "@angular/forms";
import { updateSmartFormAction } from "../store/actions";
import { FeedStatus, SmartFormDefinition } from "@vhealth/engage";
import { assign } from "lodash";
import { VuiValidators } from "@vui";
import { TranslocoService } from "@ngneat/transloco";
import { SmartFormPoolFacade } from "../store/facade";
import { Cid10, FormDefinition, FormOptionsComponent } from "@vhealth/vforms-wrapper";

export interface EditTemplateData {
    id: number;
    name: string;
    comments: string;
    definition: string;
    notifyOnAnswered?: boolean;
    displayOnFeed?: boolean;
    horizontalEvolution?: boolean;
}

@Component({
    selector: "edit-template",
    templateUrl: "./edit-template.component.html",
    styleUrls: ["./edit-template.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class EditTemplateComponent implements OnInit, OnDestroy {
    private _unsubscribeAll = new Subject();

    loading$: Observable<boolean>;

    currentForm: FormGroup;

    feedStatuses = [FeedStatus.Visible, FeedStatus.PatientHidden, FeedStatus.Hidden];

    @ViewChild("activityTitle", { read: ElementRef }) activityTitleInput: ElementRef;

    definition: string;

    constructor(
        private _matDialog: MatDialog,
        private _dialogRef: MatDialogRef<EditTemplateComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: EditTemplateData,
        private _formBuilder: FormBuilder,
        private _actions$: Actions,
        private _notifyService: NotifyService,
        private _translateService: TranslocoService,
        private _facade: SmartFormPoolFacade
    ) {
        this.loading$ = this._facade.updating$;

        this.currentForm = this._formBuilder.group({
            id: [null, VuiValidators.required],
            name: [null, VuiValidators.required],
            comments: [null, VuiValidators.required],
            definition: [null, VuiValidators.required],
            notifyOnAnswered: [false],
            displayOnFeed: [false],
            horizontalEvolution: [false],
        });

        let prevDefinition = JSON.parse(this._data.definition);
        prevDefinition.excludedFields = [Cid10];
        prevDefinition.requiredDescription = true;

        this.definition = JSON.stringify(prevDefinition);
    }

    ngOnInit() {
        this._actions$.pipe(ofType(updateSmartFormAction.complete), takeUntil(this._unsubscribeAll)).subscribe(() => {
            this._notifyService.success(
                this._translateService.translate("health.engage.form-pool.edit-template.successfully-message")
            );
            this._dialogRef.close();
        });

        this.currentForm.patchValue({
            ...this._data,
        });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    showOptions() {
        this._matDialog
            .open(FormOptionsComponent, {
                data: {
                    form: JSON.parse(this.definition),
                    notifyOnAnswered: this.currentForm.get("notifyOnAnswered").value,
                    displayOnFeed: this.currentForm.get("displayOnFeed").value,
                    horizontalEvolution: this.currentForm.get("horizontalEvolution").value,
                },
            })
            .afterClosed()
            .subscribe((result) => {
                if (result) {
                    this.definition = JSON.stringify(result);
                    this.currentForm.patchValue({
                        notifyOnAnswered: result.notifyOnAnswered,
                        displayOnFeed: result.displayOnFeed,
                        horizontalEvolution: result.horizontalEvolution,
                    });
                }
            });
    }

    onFormSave(definition: FormDefinition) {
        this.currentForm.patchValue({
            definition: JSON.stringify(definition),
            name: definition.title,
            comments: definition.description,
        });

        this._facade.updateDefinitonTemplate(assign(new SmartFormDefinition(), this.currentForm.getRawValue()));
    }

    onFormCancel() {
        this._dialogRef.close();
    }
}
