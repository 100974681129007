<ng-container *transloco="let t; read: 'health.engage.protocol-pool.edit-protocol'">
    <ng-container *transloco="let root">
        <ui-dialog-base [allowCancelTouchingOutside]="false" [title]="t('title')" [classes]="['sm:w-80vw lg:w-60vw']">
            <div *ngIf="loading$ | async" class="absolute inset-x-0 top-0">
                <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
            </div>

            <div
                class="flex-auto h-full p-6 overflow-y-auto overscroll-y-contain sm:overflow-hidden sm:overscroll-auto"
                fuseScrollbar
            >
                <form [formGroup]="form" class="w-full flex-auto">
                    <div class="flex flex-col space-y-4 w-full">
                        <mat-form-field class="fuse-mat-no-subscript">
                            <mat-label>{{ t("field-name-title") }}</mat-label>
                            <input
                                cdkFocusInitial
                                maxlength="256"
                                matInput
                                [placeholder]="t('field-name-placeholder')"
                                formControlName="name"
                            />
                        </mat-form-field>

                        <div>
                            <mat-checkbox
                                formControlName="isPublic"
                                [matTooltip]="t('field-public-tooltip')"
                                [matTooltipPosition]="'right'"
                            >
                                {{ t("field-public-title") }}
                            </mat-checkbox>
                        </div>

                        <ng-container
                            *ngIf="{
                                isEditing: (editing$ | async),
                                editingItem: editingItem$ | async,
                                editingForm: editingForm$ | async,
                                formItems: formItems$ | async,
                                activityItems: activityItems$ | async
                            } as result"
                        >
                            <div class="flex flex-col space-y-4" *ngIf="result.formItems.length > 0">
                                <div *ngFor="let activity of result.formItems">
                                    <div
                                        class="flex flex-col space-y-4 border rounded shadow-md w-full p-4 bg-gray-50"
                                        *ngIf="result.isEditing && activity.storeId === result.editingForm?.storeId"
                                        [formGroup]="currentActivityForm"
                                    >
                                        <mat-form-field class="fuse-mat-no-subscript">
                                            <mat-label>{{ t("field-name-title") }}</mat-label>
                                            <input
                                                #activityTitle
                                                matInput
                                                formControlName="title"
                                                [placeholder]="t('field-name-placeholder')"
                                            />
                                        </mat-form-field>
                                        <mat-form-field class="fuse-mat-no-subscript">
                                            <mat-label>{{ t("field-comments-title") }}</mat-label>
                                            <textarea
                                                matInput
                                                formControlName="comments"
                                                [placeholder]="t('field-comments-placeholder')"
                                            ></textarea>
                                        </mat-form-field>
                                        <mat-form-field class="fuse-mat-no-subscript">
                                            <mat-label>{{ t("field-visibility-title") }}</mat-label>
                                            <mat-select formControlName="feedStatus">
                                                <mat-option *ngFor="let status of feedStatuses" [value]="status">
                                                    {{ t("protocol-visibility-" + status) }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <mat-checkbox formControlName="documentStatus">
                                            {{ t("field-document-status-title") }}
                                        </mat-checkbox>
                                        <div class="flex flex-row items-center space-x-4">
                                            <div
                                                class="flex flex-row items-center"
                                                *ngIf="result.editingForm.recurrence"
                                            >
                                                <div
                                                    class="w-full rounded py-2.5 px-1 text-lg leading-none text-accent-500 text-justify transition hover:duration-150 duration-150 ease-in-out cursor-pointer hover:bg-accent-50"
                                                    (click)="updateRecurrence(result.editingForm)"
                                                    color="accent"
                                                >
                                                    {{ parseRecurrenceText(result.editingForm) }}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="flex flex-row space-x-4 items-center justify-start w-full">
                                            <button
                                                mat-flat-button
                                                color="accent"
                                                (click)="acceptFormChanges(result.editingForm)"
                                                [disabled]="!currentActivityForm.valid"
                                            >
                                                {{
                                                    t(
                                                        result.editingForm.storeId > 0
                                                            ? "activity-edition.action-update"
                                                            : "activity-edition.action-add"
                                                    )
                                                }}
                                            </button>
                                            <button mat-button (click)="cancelFormEdition(result.editingForm)">
                                                {{ t("activity-edition.action-cancel") }}
                                            </button>
                                        </div>
                                    </div>

                                    <ng-container
                                        *ngIf="!result.isEditing || result.editingForm?.storeId !== activity.storeId"
                                    >
                                        <div
                                            class="flex flex-row items-center justify-between border rounded shadow-sm w-full py-2 px-4 cursor-pointer group hover:bg-primary-50"
                                            (click)="startFormEdition(activity)"
                                        >
                                            <div class="flex flex-row items-center space-x-2">
                                                <span class="font-semibold">{{ activity.title }}</span>
                                                <span>- {{ parseRecurrenceText(activity) }}</span>
                                            </div>

                                            <div class="invisible group-hover:visible flex flex-row space-x-2">
                                                <button
                                                    mat-icon-button
                                                    color="warn"
                                                    (click)="deleteForm($event, activity)"
                                                >
                                                    <mat-icon svgIcon="heroicons_outline:trash"></mat-icon>
                                                </button>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="flex flex-col space-y-4" *ngIf="result.activityItems.length > 0">
                                <div *ngFor="let activity of result.activityItems">
                                    <div
                                        class="flex flex-col space-y-4 border rounded shadow-md w-full p-4 bg-gray-50"
                                        *ngIf="result.isEditing && activity.storeId === result.editingItem?.storeId"
                                        [formGroup]="currentActivityForm"
                                    >
                                        <mat-form-field class="fuse-mat-no-subscript">
                                            <mat-label>{{ t("field-title") }}</mat-label>
                                            <input
                                                #activityTitle
                                                matInput
                                                formControlName="title"
                                                [placeholder]="t('field-title-placeholder')"
                                            />
                                        </mat-form-field>
                                        <mat-form-field class="fuse-mat-no-subscript">
                                            <mat-label>{{ t("field-comments-title") }}</mat-label>
                                            <textarea
                                                matInput
                                                formControlName="comments"
                                                [placeholder]="t('field-comments-placeholder')"
                                            ></textarea>
                                        </mat-form-field>
                                        <mat-form-field class="fuse-mat-no-subscript">
                                            <mat-label>{{ t("field-visibility-title") }}</mat-label>
                                            <mat-select formControlName="feedStatus">
                                                <mat-option *ngFor="let status of feedStatuses" [value]="status">
                                                    {{ t("protocol-visibility-" + status) }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <mat-checkbox formControlName="documentStatus">
                                            {{ t("field-document-status-title") }}
                                        </mat-checkbox>

                                        <div class="flex flex-row items-center space-x-4">
                                            <div
                                                class="flex flex-row items-center"
                                                *ngIf="result.editingItem.recurrence"
                                            >
                                                <div
                                                    class="w-full rounded py-2.5 px-1 text-lg leading-none text-accent-500 text-justify transition hover:duration-150 duration-150 ease-in-out cursor-pointer hover:bg-accent-50"
                                                    (click)="updateRecurrence(result.editingItem)"
                                                    color="accent"
                                                >
                                                    {{ parseRecurrenceText(result.editingItem) }}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="flex flex-row space-x-4 items-center justify-start w-full">
                                            <button
                                                mat-flat-button
                                                color="accent"
                                                (click)="acceptActivityChanges(result.editingItem)"
                                                [disabled]="!currentActivityForm.valid"
                                            >
                                                {{
                                                    t(
                                                        result.editingItem.storeId > 0
                                                            ? "activity-edition.action-update"
                                                            : "activity-edition.action-add"
                                                    )
                                                }}
                                            </button>
                                            <button mat-button (click)="cancelActivityEdition(result.editingItem)">
                                                {{ t("activity-edition.action-cancel") }}
                                            </button>
                                        </div>
                                    </div>

                                    <ng-container
                                        *ngIf="!result.isEditing || result.editingItem?.storeId !== activity.storeId"
                                    >
                                        <div
                                            class="flex flex-row items-center justify-between border rounded shadow-sm w-full py-2 px-4 cursor-pointer group hover:bg-primary-50"
                                            (click)="startActivityEdition(activity)"
                                        >
                                            <div class="flex flex-row items-center space-x-2">
                                                <span class="font-semibold">{{ activity.title }}</span>
                                                <span>- {{ parseRecurrenceText(activity) }}</span>
                                            </div>

                                            <div class="invisible group-hover:visible flex flex-row space-x-2">
                                                <button
                                                    mat-icon-button
                                                    color="warn"
                                                    (click)="deleteActivity($event, activity)"
                                                >
                                                    <mat-icon svgIcon="heroicons_outline:trash"></mat-icon>
                                                </button>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>

                            <div
                                class="flex flex-row items-center justify-between border border-accent-100 rounded shadow-sm w-full px-3 cursor-pointer"
                                *ngIf="!result.isEditing"
                                (click)="addActivity()"
                            >
                                <div class="flex flex-row items-center space-x-1 py-3">
                                    <mat-icon svgIcon="heroicons_outline:plus" class="icon-size-6"></mat-icon>
                                    <span>{{ t("add-reminder-title") }}</span>
                                </div>
                                <button mat-button color="accent" (click)="startWithActivityTemplate($event)">
                                    {{ t("use-reminder-definition") }}
                                </button>
                            </div>
                            <div *ngIf="!result.isEditing">ou</div>

                            <div
                                class="flex flex-row space-x-1 items-center border border-accent-100 rounded shadow-sm w-full p-3 cursor-pointer"
                                *ngIf="!result.isEditing"
                                (click)="addForm()"
                            >
                                <mat-icon svgIcon="heroicons_outline:plus" class="icon-size-6"></mat-icon>
                                <span>{{ t("add-form-title") }}</span>
                            </div>
                        </ng-container>
                    </div>
                </form>
            </div>

            <dialog-actions class="flex flex-row items-center space-x-4 justify-end">
                <button mat-stroked-button mat-dialog-close>
                    {{ root("actions.cancel") }}
                </button>
                <button
                    mat-flat-button
                    [disabled]="!form.valid || !(validActivities$ | async) || (loading$ | async) || (editing$ | async)"
                    (click)="save()"
                    color="primary"
                >
                    {{ root("actions.save") }}
                </button>
            </dialog-actions>
        </ui-dialog-base>
    </ng-container>
</ng-container>
