import { createSelector } from "@ngrx/store";
import { getFeedState } from "./base";
import { FeedState } from "./model";

export const selectAll = createSelector(getFeedState, (state: FeedState) => state.datasource);

export const selectIsLoading = createSelector(getFeedState, (state: FeedState) => state.loading);

export const selectIsCreating = createSelector(getFeedState, (state: FeedState) => state.creating);

export const selectAtLeastOneExists = createSelector(
    getFeedState,
    (state: FeedState) => state.datasource && state.datasource.length > 0
);

export const selectPatientId = createSelector(getFeedState, (state: FeedState) => state.patientId);
