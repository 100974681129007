import { createFeatureSelector, createSelector } from "@ngrx/store";
import { STATS_DOCUMENTS_FEATURE_KEY } from "./reducer";
import { IDocumentStatState } from "./model";

export const featureState = createFeatureSelector<IDocumentStatState>(STATS_DOCUMENTS_FEATURE_KEY);

export const selectPrescriptionStats = createSelector(featureState, (state) => state.prescription);

export const selectIsLoading = createSelector(featureState, (state) => state.loading);

export const selectPrescriptionHistory = createSelector(featureState, (state) => state.prescriptionHistory);