<!--<div class="roster-contact" [title]="contact.name">-->

    <!--<div class="roster-contact-avatar">-->
        <!--<img [src]="contact.avatar"/>-->
    <!--</div>-->

    <!--<div class="roster-contact-name">-->
        <!--{{contact.name}}-->
    <!--</div>-->

    <!--<div class="roster-contact-status">-->
        <!--<div *ngIf="unreadCount"-->
             <!--class="unread-message-badge">{{unreadCount}}</div>-->
        <!--<div *ngIf="(contact.presence$ | async) !== presence.unavailable"-->
             <!--class="roster-presence"-->
             <!--[ngClass]="'roster-presence&#45;&#45;' + (contact.presence$ | async)">●-->
        <!--</div>-->
    <!--</div>-->

<!--</div>-->

<div class="contacts-list-item" [ngClass]="(contact.presence$ | async) !== presence.unavailable ? 'online' : 'offline'" [class.active]="active">
    <img class="avatar" [src]="contact.avatar" [matTooltip]="contact.name" matTooltipPosition="left">
    <div class="unread-count" *ngIf="unreadCount">{{unreadCount}}</div>
    <div class="status-icon" [ngClass]="(contact.presence$ | async) !== presence.unavailable ? 'online' : 'offline'"></div>
</div>