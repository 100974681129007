import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap } from "rxjs/operators";
import * as actions from "./actions";
import { of } from "rxjs";
import { assign } from "lodash";
import {
    ActivityDefinitionClient,
    ActivityDefinitionDto,
    ActivityFolderClient,
    ActivityFolderDto,
    CreateActivityFolderRequest,
    CreateActivityDefinitionRequest,
    ActivityItemDefinition,
    UpdateActivityDefinitionRequest,
} from "@vhealth/engage";
import { ReminderFolder } from "./model";
import { createFolderTree } from "../../utils";

@Injectable()
export class ReminderEffects {
    constructor(
        private actions$: Actions,
        private _definitionApi: ActivityDefinitionClient,
        private _folderApi: ActivityFolderClient
    ) { }

    load$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(actions.loadReminderFoldersAction.execute),
                mergeMap(({ folder }) => {
                    return this._folderApi.list().pipe(
                        map((result: ActivityFolderDto[]) => {
                            let folders: ReminderFolder[] = createFolderTree<ReminderFolder>(
                                result.map((r) => assign(new ReminderFolder(), r))
                            );

                            return actions.loadReminderFoldersAction.complete({
                                folders: folders,
                            });
                        }),
                        catchError((error) => of(actions.loadReminderFoldersAction.failure({ error: error })))
                    );
                })
            ),
        { dispatch: true }
    );

    createFolder$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(actions.createReminderFolderAction.execute),
                mergeMap(({ parentFolderId, name, isPublic }) => {
                    let request: CreateActivityFolderRequest = {
                        name: name,
                        isPublic: isPublic,
                    };

                    return this._folderApi.create(request, parentFolderId).pipe(
                        map((result) =>
                            actions.createReminderFolderAction.complete({
                                folder: assign(new ReminderFolder(), result),
                            })
                        ),
                        catchError((error) => of(actions.createReminderFolderAction.failure({ error: error })))
                    );
                })
            ),
        { dispatch: true }
    );

    updateFolder$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(actions.updateReminderFolderAction.execute),
                mergeMap(({ id, name, isPublic }) => {
                    let request = {
                        name: name,
                        isPublic: isPublic,
                    };
                    return this._folderApi.update(id, request).pipe(
                        map((result) =>
                            actions.updateReminderFolderAction.complete({
                                folder: assign(new ReminderFolder(), result),
                            })
                        ),
                        catchError((error) => of(actions.updateReminderFolderAction.failure({ error: error })))
                    );
                })
            ),
        { dispatch: true }
    );

    deleteFolder$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(actions.deleteReminderFolderAction.execute),
                mergeMap(({ id }) =>
                    this._folderApi.delete(id).pipe(
                        map(() => actions.deleteReminderFolderAction.complete({ id })),
                        catchError((error) => of(actions.deleteReminderFolderAction.failure({ error: error })))
                    )
                )
            ),
        { dispatch: true }
    );

    createReminderDefinitionTemplate$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(actions.createReminderDefinitionAction.execute),
                mergeMap(({ parentFolderId, item }) => {
                    let request: CreateActivityDefinitionRequest = {
                        ...item,
                        comments: item.comments ?? "",
                    };
                    return this._folderApi.createForm(parentFolderId, request).pipe(
                        map((result) =>
                            actions.createReminderDefinitionAction.complete({
                                item: assign(new ActivityItemDefinition(), { ...result, creationTime: new Date() }),
                            })
                        ),
                        catchError((error) => of(actions.createReminderDefinitionAction.failure({ error: error })))
                    );
                })
            ),
        { dispatch: true }
    );

    updateTemplate$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(actions.updateReminderDefinitionAction.execute),
                mergeMap(({ item }) => {
                    let request: UpdateActivityDefinitionRequest = {
                        ...item,
                        comments: item.comments ?? "",
                    };
                    return this._definitionApi.update(item.id, request).pipe(
                        map((result) =>
                            actions.updateReminderDefinitionAction.complete({
                                item: assign(new ActivityItemDefinition(), { ...result, creationTime: new Date() }),
                            })
                        ),
                        catchError((error) => of(actions.updateReminderDefinitionAction.failure({ error: error })))
                    );
                })
            ),
        { dispatch: true }
    );

    moveDefinition$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(actions.moveReminderDefinitionAction.execute),
                mergeMap(({ id, folderId }) =>
                    this._definitionApi.move(id, folderId).pipe(
                        map(() => actions.moveReminderDefinitionAction.complete({ id, folderId })),
                        catchError((error) => of(actions.moveReminderDefinitionAction.failure({ error: error })))
                    )
                )
            ),
        { dispatch: true }
    );

    deleteTemplate$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(actions.deleteReminderDefinitionAction.execute),
                mergeMap(({ id }) =>
                    this._definitionApi.delete(id).pipe(
                        map(() => actions.deleteReminderDefinitionAction.complete({ id })),
                        catchError((error) => of(actions.deleteReminderDefinitionAction.failure({ error: error })))
                    )
                )
            ),
        { dispatch: true }
    );

    loadTemplate$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(actions.loadReminderDefinitionsAction.execute),
                mergeMap(({ id }) =>
                    this._folderApi.forms(id).pipe(
                        map((result: ActivityDefinitionDto[]) =>
                            actions.loadReminderDefinitionsAction.complete({
                                items: result.map((r) => assign(new ActivityItemDefinition(), r)),
                            })
                        ),
                        catchError((error) => of(actions.loadReminderDefinitionsAction.failure({ error: error })))
                    )
                )
            ),
        { dispatch: true }
    );
}
