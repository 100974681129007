<div class="flex flex-col space-y-2 p-4 bg-white shadow-sm rounded">
  <div class="flex flex-col">
    <div class="flex flex-row items-center">
      <span class="text-xl font-bold">Agendamentos</span>
    </div>
  </div>

  <div class="flex flex-row place-content-around space-x-6">

    <div class="flex flex-col bg-white">
      <span class="text-center p-2">Total</span>
      <div class="flex flex-row items-center h-96" #graphContainer>
        <apx-chart *ngIf="totalChartOptions" class="w-36"
                   [series]="totalChartOptions.series"
                   [chart]="totalChartOptions.chart"
                   [stroke]="totalChartOptions.stroke"
                   [dataLabels]="totalChartOptions.dataLabels"
                   [plotOptions]="totalChartOptions.plotOptions"
                   [xaxis]="totalChartOptions.xaxis"
                   [colors]="totalChartOptions.colors"
                   [fill]="totalChartOptions.fill"
                   [yaxis]="totalChartOptions.yaxis"
                   [legend]="totalChartOptions.legend"
                   [grid]="totalChartOptions.grid"
        ></apx-chart>
      </div>
    </div>

    <div class="flex flex-col bg-white">
      <span class="text-center p-2">Presencial</span>
      <div class="flex flex-row items-center h-96">
        <apx-chart *ngIf="inPersonChartOptions" class="w-36"
                   [series]="inPersonChartOptions.series"
                   [chart]="inPersonChartOptions.chart"
                   [stroke]="inPersonChartOptions.stroke"
                   [dataLabels]="inPersonChartOptions.dataLabels"
                   [plotOptions]="inPersonChartOptions.plotOptions"
                   [xaxis]="inPersonChartOptions.xaxis"
                   [colors]="inPersonChartOptions.colors"
                   [fill]="inPersonChartOptions.fill"
                   [yaxis]="inPersonChartOptions.yaxis"
                   [legend]="inPersonChartOptions.legend"
                   [grid]="inPersonChartOptions.grid"
        ></apx-chart>
      </div>
    </div>
    
    <div class="flex flex-col bg-white">
      <span class="text-center p-2">Online</span>
      <div class="flex flex-row items-center h-96">
        <apx-chart *ngIf="onlineChartOptions" class="w-36"
                   [series]="onlineChartOptions.series"
                   [chart]="onlineChartOptions.chart"
                   [stroke]="onlineChartOptions.stroke"
                   [dataLabels]="onlineChartOptions.dataLabels"
                   [plotOptions]="onlineChartOptions.plotOptions"
                   [xaxis]="onlineChartOptions.xaxis"
                   [colors]="onlineChartOptions.colors"
                   [fill]="onlineChartOptions.fill"
                   [yaxis]="onlineChartOptions.yaxis"
                   [legend]="onlineChartOptions.legend"
                   [grid]="onlineChartOptions.grid"
        ></apx-chart>
      </div>
    </div>

    <div class="flex flex-col bg-white">
      <span class="text-center p-2">Tipo de atendimento</span>
      <div class="flex flex-row items-center h-96">
        <apx-chart *ngIf="attendanceTypeChartOptions"
                   [series]="attendanceTypeChartOptions.series"
                   [chart]="attendanceTypeChartOptions.chart"
                   [labels]="attendanceTypeChartOptions.labels"
                   [legend]="attendanceTypeChartOptions.legend"
                   [plotOptions]="attendanceTypeChartOptions.plotOptions"
        ></apx-chart>
      </div>
    </div>

    <div class="flex flex-col bg-white">
      <span class="text-center p-2">Tempo médio de atendimento</span>
      <div class="flex flex-row items-center place-content-center h-96">
        <div class="flex flex-col space-y-2 place-content-center items-center">
          <mat-icon class="w-12 h-12" svgIcon="heroicons_outline:clock"></mat-icon>
          <span class="text-2xl font-semibold"
                *ngIf="{ duration: (serviceDuration$ | async) } as result">
            {{result.duration?.avg | number : '1.0-0' }}min
          </span>
        </div>
      </div>
    </div>

  </div>

</div>