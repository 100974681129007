import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { HealthEngageComponent } from "./health-engage.component";
import { MatTabsModule } from "@angular/material/tabs";
import { FormPoolModule } from "./form-pool";
import { ProtocolPoolModule } from "./protocol-pool";
import { ReminderPoolModule } from "./reminder-pool";
import { FeatureGuard } from "../../../../guards/feature-route.guard";
import { TranslocoCoreModule } from "@i18n";

export const HEALTH_ENGAGE_FEATURE = "Health.Features.Engage";

@NgModule({
    declarations: [HealthEngageComponent],
    imports: [
        CommonModule,
        RouterModule.forChild([
            {
                path: "",
                canActivate: [FeatureGuard],
                data: {
                    features: [HEALTH_ENGAGE_FEATURE],
                },
                component: HealthEngageComponent,
            },
            {
                path: "public",
                loadChildren: () => import("./feed/public/public.module").then((m) => m.EngagePublicModule),
            },
        ]),
        MatTabsModule,
        FormPoolModule,
        ReminderPoolModule,
        ProtocolPoolModule,
        TranslocoCoreModule,
    ],
})
export class HealthEngageModule {}
