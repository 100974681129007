import { Inject, Injectable, Optional } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { GetInactiveTenantsRequest, SessionApi, STATISTICS_API_BASE_URL } from "../../api/proxies";
import * as actions from "./actions"
import { catchError, map, mergeMap } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { HttpClient, HttpHeaders, HttpResponse, HttpResponseBase } from "@angular/common/http";
import { blobToText, throwException } from "../../tools/utils";

@Injectable()
export class StatsSessionEffects {

    private baseUrl: string;
    
    constructor(
        private actions$: Actions,
        private _api: SessionApi,
        private _httpClient: HttpClient,
        @Optional() @Inject(STATISTICS_API_BASE_URL) baseUrl?: string) {
        this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : "";
    }
    
    getInactiveClients$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getInactiveClients),
            mergeMap(({ sinceDate }) =>
                this._api.inactive(GetInactiveTenantsRequest.fromJS({sinceDate: sinceDate})).pipe(
                    map((result) => actions.getInactiveClientsComplete({items: result})),
                    catchError((error) => of(actions.getInactiveClientsError({error})))
                )
            )
        )
    );

    exportInactiveClients = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.exportInactiveClients),
            mergeMap(({ sinceDate }) => {
                let url_ = this.baseUrl + "/api/stats/session/export-inactive";
                url_ = url_.replace(/[?&]$/, "");
                let options_: any = {
                    observe: "response",
                    responseType: "blob",
                    headers: new HttpHeaders({
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    }),
                    body: {
                        sinceDate,
                    },
                };

                return this._httpClient.request("post", url_, options_).pipe(
                    mergeMap((response_: any) => {
                        return this.processFileBlob(response_);
                    }),
                    mergeMap((blob) => {
                        const blobUrl = URL.createObjectURL(blob);
                        let anchor = document.createElement("a");
                        document.body.appendChild(anchor);
                        anchor.style.display = "none";
                        anchor.href = blobUrl;
                        anchor.download = "clientes-inativos.xlsx";
                        anchor.click();
                        window.URL.revokeObjectURL(blobUrl);
                        return of(actions.exportInactiveClientsComplete());
                    }),
                    catchError((error) => of(actions.exportInactiveClientsError({ error })))
                );
            })
        )
    );

    protected processFileBlob(response: HttpResponseBase): Observable<Blob> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse
                ? response.body
                : (<any>response).error instanceof Blob
                    ? (<any>response).error
                    : undefined;

        let _headers: any = {};
        if (response.headers) {
            for (let key of response.headers.keys()) {
                _headers[key] = response.headers.get(key);
            }
        }
        if (status === 200) {
            return of(responseBlob);
        } else if (status === 401) {
            return blobToText(responseBlob).pipe(
                mergeMap((_responseText) => {
                    return throwException("A server error occurred.", status, _responseText, _headers);
                })
            );
        } else if (status !== 200 && status !== 204) {
            return blobToText(responseBlob).pipe(
                mergeMap((_responseText) => {
                    return throwException("An unexpected server error occurred.", status, _responseText, _headers);
                })
            );
        }
        return of<Blob>(<any>null);
    }
}
