import { Injectable } from "@angular/core";
import { AppSessionService } from "@shared/session/app-session.service";
import { environment } from "../../../../environments/environment";
import { timer } from "rxjs";

declare global {
    interface Window {
        wootric: any;
        wootricSettings: any;
    }
}

@Injectable({
    providedIn: "root",
})
export class SupportNPSService {
    counter = 0;
    
    constructor(private _usersService: AppSessionService) {
    }
    
    init() {
        this._usersService.onSessionChanged.subscribe((user) => {
            if (user) {
                this.startLogged(user.id, user.hash, user.creationTimeEpoch, user.emailAddress);
            }
        });
    }

    startLogged(userId, hash, creationTime, emailAddress) {
        this.counter++;
        let wootric = window?.wootric;
        if (wootric) {
            window.wootricSettings = {
                email: emailAddress,
                id: userId,
                hash,
                created_at: creationTime,
                account_token: environment.wootricAccountToken,
            }
            wootric("run");
        } else {
            if (this.counter > 5) {
                return;
            }
            timer(2000).subscribe(() => {
                this.startLogged(userId, hash, creationTime, emailAddress);
            });
        }
    }
}
