import { ErrorHandler, NgModule } from "@angular/core";
import { ApplicationinsightsAngularpluginErrorService } from "@microsoft/applicationinsights-angularplugin-js";
import { MonitoringService } from "./monitoring.service";

@NgModule({
    providers: [
        MonitoringService,
        {
            provide: ErrorHandler,
            useClass: ApplicationinsightsAngularpluginErrorService,
        },
    ],
})
export class InsightsModule {
    constructor(private insights: MonitoringService) {}
}
