import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { AppointmentsApi, GetAppointmentStatsRequest } from "../../api/proxies";
import * as actions from "./actions"
import { catchError, map, mergeMap, withLatestFrom } from "rxjs/operators";
import { of } from "rxjs";
import * as fromStatActions from "../../store/actions"
import { Store } from "@ngrx/store";
import { IStatsState } from "../../store";
import { selectPeriod, selectTenant } from "../../store/selectors";

@Injectable()
export class AppointmentStatsEffects {
    constructor(
        private _statsStore: Store<IStatsState>,
        private actions$: Actions,
        private _api: AppointmentsApi,) {
    }

    setPeriod$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromStatActions.setPeriod),
            withLatestFrom(this._statsStore.select(selectTenant)),
            map(([{period}, tenant]) => {
                return actions.getStats({start: period.start, end: period.end, tenantId: tenant?.id});
            })
        )
    );

    setClient$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromStatActions.setClient),
            withLatestFrom(this._statsStore.select(selectPeriod)),
            map(([{tenant}, period]) => {
                return actions.getStats({start: period.start, end: period.end, tenantId: tenant?.id});
            })
        )
    );

    getStats$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getStats),
            mergeMap(({start, end, tenantId}) =>
                this._api.stats(GetAppointmentStatsRequest.fromJS({start: start, end: end, tenantId: tenantId})).pipe(
                    map((result) => actions.getStatsComplete({
                        scheduledExecuted: result.scheduledExecuted,
                        serviceDuration: result.serviceDuration
                    })),
                    catchError((error) => of(actions.getStatsError({error})))
                )
            )
        )
    );
}
