import { ProtocolFolderDto, SmartFormFolderDto, ActivityFolderDto } from "./api";

export interface FolderDto {
    id: number | undefined;

    name: string | undefined;

    isPublic: boolean | undefined;

    folders: any[];

    parentFolderId: number | undefined;
}

export function createFolderTree<T extends { id: number; folders: any[]; parentFolderId: number }>(
    datasource: T[]
): T[] {
    return datasource.reduce((acc, curr) => {
        if (curr.parentFolderId === null || curr.parentFolderId === undefined) {
            let folder = { ...curr, folders: getChildren<T>(curr.id, datasource) };
            acc.push(folder);
        }
        return acc;
    }, []);
}

export function flattenFolderDatasource<T extends { id: number; folders: any[] }>(folders: T[]): T[] {
    return folders.reduce((acc, curr) => {
        acc.push(curr);
        if (curr.folders.length > 0) {
            acc = acc.concat(flattenFolderDatasource(curr.folders));
        }
        return acc;
    }, []);
}

export function getChildren<T extends { id: number; folders: any[] }>(
    id: number,
    dataSource: (SmartFormFolderDto | ProtocolFolderDto | ActivityFolderDto)[]
): T[] {
    let children: T[] = [];
    for (let i = 0; i < dataSource.length; i++) {
        const folder = dataSource[i];
        if (folder.parentFolderId === id) {
            let child: T = Object.assign({}, folder) as T; // Type assertion;
            if (child) {
                child.folders = getChildren(child.id, dataSource);
                children.push(child);
            }
        }
    }
    return children;
}

export function findFolderById<T extends { id: number; folders: any[] }>(id: number, folders: T[]): T {
    for (let folder of folders) {
        if (folder.id === id) {
            return folder;
        }
        if (folder.folders.length > 0) {
            let found = findFolderById(id, folder.folders);
            if (found) {
                return found;
            }
        }
    }
    return undefined;
}

export const addLocalOffset = (hour: number): number => {
    const offset = -(new Date().getTimezoneOffset() / 60);
    if (hour + offset < 0) {
        return hour + offset + 24;
    } else if (hour + offset >= 24) {
        return hour + offset - 24;
    }
    return hour + offset;
};

export function removeLocalOffset(hour: number): number {
    const offset = -(new Date().getTimezoneOffset() / 60);
    if (hour - offset < 0) {
        return hour - offset + 24;
    } else if (hour - offset >= 24) {
        return hour - offset - 24;
    }
    return hour - offset;
}
