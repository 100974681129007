<div class="h-full w-full flex flex-col space-y-0.5">
    <mat-toolbar>
        <span class="mat-dialog-title">Novo formulário</span>
        <div class="spacer"></div>
        <button mat-button (click)="showOptions()">
            <span>Configurações avançadas</span>
        </button>
    </mat-toolbar>
    <div *ngIf="isCreating$ | async">
        <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
    </div>
    <mat-dialog-content class="mat-typography" cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
        <vform-builder-wrapper
            [definition]="definition"
            (onCancel)="onFormCancel()"
            (onSave)="onFormSave($event)"
        ></vform-builder-wrapper>
    </mat-dialog-content>
</div>
