<div class="flex flex-row space-x-8">

  <div class="flex flex-col p-4 bg-white shadow-sm rounded w-full">
    <div class="flex flex-row items-center place-content-between">
      <span class="text-xl font-bold">Prescrições</span>
      <button mat-icon-button matTooltip="Acompanhar evolução" color="primary"
              routerLink="prescription/history">
        <mat-icon svgIcon="heroicons_outline:presentation-chart-line"></mat-icon>
      </button>
    </div>
    <ng-container *ngIf="prescriptionChartOptions">
      <apx-chart
              [series]="prescriptionChartOptions.series"
              [chart]="prescriptionChartOptions.chart"
              [labels]="prescriptionChartOptions.labels"
              [legend]="prescriptionChartOptions.legend"
              [plotOptions]="prescriptionChartOptions.plotOptions"
      ></apx-chart>
    </ng-container>
  </div>

</div>
