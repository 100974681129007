<div class="space-y-2">
  <div class="flex flex-col">
    <div *ngIf="(loading$ | async)" class="absolute inset-x-0 top-0">
      <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
    </div>
    <div class="flex flex-row items-center place-content-between">
      <span class="text-2xl font-bold">Clientes inativos</span>
      <div class="flex place-content-end space-x-2 justify-center items-center">
        <button (click)="exportSpreadsheet()" color="accent" mat-stroked-button>
          <mat-icon>import_export</mat-icon>
          Exportar
        </button>
        <mat-form-field class="w-72 fuse-mat-no-subscript">
          <mat-select (selectionChange)="selectPeriod($event.value)" [value]="selected$ | async" placeholder="Filtrar">
            <mat-option [value]="1">Há mais de uma semana</mat-option>
            <mat-option [value]="2">Há mais de duas semanas</mat-option>
            <mat-option [value]="3">Há mais de um mês</mat-option>
            <mat-option [value]="4">Há mais de 6 meses</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="flex flex-row space-x-8">

      <div class="flex flex-col p-4 bg-white shadow-sm rounded w-full">

        <div class="flex flex-col space-y-0">
          <div class="flex flex-row p-2 space-x-4">
            <div class="w-2/20 px-2 font-bold">ID</div>
            <div class="w-6/20 px-2 font-bold">Nome</div>
            <div class="w-6/20 px-2 font-bold">E-mail</div>
            <div class="w-3/20 px-2 font-bold">Telefone</div>
            <div class="w-3/20 px-2 font-bold">Último Acesso</div>
          </div>

          <div *ngFor="let item of (datasource$ | async)" class="flex flex-row px-2 py-3 space-x-4 border-t">
            <div (click)="copy(item.id)" class="w-2/20 px-2 cursor-pointer hover:bg-vgray-50 rounded">{{item.id}}</div>
            <div (click)="copy(item.name)"
                 class="w-6/20 px-2 cursor-pointer hover:bg-vgray-50 rounded">{{item.name}}</div>
            <div (click)="copy(item.email)"
                 class="w-6/20 px-2 cursor-pointer hover:bg-vgray-50 rounded">{{item.email}}</div>
            <div (click)="copy(item.phoneNumber)"
                 class="w-3/20 px-2 cursor-pointer hover:bg-vgray-50 rounded">{{item.phoneNumber}}</div>
            <div class="w-3/20 px-2">{{item.lastAccess.toLocaleDateString()}}</div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>