import { NgModule } from "@angular/core";
import { EngagePublicRoutingModule } from "./public-routing.module";
import { EngagePublicSharedModule } from "./public-shared.module";

@NgModule({
    imports: [EngagePublicSharedModule, EngagePublicRoutingModule],
})
export class EngagePublicModule {
    constructor() { }
}
