<div class="chat-messages" #messageArea>
    <ng-container *ngFor="let dateMessagesGroup of contact.dateMessagesGroups">
        <div class="chat-messages-date-group">{{dateMessagesGroup.date | date:chatService.translations.dateFormat:undefined:chatService.translations.locale}}</div>
        <ngx-chat-message *ngFor="let message of dateMessagesGroup.messages"
                          [nick]="message.direction === Direction.in ? contact.name : ''"
                          [avatar]="contact.avatar"
                          [message]="message"
                          [contact]="contact"
                          [showAvatars]="showAvatars">
        </ngx-chat-message>
    </ng-container>
    <div class="chat-messages-empty" *ngIf="contact.messages.length === 0">
        {{chatService.translations.noMessages}}
    </div>
    <div class="chat-message chat-message--in" *ngIf="contact.pendingIn$ | async">
        <div class="chat-message-text chat-message-text--no-avatar">
            <span>
                {{chatService.translations.subscriptionRequestMessage}}
            </span>
            <ul class="chat-presence-subscription-actions">
                <li>
                    <a (click)="acceptSubscriptionRequest($event)" href="#">{{chatService.translations.acceptSubscriptionRequest}}</a>
                </li>
                <li>
                    <a (click)="denySubscriptionRequest($event)" href="#">{{chatService.translations.denySubscriptionRequest}}</a>
                </li>
            </ul>
        </div>
    </div>
</div>
