<ng-container *transloco="let t; read: 'health.engage.form-pool.options-dialog'">
    <div class="flex justify-between items-center" (cdkDragRootElement)="('.cdk-overlay-pane')">
        <h2 mat-dialog-title>{{ t("title") }}</h2>
        <button mat-icon-button (click)="dialogRef.close()">
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <mat-dialog-content class="flex flex-col space-y-2 pb-4 overflow-y-auto">
        <mat-checkbox [(ngModel)]="notifyOnAnswered" color="primary">{{ t("notify-on-answered") }}</mat-checkbox>
        <mat-checkbox [(ngModel)]="displayOnFeed" color="primary">{{ t("display-on-feed") }}</mat-checkbox>
        <mat-checkbox [(ngModel)]="horizontalEvolution" color="primary">{{
            t("horizontal-evolution")
        }}</mat-checkbox>
    </mat-dialog-content>

    <mat-dialog-actions class="flex justify-between items-center">
        <button mat-stroked-button (click)="dialogRef.close()">Cancelar</button>

        <div class="flex gap-6 items-center">
            <button mat-flat-button color="primary" (click)="save()">
                {{ t("save") }}
            </button>
        </div>
    </mat-dialog-actions>
</ng-container>
