import RRule, { Frequency } from "rrule";
import { format, setDayOfYear } from "date-fns";
import { ptBR } from "date-fns/locale";

export class RecurrenceHelper {
    static parseRecurrenceMessage(definition: string, stampStartDate: boolean) {
        if (!definition) {
            return "";
        }
        let rule = RRule.fromString(definition);
        let interval = rule.options.interval;
        let items: string[] = [];

        switch (rule.options.freq) {
            case Frequency.MINUTELY:
                if (interval === 1) {
                    items.push(`A cada ${interval} minuto`);
                } else {
                    items.push(`A cada ${interval} minutos`);
                }
                break;
            case Frequency.HOURLY:
                if (interval === 1) {
                    items.push(`A cada ${interval} hora`);
                } else {
                    items.push(`A cada ${interval} horas`);
                }
                break;
            case Frequency.DAILY:
                if (interval === 1) {
                    items.push(`Todos os dias`);
                } else {
                    items.push(`A cada ${interval} dias`);
                }
                break;
            case Frequency.WEEKLY:
                if (interval === 1) {
                    items.push(`Semanal, cada ${rule.options.byweekday.map((i) => this.getName(i)).join(", ")}`);
                } else {
                    items.push(
                        `A cada ${interval} semanas, cada ${rule.options.byweekday
                            .map((i) => this.getName(i))
                            .join(", ")}`
                    );
                }
                if (rule.options.byhour && rule.options.byhour?.length > 0) {
                    let validHours =
                        rule.options.byhour.filter((item) => {
                            return typeof item === "number" && item >= 0 && item <= 23;
                        }) ?? [];
                    if (validHours?.length > 0) {
                        validHours.sort((a, b) => a - b);
                        items.push(`às ${validHours.join(", ")} horas`);
                    }
                }
                break;
            case Frequency.MONTHLY:
                if (interval === 1) {
                    items.push(`Mensal, todo dia ${rule.options.bymonthday[0]}`);
                } else {
                    items.push(`A cada ${interval} meses, no dia ${rule.options.bymonthday[0]}`);
                }
                break;
            case Frequency.YEARLY:
                if (interval === 1) {
                    items.push(
                        `Anual, em ${format(setDayOfYear(new Date(), rule.options.byyearday[0]), "MMMM dd", {
                            locale: ptBR,
                        })}`
                    );
                } else {
                    items.push(
                        `A cada ${interval} anos, em ${format(
                            setDayOfYear(new Date(), rule.options.byyearday[0]),
                            "MMMM dd",
                            { locale: ptBR }
                        )}`
                    );
                }
                break;
        }
        if (rule.options.until) {
            items.push(`até ${rule.options.until.toLocaleDateString()}`);
        }

        if (rule.options.count && rule.options.count > 0 && !rule.options.until) {
            items.push(`${rule.options.count} vezes`);
        }

        if (stampStartDate) {
            return `${items.join(", ")}. Iniciar em ${format(rule.options.dtstart, "dd/MM/yyyy")} às ${format(
                rule.options.dtstart,
                "HH:mm"
            )}`;
        }

        return `${items.join(", ")}.`;
    }

    static getName(day: number) {
        switch (day) {
            case RRule.SU.weekday:
                return "domingo";
            case RRule.MO.weekday:
                return "segunda-feira";
            case RRule.TU.weekday:
                return "terça-feira";
            case RRule.WE.weekday:
                return "quarta-feira";
            case RRule.TH.weekday:
                return "quinta-feira";
            case RRule.FR.weekday:
                return "sexta-feira";
        }
        return "sábado";
    }

    static getFinishType(rule: RRule) {
        if (rule.options.count && rule.options.count > 0) {
            return "count";
        }
        if (rule.options.until && rule.options.until.getUTCFullYear() !== 9999) {
            return "until";
        }
        return "never";
    }

    static checkForLocalOffsetRemoval(rule: RRule) {
        // IMPORTANT: RRULE contructor calls parseOptios() function, wich adds one default byhour value the byhour array.
        // The default value is the hour of the dtstart property, wich is UTC. So in this there's no need to remove the local offset.
        // REFERENCE: https://github.com/jkbrzt/rrule/blob/9f2061febeeb363d03352efe33d30c33073a0242/src/parseoptions.ts#L179
        // In our case, we only use byhour definitions when the user selects more than one hour,
        // so we only remove the local offset when the array has more than one value.

        if (rule.options.byhour && rule.options.byhour.length > 1) {
            rule.options.byhour = rule.options.byhour.map((h) =>
                this.removeLocalOffset(h)
            );
        }

        return rule;

    }

    static removeLocalOffset(hour: number): number {
        const offset = -(new Date().getTimezoneOffset() / 60);
        if (hour - offset < 0) {
            return hour - offset + 24;
        } else if (hour - offset >= 24) {
            return hour - offset - 24;
        }
        return hour - offset;
    }
}
