<h1>{{title}}</h1>
<div mat-dialog-content>
    <p class="mat-body" [innerHTML]="confirmMessage"></p>
</div>
<div mat-dialog-actions class="pt-4" fxLayout="row" align="end" *xcMediaIf="'gt-xs'">
    <button mat-button *ngIf="allowCancel" (click)="dialogRef.close('cancel')">{{cancelButtonText}}</button>
    <button mat-button class="mat-warn" (click)="dialogRef.close('negative')"
            *ngIf="negativeButtonText">{{negativeButtonText}}</button>
    <button mat-button class="mat-accent" (click)="dialogRef.close('possitive')"
            *ngIf="positiveButtonText">{{positiveButtonText}}</button>
</div>

<div mat-dialog-actions class="pt-4" fxLayout="row" align="center" *xcMediaIf="'xs'">
    <button mat-button (click)="dialogRef.close('cancel')">{{cancelButtonText}}</button>
    <button mat-button color="warn" (click)="dialogRef.close('negative')"
            *ngIf="negativeButtonText">{{negativeButtonText}}</button>
    <button mat-button color="primary" (click)="dialogRef.close('possitive')"
            *ngIf="positiveButtonText">{{positiveButtonText}}</button>
</div>
