import { Injectable } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { tap, map, switchMap } from 'rxjs/operators';
import {fromPromise} from "rxjs/internal/observable/fromPromise";

declare var google: any;

@Injectable()
export class GeocodeService {
    private geocoder: any;

    constructor(private mapLoader: MapsAPILoader) {}

    private initGeocoder() {
        this.geocoder = new google.maps.Geocoder();
    }

    private waitForMapsToLoad(): Observable<boolean> {
        if(!this.geocoder) {
            return fromPromise(this.mapLoader.load())
                .pipe(
                    tap(() => this.initGeocoder()),
                    map(() => true)
                );
        }
        return of(true);
    }

    geocodeAddress(address: string): Observable<GeocodeLocation> {
        return this.waitForMapsToLoad().pipe(
            switchMap(() => {
                return new Observable<GeocodeLocation>(observer => {
                    this.geocoder.geocode({'address': address}, (results, status) => {
                        if (status == google.maps.GeocoderStatus.OK) {
                            observer.next({
                                lat: results[0].geometry.location.lat(),
                                lng: results[0].geometry.location.lng(),
                            });
                        } else {                            
                            observer.next({ lat: -15.793889, lng: -47.882778,found:false});
                        }
                        observer.complete();
                    });
                })
            })
        )
    }
}

export interface GeocodeLocation {
    lat: number;
    lng: number;
    found?: boolean;
}