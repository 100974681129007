import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { FuseSharedModule } from "@fuse/shared.module";

import { AlertComponent } from './alert.component';

@NgModule({
    imports: [
        MatCardModule,
        MatIconModule,
        FuseSharedModule,
        MatButtonModule,
    ],
    declarations: [
        AlertComponent,
    ],
    exports: [
        AlertComponent,
    ],
})
export class AlertModule{
}