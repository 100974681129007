import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import { Observable, ReplaySubject, Subject } from "rxjs";
import { ThemePalette } from "@angular/material/core";
import { SmartFormPoolFacade } from "../store/facade";
import { SmartFormFolder, actions } from "../store";
import { SmartFormDefinition } from "../../model";
import { MatDialog } from "@angular/material/dialog";
import { CreateTemplateComponent } from "../create-template/create-template.component";
import { take, takeUntil } from "rxjs/operators";
import { EditTemplateComponent } from "../edit-template/edit-template.component";
import { FormControl, FormGroup } from "@angular/forms";
import { FuseConfirmationService } from "@nx-workspace/fuse";
import { TranslocoService } from "@ngneat/transloco";
import { DatePipe } from "@angular/common";
import { Router } from "@angular/router";
import { MoveFolderDialogComponent } from "../folder-picker/move-folder-dialog/move-folder-dialog.component";
import { Actions, ofType } from "@ngrx/effects";
import { NotifyService } from "@ui/notify";

@Component({
    selector: "folder-details",
    templateUrl: "./folder-details.component.html",
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [DatePipe],
})
export class FolderDetailsComponent implements OnInit, OnDestroy {
    @ViewChild("messageInput") messageInput: ElementRef;

    drawerMode: "over" | "side" = "side";

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    background: ThemePalette = undefined;

    selected$: Observable<SmartFormFolder>;

    selected: SmartFormFolder;

    selectedFolderForms$: Observable<SmartFormDefinition[]>;

    isLoading$: Observable<boolean>;

    isUpdating$: Observable<boolean>;

    filteredForms$: ReplaySubject<SmartFormDefinition[]> = new ReplaySubject<SmartFormDefinition[]>(1);

    isThereAnyForm$: Observable<boolean>;

    displayedColumns: string[] = ["name", "description", "creationTime", "creatorUserName", "symbol"];

    searchForm: FormGroup = new FormGroup({
        text: new FormControl(""),
    });

    constructor(
        public _facade: SmartFormPoolFacade,
        private _router: Router,
        private _matDialog: MatDialog,
        private _confirmationService: FuseConfirmationService,
        private _translateService: TranslocoService,
        private _actions: Actions,
        private _notifyService: NotifyService
    ) {
        this.selected$ = _facade.selected$;

        this.selectedFolderForms$ = _facade.selectedFolderForms$;

        this.isThereAnyForm$ = _facade.isThereAnyForm$;

        this.isLoading$ = _facade._loadingDetails$;

        this.isUpdating$ = _facade.updating$;
    }

    ngOnInit(): void {
        this.selected$.pipe(takeUntil(this._unsubscribeAll)).subscribe((selected) => {
            if (selected) {
                this.selected = selected;
                this._facade.loadSmartFormAction(this.selected.id);
            }
        });

        this.selectedFolderForms$.pipe(takeUntil(this._unsubscribeAll)).subscribe((forms) => {
            this.filteredForms$.next(forms);
        });

        this.searchForm
            .get("text")
            .valueChanges.pipe(takeUntil(this._unsubscribeAll))
            .subscribe((text) => {
                this.applyFilter(text.toLowerCase());
            });

        this._actions
            .pipe(takeUntil(this._unsubscribeAll), ofType(actions.deleteSmartFormAction.complete))
            .subscribe((action) => {
                this._notifyService.success(
                    this._translateService.translate("health.engage.form-pool.delete-definition.successfully-message")
                );
            });

        this._actions
            .pipe(takeUntil(this._unsubscribeAll), ofType(actions.deleteSmartFormAction.failure))
            .subscribe((action) => {
                this._notifyService.error(
                    this._translateService.translate("health.engage.form-pool.delete-definition.error-message")
                );
            });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    createFormDefinitionTemplate() {
        this._matDialog.open(CreateTemplateComponent, {
            panelClass: "create-template-dialog",
            data: {
                parentFolderId: this.selected.id,
            },
        });
    }

    updateFormDefinitionTemplate(form: SmartFormDefinition) {
        this._matDialog.open(EditTemplateComponent, {
            panelClass: "edit-template-dialog",
            data: {
                parentFolderId: this.selected.id,
                definition: form.definition,
                name: form.name,
                comments: form.comments,
                id: form.id,
                notifyOnAnswered: form.notifyOnAnswered,
                displayOnFeed: form.displayOnFeed,
                horizontalEvolution: form.horizontalEvolution,
            },
        });
    }

    deleteTemplate(form: SmartFormDefinition) {
        this._confirmationService
            .open({
                title: this._translateService.translate("health.engage.form-pool.delete-definition.alert-title"),
                message: this._translateService.translate("health.engage.form-pool.delete-definition.alert-message", {
                    name: form.name,
                }),
                actions: {
                    confirm: {
                        label: this._translateService.translate("ACTION_DELETE"),
                        color: "warn",
                    },
                    cancel: {
                        label: this._translateService.translate("ACTION_CANCEL"),
                    },
                },
            })
            .afterClosed()
            .subscribe((result) => {
                if (result === "confirmed") {
                    this._facade.deleteTemplate(form.id);
                }
            });
    }

    moveFormDefinition(form: SmartFormDefinition) {
        this._matDialog.open(MoveFolderDialogComponent, {
            panelClass: "template-folder-picker-dialog",
            data: {
                formId: form.id,
            },
        });
    }

    duplicateTemplate(form: SmartFormDefinition) {
        this._matDialog.open(CreateTemplateComponent, {
            panelClass: "create-template-dialog",
            data: {
                parentFolderId: this.selected.id,
                definition: form.definition,
                name: form.name + " - Cópia",
                comments: form.comments,
                id: form.id,
                notifyOnAnswered: form.notifyOnAnswered,
                displayOnFeed: form.displayOnFeed,
                horizontalEvolution: form.horizontalEvolution,
            },
        });
    }

    applyFilter(text: string) {
        this.selectedFolderForms$.pipe(take(1)).subscribe((forms) => {
            let filteredForms = forms.filter((form) => {
                return form.name.toLowerCase().includes(text.toLowerCase());
            });
            this.filteredForms$.next(filteredForms);
        });
    }

    redirectToSettings() {
        this._facade.select(undefined);
        this._router.navigate(["/smart-forms/settings"]);
    }
}
