import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { NotificationSettingsState } from "./model";
import {
    allowScheduleNotificationsChange,
    allowPreFormResponseNotificationsChange,
    getNotificationsSettings,
} from "./actions";
import { allowPreFormResponseNotifications, allowScheduleNotifications, isLoading } from "./selectors";
import { Observable } from "rxjs";
import { take } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class NotificationSettingsFacade {
    loading$: Observable<boolean> = this._store.select(isLoading);

    allowScheduleNotifications$: Observable<boolean> = this._store.select(allowScheduleNotifications);

    allowPreFormResponseNotifications$: Observable<boolean> = this._store.select(allowPreFormResponseNotifications);

    constructor(private _store: Store<NotificationSettingsState>) {}

    getNotificationSettings() {
        this._store.dispatch(getNotificationsSettings.execute());
    }

    allowScheduleNotificationsChange(value: boolean) {
        this.allowPreFormResponseNotifications$.pipe(take(1)).subscribe((allowPreFormResponseNotifications) => {
            this._store.dispatch(
                allowScheduleNotificationsChange.execute({
                    allowScheduleNotifications: value,
                    allowPreFormResponseNotifications,
                })
            );
        });
    }

    allowPreFormResponseNotificationsChange(value: boolean) {
        this.allowScheduleNotifications$.pipe(take(1)).subscribe((allowScheduleNotifications) => {
            this._store.dispatch(
                allowPreFormResponseNotificationsChange.execute({
                    allowPreFormResponseNotifications: value,
                    allowScheduleNotifications,
                })
            );
        });
    }
}
