import {Injector, Injectable} from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import {LocalizationService} from "abp-ng2-module";

@Injectable({
    providedIn: 'root'
})
export class TranslationService {
    
    localizationSourceName = AppConsts.localization.defaultLocalizationSourceName;
    localization: LocalizationService;
    
    constructor(injector: Injector) {
        this.localization = injector.get(LocalizationService);
    }

    l(key: string, ...args: any[]): string {
        let localizedText = this.localization.localize(key, this.localizationSourceName);

        if (!localizedText) {
            localizedText = key;
        }

        if (!args || !args.length) {
            return localizedText;
        }

        args.unshift(localizedText);
        return abp.utils.formatString.apply(this, args);
    }
}
