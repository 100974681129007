import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppServiceBase } from '@shared/app-service-base';
import { PendingTermsOfService, TermsOfServiceServiceProxy } from '@shared/service-proxies/service-proxies';

@Injectable()
export class PendingTermsOfServiceSignatureService extends AppServiceBase {
    termPageInfoEmitter$ = new BehaviorSubject(false);

    disabled$ = new BehaviorSubject(true);

    constructor(_injector: Injector, private _api: TermsOfServiceServiceProxy) {
        super(_injector);
    }

    next() {
        this.termPageInfoEmitter$.next(true);
    }

    onScroll(event) {
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 32) {
            this.disabled$.next(false);
        } else {
            this.disabled$.next(true);
        }
    }

    fetchUrl = async (url) => {
        const response = await fetch(url);
        return response.text();
    };

    checkForPendingTermsOfServiceSignature(): Observable<PendingTermsOfService> {
        return this._api.get();
    }

    sign(id: number): Observable<boolean> {
        return this._api.sign(id);
    }
}
