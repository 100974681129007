import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { RecentActivityPickerComponentStore } from "./recent-activity-picker-component-store";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { RecurrenceHelper } from "@vui";
import { FormGroup } from "@angular/forms";
import { Subject } from "rxjs";
import { debounceTime, takeUntil } from "rxjs/operators";
import { ActivityItemHistoryDto } from "../../api/api";

@Component({
    selector: "recent-activity-picker",
    templateUrl: "./recent-activity-picker.component.html",
    providers: [RecentActivityPickerComponentStore],
})
export class RecentActivityPickerComponent implements OnInit, OnDestroy {
    _unsubscribeAll = new Subject();

    activities$ = this._store.activities$;

    @Input() form: FormGroup;

    @Input() controlName: string;

    private readonly ONE_SECOND_DEBOUNCE = 1000;

    @Output() onActivitySelected = new EventEmitter<ActivityItemHistoryDto>();

    @Output() onStartWithTemplate = new EventEmitter<boolean>();

    constructor(private _store: RecentActivityPickerComponentStore) {}

    ngOnInit() {
        this.form
            .get(this.controlName)
            .valueChanges.pipe(debounceTime(this.ONE_SECOND_DEBOUNCE), takeUntil(this._unsubscribeAll))
            .subscribe((keyword: string) => {
                if (!keyword) return;
                this._store.loadActivities(keyword.toLowerCase());
            });
    }

    ngOnDestroy() {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    removeActivityFromHistory(event: any, activity: ActivityItemHistoryDto) {
        event.stopPropagation();
        this._store.removeFromHistory(activity);
    }

    displayActivity(activity: ActivityItemHistoryDto) {
        return activity ?? "";
    }

    selectActivity(event: MatAutocompleteSelectedEvent) {
        this.onActivitySelected.emit(event.option.value);
    }

    parseRecurrenceText(recurrence: string) {
        return RecurrenceHelper.parseRecurrenceMessage(recurrence, false);
    }

    startWithTemplate() {
        this.onStartWithTemplate.emit(true);
    }
}
