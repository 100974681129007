import { Component, Injector, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { Subject } from "rxjs";
import { fuseAnimations } from "@fuse/animations";
import { FuseProgressBarService } from "@fuse/components/progress-bar/progress-bar.service";
import { PagedListingComponentBase, PagedRequestDto } from "@shared/paged-listing-component-base";
import { finalize } from "rxjs/internal/operators";
import { NotificationService } from "@app/wapps/notification/notification.service";
import { Notification } from "@app/wapps/notification/notification.model";
import { FuseConfirmDialogComponent } from "@fuse/components/confirm-dialog/confirm-dialog.component";
import { ObjectPagedResultDto } from "@shared/service-proxies/service-proxies";
import { MatDialog } from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";

class GetNotificationsDto extends PagedRequestDto {
    keyword: string | undefined;
    sorting: string | undefined;
}

@Component({
    selector: "notification",
    templateUrl: "./notifications.component.html",
    styleUrls: ["./notifications.component.scss"],
    animations: fuseAnimations,
})
export class NotificationsComponent extends PagedListingComponentBase<Notification> implements OnInit, OnDestroy {
    // Private
    protected _unsubscribeAll: Subject<any>;

    rows: Notification[];

    selected: any[];

    columns: string[] = ["icon", "title", "subtitle", "id"];

    displayedColumns: string[] = ["icon", "title", "subtitle", "id"];

    constructor(
        injector: Injector,
        _fb: FormBuilder,
        private _fuseProgressBarService: FuseProgressBarService,
        private _matDialog: MatDialog,
        private _service: NotificationService
    ) {
        super(injector);
        this._unsubscribeAll = new Subject();
        this.rows = [];
        this.selected = [];
    }

    ngOnInit(): void {
        this.refresh();
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    setPage(pageInfo) {
        this.getDataPage(pageInfo.offset);
    }

    onPageChanged(pageEvent: PageEvent) {
        this.getDataPage(pageEvent.pageIndex);
    }

    protected delete(item: Notification): void {
        this.showLoadingIndicator();
        this._service
            .delete(item.id)
            .then(() => {
                this.refresh();
            })
            .catch(() => {
                this.hideLoadingIndicator();
                this.notify.error("Ocorreu um erro ao excluir a notificação, por favor, tente mais tarde");
            });
    }

    protected list(request: GetNotificationsDto, pageNumber: number, finishedCallback: Function): void {
        this.showLoadingIndicator();
        this._service
            .getNotifications(request.skipCount, request.maxResultCount)
            .pipe(
                finalize(() => {
                    finishedCallback();
                })
            )
            .subscribe(
                (result: ObjectPagedResultDto) => {
                    this.rows = this._service.parse(result.items);
                    this.showPaging(result, pageNumber);
                },
                () => {},
                () => {
                    this.hideLoadingIndicator();
                    this.changeDetector.detectChanges();
                }
            );
    }

    showLoadingIndicator(): void {
        this._fuseProgressBarService.show();
    }

    hideLoadingIndicator(): void {
        this._fuseProgressBarService.hide();
    }

    onSelect(notification: Notification) {
        console.log('here', notification);
        this._service.handleOpenNotification(notification);
    }

    markAsRead(event, notification: Notification) {
        event.stopPropagation();
        this.showLoadingIndicator();
        this._service
            .setRead(notification.id)
            .then(() => {
                this.refresh();
            })
            .catch(() => {
                this.hideLoadingIndicator();
                this.notify.error("Ocorreu um erro ao processar seu pedido, por favor, tente mais tarde");
            });
    }

    onDelete(event, notification: Notification) {
        event.stopPropagation();
        this.delete(notification);
    }

    deleteAll() {
        let dialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false,
        });
        dialogRef.componentInstance.title = this.l("Excluir todas as notificações");
        dialogRef.componentInstance.confirmMessage = this.l(
            "Você tem certeza que deseja excluir todas as notificações?"
        );

        dialogRef.componentInstance.cancelButtonText = this.l("Action.No");
        dialogRef.componentInstance.negativeButtonText = this.l("Action.Yes");
        dialogRef.afterClosed().subscribe((result) => {
            if (result == FuseConfirmDialogComponent.ActionNegative) {
                this.showLoadingIndicator();
                this._service
                    .deleteAll()
                    .then(() => {
                        this.refresh();
                    })
                    .catch(() => {
                        this.hideLoadingIndicator();
                        this.notify.error("Ocorreu um erro ao processar seu pedido, por favor, tente mais tarde");
                    });
            }
        });
    }

    markAllAsRead() {
        this.showLoadingIndicator();
        this._service
            .setReadAll()
            .then(() => {
                this.refresh();
            })
            .catch(() => {
                this.hideLoadingIndicator();
                this.notify.error("Ocorreu um erro ao processar seu pedido, por favor, tente mais tarde");
            });
    }
}
