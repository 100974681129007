/**
 * converts date objects to date strings like '2011-10-05'
 */
export function extractDateStringFromDate(date: Date): string {
    const isoString = date.toISOString();
    return isoString.slice(0, isoString.indexOf('T'));
}

export function extractTimeStringFromDate(date: Date | string) : string {

    let dateObj:Date;

    if (typeof date == 'string')
        dateObj = new Date(date);

    if (date['_d'])
        dateObj = date['_d'];

    return dateObj.toLocaleTimeString();
}
