import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from "@angular/core";
import { ProtocolFacade } from "../store/facade";
import { Observable, ReplaySubject, Subject, combineLatest } from "rxjs";
import { ProtocolFolder } from "../store";
import { ProtocolDefinition, ProtocolFolderFlatNode, ProtocolFolderNode } from "../../model";
import { FormControl, FormGroup } from "@angular/forms";
import { FlatTreeControl } from "@angular/cdk/tree";
import { MatTreeFlatDataSource, MatTreeFlattener } from "@angular/material/tree";
import { take, takeUntil } from "rxjs/operators";

@Component({
    selector: "protocol-template-picker",
    templateUrl: "./protocol-template-picker.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProtocolPickerComponent implements OnInit, OnDestroy {
    private _unsubscribeAll = new Subject();

    isLoading$: Observable<boolean>;

    filteredFolders$: ReplaySubject<ProtocolFolder[]> = new ReplaySubject<ProtocolFolder[]>(1);

    filteredDefinitions$: ReplaySubject<ProtocolDefinition[]> = new ReplaySubject<ProtocolDefinition[]>(1);

    protocolFolders: ProtocolFolderNode[] = [];

    flatNodeMap: Map<ProtocolFolderFlatNode, ProtocolFolderNode> = new Map();

    nestedNodeMap: Map<string, ProtocolFolderFlatNode> = new Map();

    searchForm: FormGroup = new FormGroup({
        text: new FormControl(""),
    });

    private _transformer = (node: ProtocolFolderNode, level) => {
        const flatNode = new ProtocolFolderFlatNode();
        flatNode.level = level;
        flatNode.expandable = node.children && node.children?.length > 0;
        flatNode.title = node.title;
        flatNode.parentId = node.parentId;
        flatNode.data = node.data;
        this.flatNodeMap.set(flatNode, node);
        this.nestedNodeMap.set(node.title, flatNode);
        return flatNode;
    };

    treeControl = new FlatTreeControl<ProtocolFolderFlatNode>(
        (node) => node.level,
        (node) => node.expandable
    );

    treeFlattener = new MatTreeFlattener(
        this._transformer,
        (node) => node.level,
        (node) => node.expandable,
        (node) => node.children
    );

    dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    hasChild = (_: number, node: ProtocolFolderFlatNode) => node.expandable;

    folders$: Observable<ProtocolFolder[]>;

    protocols$: Observable<ProtocolDefinition[]>;

    selectedFolder$: Observable<ProtocolFolder>;

    selectedFolder: ProtocolFolder;

    isThereAnyFolder$: Observable<boolean>;

    @Input() selected: ProtocolDefinition;

    @Output() onSelected = new EventEmitter<ProtocolDefinition>();

    constructor(private _facade: ProtocolFacade, private _changeDetectorRef: ChangeDetectorRef) {
        this.folders$ = this._facade.folders$;
        this.selectedFolder$ = this._facade.current$;
        this.isThereAnyFolder$ = this._facade._isThereAnyFolder$;
        this.isLoading$ = this._facade.loading$;
        this.protocols$ = this._facade.selectedFolderProtocols$;
        this._facade.root();
    }

    ngOnInit(): void {
        this.selectedFolder$.pipe(takeUntil(this._unsubscribeAll)).subscribe((folder) => {
            this.selectedFolder = folder;
            if (folder) {
                this._facade.load(folder.id);
                this.onSelected.emit(null);
            }
        });

        this.filteredFolders$.pipe(takeUntil(this._unsubscribeAll)).subscribe((folders) => {
            this.protocolFolders = folders.map((folder) => {
                return new ProtocolFolderNode(folder, folder.parentFolderId ?? 0);
            });
            this.dataSource.data = this.protocolFolders;
            this.treeControl.expandAll();
            this._changeDetectorRef.detectChanges();
        });

        this.folders$.pipe(takeUntil(this._unsubscribeAll)).subscribe((folders) => {
            this.filteredFolders$.next(folders);
        });

        this.protocols$.pipe(takeUntil(this._unsubscribeAll)).subscribe((protocols) => {
            this.filteredDefinitions$.next(protocols);
        });

        this.searchForm.valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe((value) => {
            this.applyFilter(value.text.toLowerCase());
        });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    navigate(folder: ProtocolFolder) {
        this._facade.navigate(folder);
    }

    goBack() {
        this._facade.goBack();
    }

    select(folder: ProtocolFolder) {
        this._facade.select(folder.id);
    }

    selectProtocol(event: any, template: ProtocolDefinition) {
        event.stopPropagation();
        this.onSelected.emit(template);
    }

    applyFilter(text: string) {
        combineLatest([this.folders$, this.protocols$])
            .pipe(take(1))
            .subscribe(([folders, forms]) => {
                this.filteredFolders$.next(folders);

                let filteredForms = forms.filter((form) => form.name.toLowerCase().includes(text));
                this.filteredDefinitions$.next(filteredForms);

                let keepSelected: boolean = filteredForms.length > 0;
                let filteredFolders = this.filterFoldersByName(folders, text, keepSelected);
                this.filteredFolders$.next(filteredFolders);
            });
    }

    filterFoldersByName(folders: ProtocolFolder[], searchText: string, keepSelected: boolean) {
        let filteredFolders = [];
        folders.forEach((folder) => {
            if (
                folder.name.toLowerCase().includes(searchText) ||
                (keepSelected && folder.id === this.selectedFolder?.id)
            ) {
                filteredFolders.push(folder);
            } else if (folder.folders) {
                let filteredSubFolders = this.filterFoldersByName(folder.folders, searchText, keepSelected);
                if (filteredSubFolders.length > 0) {
                    let filteredFolder = Object.assign({}, folder);
                    filteredFolder.folders = filteredSubFolders;
                    filteredFolders.push(filteredFolder);
                }
            }
        });
        return filteredFolders;
    }
}
