import { createAction, props } from "@ngrx/store";

export const getStats = createAction("[Stats Appointments] getStats", props<{
    start: Date,
    end: Date,
    tenantId: number | undefined
}>());

export const getStatsComplete = createAction(
    "[Stats Appointments] getStatsComplete",
    props<{ scheduledExecuted: any, serviceDuration: any }>()
);

export const getStatsError = createAction("[Stats Appointments] getStatsError", props<{ error: any }>());