import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, take } from "rxjs/operators";
import * as actions from "./actions";
import { of } from "rxjs";
import { assign } from "lodash";
import { ProtocolFolder } from "./model";
import { addLocalOffset, createFolderTree } from "../../utils";
import { ProtocolDefinition } from "../../model";
import {
    CreateProtocolDefinitionRequest,
    CreateProtocolFolderRequest,
    ProtocolDefinitionClient,
    ProtocolDefinitionDto,
    ProtocolFolderClient,
    ProtocolFolderDto,
    UpdateProtocolDefinitionRequest,
} from "../../api";
import RRule from "rrule";
import { ProtocolFacade } from "./facade";

@Injectable()
export class ProtocolsEffects {
    constructor(
        private actions$: Actions,
        private _facade: ProtocolFacade,
        private _folderApi: ProtocolFolderClient,
        private _api: ProtocolDefinitionClient
    ) { }

    loadFolders$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(actions.loadFoldersAction.execute),
                mergeMap(({ folder }) => {
                    return this._folderApi.list().pipe(
                        map((result: ProtocolFolderDto[]) => {
                            let folders: ProtocolFolder[] = createFolderTree<ProtocolFolder>(
                                result.map((r) => assign(new ProtocolFolder(), r))
                            );
                            return actions.loadFoldersAction.complete({
                                folders: folders,
                            });
                        }),
                        catchError((error) => of(actions.loadFoldersAction.failure({ error: error })))
                    );
                })
            ),
        { dispatch: true }
    );

    createFolder$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(actions.createProtocolFolderAction.execute),
                mergeMap(({ parentFolderId, name, isPublic }) => {
                    let request: CreateProtocolFolderRequest = {
                        name: name,
                        isPublic: isPublic,
                    };
                    return this._folderApi.create(request, parentFolderId).pipe(
                        map((result) =>
                            actions.createProtocolFolderAction.complete({
                                folder: assign(new ProtocolFolder(), result),
                            })
                        ),
                        catchError((error) => of(actions.createProtocolFolderAction.failure({ error: error })))
                    );
                })
            ),
        { dispatch: true }
    );

    updateFolder$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(actions.updateProtocolFolderAction.execute),
                mergeMap(({ id, name, isPublic }) => {
                    let request = {
                        name: name,
                        isPublic: isPublic,
                    };
                    return this._folderApi.update(id, request).pipe(
                        map((result) =>
                            actions.updateProtocolFolderAction.complete({
                                folder: assign(new ProtocolFolder(), result),
                            })
                        ),
                        catchError((error) => of(actions.updateProtocolFolderAction.failure({ error: error })))
                    );
                })
            ),
        { dispatch: true }
    );

    deleteFolder$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(actions.deleteProtocolFolderAction.execute),
                mergeMap(({ id }) =>
                    this._folderApi.delete(id).pipe(
                        map(() => actions.deleteProtocolFolderAction.complete({ id })),
                        catchError((error) => of(actions.deleteProtocolFolderAction.failure({ error: error })))
                    )
                )
            ),
        { dispatch: true }
    );

    loadProtocols$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(actions.loadProtocolsAction.execute),
                mergeMap(({ id }) =>
                    this._folderApi.forms(id).pipe(
                        map((result: ProtocolDefinitionDto[]) => {
                            let protocols: ProtocolDefinition[] = result.map((e: ProtocolDefinitionDto) => {
                                return assign(new ProtocolDefinition(), {
                                    ...e,
                                    items: [
                                        ...e.items.map((item) => {
                                            let itemRecurrence = RRule.fromString(item.recurrence);
                                            let options = { ...itemRecurrence.options };
                                            if (options.byhour && options.byhour.length > 0) {
                                                options.byhour = [
                                                    ...options.byhour.map((hour) => addLocalOffset(hour)),
                                                ];
                                            }

                                            let newRule = new RRule(options);

                                            return assign(item, {
                                                ...item,
                                                recurrence: newRule.toString(),
                                            });
                                        }),
                                    ],
                                });
                            });
                            return actions.loadProtocolsAction.complete({
                                protocols: protocols,
                            });
                        }),
                        catchError((error) => of(actions.loadProtocolsAction.failure({ error: error })))
                    )
                )
            ),
        { dispatch: true }
    );

    create$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(actions.createProtocolAction.execute),
                mergeMap(({ parentFolderId, protocol }) => {
                    let request: CreateProtocolDefinitionRequest = {
                        ...protocol,
                    };

                    return this._folderApi.createProtocol(parentFolderId, request).pipe(
                        map((result) =>
                            actions.createProtocolAction.complete({
                                protocol: assign(new ProtocolDefinition(), {
                                    ...result,
                                    creationTime: new Date(),
                                }),
                            })
                        ),
                        catchError((error) => of(actions.createProtocolAction.failure({ error: error })))
                    );
                })
            ),
        { dispatch: true }
    );

    update$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(actions.updateProtocolAction.execute),
                mergeMap(({ protocol }) => {
                    let request: UpdateProtocolDefinitionRequest = {
                        ...protocol,
                    };
                    return this._api.update(protocol.id, request).pipe(
                        map((result: ProtocolDefinitionDto) =>
                            actions.updateProtocolAction.complete({
                                protocol: assign(new ProtocolDefinition(), {
                                    ...result,
                                    creationTime: new Date(),
                                }),
                            })
                        ),
                        catchError((error) => of(actions.updateProtocolAction.failure({ error: error })))
                    );
                })
            ),
        { dispatch: true }
    );

    delete$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(actions.deleteProtocolAction.execute),
                mergeMap(({ id }) =>
                    this._api.delete(id).pipe(
                        map(() => actions.deleteProtocolAction.complete({ id })),
                        catchError((error) => of(actions.deleteProtocolAction.failure({ error: error })))
                    )
                )
            ),
        { dispatch: true }
    );

    move$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(actions.moveProtocolDefinitionAction.execute),
                mergeMap(({ id, folderId }) =>
                    this._api.move(id, folderId).pipe(
                        map(() => actions.moveProtocolDefinitionAction.complete({ id, folderId })),
                        catchError((error) => of(actions.moveProtocolDefinitionAction.failure({ error: error })))
                    )
                )
            ),
        { dispatch: true }
    );
}
