/* eslint-disable */
import { NotifyService } from "@ui/notify";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { NgZone } from "@angular/core";

export class SnackbarNotifyService implements NotifyService {
    constructor(private readonly snackBar: MatSnackBar, private readonly zone: NgZone) {
        //super();
    }

    error(message: string, title?: string, options?: any): void {
        this.show(message, {
            duration: 3000,
            panelClass: "error-notification-overlay",
        });
    }

    info(message: string, title?: string, options?: any): void {
        this.show(message, {
            duration: 2000,
            panelClass: "info-notification-overlay",
        });
    }

    success(message: string, title?: string, options?: any): void {
        this.show(message, {
            duration: 2000,
            panelClass: "success-notification-overlay",
        });
    }

    warn(message: string, title?: string, options?: any): void {
        this.show(message, {
            duration: 2500,
            panelClass: "warning-notification-overlay",
        });
    }

    private show(message: string, configuration: MatSnackBarConfig) {
        if (configuration.horizontalPosition === undefined) {
            configuration.horizontalPosition = "end";
        }
        if (configuration.verticalPosition === undefined) {
            configuration.verticalPosition = "bottom";
        }
        // Need to open snackBar from Angular zone to prevent issues with its position per
        // https://stackoverflow.com/questions/50101912/snackbar-position-wrong-when-use-errorhandler-in-angular-5-and-material
        this.zone.run(() => this.snackBar.open(message, null, configuration));
    }
}
