import { NgModule } from "@angular/core";
import { SmartFormCollectComponent } from "./form/smart-form-collect.component";
import { CommonModule } from "@angular/common";
import { FuseSharedModule } from "@fuse/shared.module";
import { FuseProgressBarModule, FuseSidebarModule } from "@fuse/components";
import { ReactiveFormsModule } from "@angular/forms";
import { FormlyMaterialModule } from "@ngx-formly/material";
import { FormlyModule } from "@ngx-formly/core";
import { SmartFormCollectCompletedComponent } from "./form/smart-form-collect-completed.component";
import { SmartFormCollectCanceledComponent } from "./form/smart-form-collect-canceled.component";
import { SmartFormCollectUnavailableComponent } from "./form/smart-form-collect-unavailable.component";
import { SmartFormViewerComponent } from "./form/smart-form-viewer.component";
import { FormsWrapperModule } from "@vhealth/vforms-wrapper";
import { MatDividerModule } from "@angular/material/divider";
import { MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { A11yModule } from "@angular/cdk/a11y";
import { ActivityCollectComponent } from "./activity/activity-collect.component";
import { ActivityViewerComponent } from "./activity/activity-viewer.component";
import { ActivityCollectUnavailableComponent } from "./activity/activity-collect-unavailable.component";
import { ActivityCollectCanceledComponent } from "./activity/activity-collect-canceled.component";
import { ActivityCollectCompletedComponent } from "./activity/activity-collect-completed.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";

@NgModule({
    declarations: [
        SmartFormCollectComponent,
        SmartFormCollectCompletedComponent,
        SmartFormCollectCanceledComponent,
        SmartFormCollectUnavailableComponent,
        SmartFormViewerComponent,
        ActivityCollectComponent,
        ActivityCollectCompletedComponent,
        ActivityCollectCanceledComponent,
        ActivityCollectUnavailableComponent,
        ActivityViewerComponent,
    ],
    imports: [
        CommonModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseProgressBarModule,
        ReactiveFormsModule,
        FormlyMaterialModule,
        FormlyModule,
        FormsWrapperModule,
        MatDividerModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        A11yModule,
    ],
    exports: [
        SmartFormCollectComponent,
        SmartFormCollectCompletedComponent,
        SmartFormCollectCanceledComponent,
        SmartFormCollectUnavailableComponent,
        SmartFormViewerComponent,
        ActivityCollectComponent,
        ActivityCollectCompletedComponent,
        ActivityCollectCanceledComponent,
        ActivityCollectUnavailableComponent,
        ActivityViewerComponent,
    ],
})
export class EngagePublicSharedModule {
    constructor() {}
}
