import { Injectable, Injector } from "@angular/core";
import { AppServiceBase } from "@shared/app-service-base";
import { BehaviorSubject } from "rxjs";
import { Alert } from './alert.model'

@Injectable({
    providedIn: 'root',
})
export class AlertService extends AppServiceBase {
    alertInfoEmitter: BehaviorSubject<Alert[]> = new BehaviorSubject<Alert[]>(null)

    alerts = []

    constructor (
        _injector: Injector,
    ) {
        super(_injector)
    }

    publishAlert (newAlert:Alert) {
        const findAlertById = this.alerts.find((element) => element.id === newAlert.id);
        if (findAlertById) {
            return this.alertInfoEmitter.next(this.alerts);
        }
        this.alerts.push(newAlert)
        return this.alertInfoEmitter.next(this.alerts)
    }

    dismiss(alert: Alert) {
        console.log(alert)
        const alertToDismiss = this.alerts.find((element) => alert.id === element.id);
        alertToDismiss.isHidden = true;
    }


    execute(alert: Alert) {
        console.log(alert)
        alert.action(alert);
        this.dismiss(alert);
    }
}
