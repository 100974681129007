export const HealthPermissionNames = {
   ProceduresPermission: "Health.Permission.ProceduresPermission",
   ProceduresPermissionQuery: "Health.Permission.Procedures.Query",
   ProceduresPermissionQueryMine: "Health.Permission.Procedures.QueryMine",
   ProceduresPermissionCreate: "Health.Permission.Procedures.Create",
   ProceduresPermissionUpdate: "Health.Permission.Procedures.Update",
   ProceduresPermissionDelete: "Health.Permission.Procedures.Delete",

   InsurancePermission: "Health.Permission.InsurancePermission",
   InsurancePermissionQuery: "Health.Permission.Insurance.Query",
   InsurancePermissionCreate: "Health.Permission.Insurance.Create",
   InsurancePermissionUpdate: "Health.Permission.Insurance.Update",
   InsurancePermissionDelete: "Health.Permission.Insurance.Delete",

   CareUnitsPermission: "Health.Permission.CareUnits",
   CareUnitsPermissionQuery: "Health.Permission.CareUnits.Query",
   CareUnitsPermissionCreate: "Health.Permission.CareUnits.Create",
   CareUnitsPermissionUpdate: "Health.Permission.CareUnits.Update",
   CareUnitsPermissionDelete: "Health.Permission.CareUnits.Delete",
};

export const HealthPermissionAdminAll = [
   HealthPermissionNames.ProceduresPermission,
   HealthPermissionNames.InsurancePermission,
];
