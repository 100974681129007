import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { ReminderPoolFacade } from "./store/facade";
import { Observable, ReplaySubject, Subject } from "rxjs";
import { TranslocoService } from "@ngneat/transloco";
import { ReminderFolderFlatNode, ReminderFolderNode } from "@vhealth/engage";
import { ReminderFolder, actions } from "./store";
import { MatDialog } from "@angular/material/dialog";
import { FuseConfirmationService } from "@nx-workspace/fuse";
import { FormControl, FormGroup } from "@angular/forms";
import { FlatTreeControl } from "@angular/cdk/tree";
import { MatTreeFlatDataSource, MatTreeFlattener } from "@angular/material/tree";
import { take, takeUntil } from "rxjs/operators";
import { CreateFolderComponent } from "./create-folder/create-folder.component";
import { EditFolderComponent } from "./edit-folder/edit-folder.component";
import { NotifyService } from "@ui/notify";
import { Actions, ofType } from "@ngrx/effects";

@Component({
    selector: "reminder-pool",
    templateUrl: "./reminder-pool.component.html",
    styleUrls: ["./reminder-pool.component.scss"],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReminderPoolComponent implements OnInit, OnDestroy {
    private _unsubscribeAll = new Subject();

    isLoading$: Observable<boolean>;

    isUpdating$: Observable<boolean>;

    folders$: Observable<ReminderFolder[]>;

    selected$: Observable<ReminderFolder>;

    isThereAnyData$: Observable<boolean>;

    filteredReminderFolders$: ReplaySubject<ReminderFolder[]> = new ReplaySubject<ReminderFolder[]>(1);

    ReminderFolders: ReminderFolderNode[] = [];

    flatNodeMap: Map<ReminderFolderFlatNode, ReminderFolderNode> = new Map();

    nestedNodeMap: Map<string, ReminderFolderFlatNode> = new Map();

    searchForm: FormGroup = new FormGroup({
        text: new FormControl(""),
    });

    private _transreminderer = (node: ReminderFolderNode, level) => {
        const flatNode = new ReminderFolderFlatNode();
        flatNode.level = level;
        flatNode.expandable = node.children && node.children?.length > 0;
        flatNode.title = node.title;
        flatNode.parentId = node.parentId;
        flatNode.data = node.data;
        this.flatNodeMap.set(flatNode, node);
        this.nestedNodeMap.set(node.title, flatNode);
        return flatNode;
    };

    treeControl = new FlatTreeControl<ReminderFolderFlatNode>(
        (node) => node.level,
        (node) => node.expandable
    );

    treeFlattener = new MatTreeFlattener(
        this._transreminderer,
        (node) => node.level,
        (node) => node.expandable,
        (node) => node.children
    );

    dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    hasChild = (_: number, node: ReminderFolderFlatNode) => node.expandable;

    constructor(
        private _changeDetector: ChangeDetectorRef,
        private _matDialog: MatDialog,
        private _translateService: TranslocoService,
        private _facade: ReminderPoolFacade,
        private _confirmationService: FuseConfirmationService,
        private _notifyService: NotifyService,
        private _actions: Actions
    ) {
        this.folders$ = this._facade.folders$;
        this.selected$ = this._facade.selected$;
        this.isThereAnyData$ = this._facade._isThereAnyData$;
        this.isLoading$ = this._facade.loading$;
        this.isUpdating$ = this._facade.updating$;
        this._facade.root();
    }

    ngOnInit(): void {
        this.filteredReminderFolders$.pipe(takeUntil(this._unsubscribeAll)).subscribe((folders) => {
            this.ReminderFolders = folders.map((folder) => {
                return new ReminderFolderNode(folder, folder.parentFolderId ?? 0);
            });
            this.dataSource.data = this.ReminderFolders;
            this.treeControl.expandAll();
            this._changeDetector.markForCheck();
        });

        this.folders$.pipe(takeUntil(this._unsubscribeAll)).subscribe((folders) => {
            this.filteredReminderFolders$.next(folders);
        });

        this.searchForm
            .get("text")
            .valueChanges.pipe(takeUntil(this._unsubscribeAll))
            .subscribe((text) => {
                this.applyFilter(text.toLowerCase());
            });

        this._actions
            .pipe(ofType(actions.deleteReminderFolderAction.complete), takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this._notifyService.success(
                    this._translateService.translate("health.engage.reminder-pool.delete.successfully-message")
                );
            });

        this._actions
            .pipe(ofType(actions.deleteReminderFolderAction.failure), takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this._notifyService.error(
                    this._translateService.translate("health.engage.reminder-pool.delete.error-message")
                );
            });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    reload(nodes: ReminderFolderNode[]) {
        nodes.forEach((node) => {
            if (node.children) {
                this.reload(node.children);
            }
        });
    }

    map(items: ReminderFolder[], parentId: number | null = null): ReminderFolderNode[] {
        return items.map((item) => {
            const node = new ReminderFolderNode(item, parentId);
            node.children = this.map(item.folders, item.parentFolderId);
            return node;
        });
    }

    createFolder(parentId: number = null) {
        //Without the timeout the text inside the Dialog will not gain the focus
        setTimeout(() => {
            this._matDialog.open(CreateFolderComponent, {
                panelClass: "dialog-v2",
                data: {
                    parentId,
                },
            });
        });
    }

    editFolder(event: any, folder: ReminderFolderFlatNode) {
        //Without the timeout the text inside the Dialog will not gain the focus
        setTimeout(() => {
            this._matDialog.open(EditFolderComponent, {
                panelClass: "dialog-v2",
                data: folder.data,
            });
        });
    }

    deleteFolder(event: any, folder: ReminderFolderFlatNode) {
        //Without the timeout the text inside the Dialog will not gain the focus
        setTimeout(() => {
            this._confirmationService
                .open({
                    title: this._translateService.translate("health.engage.reminder-pool.delete.alert.title"),
                    message: this._translateService.translate("health.engage.reminder-pool.delete.alert.message", {
                        name: folder.data.name,
                    }),
                    actions: {
                        confirm: {
                            label: this._translateService.translate("ACTION_DELETE"),
                        },
                        cancel: {
                            label: this._translateService.translate("ACTION_CANCEL"),
                        },
                    },
                })
                .afterClosed()
                .subscribe((result) => {
                    if (result === "confirmed") {
                        this._facade.deleteFolder(folder.data.id);
                    }
                });
        });
    }

    applyFilter(text: string) {
        this.folders$.pipe(take(1)).subscribe((folders) => {
            let filteredFolders = this.filterFoldersByName(folders, text);
            this.filteredReminderFolders$.next(filteredFolders);
        });
    }

    filterFoldersByName(folders: ReminderFolder[], searchText: string) {
        let filteredFolders = [];
        folders.forEach((folder) => {
            if (folder.name.toLowerCase().includes(searchText)) {
                filteredFolders.push(folder);
            } else if (folder.folders) {
                let filteredSubFolders = this.filterFoldersByName(folder.folders, searchText);
                if (filteredSubFolders.length > 0) {
                    let filteredFolder = Object.assign({}, folder);
                    filteredFolder.folders = filteredSubFolders;
                    filteredFolders.push(filteredFolder);
                }
            }
        });
        return filteredFolders;
    }

    selectNode(node: ReminderFolderFlatNode) {
        this._facade.select(node.data.id);
    }
}
