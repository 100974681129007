<ng-container *transloco="let root">
    <ng-container *transloco="let t; read: 'health.engage.form-pool.folder-picker'">

    <div class="flex flex-col w-full h-full" [ngClass]="classes">
        <div class="flex flex-0 items-center justify-between h-16 pl-3 pr-2 text-default border-b shadow-sm">
            <span>{{t("select-folder")}}</span>

            <button mat-icon-button [tabIndex]="-1">
                <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x'" mat-dialog-close></mat-icon>
            </button>
        </div>

        <div cdkTrapFocus [cdkTrapFocusAutoCapture]="true" class="flex-grow w-full overflow-hidden relative">
            <form-folder-picker (onFolderSelected)="handleSelection($event)" [selected]="selected"></form-folder-picker>
        </div>

        <!-- Actions -->
       <div
           class="flex flex-col sm:flex-row sm:items-center justify-end py-4 px-6 border-t shadow bg-gray-50 space-x-4"
       >
           <button mat-stroked-button mat-dialog-close>
               {{ root("actions.cancel") }}
           </button>
           <button mat-flat-button (click)="save()" color="primary">
               {{ root("actions.save") }}
           </button>
       </div>
    </div>
</ng-container>
</ng-container>
