<div class="w-full h-full flex flex-col">

  <!-- header -->
  <div class="grow-0 w-full flex flex-row items-center place-content-between chat-header pl-5 pr-2 border-b shadow-sm">

    <div class="flex flex-row items-center space-x-5 cursor-pointer" (click)="unfoldSidebarTemporarily()" *ngIf="selectedContact === null">
      <mat-icon class="s-32" svgIcon="chat"></mat-icon>
      <span>Chat</span>
    </div>

    <div class="flex flex-row items-center space-x-5 cursor-pointer" (click)="unfoldSidebarTemporarily()" *ngIf="selectedContact">
      <img [src]="selectedContact.avatar" class="w-8 h-8 rounded-full" [alt]="selectedContact.name"/>
      <span class="truncate">{{ selectedContact.name }}</span>
    </div>

    <div class="flex flex-row">
      <button *ngIf="isAudioEnabled" mat-icon-button
              (click)="toggleAudioNotification()">
        <mat-icon class="secondary-text s-20">volume_up</mat-icon>
      </button>

      <button *ngIf="!isAudioEnabled" mat-icon-button
              (click)="toggleAudioNotification()">
        <mat-icon class="secondary-text s-20">volume_off</mat-icon>
      </button>

      <button mat-icon-button (click)="foldSidebarTemporarily();resetChat();" class="hidden md:inline-block">
        <mat-icon class="secondary-text s-20">close</mat-icon>
      </button>

      <button mat-icon-button (click)="toggleSidebarOpen();resetChat();" class="inline-block md:hidden">
        <mat-icon class="secondary-text">close</mat-icon>
      </button>
    </div>

  </div>

  <div class="flex flex-row h-full overflow-hidden">
    <div class="w-18 h-full shadow-sm border-r">
      <c360-chat-roster-list [rosterState]="'shown'"
                             [contacts]="contacts"
                             [contactRequestsReceived$]="contactRequestsReceived$"
                             [contactRequestsSent$]="contactRequestsSent$"
                             [contactsUnaffiliated$]="contactsUnaffiliated$">
      </c360-chat-roster-list>
    </div>

    <div class="flex flex-col w-full h-full overflow-hidden">
      <c360-chat-message-list [showAvatars]="false"></c360-chat-message-list>
    </div>
  </div>

</div>