import { Injectable } from "@angular/core";
import { AppSessionService } from "@shared/session/app-session.service";
import { BehaviorSubject, combineLatest } from "rxjs";
import { environment } from "../../../../environments/environment";
import { SupportServiceProxy } from "@shared/service-proxies/service-proxies";
import { AppConsts } from "@shared/AppConsts";
import { FuseMediaWatcherService } from "@nx-workspace/fuse";
import { HealthEngageCommunicationService } from "@vhealth/engage";

declare global {
    interface Window {
        drift: any;
        Intercom: any;
        hsConversationsSettings: any;
        hsConversationsOnReady: any;
        HubSpotConversations: any;
    }
}

@Injectable({
    providedIn: "root",
})
export class SupportChatService {
    chatState: boolean[];

    opened: boolean;

    loggedTenant: any;

    loggedUser: any;

    onChatScriptInjected: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    enabled: boolean;

    chatScriptInjected: boolean;

    hideRequested: boolean;

    constructor(
        private _usersService: AppSessionService,
        private _supportApi: SupportServiceProxy,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _healthEngageCommunicationService: HealthEngageCommunicationService
    ) {
        window.hsConversationsOnReady = [
            () => {
                this.onChatScriptInjected.next(true);
            },
        ];

        this.start();
        let sessionChanged$ = this._usersService.onSessionChanged;
        let tenantInfoChanged$ = this._usersService.onTenantInfoChanged;
        combineLatest(sessionChanged$, tenantInfoChanged$).subscribe((value) => {
            let user = value[0];
            let tenant = value[1];
            if (user && tenant) {
                this.loggedTenant = tenant;
                this.loggedUser = user;
                if (this.chatScriptInjected) {
                    this.startLogged(user, tenant);
                }
            } else {
                this.loggedTenant = null;
                this.loggedUser = null;
                if (this.chatScriptInjected) {
                    this.start();
                }
            }
        });
        this.chatState = [];
        this.opened = true;
        this.enabled = environment.supportChatEnabled && AppConsts.SupportChat !== "none";
        this.onChatScriptInjected.subscribe((injected) => {
            this.chatScriptInjected = injected;
            if (injected) {
                this.start();
            }
        });
        
        combineLatest(sessionChanged$, tenantInfoChanged$).subscribe((value) => {
            let user = value[0];
            let tenant = value[1];
            if (user && tenant) {
                this.loggedTenant = tenant;
                this.loggedUser = user;
                if (this.chatScriptInjected) {
                    this.startLogged(user, tenant);
                }
            } else {
                this.loggedTenant = null;
                this.loggedUser = null;
                if (this.chatScriptInjected) {
                    this.start();
                }
            }
        });

        const heChatVisibility$ = this._healthEngageCommunicationService.chatVisibility$;
        const mediaChange$ = this._fuseMediaWatcherService.onMediaChange$;
        combineLatest(heChatVisibility$, mediaChange$).subscribe(([heChatVisibility, mediaChange]) => {
            const hideByMedia = !mediaChange.matchingAliases.includes("md");
            if (hideByMedia || !heChatVisibility) {
                this.hide();
            } else {
                this.show();
            }
        });
    }

    start() {
        if (!environment.supportChatEnabled || this._usersService.isImpersonating()) {
            return;
        }
        
        if (window.HubSpotConversations) {
            setTimeout(() => {
                if (this.hideRequested) {
                    return;
                }

                if (!this.loggedUser) {
                    window.HubSpotConversations.widget.load();
                } else {
                    this.startLogged(this.loggedUser, this.loggedTenant);
                }
            }, 2000);
        }

        if (!!window?.Intercom) {
            window.Intercom("boot", {
                api_base: "https://api-iam.intercom.io",
                app_id: "f6u81a48",
                hide_default_launcher: true,
            });
        }
    }

    startLogged(user, tenant) {
        if (!environment.supportChatEnabled || this._usersService.isImpersonating()) {
            return;
        }

        if (window?.HubSpotConversations) {
            window.HubSpotConversations.widget.remove();
            this._supportApi.getIdentifier().subscribe((token) => {
                window.hsConversationsSettings = {
                    identificationEmail: user.emailAddress,
                    identificationToken: token,
                };
                setTimeout(() => {
                    if (!this.hideRequested) {
                        window.HubSpotConversations.widget.load();
                    }
                }, 1000);
            });
        }
    }

    hide() {
        this.hideRequested = true;
        if (window?.HubSpotConversations) {
            window.HubSpotConversations.widget.remove();
            this.chatState.push(this.opened);
            this.opened = false;
        }
    }

    show() {
        this.hideRequested = false;
        if (!!window?.HubSpotConversations) {
            let previousState = this.chatState.pop();
            if (previousState) {
                window.HubSpotConversations.widget.load();
                this.opened = true;
            }
        }
    }

    hideMessengerPanel() {
        if (window?.HubSpotConversations) {
            window.HubSpotConversations.widget.close();
        }
    }

    showMessengerPanel() {
        if (window?.HubSpotConversations) {
            window.HubSpotConversations.widget.open();
        }
    }
}
