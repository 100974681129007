import { createApiActions } from "@store/api-action";
import { props } from "@ngrx/store";

export const getNotificationsSettings = createApiActions("getNotificationsSettings", "NotificationSettings", {
    errorConfig: props<{ error: any }>(),
    completeConfig: props<{
        settings: { allowScheduleNotifications: boolean; allowPreFormResponseNotifications: boolean };
    }>(),
});

export const allowScheduleNotificationsChange = createApiActions(
    "allowScheduleNotificationsChange",
    "NotificationSettings",
    {
        errorConfig: props<{ error: any }>(),
        completeConfig: props<{
            settings: { allowScheduleNotifications: boolean; allowPreFormResponseNotifications: boolean };
        }>(),
        clientConfig: props<{ allowScheduleNotifications: boolean; allowPreFormResponseNotifications: boolean }>(),
    }
);

export const allowPreFormResponseNotificationsChange = createApiActions(
    "allowPreFormResponseNotifications",
    "NotificationSettings",
    {
        errorConfig: props<{ error: any }>(),
        completeConfig: props<{
            settings: { allowScheduleNotifications: boolean; allowPreFormResponseNotifications: boolean };
        }>(),
        clientConfig: props<{ allowPreFormResponseNotifications: boolean; allowScheduleNotifications: boolean }>(),
    }
);
