import { NgModule } from '@angular/core';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { FuseSharedModule } from '@fuse/shared.module';
import { TextMaskModule } from 'angular2-text-mask';
import {AddressComponent} from "@app/wapps/core/address/address.component";
import {AgmCoreModule, LAZY_MAPS_API_CONFIG} from "@agm/core";
import {GeocodeService} from "@app/wapps/core/address/geocode.service";
import { FuseDirectivesModule } from '@fuse/directives/directives';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CustomMaterialModule} from "@app/wapps/core/material.module";
import {MapsApiLoaderService} from "@app/wapps/core/maps-api-loader.service";
import {AddressFormComponent} from "@app/wapps/core/address/address-form.component";

@NgModule({
    declarations: [
        AddressComponent,
        AddressFormComponent
    ],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CustomMaterialModule,
        TextMaskModule,
        FuseSharedModule,
        AgmCoreModule.forRoot(),
        MatGoogleMapsAutocompleteModule,
        FuseDirectivesModule
    ], exports: [
        AddressComponent,
        AddressFormComponent,
        CustomMaterialModule
    ],
    providers: [
        GeocodeService,
        {provide: LAZY_MAPS_API_CONFIG, useClass: MapsApiLoaderService}
    ]
})
export class AddressModule
{
}
