import { createReducer, on } from "@ngrx/store";
import { actions, IStatsSessionsState } from ".";

export const STATS_SESSIONS_FEATURE_KEY = "stats.sessions";

export const initialStatsSessionsState: IStatsSessionsState = {
    items: [],
    period: 1,
    loading: false,
    total: 0
};

export const getStatsSessionsReducer = createReducer(
    initialStatsSessionsState,
    on(actions.setPeriod, (state, { period }) => ({
        ...state,
        period: period
    })),
    on(actions.getInactiveClients, (state) => ({
        ...state,
        loading: true,
    })),
    on(actions.getInactiveClientsComplete, (state, {items}) => {
        return {
            ...state,
            items: items,
            loading: false,
        };
    }),
    on(actions.getInactiveClientsError, (state, {error}) => ({
        ...state,
        loading: false,
    })),
    on(actions.exportInactiveClients, (state) => ({
        ...state,
        loading: true,
    })),
    on(actions.exportInactiveClientsComplete, (state) => {
        return {
            ...state,
            loading: false,
        };
    }),
    on(actions.exportInactiveClientsError, (state, {error}) => ({
        ...state,
        loading: false,
    })),
);
