import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import {MatGridListModule} from '@angular/material/grid-list';
import { FusePipesModule } from '@fuse/pipes/pipes.module';
import { FuseMaterialIconPickerComponent } from '@fuse/components/material-icon-picker/material-icon-picker.component';
import {MaterialIconPickerDialogComponent} from "./material-icon-picker-dialog.component";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatRippleModule} from "@angular/material/core";

@NgModule({
    declarations: [
        FuseMaterialIconPickerComponent,
        MaterialIconPickerDialogComponent
    ],
    imports: [
        CommonModule,

        FlexLayoutModule,

        MatButtonModule,
        MatIconModule,
        //MatMenuModule,
        MatRippleModule,
        MatGridListModule,
        //MatProgressBarModule,
        FusePipesModule
    ],
    exports: [
        FuseMaterialIconPickerComponent,
        MaterialIconPickerDialogComponent
    ], 
    entryComponents: [
        MaterialIconPickerDialogComponent
    ]
})
export class FuseMaterialIconPickerModule
{
}
