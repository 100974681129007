export abstract class NotifyService {
    protected constructor() {}

    abstract info(message: string, title?: string, options?: any): void;

    abstract success(message: string, title?: string, options?: any): void;

    abstract warn(message: string, title?: string, options?: any): void;

    abstract error(message: string, title?: string, options?: any): void;
}
