import { Component, OnDestroy, OnInit } from "@angular/core";
import { FuseProgressBarService } from "@fuse/components/progress-bar/progress-bar.service";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { finalize } from "rxjs/operators";
import { ExecuteActivityRequest, GetActivityItemResponse, PatientClient } from "../../../api";
import { FuseConfigService } from "@fuse/services/config.service";
import { HealthEngageCommunicationService } from "../../../health-engage-communication.service";

declare global {
    interface Window {
        MobileApi: any;
    }
}

@Component({
    selector: "activity-collect",
    templateUrl: "./activity-collect.component.html",
})
export class ActivityCollectComponent implements OnInit, OnDestroy {
    itemId: number;

    timestamp: number;

    value: any = {};

    comments: string;

    title: string;

    description: string;

    constructor(
        private activeRoute: ActivatedRoute,
        private _router: Router,
        private _progressBarService: FuseProgressBarService,
        private _api: PatientClient,
        private _fuseConfigService: FuseConfigService,
        private _healthEngageCommunicationService: HealthEngageCommunicationService
    ) {
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true,
                },
                toolbar: {
                    hidden: true,
                },
                footer: {
                    hidden: true,
                },
                sidepanel: {
                    hidden: true,
                },
            },
        };
    }

    ngOnInit(): void {
        this._progressBarService.show();
        this._healthEngageCommunicationService.emitChatVisibility(false);
        this.activeRoute.paramMap.subscribe((params: ParamMap) => {
            this.itemId = Number(params.get("itemId"));
            this.timestamp = Number(params.get("timestamp"));
            this._api
                .getActivity(this.itemId, this.timestamp)
                .pipe(
                    finalize(() => {
                        this._progressBarService.hide();
                    })
                )
                .subscribe((result: GetActivityItemResponse) => {
                    if (!result.allowAnswer) {
                        window.MobileApi?.postMessage("[form] locked");
                        this._router
                            .navigate(["locked"], {
                                relativeTo: this.activeRoute,
                                skipLocationChange: false,
                                replaceUrl: true,
                            })
                            .then();
                    }
                    this.title = result.name;
                    this.description = result.description;
                });
        });
    }

    ngOnDestroy(): void {
        this._healthEngageCommunicationService.emitChatVisibility(true);
    }

    onCancel() {
        window.MobileApi?.postMessage("[form] canceled");
        this._router
            .navigate(["canceled"], { relativeTo: this.activeRoute, skipLocationChange: false, replaceUrl: true })
            .then();
    }

    save() {
        let request: ExecuteActivityRequest = {
            comments: this.comments ?? "",
        };
        this._progressBarService.show();
        this._api
            .executeActivityItem(this.itemId, this.timestamp, request)
            .pipe(
                finalize(() => {
                    this._progressBarService.hide();
                })
            )
            .subscribe((responseId) => {
                window.MobileApi?.postMessage(`[form] completed:${responseId}`);
                this._router
                    .navigate(["completed"], {
                        relativeTo: this.activeRoute,
                        skipLocationChange: false,
                        replaceUrl: true,
                    })
                    .then();
            });
    }
}
