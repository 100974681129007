import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { selectPatientId, selectIsLoading, selectAtLeastOneExists, selectAll, selectIsCreating } from "./selectors";
import { FeedState } from "./model";
import { Protocol, ProtocolItemDefinition, ProtocolDefinition } from "../../model";
import * as actions from "./actions";
import { take } from "rxjs/operators";

@Injectable()
export class FeedFacade {
    loading$: Observable<boolean> = this._store.select(selectIsLoading);

    datasource$: Observable<Protocol[]> = this._store.select(selectAll);

    isThereAnyData$: Observable<boolean> = this._store.select(selectAtLeastOneExists);

    patientId$: Observable<string | undefined> = this._store.select(selectPatientId);

    creating$: Observable<boolean> = this._store.select(selectIsCreating);

    constructor(private _store: Store<FeedState>) { }

    load(patientId: string) {
        this._store.dispatch(
            actions.loadFeedAction.execute({
                patientId: patientId,
            })
        );
    }

    create(name: string, activities: ProtocolItemDefinition[], sign: boolean) {
        this.patientId$.pipe(take(1)).subscribe((patientId: string) => {
            this._store.dispatch(
                actions.createProtocolAction.execute({
                    patientId: patientId,
                    name: name,
                    activities: activities,
                    sign: sign,
                })
            );
        });
    }

    downloadDocument(protocol: Protocol, name: string | undefined) {
        this._store.dispatch(
            actions.downloadProtocolDocumentAction.execute({
                id: protocol.id,
                name: name,
            })
        );
    }

    printDocument(protocol: Protocol) {
        this._store.dispatch(
            actions.printProtocolDocumentAction.execute({
                id: protocol.id,
            })
        );
    }

    showDocument(protocol: Protocol) {
        this._store.dispatch(
            actions.showProtocolDocumentAction.execute({
                id: protocol.id,
            })
        );
    }

    delete(id: number) {
        this._store.dispatch(
            actions.deleteProtocolAction.execute({
                id: id,
            })
        );
    }

    createTemplate(folderId: number, template: ProtocolDefinition) {
        this._store.dispatch(
            actions.createProtocolTemplateAction.execute({
                parentFolderId: folderId,
                template,
            })
        );
    }

    sendDocumentByEmail(id: number) {
        this._store.dispatch(
            actions.sendProtocolDocumentByEmailAction.execute({
                id: id,
            })
        );
    }
}
