// "Production" enabled environment
export const environment = {
    production: true,
    hmr: false,
    api_url: "",
    instrumentationKey: "80a42b99-e879-48dd-9de2-2311f3ca3238",
    vforms_url: "https://app.vsaude.com.br",
    vadmin_url: "https://app.vsaude.com.br/v2",
    supportChatEnabled: true,
    wootricAccountToken: "NPS-e9e040ff",
    gtmId: "GTM-K4LHFP5",
    heatmapKey: "g1qgh2jyt5",
    z_url: 'https://api.zoop.ws',
    z_marketplace: '4680b7a017af4cc4be446a3e49987d36',
    z_key: 'zpk_prod_HK573NxTE9YV0abCLhJyCXu2',
    blob_url: 'https://stvsaudeprd.blob.core.windows.net',
    statistics_api_url: "",
};