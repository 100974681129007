import {createReducer, on} from "@ngrx/store";
import {actions, IDocumentStatState} from ".";

export const STATS_DOCUMENTS_FEATURE_KEY = "stats.documents";

export const initialStatsState: IDocumentStatState = {
    prescription: undefined,
    prescriptionHistory: [],
    loading: false,
};

export const getDocumentStatsReducer = createReducer(
    initialStatsState,
    on(actions.getStats, (state) => ({
        ...state,
        loading: true,
    })),
    on(actions.getStatsComplete, (state, {prescription}) => {
        return {
            ...state,
            prescription: prescription,
            loading: false,
        };
    }),
    on(actions.getStatsError, (state, {error}) => ({
        ...state,
        loading: false,
    })),

    on(actions.getPrescriptionHistory, (state) => ({
        ...state,
        loading: true,
    })),
    on(actions.getPrescriptionHistoryComplete, (state, {prescriptionHistory}) => {
        return {
            ...state,
            prescriptionHistory: prescriptionHistory,
            loading: false,
        };
    }),
    on(actions.getPrescriptionHistoryError, (state, {error}) => ({
        ...state,
        loading: false,
    })),
);
