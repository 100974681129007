import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from "@angular/core";
import { SmartFormPoolFacade } from "../store/facade";
import { Observable, ReplaySubject, Subject, combineLatest } from "rxjs";
import { SmartFormDefinition, SmartFormFolderFlatNode, SmartFormFolderNode } from "@vhealth/engage";
import { SmartFormFolder } from "../store";
import { take, takeUntil } from "rxjs/operators";
import { FormControl, FormGroup } from "@angular/forms";
import { FlatTreeControl } from "@angular/cdk/tree";
import { MatTreeFlatDataSource, MatTreeFlattener } from "@angular/material/tree";

@Component({
    selector: "smart-form-picker",
    templateUrl: "./smart-form-picker.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmartFormPickerComponent implements OnInit, OnDestroy {
    private _unsubscribeAll = new Subject();

    filteredFolders$: ReplaySubject<SmartFormFolder[]> = new ReplaySubject<SmartFormFolder[]>(1);

    filteredForms$: ReplaySubject<SmartFormDefinition[]> = new ReplaySubject<SmartFormDefinition[]>(1);

    smartFormFolders: SmartFormFolderNode[] = [];

    flatNodeMap: Map<SmartFormFolderFlatNode, SmartFormFolderNode> = new Map();

    nestedNodeMap: Map<string, SmartFormFolderFlatNode> = new Map();

    searchForm: FormGroup = new FormGroup({
        text: new FormControl(""),
    });

    private _transformer = (node: SmartFormFolderNode, level) => {
        const flatNode = new SmartFormFolderFlatNode();
        flatNode.level = level;
        flatNode.expandable = node.children && node.children?.length > 0;
        flatNode.title = node.title;
        flatNode.parentId = node.parentId;
        flatNode.data = node.data;
        this.flatNodeMap.set(flatNode, node);
        this.nestedNodeMap.set(node.title, flatNode);
        return flatNode;
    };

    treeControl = new FlatTreeControl<SmartFormFolderFlatNode>(
        (node) => node.level,
        (node) => node.expandable
    );

    treeFlattener = new MatTreeFlattener(
        this._transformer,
        (node) => node.level,
        (node) => node.expandable,
        (node) => node.children
    );

    dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    hasChild = (_: number, node: SmartFormFolderFlatNode) => node.expandable;

    isLoading$: Observable<boolean>;

    folders$: Observable<SmartFormFolder[]>;

    forms$: Observable<SmartFormDefinition[]>;

    selectedFolder$: Observable<SmartFormFolder>;

    selectedFolder: SmartFormFolder;

    isThereAnyData$: Observable<boolean>;

    @Input() selected: SmartFormDefinition;

    @Output() onSelected = new EventEmitter<SmartFormDefinition>();

    constructor(private _facade: SmartFormPoolFacade, private _changeDetector: ChangeDetectorRef) {
        this.folders$ = this._facade.folders$;
        this.selectedFolder$ = this._facade.selected$;
        this.forms$ = this._facade.selectedFolderForms$;
        this.isThereAnyData$ = this._facade._isThereAnyData$;
        this.isLoading$ = this._facade.loading$;
        this._facade.root();
    }

    ngOnInit(): void {
        this.selectedFolder$.pipe(takeUntil(this._unsubscribeAll)).subscribe((selected) => {
            this.selectedFolder = selected;
            if (selected) {
                this._facade.loadSmartFormAction(selected.id);
                this.onSelected.emit(null);
            }
        });

        this.filteredFolders$.pipe(takeUntil(this._unsubscribeAll)).subscribe((folders) => {
            this.smartFormFolders = folders.map((folder) => {
                return new SmartFormFolderNode(folder, folder.parentFolderId ?? 0);
            });
            this.dataSource.data = this.smartFormFolders;
            this.treeControl.expandAll();
            this._changeDetector.markForCheck();
        });

        this.folders$.pipe(takeUntil(this._unsubscribeAll)).subscribe((folders) => {
            this.filteredFolders$.next(folders);
        });

        this.forms$.pipe(takeUntil(this._unsubscribeAll)).subscribe((forms) => {
            this.filteredForms$.next(forms);
        });

        this.searchForm
            .get("text")
            .valueChanges.pipe(takeUntil(this._unsubscribeAll))
            .subscribe((text) => {
                this.applyFilter(text.toLowerCase());
            });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    select(folder: SmartFormFolder) {
        this._facade.select(folder.id);
    }

    goBack() {
        this._facade.goBack();
    }

    selectFormDefinition(event: any, definition: SmartFormDefinition) {
        event.stopPropagation();
        this.onSelected.emit(definition);
    }

    applyFilter(text: string) {
        combineLatest([this.folders$, this.forms$])
            .pipe(take(1))
            .subscribe(([folders, forms]) => {
                this.filteredFolders$.next(folders);

                let filteredForms = forms.filter((form) => form.name.toLowerCase().includes(text));
                this.filteredForms$.next(filteredForms);

                let keepSelected: boolean = filteredForms.length > 0;
                let filteredFolders = this.filterFoldersByName(folders, text, keepSelected);
                this.filteredFolders$.next(filteredFolders);
            });
    }

    filterFoldersByName(folders: SmartFormFolder[], searchText: string, keepSelected: boolean) {
        let filteredFolders = [];
        folders.forEach((folder) => {
            if (
                folder.name.toLowerCase().includes(searchText) ||
                (keepSelected && folder.id === this.selectedFolder?.id)
            ) {
                filteredFolders.push(folder);
            } else if (folder.folders) {
                let filteredSubFolders = this.filterFoldersByName(folder.folders, searchText, keepSelected);
                if (filteredSubFolders.length > 0) {
                    let filteredFolder = Object.assign({}, folder);
                    filteredFolder.folders = filteredSubFolders;
                    filteredFolders.push(filteredFolder);
                }
            }
        });
        return filteredFolders;
    }
}
