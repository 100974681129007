import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { TranslocoModule } from "@ngneat/transloco";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { StoreModule } from "@ngrx/store";
import * as fromStore from "./store/index";
import { EffectsModule } from "@ngrx/effects";
import { MatButtonModule } from "@angular/material/button";
import { MatDividerModule } from "@angular/material/divider";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatOptionModule } from "@angular/material/core";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { VuiModule } from "@vui";
import { MatDialogModule } from "@angular/material/dialog";
import { UiDialogModule } from "@ui/ui-dialog";
import { MatInputModule } from "@angular/material/input";
import { A11yModule } from "@angular/cdk/a11y";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { ButtonToggleModule, FuseScrollbarModule } from "@nx-workspace/fuse";
import { MatMenuModule } from "@angular/material/menu";
import { RecurrencePickerModule, DateFormFieldModule } from "@vui";
import { AbpHttpInterceptor } from "@api";
import { MatTreeModule } from "@angular/material/tree";
import { CreateFolderComponent } from "./create-folder/create-folder.component";
import { EditFolderComponent } from "./edit-folder/edit-folder.component";
import { ProtocolPickerDialogComponent } from "./protocol-picker/protocol-form-template-picker-dialog.component";
import { ProtocolPickerComponent } from "./protocol-picker/protocol-template-picker.component";
import { MoveFolderDialogComponent } from "./folder-picker/move-folder-dialog/move-folder-dialog.component";
import { FolderPickerComponent } from "./folder-picker/protocol-folder-picker.component";
import { CreateProtocolComponent } from "./create-template/create-template.component";
import { EditProtocolComponent } from "./edit-template/edit-template.component";
import { ProtocolFolderClient } from "../api";
import { FormPoolSharedModule } from "../form-pool/form-pool-shared.module";
import { ReminderPoolSharedModule } from "../reminder-pool/reminder-pool-shared.module";
import { FolderPickerDialogComponent } from "./folder-picker/folder-picker-dialog.component";
import { CommonModule } from "@angular/common";
import { MatTooltipModule } from "@angular/material/tooltip";

@NgModule({
    declarations: [
        ProtocolPickerDialogComponent,
        ProtocolPickerComponent,
        CreateFolderComponent,
        EditFolderComponent,
        MoveFolderDialogComponent,
        FolderPickerDialogComponent,
        FolderPickerComponent,
        CreateProtocolComponent,
        EditProtocolComponent,
    ],
    imports: [
        CommonModule,
        StoreModule.forFeature(fromStore.PROTOCOL_FEATURE_KEY, fromStore.getProtocolsReducer),
        EffectsModule.forFeature([fromStore.ProtocolsEffects]),

        FormPoolSharedModule,
        ReminderPoolSharedModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        TranslocoModule,

        MatButtonModule,
        MatDividerModule,
        MatFormFieldModule,
        MatIconModule,
        MatOptionModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatSelectModule,
        MatSidenavModule,
        MatSortModule,
        MatTableModule,
        MatTreeModule,
        MatDialogModule,
        MatInputModule,
        MatCheckboxModule,
        MatTooltipModule,
        UiDialogModule,
        A11yModule,
        ButtonToggleModule,
        MatMenuModule,
        FuseScrollbarModule,
        VuiModule,
        DateFormFieldModule,
        RecurrencePickerModule,
    ],
    providers: [ProtocolFolderClient, { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true }],
    exports: [
        ProtocolPickerDialogComponent,
        ProtocolPickerComponent,
        CreateFolderComponent,
        EditFolderComponent,
        MoveFolderDialogComponent,
        FolderPickerDialogComponent,
        FolderPickerComponent,
        CreateProtocolComponent,
        EditProtocolComponent,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ProtocolPoolSharedModule {}
