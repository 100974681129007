import { APP_INITIALIZER, LOCALE_ID, NgModule, CUSTOM_ELEMENTS_SCHEMA, DEFAULT_CURRENCY_CODE } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { RouterModule, Routes } from "@angular/router";
import "hammerjs";
import { SharedModule } from "@shared/shared.module";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FuseProgressBarModule, FuseSidebarModule } from "@fuse/components";
import { LayoutModule } from "./layout/layout.module";
import { FuseSharedModule } from "@fuse/shared.module";
import { API_BASE_URL } from "@shared/service-proxies/service-proxies";
import { FuseModule } from "@fuse/fuse.module";
import { fuseConfig } from "./fuse-config";
import { ServiceProxyModule } from "@shared/service-proxies/service-proxy.module";
import { TranslateModule } from "@ngx-translate/core";
import { AppConsts } from "@shared/AppConsts";
import { AppComponent } from "app/app.component";
import localePt from "@angular/common/locales/pt";
import { registerLocaleData } from "@angular/common";
import { PasswordMatchValidatorDirective } from "@app/wapps/core/password.match.directive";
import { AgmCoreModule, LAZY_MAPS_API_CONFIG } from "@agm/core";
import { MatGoogleMapsAutocompleteModule } from "@angular-material-extensions/google-maps-autocomplete";
import { ImageCropperModule } from "ngx-image-cropper";
import { NotificationService } from "./wapps/notification/notification.service";
import { MomentModule } from "ngx-moment";
import { NgxDnDModule } from "@swimlane/ngx-dnd";
import { FacebookPixelService } from "@app/FacebookPixelService";
import { MapsApiLoaderService } from "@app/wapps/core/maps-api-loader.service";
import { AbpModule } from "abp-ng2-module";
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { AppInitializer } from "../app-initializer";
import { NotificationModule } from "@app/wapps/core/notification/notification.module";
import { CommonModule } from "@angular/common";
import { IMaskModule } from "angular-imask";
import { UsersService } from "./wapps/system/users/users.service";
import { UrlHistoryService } from "./wapps/core/url-history.service";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { ReactiveFormsModule } from "@angular/forms";
import { FormlyModule } from "@ngx-formly/core";
import { FormlyMaterialModule } from "@ngx-formly/material";
import { PendingTermsOfServiceSignatureModule } from "@app/wapps/legal/pending-terms-of-service/pending-terms-of-service-signature.module";
import { InsightsModule } from "@app/monitoring.module";
import { AudioService } from "@app/audio.service";
import { environment } from "../environments/environment";
import { GoogleTagManagerModule } from "angular-google-tag-manager";
import { HtmlInjectorService } from "@app/HtmlInjectorService";
import { MomentLocaleService } from "@app/moment-locale.service";
import { TranslocoCoreModule } from "@i18n";
import { IconsModule } from "../../../vadmin/src/app/core/icons/icons.module";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { StoreRouterConnectingModule } from "@ngrx/router-store";
import { FuseConfigModule, FuseScrollbarModule } from "@nx-workspace/fuse";
import { FuseModule as FuseV2Module } from "@nx-workspace/fuse";
import { UiNotifyModule } from "@ui/notify";
import { STATISTICS_API_BASE_URL } from "@dashboard-statistics";
import { LEGACY_API_BASE_URL, SESSION_COOKIE_NAME } from "@api";
import { TourMatMenuModule } from "ngx-ui-tour-md-menu"
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatIconModule } from "@angular/material/icon";
import { SMART_FORMS_API_BASE_URL } from "@vhealth/engage";
import { FORMS_BUILDER_URL } from "@vhealth/vforms-wrapper";

export const DD_MM_YYYY_Format = {
    parse: {
        dateInput: "LL",
    },
    display: {
        dateInput: "DD/MM/YYYY",
        monthYearLabel: "MMM YYYY",
        dateA11yLabel: "LL",
        monthYearA11yLabel: "MMMM YYYY",
    },
};

const appRoutes: Routes = [
    {
        path: "",
        loadChildren: () => import("./wapps/wapps.module").then((m) => m.WappsModule),
        data: {preload: true},
    },
];

export function getRemoteServiceBaseUrl(): string {
    AppConsts.appBaseUrl = location.origin;
    let apiUrl = environment.api_url;

    if (apiUrl?.length === 0) {
        if (!document.location.origin) {
            const port = document.location.port ? ":" + document.location.port : "";
            apiUrl = document.location.protocol + "//" + document.location.hostname + port;
        } else {
            apiUrl = document.location.origin;
        }
    }

    AppConsts.remoteServiceBaseUrl = apiUrl;

    return AppConsts.remoteServiceBaseUrl;
}

export function getCurrentLanguage(): string {
    return abp.localization.currentLanguage.name;
}

export function getRemoteStatisticsApiBaseUrl(): string {
    let baseUrl = "";
    if (environment.statistics_api_url !== undefined) {
        baseUrl = environment.statistics_api_url;
    } else {
        if (!document.location.origin) {
            const port = document.location.port ? ":" + document.location.port : "";
            baseUrl = document.location.protocol + "//" + document.location.hostname + port;
        } else {
            baseUrl = document.location.origin;
        }
    }
    return baseUrl;
}

registerLocaleData(localePt);

@NgModule({
    declarations: [AppComponent, PasswordMatchValidatorDirective],
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, {enableTracing: false}),
        NgxDnDModule.forRoot(),
        NotificationModule,
        TranslateModule.forRoot(),
        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        LayoutModule,

        //abp
        SharedModule.forRoot(),
        AbpModule,
        ServiceProxyModule,
        ImageCropperModule,

        AgmCoreModule.forRoot(),
        MatGoogleMapsAutocompleteModule,
        MomentModule,

        IMaskModule,

        ReactiveFormsModule,
        FormlyMaterialModule,
        FormlyModule.forRoot({
            validationMessages: [{name: "required", message: "This field is required"}],
        }),
        PendingTermsOfServiceSignatureModule,

        InsightsModule,

        GoogleTagManagerModule.forRoot({
            id: environment.gtmId
        }),

        FuseV2Module,
        FuseConfigModule.forRoot({
            layout: "dense",
            scheme: "light",
            theme: "default",
        }),
        FuseScrollbarModule,

        //vadmin's CoreModule
        IconsModule, TranslocoCoreModule, UiNotifyModule,

        // 3rd party modules that require global configuration via forRoot
        StoreModule.forRoot({}),
        EffectsModule.forRoot([]),
        !environment.production ? StoreDevtoolsModule.instrument() : [],
        StoreRouterConnectingModule.forRoot(),

        TourMatMenuModule.forRoot(),
        MatButtonModule, MatCardModule, MatIconModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    entryComponents: [],
    exports: [RouterModule],
    providers: [
        UrlHistoryService,
        UsersService,
        NotificationService,
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {appearance: "fill", floatLabel: "always"},
        },
        {provide: DEFAULT_CURRENCY_CODE, useValue: "R$"},
        {provide: MAT_DATE_LOCALE, useValue: "pt-BR"},
        {provide: MAT_DATE_FORMATS, useValue: DD_MM_YYYY_Format},
        {provide: API_BASE_URL, useFactory: getRemoteServiceBaseUrl},
        {
            provide: APP_INITIALIZER,
            useFactory: (appInitializer: AppInitializer) => appInitializer.init(),
            deps: [AppInitializer],
            multi: true,
        },
        {
            provide: LOCALE_ID,
            useFactory: getCurrentLanguage,
        },
        {provide: LAZY_MAPS_API_CONFIG, useClass: MapsApiLoaderService},
        FacebookPixelService,
        AudioService,
        HtmlInjectorService,
        MomentLocaleService,
        {provide: STATISTICS_API_BASE_URL, useFactory: getRemoteStatisticsApiBaseUrl},
        {provide: SESSION_COOKIE_NAME, useValue: "Abp.AuthToken"},
        {provide: LEGACY_API_BASE_URL, useFactory: getRemoteServiceBaseUrl},
        {provide: SMART_FORMS_API_BASE_URL, useFactory: getRemoteServiceBaseUrl},
        {provide: FORMS_BUILDER_URL, useValue: environment.vforms_url},
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor() {
    }
}
