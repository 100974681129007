import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Component, OnInit, ViewEncapsulation, OnDestroy, Input, Inject } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { ProtocolFolder } from "../store";
import { ProtocolFacade } from "../store/facade";
import { ProtocolDefinition } from "../../model";
import { FuseMediaWatcherService } from "@nx-workspace/fuse";
import { takeUntil } from "rxjs/operators";

@Component({
    selector: "protocol-template-picker-dialog",
    templateUrl: "./protocol-form-template-picker-dialog.component.html",
    encapsulation: ViewEncapsulation.None,
})
export class ProtocolPickerDialogComponent implements OnInit, OnDestroy {
    private _unsubscribeAll = new Subject();

    @Input()
    classes: string[] = ["md:w-60vw", "lg:w-50vw", "xl:w-40vw"];

    stack$: Observable<ProtocolFolder[]>;

    current$: Observable<ProtocolFolder>;

    selected: ProtocolDefinition;

    constructor(
        private _dialogRef: MatDialogRef<ProtocolPickerDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _facade: ProtocolFacade,
        private _fuseMediaWatcherService: FuseMediaWatcherService
    ) {
        this._dialogRef.addPanelClass(["sm-full-screen", "dialog-v2"]);
        this.stack$ = this._facade.folders$;
        this.current$ = this._facade.current$;
    }

    ngOnInit() {
        let position: any = {
            top: `${this._data.top}px`,
            right: `${window.innerWidth - Number(this._data.right)}px`,
        };

        if (this._data.left) {
            position = {
                top: `${this._data.top}px`,
                left: `${Number(this._data.left)}px`,
            };
        }

        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {
                if (matchingAliases.includes("md")) {
                    this._dialogRef.updatePosition(position);
                } else {
                    this._dialogRef.updatePosition({ top: "0", right: "0" });
                }
            });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    goBack() {
        this._facade.goBack();
    }

    save() {
        this._dialogRef.close(this.selected);
    }

    closeDialog() {
        this._dialogRef.close();
    }

    handleSelection(protocol: ProtocolDefinition) {
        this.selected = protocol;
    }
}
