import {HttpClient} from '@angular/common/http';
import {Component, Inject, Input, OnInit} from '@angular/core';
import {Direction, Message, MessageState} from "@ngx-chat/lib/core/message";
import {Contact} from "@ngx-chat/lib/core/contact";
import {MessageStatePlugin, StateDate} from "@ngx-chat/lib/services/adapters/xmpp/plugins/message-state.plugin";
import {ChatService, ChatServiceToken} from "@ngx-chat/lib/services/chat-service";
import {XmppChatAdapter} from "@ngx-chat/lib/services/adapters/xmpp/xmpp-chat-adapter.service";
import {extractUrls} from "@ngx-chat/lib/core/utils-links";
import {DateMessagesGroup} from "../../../../../@ngx-chat/lib/core/message-store";
import {DatePipe} from "@angular/common";

export const MAX_IMAGE_SIZE = 250 * 1024;

@Component({
    selector: 'c360-chat-message',
    templateUrl: './chat-message.component.html',
    styleUrls: ['./chat-message.component.scss']
})
export class C360ChatMessageComponent implements OnInit {

    @Input()
    showAvatars: boolean;

    @Input()
    avatar?: string;

    @Input()
    group: DateMessagesGroup;
    
    @Input()
    message: Message;

    @Input()
    nick: string;

    @Input()
    contact: Contact;
    
    index: number;

    imageLink: string;

    MessageState = MessageState;

    messageStatePlugin: MessageStatePlugin;

    constructor(
        @Inject(ChatServiceToken) public chatService: ChatService,
        private httpClient: HttpClient, public datepipe: DatePipe) {
        this.messageStatePlugin = this.chatService.getPlugin(MessageStatePlugin);
    }

    ngOnInit() {
        this.tryFindImageLink();
        this.index = this.group.messages.findIndex(m => m.id === this.message.id);
    }

    private async tryFindImageLink() {
        if (this.chatService instanceof XmppChatAdapter) {
            for (const url of extractUrls(this.message.body)) {
                try {
                    const headRequest = await this.httpClient.head(url, {observe: 'response'}).toPromise();
                    const contentType = headRequest.headers.get('Content-Type');
                    const isImage = contentType && contentType.startsWith('image');
                    const contentLength = headRequest.headers.get('Content-Length');
                    if (isImage && parseInt(contentLength, 10) < MAX_IMAGE_SIZE) {
                        this.imageLink = url;
                        break;
                    }
                } catch (e) {
                }
            }
        }
    }

    getMessageState() {
        if (this.message.state) {
            return this.message.state;
        } else if (this.messageStatePlugin && this.contact) {
            const date = this.message.datetime;
            const states = this.messageStatePlugin.getContactMessageState(this.contact.jidBare.toString());
            return this.getStateForDate(date, states);
        }
    }

    private getStateForDate(date: Date, states: StateDate) {
        if (date <= states.lastRecipientSeen) {
            return MessageState.RECIPIENT_SEEN;
        } else if (date <= states.lastRecipientReceived) {
            return MessageState.RECIPIENT_RECEIVED;
        } else if (date <= states.lastSent) {
            return MessageState.SENT;
        }
    }

    /**
     * Decide whether to show or not the contact's avatar in the message row
     *
     * @param message
     * @param i
     * @returns {boolean}
     */
    get shouldShowContactAvatar(): boolean {
        let previous = this.isFirstMessageOfGroup ? null : this.group.messages[this.index - 1];
        return this.showAvatars && this.message.direction === Direction.in && (previous === null || previous.direction === Direction.out);
        // return (
        //     this.selectedContact !== null && message.sender === this.selectedContact.id && ((this.thread.messages[i + 1] && this.thread.messages[i + 1].sender !== this.selectedContact.id) || !this.thread.messages[i + 1])
        // );
    }

    /**
     * Check if the given message is the first message of a group
     *
     * @param message
     * @param i
     * @returns {boolean}
     */
    get isFirstMessageOfGroup(): boolean {
        let previous = this.index === 0 ? null : this.group.messages[this.index - 1];
        return previous === null || this.message.direction !== previous.direction;
        // return (i === 0 || this.thread.messages[i - 1] && this.thread.messages[i - 1].sender !== message.sender);
    }

    /**
     * Check if the given message is the last message of a group
     *
     * @param message
     * @param i
     * @returns {boolean}
     */
    get isLastMessageOfGroup(): boolean {
        let next = this.index === this.group.messages.length - 1 ? null : this.group.messages[this.index + 1];
        return next === null || this.message.direction !== next.direction;
        // return (i === this.thread.messages.length - 1 || this.thread.messages[i + 1] && this.thread.messages[i + 1].sender !== message.sender);
    }
}
