import {Injectable, NgZone} from "@angular/core";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";

@Injectable({ 
    providedIn: "root" 
})
export class NotificationService {
    constructor(
        private readonly snackBar: MatSnackBar,
        private readonly zone: NgZone) {
        (window as any)._notificationService = this;
    }
    
    default(message: string) {
        this.show(
            message,
            {
                duration: 2000,
                panelClass: "default-notification-overlay",
            }
        );
    }

    public info(message: string) {
        this.show(
            message,
            {
                duration: 2000,
                panelClass: "info-notification-overlay",
            }
        );
    }

    success(message: string) {
        this.show(
            message,
            {
                duration: 2000,
                panelClass: "success-notification-overlay",
            }
        );
    }

    warn(message: string) {
        this.show(
            message,
            {
                duration: 2500,
                panelClass: "warning-notification-overlay",
            }
        );
    }

    error(message: string) {
        this.show(
            message,
            {
                duration: 3000,
                panelClass: "error-notification-overlay",
            }
        );
    }

    private show(
        message: string,
        configuration: MatSnackBarConfig) {
        if(configuration.horizontalPosition === undefined) {
            configuration.horizontalPosition = "right";
        }
        if(configuration.verticalPosition === undefined) {
            configuration.verticalPosition = "bottom";
        }
        // Need to open snackBar from Angular zone to prevent issues with its position per
        // https://stackoverflow.com/questions/50101912/snackbar-position-wrong-when-use-errorhandler-in-angular-5-and-material
        this.zone.run(() => this.snackBar.open(message, null, configuration));
    }
}