import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import {
    Component,
    OnInit,
    ViewEncapsulation,
    OnDestroy,
    ChangeDetectorRef,
    ViewChild,
    ElementRef,
    Inject,
    Optional,
} from "@angular/core";
import { Observable, of, Subject } from "rxjs";
import { delay, takeUntil } from "rxjs/operators";
import { Actions, ofType } from "@ngrx/effects";
import { NotifyService } from "@ui/notify";
import { FormBuilder, FormGroup } from "@angular/forms";
import { createProtocolAction } from "../store/actions";
import { CreateProtocolComponentStore } from "./create-template-component-store";
import { assign } from "lodash";
import { RecurrencePickerDialogComponent, RecurrenceHelper, VuiValidators } from "@vui";
import { FuseConfirmationService } from "@nx-workspace/fuse";
import { TranslocoService } from "@ngneat/transloco";
import { ActivityItemDefinition, FormItemDefinition, ProtocolDefinition } from "../../model";
import { SmartFormPickerDialogComponent } from "../../form-pool/form-definition-picker/smart-form-picker-dialog.component";
import { ReminderPickerDialogComponent } from "../../reminder-pool/reminder-picker/reminder-picker-dialog.component";
import { ActivityItemHistoryDto, DocumentStatus, FeedStatus } from "../../api";
import { FeedFacade } from "../store/facade";

@Component({
    selector: "create-template",
    templateUrl: "./create-template.component.html",
    encapsulation: ViewEncapsulation.None,
    providers: [CreateProtocolComponentStore],
})
export class CreateProtocolComponent implements OnInit, OnDestroy {
    private _unsubscribeAll = new Subject();

    isCreating$: Observable<boolean>;

    form: FormGroup;

    formItems$ = this._store.formItems$;

    activityItems$ = this._store.activityItems$;

    readonly validProtocols$ = this._store.isValid$;

    readonly editing$ = this._store.editing$;

    readonly editingItem$ = this._store.editingItem$;

    readonly editingForm$ = this._store.editingForm$;

    currentActivityForm: FormGroup;

    @ViewChild("activityTitle", { read: ElementRef }) activityTitleInput: ElementRef;

    feedStatuses = [FeedStatus.Visible, FeedStatus.PatientHidden, FeedStatus.Hidden];

    constructor(
        private _matDialog: MatDialog,
        private _changeDetector: ChangeDetectorRef,
        private _dialogRef: MatDialogRef<CreateProtocolComponent>,
        private _formBuilder: FormBuilder,
        private _facade: FeedFacade,
        private actions$: Actions,
        private _notifyService: NotifyService,
        private _store: CreateProtocolComponentStore,
        private _translateService: TranslocoService,
        private _confirmationService: FuseConfirmationService,
        @Inject(MAT_DIALOG_DATA) @Optional() private _data?: ProtocolDefinition
    ) {
        this.isCreating$ = this._facade.creating$;

        this.form = this._formBuilder.group({
            name: [null, VuiValidators.required],
            isPublic: [true],
            sign: [true],
        });

        this.currentActivityForm = this._formBuilder.group({
            id: [null, VuiValidators.required],
            title: [null, VuiValidators.required],
            activityId: [null],
            formId: [null],
            formName: [null],
            comments: [null],
            recurrence: [null],
            feedStatus: [FeedStatus.Visible, VuiValidators.required],
            documentStatus: [DocumentStatus.Visible, VuiValidators.required],
        });
    }

    ngOnInit() {
        this.actions$.pipe(ofType(createProtocolAction.complete), takeUntil(this._unsubscribeAll)).subscribe(() => {
            this._notifyService.success(
                this._translateService.translate("health.engage.protocol-pool.create-protocol.successfully-message")
            );
            this._dialogRef.close();
        });

        this._store.editingItem$.pipe(takeUntil(this._unsubscribeAll)).subscribe((activity: ActivityItemDefinition) => {
            if (activity) {
                this.currentActivityForm.patchValue({
                    ...activity,
                    id: this._store.currentId,
                });
                //Focus on the title element
                of(true)
                    .pipe(delay(150))
                    .subscribe(() => {
                        this.activityTitleInput.nativeElement.focus();
                    });
            } else {
                this.currentActivityForm.reset();
                this.currentActivityForm.patchValue({ delivered: true });
            }
            this._changeDetector.detectChanges();
        });

        this._store.editingForm$.pipe(takeUntil(this._unsubscribeAll)).subscribe((activity: FormItemDefinition) => {
            if (activity) {
                this.currentActivityForm.patchValue({
                    ...activity,
                    formId: activity.form?.id,
                    formName: activity.form?.name,
                    id: this._store.currentId,
                });

                //Focus on the title element
                of(true)
                    .pipe(delay(150))
                    .subscribe(() => {
                        this.activityTitleInput.nativeElement.focus();
                    });
            } else {
                this.currentActivityForm.reset();
                this.currentActivityForm.patchValue({ delivered: true });
            }
            this._changeDetector.detectChanges();
        });

        if (this._data?.id) {
            this.form.patchValue({
                name: this._data.name,
                isPublic: this._data.isPublic,
            });

            for (const activity of this._data?.items) {
                switch (activity.discriminator) {
                    case "FormItemDefinitionDto":
                    case "FormItemDto":
                        let formItem = activity as FormItemDefinition;
                        this._store.addForm(formItem);
                        this.currentActivityForm.patchValue({
                            id: this._store.currentId,
                            title: formItem.title,
                            comments: formItem.comments,
                            formId: formItem.form.id,
                            formName: formItem.form.name,
                            recurrence: formItem.recurrence,
                            documentStatus: formItem.documentStatus,
                        });
                        this.acceptFormChanges(this.currentActivityForm.getRawValue());
                        break;
                    case "ActivityItemDefinitionDto":
                    case "ActivityItemDto":
                        let activityItem = activity as ActivityItemDefinition;
                        this._store.addActivity(activityItem);
                        this.currentActivityForm.patchValue({
                            id: this._store.currentId,
                            title: activityItem.title,
                            comments: activityItem.comments,
                            recurrence: activityItem.recurrence,
                            documentStatus: activityItem.documentStatus,
                        });
                        this.acceptActivityChanges(this.currentActivityForm.getRawValue());
                        break;
                    default:
                        break;
                }
            }
        }
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    addForm() {
        this._store.addForm();
        this._matDialog
            .open(SmartFormPickerDialogComponent)
            .afterClosed()
            .subscribe((result) => {
                if (result) {
                    this.currentActivityForm.patchValue({
                        title: result.name,
                        comments: result.comments,
                        formId: result.id,
                        formName: result.name,
                        feedStatus: result.feedStatus,
                    });
                }
            });
    }

    startWithActivityTemplate(event) {
        if (event instanceof MouseEvent) {
            event.stopPropagation();
        }
        this._matDialog
            .open(ReminderPickerDialogComponent)
            .afterClosed()
            .subscribe((result) => {
                if (result) {
                    if (!this.currentActivityForm.get("id").value) {
                        this._store.addActivity();
                    }
                    this.currentActivityForm.patchValue({
                        title: result.title,
                        comments: result.comments,
                        activityId: result.id,
                        feedStatus: result.feedStatus,
                    });
                }
            });
    }

    handleActivitySelection(activity: ActivityItemHistoryDto) {
        this.currentActivityForm.patchValue({
            title: activity.title,
            comments: activity.comments,
            recurrence: activity.recurrence,
        });
        let newActivity = assign(new ActivityItemDefinition(activity.recurrence), {
            title: activity.title,
            comments: activity.comments,
        });
        this._store.handleActivitySelection(newActivity);
    }

    addActivity() {
        this._store.addActivity();
    }

    editForm() {
        this._matDialog
            .open(SmartFormPickerDialogComponent)
            .afterClosed()
            .subscribe((result) => {
                if (result) {
                    this.currentActivityForm.patchValue({
                        title: result.title,
                        comments: result.comments,
                        formId: result.id,
                        formName: result.name,
                        feedStatus: result.feedStatus,
                    });
                }
            });
    }

    editActivity() {
        let activity: ActivityItemDefinition = assign(new ActivityItemDefinition(), {
            ...this.currentActivityForm.getRawValue(),
        });
        this.startActivityEdition(activity);
    }

    updateRecurrence(activity: FormItemDefinition | ActivityItemDefinition) {
        let ref = this._matDialog.open(RecurrencePickerDialogComponent, {
            data: {
                recurrence: activity.recurrence,
            },
        });

        ref.afterClosed().subscribe((recurrence: string | undefined) => {
            if (recurrence) {
                this.currentActivityForm.patchValue({ recurrence });
                activity.recurrence = recurrence;
                this._changeDetector.markForCheck();
            }
        });
    }

    parseRecurrenceText(activity: FormItemDefinition | ActivityItemDefinition) {
        return RecurrenceHelper.parseRecurrenceMessage(activity.recurrence, true);
    }

    acceptFormChanges(activity: FormItemDefinition) {
        let newActivity = assign(activity, {
            ...this.currentActivityForm.getRawValue(),
            form: {
                id: this.currentActivityForm.get("formId").value,
                name: this.currentActivityForm.get("formName").value,
            },
        });
        this._store.acceptFormChanges(newActivity);
    }

    acceptActivityChanges(activity: ActivityItemDefinition) {
        let newActivity = assign(activity, {
            ...activity,
            activityId: this.currentActivityForm.get("activityId").value,
            title: this.currentActivityForm.get("title").value,
            comments: this.currentActivityForm.get("comments").value,
            recurrence: this.currentActivityForm.get("recurrence").value,
            feedStatus: this.currentActivityForm.get("feedStatus").value,
            documentStatus: this.currentActivityForm.get("documentStatus").value,
        });
        this._store.acceptActivityChanges(newActivity);
    }

    cancelFormEdition(activity: FormItemDefinition) {
        this._store.cancelFormEdition(activity);
    }

    cancelItemEdition(activity: ActivityItemDefinition) {
        this._store.cancelItemEdition(activity);
    }

    startFormEdition(activity: FormItemDefinition) {
        assign(new FormItemDefinition(), {
            ...activity,
            form: {
                id: this.currentActivityForm.get("formId").value,
                name: this.currentActivityForm.get("formName").value,
            },
        });

        this._store.startFormEdition(activity);
    }

    startActivityEdition(activity: ActivityItemDefinition) {
        this._store.startActivityEdition(activity);
    }

    deleteForm(event: any, activity: FormItemDefinition) {
        event.stopPropagation();
        this._confirmationService
            .open({
                title: this._translateService.translate(
                    "health.engage.protocol-pool.create-protocol.form-deletion-alert-title"
                ),
                message: this._translateService.translate(
                    "health.engage.protocol-pool.create-protocol.form-deletion-alert-message",
                    { name: activity.title }
                ),
                actions: {
                    confirm: {
                        label: this._translateService.translate("actions.yes"),
                    },
                    cancel: {
                        label: this._translateService.translate("actions.no"),
                    },
                },
            })
            .afterClosed()
            .subscribe((result) => {
                if (result === "confirmed") {
                    this._store.deleteForm(activity);
                }
            });
    }

    deleteActivity(event: any, activity: ActivityItemDefinition) {
        event.stopPropagation();
        this._confirmationService
            .open({
                title: this._translateService.translate(
                    "health.engage.protocol-pool.create-protocol.reminder-deletion-alert-title"
                ),
                message: this._translateService.translate(
                    "health.engage.protocol-pool.create-protocol.reminder-deletion-alert-message",
                    { name: activity.title }
                ),
                actions: {
                    confirm: {
                        label: this._translateService.translate("actions.yes"),
                    },
                    cancel: {
                        label: this._translateService.translate("actions.no"),
                    },
                },
            })
            .afterClosed()
            .subscribe((result) => {
                if (result === "confirmed") {
                    this._store.deleteActivity(activity);
                }
            });
    }

    save() {
        if (this.form.valid) {
            let data = this.form.getRawValue();
            this._store.save(data);
        }
    }

    closeDialog() {
        this._dialogRef.close();
    }
}
