import {Notification} from "./notification.model";
import {Injector} from "@angular/core";
import {AppConsts} from "@shared/AppConsts";
import {Router} from "@angular/router";
import {Location} from "@angular/common";
import {LocalizationService} from "abp-ng2-module";

export class NotificationParser
{
    protected localizationSourceName = AppConsts.localization.defaultLocalizationSourceName;

    protected localization: LocalizationService;

    protected router: Router;

    protected location: Location
    
    constructor(injector: Injector)
    {
        this.localization = injector.get(LocalizationService);
        this.router = injector.get(Router);
        this.location = injector.get(Location);
    }

    protected l(key: string, ...args: any[]): string {
        let localizedText = this.localization.localize(key, this.localizationSourceName);

        if (!localizedText) {
            localizedText = key;
        }

        if (!args || !args.length) {
            return localizedText;
        }

        args.unshift(localizedText);
        return abp.utils.formatString.apply(this, args);
    }
    
    parse(userNotification) : Notification {
        return null;
    }
    
    open(notification: Notification) {
        
    }
    
}