import { NgModule } from "@angular/core";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { CareUnitHttpInterceptor } from "./care-unit-http-interceptor";
import { HttpConfigurationService } from "./http-configuration.service";
import { AbpHttpInterceptor } from "./abp-http-interceptor";

@NgModule({
    providers: [
        HttpConfigurationService,
        {provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: CareUnitHttpInterceptor, multi: true},
    ],
})
export class ApiCommonModule {
}
