/* eslint-disable */
import { Inject, Injectable, InjectionToken } from "@angular/core";
import { CustomCookieService } from "./custom-cookie.service";

export const SESSION_COOKIE_NAME = new InjectionToken<string>("SESSION_COOKIE_NAME");

@Injectable({
    providedIn: "root",
})
export class TokenService {

    _token: string | undefined;

    constructor(@Inject(SESSION_COOKIE_NAME) private cookieName: string, 
                private _cookieService: CustomCookieService) {
        this._token = this._cookieService.get(this.cookieName);
    }

    getToken(): string {
        return this._token ?? "";
    }

    clearToken(): void {
        this._cookieService.delete(this.cookieName);
        this._token = undefined;
    }

    setToken(authToken: string, expireDate?: Date): void {
        this._token = authToken;
        this._cookieService.set(this.cookieName, authToken, 180);
    }

    getClaim(name: string) {
        if (!this._token) {
            return null;
        }
        let base64Url = this._token.split(".")[1];
        let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        let jsonPayload = decodeURIComponent(atob(base64).split("").map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(""));
        let payload = JSON.parse(jsonPayload);
        return payload[name];
    }
}
