/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { on } from "@ngrx/store";
import _ from "lodash";
import { ApiActions } from "./apiActions";

export const createApiActionReducers = <
    ActionName extends string,
    EntityNameSingular extends string,
    Props extends object,
>(
    actions: ApiActions<ActionName, EntityNameSingular, Props>,
    actionName: ActionName,
) => {
    const clientAction = actions.execute;
    const apiSuccessReturnAction = actions.complete;
    const apiErrorReturnAction = actions.failure;
    let reducers = [];
    if (clientAction) {
        reducers.push(
            on(clientAction, (state: any, errProps) => {
                let loading = _.cloneDeep(state.loading);
                loading[actionName] = true;
                return {
                    ...state,
                    loading: loading,
                    error: undefined
                };
            }),
        );
    }
    if (apiSuccessReturnAction) {
        reducers.push(
            on(apiSuccessReturnAction, (state: any) => {
                let loading = _.cloneDeep(state.loading);
                loading[actionName] = false;
                return {
                    ...state,
                    loading: loading,
                    error: undefined
                };
            }),
        );
    }
    if (apiErrorReturnAction) {
        reducers.push(
            on(apiErrorReturnAction, (state: any, errProps) => {
                let loading = _.cloneDeep(state.loading);
                loading[actionName] = false;
                return {
                    ...state,
                    loading: loading,
                    error: errProps
                };
            }),
        );
    }
    return reducers as unknown as any[];
};
