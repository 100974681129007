<ng-container *transloco="let root">
    <ng-container *transloco="let t; read: 'health.engage.reminder-pool.reminder-picker'">
        <div class="flex flex-col w-full h-full" [ngClass]="classes">
            <div class="flex flex-0 items-center justify-between h-16 pl-3 pr-2 text-default border-b shadow-sm">
                <span>{{ t("select-reminder") }}</span>

                <button mat-icon-button [tabIndex]="-1">
                    <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x'" mat-dialog-close></mat-icon>
                </button>
            </div>

            <div cdkTrapFocus [cdkTrapFocusAutoCapture]="true" class="flex-grow w-full overflow-hidden relative">
                <reminder-picker (onSelected)="handleSelection($event)" [selected]="selected"></reminder-picker>
            </div>

            <!-- Actions -->
            <div
                class="flex flex-col sm:flex-row sm:items-center justify-end py-4 px-6 border-t shadow bg-gray-50 space-y-4 sm:space-y-0 sm:space-x-4"
            >
                <button mat-stroked-button mat-dialog-close>
                    {{ root("actions.cancel") }}
                </button>
                <button [disabled]="!selected" mat-flat-button (click)="save()" color="primary">
                    {{ root("actions.use-template") }}
                </button>
            </div>
        </div>
    </ng-container>
</ng-container>
