import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import {
    selectScheduledExecuted,
    selectServiceDuration,
    selectIsLoading,
} from "./selectors";
import { GetScheduledExecutedResult, GetServiceDurationResult } from "@dashboard-statistics";
import { IAppointmentStatState } from "./model";

@Injectable({
    providedIn: "root",
})
export class AppointmentStatsFacade {
    isLoading$: Observable<boolean> = this._store.select(selectIsLoading);

    scheduledExecuted$: Observable<GetScheduledExecutedResult> = this._store.select(selectScheduledExecuted);

    serviceDuration$: Observable<GetServiceDurationResult> = this._store.select(selectServiceDuration);

    constructor(private _store: Store<IAppointmentStatState>) {
    }
}
