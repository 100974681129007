import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { TranslocoModule } from "@ngneat/transloco";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDividerModule } from "@angular/material/divider";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatOptionModule } from "@angular/material/core";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { VuiModule } from "@vui";
import { MatDialogModule } from "@angular/material/dialog";
import { UiDialogModule } from "@ui/ui-dialog";
import { MatInputModule } from "@angular/material/input";
import { A11yModule } from "@angular/cdk/a11y";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { ButtonToggleModule, FuseScrollbarModule } from "@nx-workspace/fuse";
import { MatMenuModule } from "@angular/material/menu";
import { SmartFormFeedComponent } from "./activity-smart-form-feed.component";
import { CreateProtocolComponent } from "./create-protocol/create-template.component";
import { StoreModule } from "@ngrx/store";
import * as fromStore from "./store";
import { EffectsModule } from "@ngrx/effects";
import { SmartFormEvolutionComponent } from "./smart-form-evolution/smart-form-evolution.component";
import { MatTooltipModule } from "@angular/material/tooltip";
import { NameDialogModule, FileNameDialogModule } from "@vui";
import { FormDefinitionClient, FormFolderClient } from "../api";
import { EngagePublicModule } from "./public/public.module";
import { FormPoolSharedModule } from "../form-pool/form-pool-shared.module";
import { ProtocolPoolSharedModule } from "../protocol-pool/protocol-pool.shared.module";
import { ReminderPoolSharedModule } from "../reminder-pool/reminder-pool-shared.module";
import { FeedFacade } from "./store/facade";
import { ScrollingModule } from "@angular/cdk/scrolling";

@NgModule({
    declarations: [SmartFormFeedComponent, CreateProtocolComponent, SmartFormEvolutionComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        StoreModule.forFeature(fromStore.FEED_FEATURE_KEY, fromStore.getFeedReducer),
        EffectsModule.forFeature([fromStore.FeedEffects]),

        HttpClientModule,
        TranslocoModule,

        EngagePublicModule,
        FormPoolSharedModule,
        ProtocolPoolSharedModule,

        MatButtonModule,
        MatTooltipModule,
        MatDividerModule,
        MatFormFieldModule,
        MatIconModule,
        MatOptionModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatSelectModule,
        MatSidenavModule,
        MatSortModule,
        MatTableModule,
        MatDialogModule,
        MatInputModule,
        MatCheckboxModule,
        ScrollingModule,

        UiDialogModule,
        A11yModule,
        ButtonToggleModule,
        MatMenuModule,
        MatTooltipModule,
        FuseScrollbarModule,
        VuiModule,

        NameDialogModule,
        FileNameDialogModule,

        ReminderPoolSharedModule,
    ],
    providers: [FormFolderClient, FormDefinitionClient, CreateProtocolComponent, FeedFacade],
    exports: [SmartFormFeedComponent, SmartFormEvolutionComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class HealthEngageFeedModule {}
