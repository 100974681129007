import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { FuseProgressBarService } from "@fuse/components/progress-bar/progress-bar.service";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { finalize } from "rxjs/operators";
import { ExecuteFormRequest, GetFormItemFormResponse, PatientClient } from "../../../api";
import { FuseConfigService } from "@fuse/services/config.service";
import { HealthEngageCommunicationService } from "../../../health-engage-communication.service";

declare global {
    interface Window {
        MobileApi: any;
    }
}

@Component({
    selector: "smart-form-collect",
    templateUrl: "./smart-form-collect.component.html",
})
export class SmartFormCollectComponent implements OnInit, OnDestroy {
    itemId: number;

    timestamp: number;

    formId: string;

    form: any;

    value: any = {};

    constructor(
        private activeRoute: ActivatedRoute,
        private _router: Router,
        private _progressBarService: FuseProgressBarService,
        private _api: PatientClient,
        private _cdr: ChangeDetectorRef,
        private _fuseConfigService: FuseConfigService,
        private _healthEngageCommunicationService: HealthEngageCommunicationService
    ) {
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true,
                },
                toolbar: {
                    hidden: true,
                },
                footer: {
                    hidden: true,
                },
                sidepanel: {
                    hidden: true,
                },
            },
        };
    }

    ngOnInit(): void {
        this._progressBarService.show();
        this._healthEngageCommunicationService.emitChatVisibility(false);
        this.activeRoute.paramMap.subscribe((params: ParamMap) => {
            this.itemId = Number(params.get("itemId"));
            this.formId = params.get("formId");
            this.timestamp = Number(params.get("timestamp"));
            this._api
                .getForm(this.itemId, Number(this.formId), this.timestamp)
                .pipe(
                    finalize(() => {
                        this._progressBarService.hide();
                    })
                )
                .subscribe(
                    (result: GetFormItemFormResponse) => {
                        if (!result.allowAnswer) {
                            window.MobileApi?.postMessage("[form] locked");
                            this._router
                                .navigate(["locked"], {
                                    relativeTo: this.activeRoute,
                                    skipLocationChange: false,
                                    replaceUrl: true,
                                })
                                .then();
                        }
                        this.form = result;
                        this._cdr.detectChanges();
                    },
                    (error) => {
                        window.MobileApi?.postMessage("[form] locked");
                        this._router
                            .navigate(["locked"], {
                                relativeTo: this.activeRoute,
                                skipLocationChange: false,
                                replaceUrl: true,
                            })
                            .then();
                    }
                );
        });
    }

    onCancel() {
        window.MobileApi?.postMessage("[form] canceled");
        this._router
            .navigate(["canceled"], { relativeTo: this.activeRoute, skipLocationChange: false, replaceUrl: true })
            .then();
    }

    onSave(data: string) {
        let request: ExecuteFormRequest = {
            response: data,
        };
        this._progressBarService.show();
        this._api
            .executeFormItem(this.itemId, this.timestamp, request, this.formId)
            .pipe(
                finalize(() => {
                    this._progressBarService.hide();
                })
            )
            .subscribe((responseId) => {
                window.MobileApi?.postMessage(`[form] completed:${responseId}`);
                this._router
                    .navigate(["completed"], {
                        relativeTo: this.activeRoute,
                        skipLocationChange: false,
                        replaceUrl: true,
                    })
                    .then();
            });
    }

    ngOnDestroy(): void {
        this._healthEngageCommunicationService.emitChatVisibility(true);
    }
}
