import { Injectable } from "@angular/core";
import { TourService } from "ngx-ui-tour-md-menu";
import {
    DRAFTS_TOUR_NAME,
    DraftsTour,
    MEDICAL_RECORD_TOUR_NAME,
    MedicalRecordTour,
    PERMISSION_TOUR_NAME,
    PermissionTour,
    VIDAAS_SIGNATURE_TOUR_NAME,
    VTour,
    VidaasSignatureTour,
} from "./tour-steps";
import { TranslocoService } from "@ngneat/transloco";
import { UserServiceProxy } from "@shared/service-proxies/service-proxies";
import { startOfTomorrow } from "date-fns";
import { CookieService } from "ngx-cookie-service";
import { AppSessionService } from "@shared/session/app-session.service";
import { FuseNavigationService } from "@fuse/components/navigation/navigation.service";

export type TourProps = {
    markTourAsVisualized?: boolean;
};

@Injectable({ providedIn: "root" })
export class VTourService {
    currentTour: VTour;

    markTourAsVisualized = true;

    nameToTourMap = new Map<string, VTour>();

    constructor(
        private tourService: TourService,
        _translocoService: TranslocoService,
        private _userService: UserServiceProxy,
        private _cookieService: CookieService,
        private _appSession: AppSessionService,
        private _fuseNavigationService: FuseNavigationService
    ) {

        this.tourService.stepShow$.pipe().subscribe((value) => {
            if (this._fuseNavigationService.getNavigationItem(value.stepId)) {
                this._fuseNavigationService.onExpandById.next(value.stepId);
            }
        });

        this.tourService.end$.subscribe(() => {
            if (this.currentTour) {
                this._userService.markTourAsVisualized(this.currentTour.TOUR_NAME).subscribe();
            }
            this.currentTour = null;
        });
    }

    start(anchorId?: string) {
    }

    initializeWithName(tourName: string) {
    }

    endWithProps(props: TourProps) {
        if (!this.currentTour && !this.currentTour.TOUR_NAME) {
            //HACK: this is a hack to avoid errors when the tour does not initiallize properly
            this.currentTour = null;
            this.tourService.end();
            return;
        }
        if (props.markTourAsVisualized) {
            this._userService.markTourAsVisualized(this.currentTour?.TOUR_NAME).subscribe();
        } else {
            this._cookieService.set(this.currentTour?.TOUR_NAME, "true", startOfTomorrow());
        }

        this.currentTour = null;
        this.tourService.end();
    }
}
