import { createAction, props } from "@ngrx/store";
import { PrescriptionStatItem } from "@dashboard-statistics";

export const getStats = createAction("[Stats Documents] getStats", props<{
    start: Date,
    end: Date,
    tenantId: number | undefined
}>());

export const getStatsComplete = createAction(
    "[Stats Documents] getStatsComplete",
    props<{ prescription: PrescriptionStatItem }>()
);

export const getStatsError = createAction("[Stats Documents] getStatsError", props<{ error: any }>());

export const getPrescriptionHistory = createAction("[Stats Documents] getPrescriptionHistory");

export const getPrescriptionHistoryComplete = createAction(
    "[Stats Documents] getPrescriptionHistoryComplete",
    props<{ prescriptionHistory: any[] }>()
);

export const getPrescriptionHistoryError = createAction("[Stats Documents] getPrescriptionHistoryError", props<{ error: any }>());
