import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {AppConsts} from "@shared/AppConsts";
import {HttpBackend, HttpClient, HttpHeaders} from "@angular/common/http";

export class RegisterPaymentProfileRequest {
    holder_name: string | undefined;
    card_expiration: string | undefined;
    card_number: string;
    card_cvv: string;
    payment_method_code: string;
    payment_company_code: string | undefined;
}

export class PaymentProfile {
    gateway_token: string | undefined;
    init(data?: any) {
        if (data) {
            this.gateway_token = data["gateway_token"];
        }
    }

    static fromJS(data: any): PaymentProfile {
        data = typeof data === 'object' ? data : {};
        let result = new PaymentProfile();
        result.init(data);
        return result;
    }
}

export class RegisterPaymentProfileResponse {
    profile: PaymentProfile | undefined;

    init(data?: any) {
        if (data) {
            this.profile = data["payment_profile"] ? PaymentProfile.fromJS(data["payment_profile"]) : <any>undefined;
        }
    }

    static fromJS(data: any): RegisterPaymentProfileResponse {
        data = typeof data === 'object' ? data : {};
        let result = new RegisterPaymentProfileResponse();
        result.init(data);
        return result;
    }
}

@Injectable()
export class VindiService {
    
    key = AppConsts.VindiToken;
    remoteServiceBaseUrl = AppConsts.VindiUrl;
    
    protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;
    
    constructor(private _httpClient: HttpClient, handler: HttpBackend ) {
        this._httpClient = new HttpClient(handler);
    }
    
    registerCreditCardPaymentProfile(holderName: string, expiration: string, number: string, cvv: string, flag: string): Observable<RegisterPaymentProfileResponse> {
        let url = this.remoteServiceBaseUrl + '/public/payment_profiles';
        let request = new RegisterPaymentProfileRequest();
        request.holder_name = holderName;
        request.card_cvv = cvv;
        request.card_expiration = expiration;
        request.card_number = number;
        request.payment_company_code = flag;
        request.payment_method_code = "credit_card";
        return new Observable((observer) => {
            const httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type':  'application/json',
                    Authorization: "Basic " + btoa(this.key + ":")
                })
            };
            
            this._httpClient.post<RegisterPaymentProfileRequest>(url, request, httpOptions)
                .subscribe((data) => {
                    let result = new RegisterPaymentProfileResponse();
                    result.init(data);
                    observer.next(result);
                    observer.complete();
                }, error => {
                    observer.error(new Error("Ocorreu um erro ao processar o pagamento. Por favor, verifique os dados e tente novamente."));
                    observer.complete();
                });
            return () => {
                // Detach the event handler from the target
                //target.removeEventListener(eventName, handler);
            };
        });
    }
}
