import { createAction, props } from "@ngrx/store";

export const setPeriod = createAction("[Stats Sessions] setPeriod", props<{ period: number }>());

export const getInactiveClients = createAction("[Stats Sessions] getInactiveClients", props<{ sinceDate: Date | undefined }>());

export const getInactiveClientsComplete = createAction(
    "[Stats Sessions] getInactiveClientsComplete",
    props<{ items: any[] }>()
);

export const getInactiveClientsError = createAction("[Stats Sessions] getInactiveClientsError", props<{ error: any }>());

export const exportInactiveClients = createAction("[Stats Sessions] exportInactiveClients", props<{ sinceDate: Date | undefined }>());

export const exportInactiveClientsComplete = createAction("[Stats Sessions] exportInactiveClientsComplete");

export const exportInactiveClientsError = createAction("[Stats Sessions] exportInactiveClientsError", props<{ error: any }>());