import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Subject } from "rxjs";
import { filter, take, takeUntil } from "rxjs/operators";
import { FuseConfigService } from "@fuse/services/config.service";
import { FuseNavigationService } from "@fuse/components/navigation/navigation.service";
import { FusePerfectScrollbarDirective } from "@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive";
import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";
import {
    CacheMobileAppInfo,
    ChangeLeftMenuVisibilityInput,
    ConfigurationServiceProxy,
    WizardServiceProxy,
} from "@shared/service-proxies/service-proxies";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { AppSessionService } from "@shared/session/app-session.service";
import { Change, ChangelogService } from "@app/wapps/changelog/changelog.service";
import { FuseProgressBarService } from "@fuse/components/progress-bar/progress-bar.service";
import { assign } from "lodash";

@Component({
    selector: "navbar-vertical-style-1",
    templateUrl: "./style-1.component.html",
    styleUrls: ["./style-1.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class NavbarVerticalStyle1Component implements OnInit, OnDestroy {
    fuseConfig: any;
    fusePerfectScrollbarUpdateTimeout: any;
    navigation: any;

    // Private
    private _fusePerfectScrollbar: FusePerfectScrollbarDirective;
    private _unsubscribeAll: Subject<any>;

    application: CacheMobileAppInfo;

    applicationIcon: SafeUrl;

    tenantName: string = "vSaúde";

    leftMenuCollapsed: boolean;

    onChangelogRoute: boolean = false;

    lastVisualizedVersion: string;

    lastVersion: Change;

    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _router: Router,
        private _wizardService: WizardServiceProxy,
        private _sessionService: AppSessionService,
        private _configurationService: ConfigurationServiceProxy,
        private _sanitizer: DomSanitizer,
        private _changelogService: ChangelogService,
        private _progressBarService: FuseProgressBarService
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this.application = new CacheMobileAppInfo();
    }

    // Directive
    @ViewChild(FusePerfectScrollbarDirective)
    set directive(theDirective: FusePerfectScrollbarDirective) {
        if (!theDirective) {
            return;
        }

        this._fusePerfectScrollbar = theDirective;

        // Update the scrollbar on collapsable item toggle
        this._fuseNavigationService.onItemCollapseToggled.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
            this.fusePerfectScrollbarUpdateTimeout = setTimeout(() => {
                this._fusePerfectScrollbar.update();
            }, 310);
        });

        // Scroll to the active item position
        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                take(1)
            )
            .subscribe(() => {
                setTimeout(() => {
                    const activeNavItem: any = document.querySelector("navbar .nav-link.active");

                    if (activeNavItem) {
                        const activeItemOffsetTop = activeNavItem.offsetTop,
                            activeItemOffsetParentTop = activeNavItem.offsetTop,
                            scrollDistance = activeItemOffsetTop - activeItemOffsetParentTop - 48 * 3 - 168;

                        this._fusePerfectScrollbar.scrollToTop(scrollDistance);
                    }
                });
            });
    }

    ngOnInit(): void {
        this._changelogService.getChangelog()
            .subscribe((value) => {
                this.lastVersion = assign(new Change(), value[0]);
            });

        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe((event: NavigationEnd) => {
                if (this._fuseSidebarService.getSidebar("main-navbar")) {
                    this._fuseSidebarService.getSidebar("main-navbar").close();
                }
                this.onChangelogRoute = event.url === "/changelog";

                if (this.onChangelogRoute) {
                    if (this.lastVisualizedVersion !== this.lastVersion.version) {
                        this.lastVisualizedVersion = this.lastVersion.version;
                        this._changelogService.updateLastDisplayedVersion(this.lastVisualizedVersion).subscribe();
                    }
                }
            });

        // Subscribe to the config changes
        this._fuseConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((config) => {
            this.fuseConfig = config;
        });

        // Get current navigation
        this._fuseNavigationService.onNavigationChanged
            .pipe(
                filter((value) => value !== null),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                this.navigation = this._fuseNavigationService.getCurrentNavigation();
            });

        this._wizardService.getMobileAppInfo().subscribe((result) => {
            this.application = result;
            if (this._sessionService.tenant !== undefined) {
                this.tenantName = this._sessionService.tenant.name;
            }
            this.applicationIcon = this._sanitizer.bypassSecurityTrustUrl(result.iconImage);
        });

        this._sessionService.onTenantInfoChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe((tenantInfo) => {
            if (!!tenantInfo) {
                setTimeout(() => {
                    this.tenantName = tenantInfo.name;
                }, 50);
            }
        });

        this.leftMenuCollapsed = abp.setting.getBoolean("LeftMenuCollapsed");

        if (!this.leftMenuCollapsed) {
            this._fuseSidebarService.getSidebar("main-navbar").unfold();
        }

        this._fuseSidebarService
            .getSidebar("main-navbar")
            .openedChanged.pipe(takeUntil(this._unsubscribeAll))
            .subscribe((opened) => {
                if (opened && !this.leftMenuCollapsed) {
                    setTimeout(() => {
                        this._fuseSidebarService.getSidebar("main-navbar").unfold();
                    }, 250);
                }
            });
    }

    ngOnDestroy(): void {
        if (this.fusePerfectScrollbarUpdateTimeout) {
            clearTimeout(this.fusePerfectScrollbarUpdateTimeout);
        }

        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    toggleSidebarOpened(): void {
        this._fuseSidebarService.getSidebar("main-navbar").toggleOpen();
    }

    toggleSidebarFolded(): void {
        this._fuseSidebarService.getSidebar("main-navbar").toggleFold();
        this.leftMenuCollapsed = !this.leftMenuCollapsed;
        this._configurationService
            .changeLeftMenuVisibility(
                ChangeLeftMenuVisibilityInput.fromJS({
                    collapsed: this._fuseSidebarService.getSidebar("main-navbar").folded,
                })
            )
            .subscribe(() => {
            });
    }

    public get image() {
        return this.applicationIcon;
    }

    redirectToChangelog() {
        this._router.navigate(["changelog"]).then();
    }

    get collapsed() {
        return this._fuseSidebarService.getSidebar("main-navbar") && this._fuseSidebarService.getSidebar("main-navbar").folded && !this._fuseSidebarService.getSidebar("main-navbar").unfolded;
    }
}
