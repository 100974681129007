import { Injectable } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { BehaviorSubject } from 'rxjs';
import {
    AuthenticateMobileUserModel,
    AuthenticateModel,
    AuthenticateResultModel, ImpersonateInput,
    TokenAuthServiceProxy
} from "@shared/service-proxies/service-proxies";
import {Router} from "@angular/router";
import {AbpSessionService, UtilsService} from 'abp-ng2-module';
import { MessageService } from 'abp-ng2-module';
import {TokenService} from 'abp-ng2-module';
import {LogService} from 'abp-ng2-module';
import {UrlHelper} from "@shared/helpers/UrlHelper";
import {SignalRAspNetCoreHelper} from "@shared/helpers/SignalRAspNetCoreHelper";

@Injectable({
    providedIn: 'root',
})
export class AppAuthService {

    onLogout: BehaviorSubject<boolean>;

    onLogin: BehaviorSubject<boolean>;

    constructor(
        private _tokenAuthService: TokenAuthServiceProxy,
        private _router: Router,
        private _utilsService: UtilsService,
        private _messageService: MessageService,
        private _tokenService: TokenService,
        private _logService: LogService,
        private _abpSessionService: AbpSessionService) {
        let encryptedAuthToken = this._abpSessionService.userId;
        let sessionStarted = !!encryptedAuthToken;
        this.onLogout = new BehaviorSubject(!sessionStarted);
        this.onLogin = new BehaviorSubject(sessionStarted);

    }

    authenticate(model:AuthenticateModel, redirectUrl: string = UrlHelper.initialUrl): Promise<any> {
        return new Promise((resolve, reject) => {
            this._tokenAuthService
                .authenticate(model)
                .subscribe((result: AuthenticateResultModel) => {
                    this.processAuthenticateResult(result, model.rememberClient, redirectUrl);
                    resolve(true);
                }, error => {
                    reject(error);
                });
        });
    }

    impersonate(model:ImpersonateInput, redirectUrl: string = UrlHelper.initialUrl): Promise<any> {
        return new Promise((resolve, reject) => {
            this._tokenAuthService
                .impersonate(model)
                .subscribe((result: AuthenticateResultModel) => {
                    this.processAuthenticateResult(result, false, redirectUrl);
                    resolve(false);
                }, error => {
                    reject(error);
                });
        });
    }

    authenticateMobileUser(model:AuthenticateMobileUserModel, redirectUrl: string = UrlHelper.initialUrl): Promise<any> {
        return new Promise((resolve, reject) => {
            this._tokenAuthService
                .authenticateMobileUserInTenant(model)
                .subscribe((result: AuthenticateResultModel) => {
                    this.processAuthenticateResult(result, false, redirectUrl);
                    resolve(true);
                }, error => {
                    reject(error);
                });
        });
    }

    public processAuthenticateResult(authenticateResult: AuthenticateResultModel, rememberMe:boolean, redirectUrl: string = UrlHelper.initialUrl) {
        if (authenticateResult.accessToken) {
            this.login(authenticateResult.accessToken, authenticateResult.encryptedAccessToken, authenticateResult.expireInSeconds, redirectUrl, rememberMe);
        } else {
            this._logService.warn('Unexpected authenticateResult!');
            this._router.navigate(['/login']);
        }
    }

    private login(accessToken: string, encryptedAccessToken: string, expireInSeconds: number, redirectUrl:string, rememberMe?: boolean): void {
        let tokenExpireDate = rememberMe ? (new Date(new Date().getTime() + 1000 * expireInSeconds)) : undefined;
        this._tokenService.setToken(
            accessToken,
            tokenExpireDate
        );
        localStorage.setItem('session_data', JSON.stringify({accessToken: accessToken, encryptedAccessToken: encryptedAccessToken, expireDate: tokenExpireDate}));

        this._utilsService.setCookieValue(
            AppConsts.authorization.encryptedAuthTokenName,
            encryptedAccessToken,
            tokenExpireDate,
            abp.appPath
        );

        let initialUrl = redirectUrl;
        if (initialUrl.indexOf('/login') > 0) {
            initialUrl = AppConsts.appBaseUrl;
        }
        SignalRAspNetCoreHelper.initSignalR();
        location.href = initialUrl;
        this.onLogin.next(true);
        this.onLogout.next(false);
    }

    logout(reload?: boolean, url?: string): void {
        abp.auth.clearToken();
        this.onLogout.next(true);
        this.onLogin.next(false);
        localStorage.removeItem("session_data");
        localStorage.removeItem("accessToken");
        setTimeout(() => {
            if (reload !== false) {
                location.href = url ? url : AppConsts.appBaseUrl;
            }
        });

    }

    getClaim(name: string) {
        if(!this._tokenService.getToken()) {
            return null;
        }
        let base64Url = this._tokenService.getToken().split('.')[1];
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        let payload = JSON.parse(jsonPayload);
        return payload[name];
    }
}
