import {EventEmitter, Inject, Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserServiceProxy} from '@shared/service-proxies/service-proxies';
import { AppSessionService } from '@shared/session/app-session.service';
import { AppAuthService } from '@shared/auth/app-auth.service';
import {ChatService, ChatServiceToken} from "@ngx-chat/lib/services/chat-service";
import {LogInRequest} from "@ngx-chat/lib/core/log-in-request";
import {Contact} from "@ngx-chat/lib/core/contact";
import { AppConsts } from '@shared/AppConsts';
import {TokenService} from "abp-ng2-module";

@Injectable()
export class XmppChatService {

    selectedContactChanged: EventEmitter<Contact>;
    
    selectedContact: Contact;
    
    xmppJwt: string;
    
    getPassowrd() {
        let base64Url = this._tokenService.getToken().split('.')[1];
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        let payload = JSON.parse(jsonPayload);
        return payload.XJWT;
    }
    
    constructor(
        private _httpClient: HttpClient,
        public _userProxy: UserServiceProxy,
        private _appSession: AppSessionService,
        private _appAuth: AppAuthService,
        private _tokenService: TokenService,
        @Inject(ChatServiceToken) public chatService: ChatService) {
        
        this.selectedContactChanged = new EventEmitter();
        this._appAuth.onLogin.subscribe((value) => {
            if (value && AppConsts.ChatEnabled) {
                this._userProxy.getChatConnectionInfo().subscribe((result) => {
                    this.xmppJwt = result.password;
                    const logInRequest: LogInRequest = {
                        domain: result.domain,
                        service: result.service,
                        password: result.password,
                        username: result.user
                    };
                    this.chatService.logIn(logInRequest);
                }, (error) => {

                });
            }
        });
        
        this._appAuth.onLogout.subscribe((value) => {
            if (value) {
                this.chatService.logOut();
                // this.end();
                //this.signOut().then();
            }
        });
    }
    
    selectContact(contact: Contact) {
        if (contact === null || this.selectedContact && this.selectedContact.equalsBareJid(contact)) {
            this.selectedContact = null;
        } else {
            this.selectedContact = contact;
        }
        this.selectedContactChanged.emit(this.selectedContact);
    }
    
}

