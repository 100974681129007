import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from "@angular/router";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class FeatureGuard implements CanActivate {
    constructor(private router: Router) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return new Promise((resolve, reject) => {
            let features = route.data.features as Array<string>;

            if (!features || features.length === 0) {
                resolve(false);
            }

            for (let feature of features) {
                if (abp.features.isEnabled(feature)) {
                    resolve(true);
                }
            }

            resolve(false);
        }).then((value) => {
            if (!value) {
                return this.router.navigate(["/patient"]).then();
            }
            return true;
        }).catch();
    }
}
