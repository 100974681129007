<div class="absolute inset-0 flex flex-col overflow-hidden">
    <div class="flex flex-row w-full items-center place-content-between p-4">
        <span class="font-bold">{{ title }}</span>
        <button mat-icon-button (click)="onCancel()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div class="flex-grow w-full px-4 overflow-y-auto">
        <div class="flex flex-col mb-6 space-y-2">
            <div class="text-hint">{{ description }}</div>
        </div>
        <div class="flex w-full p-4 place-content-between">
            <mat-form-field class="w-full">
                <mat-label>Comentários</mat-label>
                <textarea
                    matInput
                    [(ngModel)]="comments"
                    name="comments"
                    cdkTextareaAutosize
                    cdkAutosizeMinRows="3"
                ></textarea>
            </mat-form-field>
        </div>
    </div>
    <div class="flex justify-between w-full p-4">
        <button mat-button (click)="onCancel()">Cancelar</button>
        <button mat-button color="primary" (click)="save()">Salvar</button>
    </div>
</div>
