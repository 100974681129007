import {
    HttpClient,
    HttpBackend,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UserServiceProxy } from "@shared/service-proxies/service-proxies";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { map } from "rxjs/operators";
import { assign } from "lodash";

export class Change {
    date: Date;
    version: string;
    src: string;
    summary: string;
}

interface ChangelogItem {
    version: string;
    date: string;
}

@Injectable()
export class ChangelogService {
    http: HttpClient;

    lastVisualizedVersion: string;

    storageUrl: string = environment.blob_url;

    constructor(
        private _userApi: UserServiceProxy,
        handler: HttpBackend,
    ) {
        this.http = new HttpClient(handler);
    }

    getChangelog(): Observable<Change[]> {
        return this.http.get<ChangelogItem[]>(`${this.storageUrl}/vsaude-admin-changelog/changelog.json`)
            .pipe(map(i => i.map(version => assign(new Change(), {
                version: version.version,
                date: version.date,
                src: `${this.storageUrl}/vsaude-admin-changelog/${version.version}.md`,
                summary: `${this.storageUrl}/vsaude-admin-changelog/${version.version}-summary.md`,
            }))));
    }

    getLastDisplayedVersion() {
        return this._userApi.getChangelogLastDisplayedVersion().subscribe((value) => {
            this.lastVisualizedVersion = value;
        });
    }

    updateLastDisplayedVersion(version) {
        return this._userApi.updateChangelogLastDisplayedVersion(version);
    }
}
