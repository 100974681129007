<div class="w-full flex justify-center items-center h-full">
    <div class="h-full w-1/2 flex-grow container">
        <form-collect
            (onSave)="onSave($event)"
            (onCancel)="onCancel()"
            [definition]="form.definition"
            [value]="value"
            *ngIf="form"
        ></form-collect>
    </div>
</div>
