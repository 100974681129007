<ng-container *transloco="let t; read: 'stats.filter-period'">
  <div class="flex flex-row space-x-4 place-content-end">
    <mat-form-field class="w-72 fuse-mat-no-subscript">
      <mat-select [value]="selected | async" placeholder="Filtrar"
                  (selectionChange)="selectPeriod($event.value)"
                  [compareWith]="compareFilter">
        <mat-option [value]="r" *ngFor="let r of recommendedPeriods">{{t(r.id)}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="w-72 fuse-mat-no-subscript">
      <mat-select [value]="selectedTenant | async" placeholder="Filtrar por cliente"
                  (selectionChange)="selectClient($event.value)"
                  [compareWith]="compareTenant">
        <mat-option>
          <ngx-mat-select-search placeholderLabel="Pesquise o cliente" [formControl]="filterCtrl">
            <div ngxMatSelectNoEntriesFound class="flex justify-between">
              <span>Nenhum registro encontrado</span>
              <mat-progress-spinner *ngIf="loading$ | async" mode="indeterminate" [diameter]="32"
                                    color="primary"></mat-progress-spinner>
            </div>
          </ngx-mat-select-search>
        </mat-option>

        <mat-option *ngFor="let item of datasource$ | async" [value]="item">
          {{ item.name }}
        </mat-option>

        <ng-container *ngIf="!!(selectedTenant | async)">
          <mat-option [value]="null">
            Limpar
          </mat-option>
        </ng-container>
        
      </mat-select>
    </mat-form-field>
  </div>
</ng-container>